import { Typography } from '@mui/material';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import HelpIcon from '@watershed/icons/components/Help';
import PrivateIcon from '@watershed/icons/components/Private';
import { useEffect } from 'react';
import PageContainer from '@watershed/shared-frontend/components/PageContainer';

interface Props {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  Icon?: typeof HelpIcon | typeof PrivateIcon;
}

export function NotFoundPageBody({
  title = 'Page not found',
  subtitle,
  Icon = HelpIcon,
}: Props) {
  useEffect(() => {
    Analytics.error('notFoundPageShown');
  }, []);

  return (
    <>
      <Icon size={128} marginBottom={1} />
      <Typography variant="h1" align="center" paragraph>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body2" align="center">
          {subtitle}
        </Typography>
      )}
    </>
  );
}

export default function NotFoundPage({ title, subtitle, Icon }: Props) {
  return (
    <PageContainer
      isFullPage={false}
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 64,
      }}
    >
      <NotFoundPageBody title={title} subtitle={subtitle} Icon={Icon} />
    </PageContainer>
  );
}

export function ViewerNoPermissionPage({ orgName }: { orgName: string }) {
  return (
    <NotFoundPage
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
      title="Unable to view"
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
      subtitle={`You don’t have access to view this page. Reach out to an administrator at ${orgName} to request access to the Watershed dashboard.`}
      Icon={PrivateIcon}
    />
  );
}
