import isNotNullish from './isNotNullish';

export type TConnectionType<T> = { edges: Array<{ node: T | null } | null> };

export default function flattenConnection<T>(
  connection: TConnectionType<T> | null | undefined
): Array<T> {
  return (connection?.edges ?? [])
    .map((edge) => edge?.node)
    .filter(isNotNullish);
}
