import { Stack } from '@mui/material';
import { Trans } from '@lingui/react/macro';
import { useFieldInfo } from 'zod-form';
import { useState } from 'react';
import * as z from 'zod';
/* need to import react-hook-form for typescript to infer the type of ZodForm below */
import 'react-hook-form';
import Field, { FieldProps } from '@watershed/ui-core/components/Form/Field';
import DateRangePicker from '@watershed/ui-core/components/DateRangePicker';
import DateTimeUtils from '@watershed/shared-universal/utils/DateTimeUtils';
import DatePicker from '@watershed/ui-core/components/DatePicker';
import TextLink from '@watershed/ui-core/components/TextLink';
import {
  CommonZodFieldProps,
  getInputFieldProps,
  getCommonFieldProps,
  useFieldPropsFromTsController,
} from './fieldUtils';

export const OPTIONAL_DATE_RANGE_SCHEMA = z.object({
  startDate: z.date().nullish(),
  endDate: z.date().nullish(),
});
export const END_DATE_OPTIONAL_DATE_RANGE_SCHEMA = z.object({
  startDate: z.date(),
  endDate: z.date().nullish(),
});
export const DATE_RANGE_SCHEMA = z.object({
  startDate: z.date(),
  endDate: z.date(),
});

export function ZodDateField({ ...restProps }: CommonZodFieldProps) {
  const { onChange, value, ...props } =
    useFieldPropsFromTsController<Date | null>();
  return (
    <Field inputId={props.id} {...props} {...getCommonFieldProps(restProps)}>
      <DatePicker
        fullWidth
        id={props.id}
        {...getCommonFieldProps(restProps)}
        {...getInputFieldProps(restProps)}
        date={DateTimeUtils.fromMaybeJSDate(value)}
        onChange={(date) => {
          onChange(date.toJSDate());
        }}
      />
    </Field>
  );
}

function DateRangeField({
  onChange,
  value,
  readOnly,
  ...props
}: FieldProps &
  CommonZodFieldProps & {
    value: z.infer<typeof OPTIONAL_DATE_RANGE_SCHEMA> | undefined;
    onChange: (value: z.infer<typeof OPTIONAL_DATE_RANGE_SCHEMA>) => void;
  }) {
  const startDate = DateTimeUtils.fromMaybeJSDate(value?.startDate);
  const endDate = DateTimeUtils.fromMaybeJSDate(value?.endDate);
  return (
    <Field {...props}>
      {readOnly ? (
        <>
          {startDate
            ? // TODO: i18n (please resolve or remove this TODO line if legit)
              // eslint-disable-next-line @watershed/require-locale-argument
              DateTimeUtils.getIntervalOrDate(startDate, endDate).toFormat(
                'LLL d, yyyy'
              )
            : 'No dates'}
        </>
      ) : (
        <DateRangePicker
          {...getInputFieldProps(props)}
          startDate={startDate}
          endDate={endDate}
          onChange={(startDate, endDate) => {
            onChange({
              startDate: startDate?.toJSDate() ?? null,
              endDate: endDate?.toJSDate() ?? null,
            });
          }}
        />
      )}
    </Field>
  );
}

export function ZodDateRangeField({ ...restProps }: CommonZodFieldProps) {
  const fieldInfo = useFieldInfo();
  const zodType = fieldInfo.zodType as
    | typeof DATE_RANGE_SCHEMA
    | typeof OPTIONAL_DATE_RANGE_SCHEMA
    | typeof END_DATE_OPTIONAL_DATE_RANGE_SCHEMA;

  const { startDate, endDate } = zodType.shape;

  const isStartDateRequiredEndDateOptional =
    !(startDate.isOptional() || startDate.isNullable()) &&
    (endDate.isOptional() || endDate.isNullable());
  // when the startDate is required and end date is not show a special ui that lets you hide end date
  // otherwise for both required or both optional show regular date range and let the schema validation handle the nullability
  // arguably we could turn this behavior on with a prop to this component but for now this feels fine
  return isStartDateRequiredEndDateOptional ? (
    <ZodOptionalEndDateRange {...restProps} />
  ) : (
    <ZodOptionalDateRangeField {...restProps} />
  );
}

export function ZodOptionalDateRangeField({
  ...restProps
}: CommonZodFieldProps) {
  const { ...props } =
    useFieldPropsFromTsController<z.infer<typeof OPTIONAL_DATE_RANGE_SCHEMA>>();
  const { zodType } = useFieldInfo();
  const dateRangeSchema = zodType as
    | typeof OPTIONAL_DATE_RANGE_SCHEMA
    | typeof DATE_RANGE_SCHEMA;
  return (
    <DateRangeField
      inputId={props.id}
      {...restProps}
      {...props}
      required={
        !dateRangeSchema.shape.startDate.isNullable() ||
        !dateRangeSchema.shape.endDate.isNullable()
      }
    />
  );
}
export function ZodOptionalEndDateRange({ ...restProps }: CommonZodFieldProps) {
  const { onChange, ...tsProps } =
    useFieldPropsFromTsController<z.infer<typeof OPTIONAL_DATE_RANGE_SCHEMA>>();
  const [showRangeState, setShowRange] = useState(false);
  const [rememberedEndDate, setRememberedEndDate] = useState<Date | null>(null);
  const showRange = showRangeState || tsProps.value?.endDate != null;
  return (
    <>
      {showRange ? (
        <DateRangeField
          inputId={tsProps.id}
          {...restProps}
          {...tsProps}
          value={{
            ...tsProps.value,
            endDate: tsProps.value?.endDate ?? rememberedEndDate,
          }}
          onChange={onChange}
        />
      ) : (
        <Field
          inputId={tsProps.id}
          {...tsProps}
          {...getCommonFieldProps(restProps)}
        >
          <DatePicker
            fullWidth
            id={tsProps.id}
            {...getCommonFieldProps(restProps)}
            {...getInputFieldProps(restProps)}
            date={DateTimeUtils.fromMaybeJSDate(tsProps.value?.startDate)}
            onChange={(date) => {
              onChange({ startDate: date.toJSDate(), endDate: null });
            }}
          />
        </Field>
      )}
      <Stack direction="row">
        {showRange ? (
          <TextLink
            onClick={() => {
              setShowRange(false);
              setRememberedEndDate(tsProps.value?.endDate ?? null);
              onChange({
                startDate: tsProps.value?.startDate ?? null,
                endDate: null,
              });
            }}
            sx={{ color: (theme) => theme.palette.error.dark }}
            type="button"
          >
            <Trans context="Link copy">Remove end date</Trans>
          </TextLink>
        ) : (
          <TextLink
            onClick={() => {
              setShowRange(true);
            }}
            type="button"
          >
            <Trans context="Link copy">Add end date</Trans>
          </TextLink>
        )}
      </Stack>
    </>
  );
}
