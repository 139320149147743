import { useState, useRef, useEffect } from 'react';
import Button from '@watershed/ui-core/components/Button';
import { Popper, MenuItem, Typography, useTheme, Paper } from '@mui/material';
import { getSharedSidebarStyles } from '../navigation/variants/sharedSidebarStyles';
import UnstyledLink from '@watershed/ui-core/components/UnstyledLink';
import ChevronRightIcon from '@watershed/icons/components/ChevronRight';
import { NavSectionItem } from './utils';
import { mixinSx } from '@watershed/style/styleUtils';
import { Stack } from '@mui/system';

export default function NavMenu({
  name,
  items,
  handleNavMenuHover,
}: NavSectionItem['menu'] & {
  handleNavMenuHover?: (hovered: boolean) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  const sharedSidebarStyles = getSharedSidebarStyles(useTheme());
  const { COLORS, BOX_SHADOW_FOCUS } = sharedSidebarStyles;

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    handleNavMenuHover?.(true);
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
    handleNavMenuHover?.(false);
  };

  const focusItems = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Tab' && anchorEl) {
      event.preventDefault();
      const firstMenuItem = popperRef.current?.querySelector(
        '#nav-menu [tabindex]:not([tabindex="-1"])'
      ) as HTMLElement;
      firstMenuItem?.focus();
    } else if (event.key === 'Escape') {
      setAnchorEl(null);
    }
  };

  const exitPopper = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if (anchorEl && popperRef.current) {
      const firstFocusableElement = popperRef.current.querySelector(
        '#nav-menu [tabindex]:not([tabindex="-1"])'
      ) as HTMLElement;
      firstFocusableElement?.focus();
    }
  }, [anchorEl]);

  const handleMenuKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
    index: number
  ) => {
    if (event.key === 'Tab') {
      const isLastItem = index === items.length - 1;
      if (isLastItem && !event.shiftKey) {
        event.preventDefault();
        setAnchorEl(null);
        // Find the next focusable element in the NavSubSection
        const nextFocusableElement = buttonRef.current
          ?.closest('li')
          ?.nextElementSibling?.querySelector(
            '[tabindex]:not([tabindex="-1"])'
          ) as HTMLElement;
        nextFocusableElement?.focus();
      }
    }
  };

  const isOpen = Boolean(anchorEl);

  return (
    <Stack onMouseLeave={() => setAnchorEl(null)}>
      <Button
        ref={buttonRef}
        variant="text"
        size="small"
        aria-label="Open menu"
        startIcon={<ChevronRightIcon />}
        aria-controls="nav-menu"
        aria-haspopup="menu"
        aria-expanded={isOpen}
        onMouseEnter={handleMouseEnter}
        onClick={handleMouseEnter}
        onKeyDown={focusItems}
        sx={mixinSx(sharedSidebarStyles.iconButtonSxProps, {
          marginInlineEnd: -0.5,
          borderRadius: '4px',
          position: 'relative',
          zIndex: 1,
          opacity: 0,
          '[aria-current="page"] &, a:hover &, a:focus &, &[aria-expanded="true"], &:focus':
            {
              opacity: 1,
            },
          '[data-appears-collapsed="true"] &': {
            display: 'none',
          },

          '& .MuiButton-startIcon': {
            margin: 0,
            '& svg': {
              width: 16,
              height: 16,
            },
          },
          // Apply active styles when the popper is open
          ...(isOpen && {
            backgroundColor: (theme) => theme.palette.grey70,
            '&:focus-visible': {
              boxShadow: BOX_SHADOW_FOCUS,
            },
          }),
        })}
        isIcon
      />
      <Popper
        id="nav-menu"
        sx={{
          zIndex: 1000,
        }}
        open={isOpen}
        role="menu"
        anchorEl={anchorEl}
        placement="bottom-start"
        onMouseLeave={handleMouseLeave}
        onKeyDown={exitPopper}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [24, -48],
            },
          },
        ]}
      >
        <Paper
          ref={popperRef}
          sx={sharedSidebarStyles.menuPaperSxProps}
          onMouseEnter={() => handleNavMenuHover?.(true)}
          onMouseLeave={handleMouseLeave}
          tabIndex={-1}
        >
          {name && (
            <Typography
              variant="body1"
              sx={{
                paddingInlineStart: 1.5,
                paddingBlockStart: 0.5,
                color: COLORS.TEXT_SECONDARY,
              }}
            >
              {name}
            </Typography>
          )}
          {items.map((item, index) => (
            <UnstyledLink href={item.location} key={item.id}>
              <MenuItem
                tabIndex={0}
                onClick={() => {
                  setAnchorEl(null);
                  handleNavMenuHover?.(false); // Ensure sidebar is notified when an item is clicked
                }}
                onKeyDown={(event) => handleMenuKeyDown(event, index)}
                sx={{
                  '&:focus-visible': {
                    boxShadow: BOX_SHADOW_FOCUS,
                  },
                }}
              >
                <span>{item.name}</span>
              </MenuItem>
            </UnstyledLink>
          ))}
        </Paper>
      </Popper>
    </Stack>
  );
}
