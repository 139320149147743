/* @skip-file-for-translation */
import { t } from '@lingui/core/macro';
import {
  GQTaskWatershedProcessState,
  GQMeasurementProjectStatus,
  GQTaskWatershedProcessStateSimplified,
} from '../generated/graphql';
import assertNever from './assertNever';
import orderBy from 'lodash/orderBy';

export function getTaskStatusTextSimplified(
  status: GQTaskWatershedProcessStateSimplified
): string {
  switch (status) {
    case GQTaskWatershedProcessState.Completed:
      return t`Complete`;
    case GQTaskWatershedProcessState.NotStarted:
      return t`To do`;
    case GQTaskWatershedProcessState.InProgress:
      return t`In progress`;
    case GQTaskWatershedProcessStateSimplified.None:
      return t`No status`;
    default:
      return assertNever(status);
  }
}

const STATUS_ORDER = [
  GQMeasurementProjectStatus.InProgress,
  GQMeasurementProjectStatus.Planned,
  GQMeasurementProjectStatus.Completed,
];
function getIndexForStatus(status: GQMeasurementProjectStatus) {
  return STATUS_ORDER.indexOf(status);
}

export function sortMeasurementProjects<
  T extends {
    status: GQMeasurementProjectStatus;
    completedAt: Date | null;
    coverageEndDate: Date;
    deadline: Date | null;
  },
>(projects: Array<T>): Array<T> {
  return orderBy(
    projects,
    [
      // order by
      (p) => getIndexForStatus(p.status), // Active > Planned > Complete
      (p) => (p.completedAt ? p.coverageEndDate : 0), // if complete, order by coverageEndDate (latest first)
      (p) => (p.completedAt ? 0 : p.deadline), // if incomplete, order by deadline (earliest first)
    ],
    ['asc', 'desc', 'asc']
  );
}

export function getMeasurementProjectLabel(project: {
  name: string;
  completedAt: Date | null;
}): string {
  const projectName = project.name;
  return project.completedAt ? projectName : t`${projectName} (active)`;
}
