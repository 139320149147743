import omit from 'lodash/omit';
import { NextRouter } from 'next/router';

/**
 * Functions that help parse a value from a query param.
 */

// The generic type of a query param in Express.
export type QueryParam =
  | undefined
  | string
  | Array<string>
  | { [key: string]: QueryParam }
  | Array<{ [key: string]: QueryParam }>;

// Given a query param that we know is a string, parse out the string. Return
// null without throwing when receiving an array or object.
export function parseQueryParam(value: QueryParam): string | null {
  if (value === null || value === undefined || typeof value !== 'string') {
    return null;
  }
  return value;
}

// Given a query param that we know should be an array, parse out the array.
// Useful with NextJS router to avoid returning an array of values.
//
// Note two subtleties when compared to `parseQueryParam`, which are maintained
// for historical reasons but may be worth revisiting:
// 1. This doesn't accept the full range of Express query params (objects).
// 2. When given a string, this function creates a one-element array.
export function parseArrayQueryParam(
  value: string | Array<string> | null | undefined
): Array<string> | null {
  // Checks for undefined or null
  if (value == null) {
    return null;
  }
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
}

/**
 * Checks a query param and returns it if it is a safe (root-relative) redirect
 * path. Returns null if there is no `redirect` parameter or it is not safe.
 */
export function parseSafeRedirectPathQueryParam(
  redirectPath: QueryParam
): string | null {
  const redirectPathString = parseQueryParam(redirectPath);
  if (!redirectPathString || !redirectPathString?.startsWith('/')) {
    return null;
  }
  return redirectPathString;
}

/**
 * Functions that help manipulate sets of query params, in the
 * ParsedUrlQuery" shape used by NextJS.
 */

type ParsedUrlQuery = NextRouter['query'];
export type QueryStringOrObj = ParsedUrlQuery | string | null | undefined;

export function setParam(
  query: ParsedUrlQuery,
  name: string,
  value: string | Array<string> | null | undefined
): ParsedUrlQuery {
  return {
    ...query,
    [name]: value ?? undefined,
  };
}

export function getParamsWithPrefix(
  queryParams: URLSearchParams,
  prefix: string
): URLSearchParams {
  const newQueryParams = new URLSearchParams();
  const paramNames = Array.from(queryParams.keys());
  for (const name of paramNames) {
    if (name.startsWith(prefix)) {
      const value = queryParams.get(name);
      if (value != null) {
        newQueryParams.set(name, value);
      }
    }
  }
  return newQueryParams;
}

export function omitQueryParams(
  query: ParsedUrlQuery,
  paramsToOmit: string | Array<string>
): Partial<ParsedUrlQuery> {
  return omit(query, paramsToOmit);
}

/**
 * Gets the current dynamic "route params" - e.g. the [orgId] in
 * /org/[orgId]/flags. Does not include "query params" - e.g. the piece after
 * the "?".
 */
export function getRouteParams(query: ParsedUrlQuery, asPath: string): any {
  const queryParams = new URLSearchParams(asPath.split('?')[1]);
  return omit(query, Array.from(queryParams.keys()));
}

export function omitParamsWithPrefix(
  query: ParsedUrlQuery,
  prefix: string
): any {
  return omit(
    query,
    Object.keys(query).filter((key) => key.startsWith(prefix))
  );
}
