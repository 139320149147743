import { GQPermissionType } from '@watershed/shared-universal/generated/graphql';
import { SourceObjectWithPermissionDelegate } from '@watershed/shared-universal/utils/permissionsHierarchy';
import { useUserContext } from './UserContext';
import { hasPermission } from '@watershed/shared-universal/utils/permissionUtils';
import { atom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

// Let's keep track of which perms are actively being checked by the currently
// mounted components. This is useful for debugging and logging.
// We need to do some kind of ref counting because the same perm could be used
// by multiple components at once.
export const mountedPermissionChecksAtom = atom(
  new Map<GQPermissionType, number>()
);

export default function useHasPermission(
  allowList: GQPermissionType | Array<GQPermissionType>,
  options: {
    allowAnyObject?: boolean;
    source?: SourceObjectWithPermissionDelegate;
  } = {}
) {
  const setMountedPermChecks = useSetAtom(mountedPermissionChecksAtom);
  const { permissions } = useUserContext();

  if (!Array.isArray(allowList)) {
    allowList = [allowList];
  }

  useEffect(() => {
    setMountedPermChecks((prev) => {
      const updatedMap = new Map(prev);
      for (const perm of allowList) {
        const count = updatedMap.get(perm) ?? 0;
        updatedMap.set(perm, count + 1);
      }
      return updatedMap;
    });

    return () => {
      setMountedPermChecks((prev) => {
        const updatedMap = new Map(prev);
        for (const perm of allowList) {
          const count = updatedMap.get(perm) ?? 0;
          if (count === 1) {
            updatedMap.delete(perm);
          } else {
            updatedMap.set(perm, count - 1);
          }
        }
        return updatedMap;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(allowList), setMountedPermChecks]);

  return hasPermission(permissions, allowList, options);
}
