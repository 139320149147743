import invariant from 'invariant';
import last from 'lodash/last';
import lowerCase from 'lodash/lowerCase';
import sortBy from 'lodash/sortBy';
import { getUnitTooltipForGQAggregateKind } from '../analysis/AnalysisUtils';
import { SurveyAnswer } from '../companySurveys/types';
import {
  GQAggregateKind,
  GQCdpVendorData,
  GQCompanyEmissionsUnits,
  GQEngagementTaskContentsFragment,
  GQDisclosureQualityScore,
  GQDisclosureTargetIntensityType,
  GQEmissionsSource,
  GQFilterConjunction,
  GQFilterFieldLegacy,
  GQPrivateDisclosureType,
  GQSupplierDisclosureFieldsFragment,
  GQSupplierDisclosureTargetFieldsFragment,
  GQSupplierHistoricalEmissionsFieldsFragment,
  GQDisclosureForSuppliersTableFragment,
  GQExternalReportType,
} from '../generated/graphql';
import assertNever from './assertNever';
import { getAllScopeEmissionsAndRatios } from './companyUtils';
import { CurrencyCode } from './currencies';
import isNotNullish from './isNotNullish';
import isNullish from './isNullish';
import { surveyAnswersHaveSomeEmissionsData } from '../companySurveys/utils';
import {
  ALL_GHG_IDS,
  SCOPE_1_CATEGORY_ID,
  SCOPE_2_CATEGORY_ID,
  SCOPE_3_1_ID,
  SCOPE_3_10_ID,
  SCOPE_3_11_ID,
  SCOPE_3_12_ID,
  SCOPE_3_13_ID,
  SCOPE_3_14_ID,
  SCOPE_3_15_ID,
  SCOPE_3_2_ID,
  SCOPE_3_3_ID,
  SCOPE_3_4_ID,
  SCOPE_3_5_ID,
  SCOPE_3_6_ID,
  SCOPE_3_7_ID,
  SCOPE_3_8_ID,
  SCOPE_3_9_ID,
} from '../constants';
import must from './must';
import uniq from 'lodash/uniq';
import { YM, YearMonth } from './YearMonth';
import EmissionsYear from '../companyData/EmissionsYear';
import { getCurrencySymbol } from './currencyUtils';
import { formatNumber } from './helpers';
import { DateTime } from 'luxon';
import orderBy from 'lodash/orderBy';
import DateTimeUtils from './DateTimeUtils';
import isEmpty from 'lodash/isEmpty';

export type DisclosureQualityScoreInput = Pick<
  GQCdpVendorData,
  | 'totalEmissionsNonzero'
  | 'scope1Nonzero'
  | 'scope2Nonzero'
  | 'scope3Nonzero'
  | 'scope301Or302Nonzero'
  | 'scope1Verified'
  | 'scope2Verified'
  | 'scope3Verified'
  | 'pctEvaluationStatusesMatchResponse'
  | 'disclosureQualityScore'
>;

export interface DisclosureEFQualityInput {
  disclosedScope1: boolean;
  disclosedScope2: boolean;
  disclosedScope3: boolean;
  disclosedRevenue: boolean;
  disclosedCEEActivities: boolean;
  disclosedCleanPower: boolean;
  thirdPartyVerified: boolean;
  scope3CategoryBreakdown: boolean;
  disclosedIndustry: string;
  disclosedCountries: boolean;
  explanations: Array<string>;
  improvements: Array<string>;
}

type DisclosureTargetFilters =
  GQSupplierDisclosureTargetFieldsFragment['filters'];

export const DISCLOSURE_QUALITY_SCORE_LABELS: Record<
  GQDisclosureQualityScore,
  string
> = {
  [GQDisclosureQualityScore.VeryHigh]: 'Very high confidence',
  [GQDisclosureQualityScore.High]: 'High confidence',
  [GQDisclosureQualityScore.Medium]: 'Medium confidence',
  [GQDisclosureQualityScore.Low]: 'Low confidence',
  [GQDisclosureQualityScore.Unusable]: 'Very low confidence',
};

export const DISCLOSURE_QUALITY_SCORE_COLORS: Record<
  GQDisclosureQualityScore,
  'success' | 'warning' | 'error'
> = {
  [GQDisclosureQualityScore.VeryHigh]: 'success',
  [GQDisclosureQualityScore.High]: 'success',
  [GQDisclosureQualityScore.Medium]: 'warning',
  [GQDisclosureQualityScore.Low]: 'error',
  [GQDisclosureQualityScore.Unusable]: 'error',
};

export function getDisclosureQualityScoreTitle(
  disclosureQualityScore: GQDisclosureQualityScore
): string {
  return `Watershed has ${lowerCase(
    DISCLOSURE_QUALITY_SCORE_LABELS[disclosureQualityScore]
  )} that this disclosure is complete and of reasonable quality.`;
}

function getDisclosedEmissionsString(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  if (!disclosureEmissionsData.totalEmissionsNonzero) {
    return 'It does not disclose any emissions data.';
  }

  const disclosedEmissionScopes = [];
  const undisclosedEmissionsScopes = [];
  if (disclosureEmissionsData.scope1Nonzero) {
    disclosedEmissionScopes.push('Scope 1');
    if (disclosureEmissionsData.scope2Nonzero) {
      disclosedEmissionScopes.push(' & 2');
    } else {
      undisclosedEmissionsScopes.push('2');
    }
  } else if (disclosureEmissionsData.scope2Nonzero) {
    disclosedEmissionScopes.push('Scope 2');
    undisclosedEmissionsScopes.push('1');
  } else {
    undisclosedEmissionsScopes.push('1');
    undisclosedEmissionsScopes.push('2');
  }

  if (disclosureEmissionsData.scope3Nonzero) {
    if (disclosedEmissionScopes.length === 1) {
      disclosedEmissionScopes.push(' and ');
    } else if (disclosedEmissionScopes.length > 1) {
      disclosedEmissionScopes.push(', and ');
    }
    disclosedEmissionScopes.push('some Scope 3');
  } else {
    undisclosedEmissionsScopes.push('3');
  }

  let undisclosedEmissionsString = '';
  if (undisclosedEmissionsScopes.length === 1) {
    undisclosedEmissionsString = `Scope ${undisclosedEmissionsScopes[0]}`;
  } else if (undisclosedEmissionsScopes.length === 2) {
    undisclosedEmissionsString = `Scopes ${undisclosedEmissionsScopes[0]} and ${undisclosedEmissionsScopes[1]}`;
  }

  return `It discloses ${disclosedEmissionScopes.join('')} emissions${
    undisclosedEmissionsScopes.length === 0
      ? '.'
      : `, but not ${undisclosedEmissionsString} emissions.`
  }`;
}

function getDisclosureUnusableQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  invariant(
    disclosureEmissionsData.disclosureQualityScore ===
      GQDisclosureQualityScore.Unusable,
    'Expected disclosureQualityScore to be Unusable'
  );

  return getDisclosedEmissionsString(disclosureEmissionsData);
}

function getDisclosureVeryHighQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  invariant(
    disclosureEmissionsData.disclosureQualityScore ===
      GQDisclosureQualityScore.VeryHigh,
    'Expected disclosureQualityScore to be VeryHigh'
  );

  return `${getDisclosedEmissionsString(
    disclosureEmissionsData
  )} An explanation is provided for all upstream scope 3 emissions that are not disclosed. This disclosure's emissions were verified by a third-party.`;
}

function getDisclosureHighQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  invariant(
    disclosureEmissionsData.disclosureQualityScore ===
      GQDisclosureQualityScore.High,
    'Expected disclosureQualityScore to be High'
  );

  return `${getDisclosedEmissionsString(
    disclosureEmissionsData
  )} An explanation is provided for all upstream scope 3 emissions that are not disclosed.`;
}

function getDisclosureMediumQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  invariant(
    disclosureEmissionsData.disclosureQualityScore ===
      GQDisclosureQualityScore.Medium,
    'Expected disclosureQualityScore to be Medium'
  );

  return `${getDisclosedEmissionsString(disclosureEmissionsData)} ${
    !(
      disclosureEmissionsData.scope1Verified ||
      disclosureEmissionsData.scope2Verified ||
      disclosureEmissionsData.scope3Verified
    )
      ? 'Disclosed emissions were not reported as verified nor assured by a third-party.'
      : ''
  } ${
    !(disclosureEmissionsData.pctEvaluationStatusesMatchResponse !== 1)
      ? 'An explanation is not provided for some upstream scope 3 emissions that are not disclosed.'
      : ''
  }`;
}

function getDisclosureLowQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  invariant(
    disclosureEmissionsData.disclosureQualityScore ===
      GQDisclosureQualityScore.Low,
    'Expected disclosureQualityScore to be Low'
  );

  return `${getDisclosedEmissionsString(disclosureEmissionsData)} ${
    !disclosureEmissionsData.scope301Or302Nonzero
      ? 'However, it does not disclose emissions from either capital goods and purchased goods and services.'
      : ''
  } ${
    !(
      disclosureEmissionsData.scope1Verified ||
      disclosureEmissionsData.scope2Verified ||
      disclosureEmissionsData.scope3Verified
    )
      ? 'Disclosed emissions were not reported as verified nor assured by a third-party.'
      : ''
  } ${
    !(disclosureEmissionsData.pctEvaluationStatusesMatchResponse !== 1)
      ? 'An explanation is not provided for some upstream scope 3 emissions that are not disclosed.'
      : ''
  }`;
}

export function getDisclosureQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
): string {
  switch (disclosureEmissionsData.disclosureQualityScore) {
    case GQDisclosureQualityScore.Unusable:
      return getDisclosureUnusableQualityExplanation(disclosureEmissionsData);
    case GQDisclosureQualityScore.VeryHigh:
      return getDisclosureVeryHighQualityExplanation(disclosureEmissionsData);
    case GQDisclosureQualityScore.High:
      return getDisclosureHighQualityExplanation(disclosureEmissionsData);
    case GQDisclosureQualityScore.Medium:
      return getDisclosureMediumQualityExplanation(disclosureEmissionsData);
    case GQDisclosureQualityScore.Low:
      return getDisclosureLowQualityExplanation(disclosureEmissionsData);
    default:
      assertNever(disclosureEmissionsData.disclosureQualityScore);
  }
}

export function getDisclosureEFExplanations(
  {
    disclosedScope1,
    disclosedScope2,
    disclosedScope3,
    disclosedRevenue,
    disclosedCleanPower,
    disclosedCEEActivities,
    scope3CategoryBreakdown,
    thirdPartyVerified,
    disclosedIndustry,
    disclosedCountries,
  }: Omit<DisclosureEFQualityInput, 'explanations' | 'improvements'>,
  supplierName: string
): { explanations: Array<string>; improvements: Array<string> } {
  const disclosedScopes123 =
    disclosedScope1 && disclosedScope2 && disclosedScope3;
  const disclosedAnyEmissions =
    disclosedScope1 || disclosedScope2 || disclosedScope3;

  const explanations: Array<string> = [];
  const improvements: Array<string> = [];

  const shouldThirdPartyVerifyImprovement = `${supplierName} should have their footprint verified by a third-party to ensure correctness.`;
  const shouldMeasureScopes123Improvement = `${supplierName} should measure and disclose their Scope 1, Scope 2, and upstream Scope 3 emissions per unit of revenue (revenue intensity).`;
  const shouldDiscloseCleanPowerImprovement = `${supplierName} should disclose the percentage of their energy covered by clean power.`;

  if (!disclosedCleanPower) {
    improvements.push(shouldDiscloseCleanPowerImprovement);
  }

  // Has no revenue
  if (!disclosedRevenue) {
    if (disclosedScopes123) {
      explanations.push(
        `${supplierName} disclosed their total emissions, but not their revenue or revenue intensity. Revenue intensity is needed to create an emission factor because it lets you determine how much of ${supplierName}'s total emissions should be allocated to you.`
      );
    } else if (disclosedCEEActivities) {
      explanations.push(
        `${supplierName} disclosed information about their operations, but not their revenue or revenue intensity. Revenue intensity is needed to create an emission factor because it lets you determine how much of ${supplierName}'s total emissions should be allocated to you.`
      );
    } else {
      explanations.push(
        `${supplierName} did not disclose complete emissions and did not answer any questions about their operations.`
      );
    }

    return { explanations, improvements };
  }

  // Rest: has revenue

  // All scopes = can make an EF
  if (disclosedScopes123) {
    if (scope3CategoryBreakdown) {
      let explanation = `This disclosure's emission factor is based on ${supplierName}'s disclosed Scope 1, Scope 2, and upstream Scope 3 per unit of revenue (revenue intensity)`;
      if (thirdPartyVerified) {
        explanation += ', which was verified by a third-party';
      }

      explanation += '.';
      explanations.push(explanation);
    } else {
      explanations.push(
        `This disclosure's emission factor is based on ${supplierName}'s disclosed Scope 1, Scope 2, and Scope 3 per unit of revenue (revenue intensity).`
      );
      improvements.push(
        `${supplierName} should break out specific Scope 3 categories to ensure the emission factor doesn't double-count their downstream emissions.`
      );
    }

    if (!thirdPartyVerified) {
      improvements.push(shouldThirdPartyVerifyImprovement);
    }

    return { explanations, improvements };
  }

  // Revenue + CEE
  if (disclosedCEEActivities) {
    explanations.push(
      `This disclosure's emission factor is based on industry average emissions for ${disclosedIndustry}, and adjusted based on ${supplierName}'s responses about their operations.`
    );

    if (!disclosedAnyEmissions) {
      improvements.push(shouldMeasureScopes123Improvement);
    } else if (!disclosedScope3) {
      improvements.push(
        `${supplierName} should measure and disclose their upstream Scope 3 per unit of revenue (revenue intensity).`
      );
    }

    // Revenue + no CEE
  } else {
    // TODO: Branch based on disclosedCleanPower
    let explanation = `This disclosure's emission factor is based on industry average emissions for ${disclosedIndustry}, and adjusted based on ${supplierName}'s usage of clean power`;
    if (disclosedCountries) {
      explanation += ` and countries of operation`;
    }
    explanation += '.';
    explanations.push(explanation);
    improvements.push(shouldMeasureScopes123Improvement);
  }

  return { explanations, improvements };
}

export function getEmissionsSource(
  disclosure: GQSupplierDisclosureFieldsFragment
): GQEmissionsSource {
  if (isNullish(disclosure.privateDisclosure)) {
    return GQEmissionsSource.ExternalReport;
  }

  const {
    privateDisclosure: { privateDisclosureType },
  } = disclosure;

  switch (privateDisclosureType) {
    case GQPrivateDisclosureType.Survey:
      return GQEmissionsSource.Survey;
    case GQPrivateDisclosureType.Estimate:
      return GQEmissionsSource.SurveyEstimate;
    case GQPrivateDisclosureType.WatershedMeasurement:
      return GQEmissionsSource.WatershedMeasurement;
    case GQPrivateDisclosureType.SurveyEstimate:
      return GQEmissionsSource.SurveyEstimate;
    // If it is a private CDP disclosure, the emissions are coming from an external report
    case GQPrivateDisclosureType.Cdp:
      return GQEmissionsSource.ExternalReport;
    case GQPrivateDisclosureType.ManualEntry:
      return GQEmissionsSource.ManualEntry;
    default:
      assertNever(privateDisclosureType);
  }
}

// This rank determines which disclosure to use for a supplier's emissions if
// there are multiple disclosures for the same year
export function getEmissionsSourceRank(
  historicalEmissions: Pick<
    GQSupplierHistoricalEmissionsFieldsFragment,
    'source'
  >
): number {
  const emissionsRank: Record<GQEmissionsSource, number> = {
    // Highest priority
    [GQEmissionsSource.ManualEntry]: -1,
    [GQEmissionsSource.WatershedMeasurement]: 0,
    [GQEmissionsSource.Survey]: 1,
    [GQEmissionsSource.ExternalReport]: 2,
    [GQEmissionsSource.SurveyEstimate]: 3,
    [GQEmissionsSource.CompanyNaicsCodeEstimate]: 4,
    [GQEmissionsSource.Cee]: 4,
    // Lowest priority
  };
  return emissionsRank[historicalEmissions.source];
}

export function getBestHistoricalEmissions<
  T extends GQSupplierHistoricalEmissionsFieldsFragment,
>(d1: T, d2: T): T {
  // If we have more than one disclosure for the same reporting year,
  // tiebreak based on emissions source. For example, we prefer survey data
  // over external reports.
  const rank1 = getEmissionsSourceRank(d1);
  const rank2 = getEmissionsSourceRank(d2);
  if (rank1 < rank2) {
    return d1;
  }

  const units1 = d1.units;
  const units2 = d2.units;
  // Prefer intensity units over absolute units. This situation may occur
  // if a customer inputs emissions associated with a target.
  if (
    units1 === GQCompanyEmissionsUnits.Kgco2ePerDollar &&
    units2 === GQCompanyEmissionsUnits.Kgco2e
  ) {
    return d1;
  }

  // If we have more than one disclosure for the same reporting year and
  // source, tiebreak based on publishing year. We prefer newer data.
  const year1 = d1.publishingYear ?? 0;
  const year2 = d2.publishingYear ?? 0;
  if (year1 > year2) {
    return d1;
  }

  return d2;
}

export function dedupeHistoricalEmissions<
  T extends GQSupplierHistoricalEmissionsFieldsFragment,
>(historicalEmissions: Array<T>): Array<T> {
  const bestHistoricalEmissionsRecordByYear: Record<number, T> = {};

  historicalEmissions.forEach((historicalEmission) => {
    const prev =
      bestHistoricalEmissionsRecordByYear[historicalEmission.reportingYear];
    if (isNullish(prev)) {
      bestHistoricalEmissionsRecordByYear[historicalEmission.reportingYear] =
        historicalEmission;
      return;
    }

    bestHistoricalEmissionsRecordByYear[historicalEmission.reportingYear] =
      getBestHistoricalEmissions(prev, historicalEmission);
  });

  return sortBy(
    Object.values(bestHistoricalEmissionsRecordByYear),
    (historicalEmission) => historicalEmission.reportingYear
  );
}

export function dedupeAndFilterHistoricalEmissions<
  T extends GQSupplierHistoricalEmissionsFieldsFragment,
>(historicalEmissions: Array<T>): Array<T> {
  // CHOOSING HISTORICAL EMISSIONS TO DISPLAY
  //
  // alright gang, heres the idea. the problem is that disclosures can now have varying units...
  // but we can't show different units on the same chart. So, we need to choose a unit to stick
  // with. We'll choose the unit of the best, most recent historical emission, and filter out any
  // other historical emissions that don't match that unit.

  // 1. Dedupe original list of historical emissions
  // so that we can find the best, most recent emissions
  const dedupedHistoricalEmissions =
    dedupeHistoricalEmissions(historicalEmissions);
  const latestHistoricalEmission = last(dedupedHistoricalEmissions);

  // 2. Filter out original historical emissions that don't match
  // the unit of the best, most recent historical emission
  const filteredHistoricalEmissions = historicalEmissions.filter(
    (emission) =>
      isNullish(latestHistoricalEmission) ||
      emission.units === latestHistoricalEmission.units
  );

  // 3. Dedupe the filtered list of historical emissions
  // so that we don't miss out on any emissions that were filtered out
  // from the step 1 dedupe due to prioritization of emissions sources
  const dedupedFilteredHistoricalEmissions = dedupeHistoricalEmissions(
    filteredHistoricalEmissions
  );

  return dedupedFilteredHistoricalEmissions;
}

export const SCOPES = ['scope1', 'scope2', 'scope3'] as const;
export type SCOPE = (typeof SCOPES)[number];

export function getHistoricalEmissionsByScope(
  emission: GQSupplierHistoricalEmissionsFieldsFragment,
  scope: SCOPE,
  useUpstreamScope3?: boolean
): number | null {
  switch (scope) {
    case 'scope1':
      return emission.scope1;
    case 'scope2':
      return emission.scope2;
    case 'scope3':
      return useUpstreamScope3
        ? EmissionsYear.getUpstreamScope3Emissions(emission) ?? emission.scope3
        : emission.scope3;
    default:
      assertNever(scope);
  }
}

export type GhgScopeOrCategory =
  | (typeof ALL_GHG_IDS)[number]
  | 'scope 1'
  | 'scope 2'
  | 'scope 3';
type HistoricalYearField = keyof GQSupplierHistoricalEmissionsFieldsFragment;
export const FILTER_VALUE_TO_HISTORICAL_YEAR_FIELD: Record<
  GhgScopeOrCategory,
  HistoricalYearField
> = {
  'scope 1': 'scope1',
  'scope 2': 'scope2',
  'scope 3': 'scope3',
  [SCOPE_1_CATEGORY_ID]: 'scope1',
  [SCOPE_2_CATEGORY_ID]: 'scope2',
  [SCOPE_3_1_ID]: 'scope301',
  [SCOPE_3_2_ID]: 'scope302',
  [SCOPE_3_3_ID]: 'scope303',
  [SCOPE_3_4_ID]: 'scope304',
  [SCOPE_3_5_ID]: 'scope305',
  [SCOPE_3_6_ID]: 'scope306',
  [SCOPE_3_7_ID]: 'scope307',
  [SCOPE_3_8_ID]: 'scope308',
  [SCOPE_3_9_ID]: 'scope309',
  [SCOPE_3_10_ID]: 'scope310',
  [SCOPE_3_11_ID]: 'scope311',
  [SCOPE_3_12_ID]: 'scope312',
  [SCOPE_3_13_ID]: 'scope313',
  [SCOPE_3_14_ID]: 'scope314',
  [SCOPE_3_15_ID]: 'scope315',
};

export function getSupplierHistoricalEmissionsMatchingFilter(
  emission: GQSupplierHistoricalEmissionsFieldsFragment,
  filter: GQSupplierDisclosureTargetFieldsFragment['filters']
): number | null {
  // If any filter is on GhgCategoryId, but we don't have category emissions, return null
  if (
    filter.expressions.some(
      (expr) => expr.field === GQFilterFieldLegacy.GhgCategoryId
    ) &&
    !EmissionsYear.hasScope3CategoryEmissions(emission)
  ) {
    return null;
  }

  // Sum up the emissions for the matching fields
  const resultFields = getMatchingHistoricalYearFieldsForFilter(filter);
  let result = 0;
  resultFields.forEach((f) => {
    const value = emission[f] as number;
    if (!isNullish(value)) {
      result += value;
    }
  });
  return result;
}

export function areTargetFiltersOverlapping(
  filtersA: DisclosureTargetFilters,
  filtersB: DisclosureTargetFilters
): boolean {
  // This filter check only supports GhgScope and GhgCategoryId
  invariant(
    filtersA.expressions.every(
      (expr) =>
        expr.field === GQFilterFieldLegacy.GhgCategoryId ||
        expr.field === GQFilterFieldLegacy.GhgScope
    ),
    'Unsupported filter expression in overlap check'
  );
  invariant(
    filtersB.expressions.every(
      (expr) =>
        expr.field === GQFilterFieldLegacy.GhgCategoryId ||
        expr.field === GQFilterFieldLegacy.GhgScope
    ),
    'Unsupported filter expression in overlap check'
  );

  // Overlapping if any field matches directly
  const fieldsA = getMatchingHistoricalYearFieldsForFilter(filtersA);
  const fieldsASet = new Set(fieldsA);
  const fieldsB = getMatchingHistoricalYearFieldsForFilter(filtersB);
  if (fieldsB.some((field) => fieldsASet.has(field))) {
    return true;
  }

  // Overlapping if A has scope3 and B has scope 3x. We can just check
  // startsWith since categories and scope both start with "scope3"
  const hasScope3A = fieldsA.some((field) => field.startsWith('scope3'));
  const hasScope3B = fieldsB.some((field) => field.startsWith('scope3'));
  if (hasScope3A && hasScope3B) {
    return true;
  }

  return false;
}

export function getTargetEndYear(
  target: Pick<
    GQSupplierDisclosureTargetFieldsFragment,
    'baseYear' | 'emissionsTarget'
  >
): YearMonth {
  return YM.plus(
    target.baseYear,
    target.emissionsTarget.values.length,
    target.emissionsTarget.frequency === 'Yearly' ? 'year' : 'month'
  );
}

function getMatchingHistoricalYearFieldsForFilter(
  filter: DisclosureTargetFilters
): Array<HistoricalYearField> {
  // We don't support filters here other than GhgCategoryId or GhgScope
  if (
    filter.expressions.some(
      (expr) =>
        expr.field !== GQFilterFieldLegacy.GhgCategoryId &&
        expr.field !== GQFilterFieldLegacy.GhgScope
    )
  ) {
    console.warn(
      `[DisclosureUtils] Unsupported filter on disclosure target`,
      filter
    );
    return [];
  }

  if (filter.expressions.length > 2) {
    console.warn(
      `[DisclosureUtils] Unsupported filter on disclosure target`,
      filter
    );
    return [];
  }

  // We only support one two-expression case: scope 3 + some category filter
  if (filter.expressions.length === 2) {
    const scope3Filter = filter.expressions.find(
      (expr) => expr.field === GQFilterFieldLegacy.GhgScope
    );
    const otherFilter = filter.expressions.find(
      (expr) => expr.field !== GQFilterFieldLegacy.GhgScope
    );
    if (
      filter.conjunction !== GQFilterConjunction.AndConjunction ||
      !scope3Filter?.value.includes('scope 3') ||
      otherFilter?.field !== GQFilterFieldLegacy.GhgCategoryId
    ) {
      console.warn(
        `[DisclosureUtils] Unsupported filter on disclosure target`,
        filter
      );
      return [];
    }
    return otherFilter.value.map((v) =>
      must(
        FILTER_VALUE_TO_HISTORICAL_YEAR_FIELD[v as GhgScopeOrCategory],
        'Unexpected filter value: ' + v
      )
    );
  }

  // Figure out which historical year fields match the filter
  let resultFields: Array<HistoricalYearField>;
  if (filter.conjunction === GQFilterConjunction.AndConjunction) {
    resultFields = uniq(Object.values(FILTER_VALUE_TO_HISTORICAL_YEAR_FIELD));
  } else {
    resultFields = [];
  }

  filter.expressions.forEach((expr) => {
    const matchingFields = expr.value.map((v) =>
      must(
        FILTER_VALUE_TO_HISTORICAL_YEAR_FIELD[v as GhgScopeOrCategory],
        'Unexpected filter value: ' + v
      )
    );
    if (filter.conjunction === GQFilterConjunction.AndConjunction) {
      resultFields = resultFields.filter((f) =>
        matchingFields.some((mf) => mf === f)
      );
    } else {
      resultFields = resultFields.concat(matchingFields);
    }
  });
  return resultFields;
}

export function getSupplierHistoricalEmissions(
  emission: GQSupplierHistoricalEmissionsFieldsFragment
): number | null {
  const scope1 = emission.scope1;
  const scope2 = emission.scope2;
  const scope3 = EmissionsYear.getScope3Emissions(emission);
  if (isNullish(scope1) || isNullish(scope2) || isNullish(scope3)) {
    return null;
  }
  return scope1 + scope2 + scope3;
}

export function getUnitLabel(
  units: GQCompanyEmissionsUnits,
  currency?: CurrencyCode | null
): string {
  // Pull out the logic for handling revenue intensity to customize with kgCO₂e / $1 instead of tCO₂e / $1M
  return units === GQCompanyEmissionsUnits.Kgco2ePerDollar
    ? `kgCO₂e / ${getCurrencySymbol(currency ?? 'USD')}`
    : getUnitTooltipForGQAggregateKind({
        aggregateKind: GQAggregateKind.Total,
        currency,
      });
}

export function getUnitTypeLabel(units: GQCompanyEmissionsUnits): string {
  switch (units) {
    case GQCompanyEmissionsUnits.Kgco2e:
      return 'Gross emissions';
    case GQCompanyEmissionsUnits.AllocatedKgco2e:
      return 'Allocated emissions';
    case GQCompanyEmissionsUnits.Kgco2ePerDollar:
      return 'Revenue intensity';
    default:
      assertNever(units);
  }
}

export function unitConversionFactorToTonsIfKgco2e(
  units: GQCompanyEmissionsUnits
): number {
  switch (units) {
    case GQCompanyEmissionsUnits.Kgco2e:
      return 1 / 1000;
    case GQCompanyEmissionsUnits.AllocatedKgco2e:
      return 1 / 1000;
    case GQCompanyEmissionsUnits.Kgco2ePerDollar:
      return 1; // Leave as kgCO2e / $1
    default:
      assertNever(units);
  }
}

export function formatEmissionsDatum(
  value: number,
  units: GQCompanyEmissionsUnits
): string {
  switch (units) {
    case GQCompanyEmissionsUnits.Kgco2e:
    case GQCompanyEmissionsUnits.AllocatedKgco2e:
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      return formatNumber(value);
    case GQCompanyEmissionsUnits.Kgco2ePerDollar:
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      return formatNumber(value, {
        maximumFractionDigits: value >= 0.001 ? 3 : 6,
      });
    default:
      assertNever(units);
  }
}

export function kgco2PerDollarToTonCo2PerMillion(
  value: number | null
): number | null {
  if (value) {
    // kgCO₂e/$1 to tCO₂e/$1M conversion unit
    return value * 1000;
  }
  return null;
}

export function getEmissionsScopeRatio(
  emission: GQSupplierHistoricalEmissionsFieldsFragment,
  scope: SCOPE
): number | null {
  switch (scope) {
    case 'scope1':
      return emission.scope1Ratio;
    case 'scope2':
      return emission.scope2Ratio;
    case 'scope3':
      return emission.scope3Ratio;
    default:
      assertNever(scope);
  }
}

function getPublishingYear(
  disclosure: GQSupplierDisclosureFieldsFragment
): number | null {
  const source = getEmissionsSource(disclosure);
  switch (source) {
    case GQEmissionsSource.Survey:
    case GQEmissionsSource.SurveyEstimate:
      return disclosure.privateDisclosure?.createdAt.getFullYear() ?? null;
    case GQEmissionsSource.ExternalReport:
      return disclosure.publicDisclosure?.publishingYear ?? null;
    case GQEmissionsSource.CompanyNaicsCodeEstimate:
    case GQEmissionsSource.WatershedMeasurement:
    case GQEmissionsSource.Cee:
    case GQEmissionsSource.ManualEntry:
      return null;
    default:
      assertNever(source);
  }
}

export function disclosureToGQSupplierHistoricalEmissions(
  disclosure: GQSupplierDisclosureFieldsFragment
): Array<GQSupplierHistoricalEmissionsFieldsFragment> {
  return (
    disclosure.historicalEmissionsYears?.map((historicalEmissionsYear) => {
      const allScopeEmissionsAndRatios = getAllScopeEmissionsAndRatios({
        ...historicalEmissionsYear,
        scope2: null,
      });

      return {
        ...allScopeEmissionsAndRatios,
        reportingYear: historicalEmissionsYear.reportingYear,
        publishingYear: getPublishingYear(disclosure),
        source: getEmissionsSource(disclosure),
        publicUrl: disclosure.publicDisclosure?.publicUrl ?? null,
        surveyId: disclosure.privateDisclosure?.surveyId ?? null,
        revenue: historicalEmissionsYear.revenue,
        revenueCurrency: historicalEmissionsYear.revenueCurrency,
        revenueUsd: historicalEmissionsYear.revenueUsd,
        units: historicalEmissionsYear.units,
        expenseCategory: historicalEmissionsYear.expenseCategory,
      };
    }) ?? []
  );
}

// we expect that supplier EFs are calculated using
// scope 1, 2, 3.1-3.8, and 3.16 (Other upstream) emissions
export function calculateSupplierEF(
  historicalEmission: {
    scope1: number | null;
    scope2: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope316: number | null;
    units: GQCompanyEmissionsUnits;
    revenue: number | null;
  } | null,
  totalSpend?: number | null
): number | null {
  if (
    isNullish(historicalEmission) ||
    isNullish(historicalEmission.scope1) ||
    isNullish(historicalEmission.scope2) ||
    isNullish(historicalEmission.scope3)
  ) {
    return null;
  }

  const scope3ForEF =
    EmissionsYear.getUpstreamScope3Emissions(historicalEmission) ??
    historicalEmission.scope3;
  const emissionsForEf =
    historicalEmission.scope1 + historicalEmission.scope2 + scope3ForEF;

  if (historicalEmission.units === GQCompanyEmissionsUnits.Kgco2e) {
    if (isNullish(historicalEmission.revenue)) {
      return null;
    }

    return emissionsForEf / historicalEmission.revenue;
  } else if (
    historicalEmission.units === GQCompanyEmissionsUnits.AllocatedKgco2e
  ) {
    if (isNullish(totalSpend)) {
      return null;
    }

    return emissionsForEf / totalSpend;
  } else if (
    historicalEmission.units === GQCompanyEmissionsUnits.Kgco2ePerDollar
  ) {
    // Else the units are Kgco2ePerDollar
    return emissionsForEf;
  } else {
    assertNever(historicalEmission.units);
  }
}

// TODO: Can we just make these the same enum?
export const COMPANY_EMISSIONS_UNITS_TO_DISCLOSURE_TARGET_INTENSITY_TYPE: Record<
  GQCompanyEmissionsUnits,
  GQDisclosureTargetIntensityType
> = {
  [GQCompanyEmissionsUnits.Kgco2e]: GQDisclosureTargetIntensityType.Absolute,
  [GQCompanyEmissionsUnits.AllocatedKgco2e]:
    GQDisclosureTargetIntensityType.Absolute, // is this right?
  [GQCompanyEmissionsUnits.Kgco2ePerDollar]:
    GQDisclosureTargetIntensityType.Revenue,
};

export function shouldShowDisclosureQualityForEngagementTask({
  engagementTask,
  engagementTaskAnswers,
}: {
  engagementTask: GQEngagementTaskContentsFragment;
  engagementTaskAnswers: Array<SurveyAnswer>;
}): boolean {
  // When suppliers attach a report, we manually extract the emissions
  // data from it. We don't want to show a quality score until we've attempted
  // to extract that emissions data.
  const hasEmissionsData = surveyAnswersHaveSomeEmissionsData(
    engagementTaskAnswers
  );

  return hasEmissionsData && isNotNullish(engagementTask.reportingYear);
}

export function isSurveyDisclosure(
  disclosureType: GQPrivateDisclosureType | undefined | null
): boolean {
  return (
    isNotNullish(disclosureType) &&
    (
      [
        GQPrivateDisclosureType.Survey,
        GQPrivateDisclosureType.SurveyEstimate,
        GQPrivateDisclosureType.Estimate,
      ] as Array<GQPrivateDisclosureType>
    ).includes(disclosureType)
  );
}

export function getDateDetailsForDisclosure(
  disclosure: Pick<
    GQDisclosureForSuppliersTableFragment,
    'publicDisclosure' | 'privateDisclosure' | 'historicalEmissionsYears'
  >
): { displayDate: string | number; date: DateTime } {
  const sortedReportingYears = orderBy(
    uniq(
      disclosure.historicalEmissionsYears?.map((year) => year.reportingYear) ??
        []
    ),
    [],
    'desc'
  );

  const mostRecentReportingYearAsDate = sortedReportingYears[0]
    ? DateTimeUtils.fromYearAndMonth(sortedReportingYears[0], 1)
    : null;
  const displayReportingYears = isEmpty(sortedReportingYears)
    ? null
    : // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/no-join-commas
      sortedReportingYears.join(', ');

  const cdpPublicReportingYear =
    disclosure.publicDisclosure?.reportType === 'CDP'
      ? disclosure.publicDisclosure?.cdpVendorData?.reportingYear
      : null;
  const cdpPublicReportingYearAsDate = cdpPublicReportingYear
    ? DateTimeUtils.fromYearAndMonth(cdpPublicReportingYear, 1)
    : null;

  const defaultDate =
    DateTimeUtils.fromMaybeJSDate(disclosure.privateDisclosure?.createdAt) ??
    DateTimeUtils.fromYearAndMonth(
      must(
        disclosure.publicDisclosure?.publishingYear,
        'Found a disclosure with no public or private disclosure'
      ),
      1
    );

  return {
    displayDate:
      displayReportingYears ?? cdpPublicReportingYear ?? defaultDate?.year,
    date:
      mostRecentReportingYearAsDate ??
      cdpPublicReportingYearAsDate ??
      defaultDate,
  };
}

export function getDisclosureType(
  disclosure: Pick<
    GQDisclosureForSuppliersTableFragment,
    'publicDisclosure' | 'privateDisclosure' | 'historicalEmissionsYears'
  >
): GQExternalReportType | GQPrivateDisclosureType | null {
  return (
    disclosure.publicDisclosure?.reportType ??
    disclosure.privateDisclosure?.privateDisclosureType ??
    null
  );
}

export function getDisclosureName(
  disclosure: Pick<
    GQDisclosureForSuppliersTableFragment,
    'publicDisclosure' | 'privateDisclosure' | 'historicalEmissionsYears'
  >,
  displayDate: string | number,
  parentCompanyName: string | null
): string {
  const baseName = isNotNullish(disclosure.publicDisclosure)
    ? `${parentCompanyName ? `${parentCompanyName}'s` : ''} ${
        disclosure.publicDisclosure.reportType
      } climate report`
    : isSurveyDisclosure(disclosure.privateDisclosure?.privateDisclosureType)
      ? 'Watershed survey response'
      : `${disclosure.privateDisclosure?.orgName ?? 'Your'} data`;
  return `${baseName} ${displayDate}`;
}
