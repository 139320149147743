import { Formik } from 'formik';
import { Trans, useLingui } from '@lingui/react/macro';
import DialogForm from '@watershed/ui-core/components/DialogForm';
import SelectField from '@watershed/ui-core/components/Form/SelectField';
import { MenuItem } from '@mui/material';
import { useAtom } from 'jotai';
import { atomLocale } from '@watershed/intl/frontend/atoms';
import { SUPPORTED_LOCALES, PSEUDO_LOCALE } from '@watershed/intl/constants';
import { useSetPreferredLocaleMutation } from '@watershed/shared-frontend/generated/urql';

interface LocaleDialogProps {
  open: boolean;
  onClose: () => void;
}

export const LocaleDialog: React.FC<LocaleDialogProps> = ({
  open,
  onClose,
}) => {
  const [, setPreferredLocale] = useSetPreferredLocaleMutation();
  const [locale, setLocale] = useAtom(atomLocale);
  const { t } = useLingui();

  return (
    <Formik
      initialValues={{ locale }}
      enableReinitialize
      onSubmit={async ({ locale }, { setSubmitting }) => {
        await setPreferredLocale({ input: { locale } }, { suspense: false });
        setLocale(locale);
        setSubmitting(false);
        onClose();
      }}
    >
      {({ isSubmitting }) => (
        <DialogForm
          open={open}
          onClose={onClose}
          header={{
            title: (
              <Trans context="The title of the dialog to switch the dashboard language">
                Switch language
              </Trans>
            ),
          }}
          submit={t({
            message: 'Switch',
            context: 'The text of the button to switch the dashboard language',
          })}
          isSubmitting={isSubmitting}
        >
          <SelectField id="locale">
            {SUPPORTED_LOCALES.filter((locale) => locale !== PSEUDO_LOCALE).map(
              (locale) => (
                <MenuItem key={locale} value={locale}>
                  {new Intl.DisplayNames(locale, { type: 'language' }).of(
                    locale
                  ) ?? locale}
                </MenuItem>
              )
            )}
          </SelectField>
        </DialogForm>
      )}
    </Formik>
  );
};
