import type { CaptureContext } from '@sentry/types';

export function captureException(
  exception: unknown,
  captureContext?: CaptureContext
): string {
  const Sentry =
    // eslint-disable-next-line no-restricted-globals -- we are explicitly using window to determine environment here
    typeof window !== 'undefined'
      ? require('@sentry/browser')
      : require('@sentry/node');
  return Sentry.captureException(exception, captureContext);
}
