import { Trans } from '@lingui/react/macro';
import { Alert, AlertTitle } from '@mui/material';
import { useState } from 'react';

export default function StagingOrgAlert({
  isStagingOrg,
}: {
  isStagingOrg: boolean;
}) {
  const [isDismissed, setIsDimissed] = useState(false);
  if (!isStagingOrg || isDismissed) return null;

  return (
    <Alert
      onClose={() => setIsDimissed(true)}
      severity="warning"
      sx={{
        position: 'fixed',
        zIndex: 1000,
        top: 2,
        left: '50%',
        transform: 'translateX(-50%)',
        border: (theme) => `1px solid ${theme.palette.warning.main}`,
      }}
    >
      <AlertTitle>
        <Trans context="alert title">Warning</Trans>
      </AlertTitle>
      <Trans>
        You are currently viewing a Staging organization, not your Production
        organization.
      </Trans>
    </Alert>
  );
}
