import { t } from '@lingui/core/macro';
import {
  GQApprovalStatus,
  GQChangelogEventPartsFragment,
  GQDataApprovalObjectChangelogQuery,
  GQDataApprovalUserUploadTaskChangelogQuery,
  GQFlags,
  GQObjectType,
  GQPermissionType,
  GQUserUploadForDatasourceFragment,
  GQUserUploadTaskWithDatasourceFragment,
} from '@watershed/shared-universal/generated/graphql';
import {
  DataApprovalsFiles,
  DataGovApprovalViewState,
  DataGovApprovalsButtonType,
  DataGovDatasourceUploadsApprovalSettings,
} from '@watershed/shared-universal/dataApprovals/dataGovApprovalsTypes';
import {
  getErroredUploads,
  getIncompleteUploads,
  getProcessingUploads,
} from '@watershed/shared-universal/measurement/userUploadUtils';
import assertNever from '@watershed/shared-universal/utils/assertNever';
import { BadInputError } from '@watershed/shared-universal/errors/BadInputError';
import { useUserContext } from '../../../../../../utils/UserContext';
import {
  UserPermissions,
  hasPermission,
} from '@watershed/shared-universal/utils/permissionUtils';
import { useFeatureFlag } from '../../../../../../utils/FeatureFlag';
import { Cache } from '@urql/exchange-graphcache';
import {
  DataApprovalObjectChangelogDocument,
  DataApprovalUserUploadTaskChangelogDocument,
} from '@watershed/shared-frontend/generated/urql';

/**
 * For Data Approvals views to pass in given GraphQL, call hooks, and get back the settings
 * for the UI re: which buttons/state/tooltips/controls to show the user
 * (based on the user's permissions, the tasks's state, and what files are uploaded)
 *
 * This pulls relevant data out of GraphQL and passes to our test/server-friendly helper method.
 */
export function useApprovalViewSettingsForDatasourceUploads({
  userUploads,
  userUploadTask,
}: {
  userUploads: Array<GQUserUploadForDatasourceFragment>;
  userUploadTask: GQUserUploadTaskWithDatasourceFragment;
}): DataGovDatasourceUploadsApprovalSettings {
  const hasDataGovApprovalFlows = useFeatureFlag(
    GQFlags.DataIngestionCsrdApprovalFlow
  );

  const {
    approvalStatus,
    approvers,
    datasource: { id: datasourceId },
    measurementProject,
  } = userUploadTask;

  const userContext = useUserContext();

  // Safety net to exit early if outside of FF or removed from FF
  if (!hasDataGovApprovalFlows) {
    return {
      approvalViewState: DataGovApprovalViewState.CANNOT_SUBMIT,
      buttonType: DataGovApprovalsButtonType.SUBMIT_FOR_APPROVAL,
      isDisabled: true,
      tooltip: t({
        message: `You do not have access to use the approval flows.`,
        comment: `A tooltip on a button to submit a task for approval when the org is not in the`,
      }),
    };
  }

  const approversWithStatus = approvers.map((a) => ({
    userId: a.user.id,
    approvalStatus: a.approvalStatus,
  }));

  return getApprovalViewSettingsForDatasourceUploads({
    approvalStatus,
    approvers: approversWithStatus,
    datasourceId,
    isMeasurementProjectActive: measurementProject.active,
    userId: userContext.userId,
    userPermissions: userContext.permissions,
    userUploads,
  });
}

/**
 * Largely for testing! You probably want useApprovalViewSettingsForDatasourceUploads() if using in UI
 *
 * Abstracted out to allow passing in simpler data structures but still get back the settings
 * for the UI re: which buttons/state/tooltips/controls to show the user
 * (based on the user's permissions, the tasks's state, and what files are uploaded)
 */
export function getApprovalViewSettingsForDatasourceUploads({
  approvalStatus,
  approvers,
  datasourceId,
  isMeasurementProjectActive,
  userId,
  userPermissions,
  userUploads,
}: {
  approvalStatus: GQApprovalStatus;
  approvers: Array<{ userId: string; approvalStatus: GQApprovalStatus | null }>;
  datasourceId: string;
  /**
   * Possibly want to remove this from basic state handling and incorporate into "locking"
   */
  isMeasurementProjectActive: boolean;
  userId: string;
  userPermissions: Readonly<UserPermissions>;
  userUploads: DataApprovalsFiles;
}): DataGovDatasourceUploadsApprovalSettings {
  const approvalViewState: DataGovApprovalViewState =
    ((): DataGovApprovalViewState => {
      switch (approvalStatus) {
        case GQApprovalStatus.Approved:
          return DataGovApprovalViewState.APPROVED;

        case GQApprovalStatus.SubmittedForApproval:
          const hasAlreadyApproved = approvers.some(
            (a) =>
              a.userId === userId &&
              a.approvalStatus === GQApprovalStatus.Approved
          );
          if (hasAlreadyApproved) {
            return DataGovApprovalViewState.APPROVED_AWAITING_OTHERS;
          }
          const hasPermissionToApproveData =
            userHasPermissionToApproveDatasource(userPermissions, datasourceId);
          const isAssignedToApproveData = approvers
            .map((a) => a.userId)
            .includes(userId);

          return hasPermissionToApproveData && isAssignedToApproveData
            ? DataGovApprovalViewState.SUBMITTED_HAS_PERMISSION
            : DataGovApprovalViewState.SUBMITTED_BUT_NO_PERMISSION;

        // NOTE: there is no explicit state for rejected. It will show as "can submit"
        // The key is to make sure we show the comments and events from rejection to communicate this.
        case GQApprovalStatus.NotReadyForApproval:
          const userHasPermissionsToSubmit =
            userHasPermissionsToSubmitDatasourceForApproval(
              userPermissions,
              datasourceId
            );
          if (!userHasPermissionsToSubmit) {
            return DataGovApprovalViewState.CANNOT_SUBMIT_NO_PERMISSION;
          }

          const hasActiveFiles = userUploads.length > 0;
          if (!hasActiveFiles) return DataGovApprovalViewState.CAN_SUBMIT_EMPTY;

          const hasIncompleteUploads =
            getIncompleteUploads(userUploads).length > 0;
          const hasProcessingUploads =
            getProcessingUploads(userUploads).length > 0;
          const hasErroredUploads = getErroredUploads(userUploads).length > 0;
          if (hasIncompleteUploads || hasErroredUploads || hasProcessingUploads)
            return DataGovApprovalViewState.CANNOT_SUBMIT;

          return DataGovApprovalViewState.CAN_SUBMIT;

        default:
          assertNever(approvalStatus);
      }
    })();

  // Based on the evaluated state, return configured settings
  switch (approvalViewState) {
    case DataGovApprovalViewState.SUBMITTED_BUT_NO_PERMISSION:
      return {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.APPROVAL_DROPDOWN,
        isDisabled: true,
        tooltip: t({
          message: `You are not assigned to approve this data.`,
          context: `Button tooltip`,
        }),
      };
    case DataGovApprovalViewState.SUBMITTED_HAS_PERMISSION:
      return {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.APPROVAL_DROPDOWN,
        isDisabled: false,
        tooltip: t({
          message: `Click to approve or reject this data.`,
          context: `Button tooltip`,
        }),
      };
    case DataGovApprovalViewState.APPROVED_AWAITING_OTHERS:
      return {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.APPROVAL_DROPDOWN,
        isDisabled: true,
        tooltip: t({
          message: `You have already approved this data. The remaining reviewers must approve.`,
          context: `Button tooltip`,
        }),
      };
    case DataGovApprovalViewState.APPROVED:
      return {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.APPROVED,
        isDisabled: true,
        tooltip: t({
          message: `This data has already been approved`,
          context: `Button tooltip`,
        }),
      };
    case DataGovApprovalViewState.CANNOT_SUBMIT:
      return {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.SUBMIT_FOR_APPROVAL,
        isDisabled: true,
        tooltip: t({
          message: `Cannot submit for approval until all files have finished processing without error.`,
          context: `Button tooltip`,
        }),
      };
    case DataGovApprovalViewState.CANNOT_SUBMIT_NO_PERMISSION:
      return {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.SUBMIT_FOR_APPROVAL,
        isDisabled: true,
        tooltip: t({
          message: `You do not have permission to submit this data for approval.`,
          context: `Button tooltip`,
        }),
      };
    case DataGovApprovalViewState.CAN_SUBMIT:
      return {
        approvalViewState,
        buttonColor: 'primary',
        buttonType: DataGovApprovalsButtonType.SUBMIT_FOR_APPROVAL,
        isDisabled: false,
        tooltip: t({
          message: `If the data is complete, submit it for the approver to review.`,
          context: `Button tooltip`,
        }),
      };
    case DataGovApprovalViewState.CAN_SUBMIT_EMPTY:
      return {
        approvalViewState,
        buttonType: DataGovApprovalsButtonType.SUBMIT_FOR_APPROVAL,
        isDisabled: false,
        tooltip: t({
          message: `If there is no data that applies here, submit empty data for the approver to review.`,
          context: `Button tooltip`,
        }),
      };
    default:
      throw new BadInputError('Add support state for ' + approvalViewState);
  }
}

export function userHasPermissionsToSubmitDatasourceForApproval(
  userPermissions: Readonly<UserPermissions>,
  datasourceId: string
): boolean {
  return hasPermission(userPermissions, [GQPermissionType.ManageDatasource], {
    source: { id: datasourceId },
  });
}

export function userHasPermissionToApproveDatasource(
  userPermissions: Readonly<UserPermissions>,
  datasourceId: string
): boolean {
  return hasPermission(userPermissions, [GQPermissionType.ApproveDatasource], {
    source: { id: datasourceId },
  });
}

function concatEvents(
  existingEvents: Array<GQChangelogEventPartsFragment>,
  newEvents: Array<GQChangelogEventPartsFragment>
): Array<GQChangelogEventPartsFragment> {
  const latestCachedEventTime = existingEvents[0]?.eventTime;
  // Safeguard against duplicating events that were already present in the changelog
  const trulyNewEvents = newEvents.filter(
    (e) => !latestCachedEventTime || e.eventTime > latestCachedEventTime
  );
  return trulyNewEvents.concat(existingEvents);
}

// Helper for updating the urql cache to insert new changelog entries returned by various mutations
export function updateDataApprovalUserUploadTaskChangelogCache(
  cache: Cache,
  targetKind: string,
  targetId: string,
  newChangelogEvents: Array<GQChangelogEventPartsFragment>
) {
  if (targetKind !== 'UserUploadTask') {
    return;
  }
  cache.updateQuery(
    {
      query: DataApprovalUserUploadTaskChangelogDocument,
      variables: {
        input: { userUploadTaskId: targetId },
      },
    },
    (data: GQDataApprovalUserUploadTaskChangelogQuery | null) => {
      if (data) {
        const existingEvents =
          data.dataApprovalUserUploadTaskChangelog.changelogEvents;
        data.dataApprovalUserUploadTaskChangelog.changelogEvents = concatEvents(
          existingEvents,
          newChangelogEvents
        );
      }
      return data;
    }
  );
}

export function updateDataApprovalObjectChangelogCache(
  cache: Cache,
  objectId: string,
  objectType: GQObjectType,
  newChangelogEvents: Array<GQChangelogEventPartsFragment>
) {
  cache.updateQuery(
    {
      query: DataApprovalObjectChangelogDocument,
      variables: {
        input: { objectId, objectType },
      },
    },
    (data: GQDataApprovalObjectChangelogQuery | null) => {
      if (data) {
        const existingEvents = data.dataApprovalObjectChangelog.changelogEvents;
        data.dataApprovalObjectChangelog.changelogEvents = concatEvents(
          existingEvents,
          newChangelogEvents
        );
      }
      return data;
    }
  );
}
