/* eslint-disable @watershed/literals-must-be-i18n-ready */
/* eslint-disable @watershed/pages-must-assign-owners */
/* page assignment not managed for _ files */

/* eslint-disable jam3/no-sanitizer-with-danger */
import '../whyDidYouRender';
import CssBaseline from '@mui/material/CssBaseline';
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/browser';
import { devtoolsExchange } from '@urql/devtools';
import AnalyticsTracker from '@watershed/analytics/AnalyticsTracker';
import { WatershedSnackbarProvider } from '@watershed/shared-frontend/components/Snackbar';
import cache from '../utils/cache';
import '../styles/index.css';
import {
  SessionOverrideContextProvider,
  useSessionOverrideContext,
} from '../utils/SessionOverrideContext';
import debugExchange from '@watershed/shared-frontend/exchanges/debugExchange';
import errorExchange from '@watershed/shared-frontend/exchanges/errorExchange';
import privilegeExchange from '@watershed/shared-frontend/exchanges/privilegeExchange';
import requestIdExchange from '@watershed/shared-frontend/exchanges/requestIdExchange';
import fullstoryExchange from '@watershed/shared-frontend/exchanges/fullstoryExchange';
import retryExchange from '@watershed/shared-frontend/exchanges/retryExchange';
import scalarsExchange from '@watershed/shared-frontend/exchanges/scalarsExchange';
import localeHeaderExchange from '@watershed/shared-frontend/exchanges/localeHeaderExchange';
import useAutoRefresh from '@watershed/shared-frontend/hooks/useAutoRefresh';
import { LOTTIE_SCRIPT_URL } from '@watershed/ui-core/constants/lottie';
import usePrefetchRoute from '@watershed/shared-frontend/hooks/usePrefetchRoute';
import sentryBeforeSend from '@watershed/shared-frontend/sentryBeforeSend';
import initializeFrontendPerformanceMonitoring from '@watershed/shared-frontend/utils/frontendPerformanceMonitoring/initializeFrontendPerformanceMonitoring';
import '@watershed/shared-frontend/utils/DataDogFrontendInit';
import { splitUrlByOperationNameExchange } from '@watershed/shared-frontend/utils/splitUrlByOperationNameExchange';
import { SENTRY_MAX_VALUE_LENGTH } from '@watershed/shared-universal/utils/universalSentryUtils';
import { HeaderKeys } from '@watershed/shared-universal/utils/constants';
import getAppVersion from '@watershed/shared-universal/utils/getAppVersion';
import Mixpanel from 'mixpanel-browser';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactGA from 'react-ga4';
import { createClient, fetchExchange, Provider } from 'urql';
import { Layouts, type NextPageWithLayout } from '../layouts/LayoutEnum';
import LoggedInLayout from '../layouts/LoggedInLayout';
import LoggedOutLayout from '../layouts/LoggedOutLayout';
import FinanceLayout from '../layouts/FinanceLayout';
import SidebarLayout from '../layouts/SidebarLayout';
import 'reactflow/dist/style.css';
import urqlSchema from '../generated/urql-schema.json';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import { getPinnedOrganizationId } from '@watershed/shared-frontend/utils/pinnedOrganizationId';
// eslint-disable-next-line no-restricted-imports
import { getTheme } from '@watershed/shared-frontend/styles/theme';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import { CurrentProductProvider } from '../utils/CurrentProductContext';
import useToggle from '@watershed/ui-core/hooks/useToggle';
import { EmissionsFactorsLayout } from '../layouts/EmissionsFactorsLayout';
import { AppCommandPaletteProvider } from '@watershed/shared-frontend/components/CommandPalette';
import { useAtomValue } from 'jotai';
import LoadingPage from '@watershed/shared-frontend/components/LoadingPage';
// eslint-disable-next-line no-restricted-imports
import { clientAtom } from 'jotai-urql';
import dynamic from 'next/dynamic';
import { Userpilot } from 'userpilot';
import DashboardWatershedI18nProvider from '../components/DashboardWatershedI18nProvider';

const GlobalCommandPalette = dynamic(
  () => import('../components/commandPalette/GlobalCommandPalette')
);

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { atomLocalizationEnv } from '@watershed/intl/frontend/atoms';
import { useSyncAtom } from '@watershed/shared-frontend/components/jotai';
import { JotaiProvider } from '@watershed/shared-frontend/components/jotai/JotaiProvider';
import deriveDefaultLocaleForFrontend from '@watershed/intl/frontend/deriveDefaultLocaleForFrontend';
import useLocale from '@watershed/intl/frontend/useLocale';
import useGlobalLocation from '@watershed/ui-core/hooks/useGlobalLocation';
import RelayEnvironment from '../utils/RelayEnvironment';
import { setNextJsPathname } from '@watershed/shared-frontend/utils/frontendHoneycomb';
import { useRouter } from 'next/router';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

if (process.env.NEXT_PUBLIC_MUI_V5_LICENSE) {
  LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_V5_LICENSE);
}

const queryClient = new QueryClient();

if (typeof window !== 'undefined') {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
    const token = process.env.NEXT_PUBLIC_USERPILOT_TOKEN;
    if (token) {
      Userpilot.initialize(token);
    }
  }
}

if (typeof window !== 'undefined') {
  setUseWhatChange(process.env.NODE_ENV === 'development');
  const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
  const sentryEnabled =
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ||
    process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true';

  Sentry.init({
    dsn,
    release: 'watershed-frontend@' + getAppVersion(),
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
    enabled: sentryEnabled,
    // Disabling autoSessionTracking fixes openHandle issues in jest when
    // Sentry is disabled. Context: https://github.com/getsentry/sentry-javascript/issues/3827
    autoSessionTracking: sentryEnabled,
    initialScope: {
      tags: { svcname: 'dashboard-ui' },
    },
    maxValueLength: SENTRY_MAX_VALUE_LENGTH,
    beforeSend: sentryBeforeSend,
    ignoreErrors: [
      // Ignore noisy Next.js error. We _should_ be able to remove this once we
      // host our assets on a CDN via Render so they don't disappear between
      // deploys 🤞. For discussion:
      // https://watershedclimate.slack.com/archives/C02JM11N62F/p1662146583775999
      /attempted to hard navigate to the same URL/,
      // Ignore error that isn't actionable
      /ResizeObserver loop limit exceeded/,
    ],
  });

  void initializeFrontendPerformanceMonitoring();
}

// Dashboard Google Analytics key
ReactGA.initialize('G-YS73DWLB4X');
// This disables reporting to Google Analytics when we're not in production.
// Docs: https://developers.google.com/analytics/devguides/collection/analyticsjs/debugging
if (!(process.env.NEXT_PUBLIC_ENVIRONMENT === 'production')) {
  ReactGA.set({ sendHitTask: null });
}

const PRODUCTION_MIXPANEL_PROJECT_ID = 'ea14cd223be763ce1b8b6894b0a8e725';
// Track development events to a separate project. Makes for easier debugging.
const DEV_MIXPANEL_PROJECT_ID = '8c7027f56f03bd885ed39ecf7de8ef28';

// we don't currently await this even though FullStory init is async
// because all the FullStory methods internally await it
void Analytics.init({
  fullStoryOrgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID,
  mixpanelProjectId:
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
      ? PRODUCTION_MIXPANEL_PROJECT_ID
      : DEV_MIXPANEL_PROJECT_ID,
});

function ClientProvider({ children }: { children: React.ReactNode }) {
  const {
    data: { footprintVersion },
  } = useSessionOverrideContext();

  /**
   * think we have some edge cases on those two that are escaping our standard
   * approach, and they continue to be relentless exceptions in our metrics.
   * it's a bit of a hammer approach but prefetches are cheap af
   */
  usePrefetchRoute('/home');
  usePrefetchRoute('/reductions/[planId]/reductions');
  const locale = useLocale();
  const localizationEnv = useAtomValue(atomLocalizationEnv);
  const localeRef = React.useRef(locale);
  const localizationEnvRef = React.useRef(localizationEnv);
  localeRef.current = locale;
  localizationEnvRef.current = localizationEnv;

  const client = useMemo(() => {
    // See https://github.com/FormidableLabs/urql-devtools for the browser extension
    // to install to use this.
    const enableDevtoolsExchange = process.env.NODE_ENV === 'development';
    const devtoolsExchanges = enableDevtoolsExchange ? [devtoolsExchange] : [];

    return createClient({
      // NOTE: This gets overridden by the `splitUrlByOperationNameExchange`
      url: '/graphql',
      exchanges: [
        ...devtoolsExchanges,
        debugExchange,
        requestIdExchange,
        localeHeaderExchange(
          () => localeRef.current,
          () => localizationEnvRef.current
        ),
        fullstoryExchange,
        scalarsExchange(urqlSchema),
        cache(urqlSchema, (locale: string) => {
          localeRef.current = deriveDefaultLocaleForFrontend(locale);
        }),
        retryExchange(),
        errorExchange,
        splitUrlByOperationNameExchange(),
        privilegeExchange(),
        fetchExchange,
      ],
      fetchOptions: () => {
        const headers: Record<string, string> = {
          Accept: 'application/json',
        };

        const organizationId = getPinnedOrganizationId();
        if (organizationId) {
          headers[HeaderKeys.activeOrganizationId] = organizationId;
        }
        if (footprintVersion) {
          headers[HeaderKeys.footprintVersion] = footprintVersion;
        }
        return { headers };
      },
    });
  }, [footprintVersion]);
  useSyncAtom(clientAtom, client);

  return <Provider value={client}>{children}</Provider>;
}

function onUpdateRequired() {
  ReactGA.event({
    category: 'Infrastructure',
    action: 'Application autoupdate',
    nonInteraction: true,
    transport: 'beacon',
  });

  Mixpanel.track('applicationAutoupdate', {
    category: 'Infrastructure',
  });
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

/**
 * We default to SidebarLayout because it is the most common.
 * To switch to another, specify the desired layout at the Page level.
 */
function getLayout(layout: Layouts | undefined) {
  switch (layout) {
    case Layouts.LoggedOut:
      return LoggedOutLayout;
    case Layouts.LoggedInFullBleed:
      return LoggedInLayout;
    case Layouts.Finance:
      return FinanceLayout;
    case Layouts.EmissionsFactors:
      return EmissionsFactorsLayout;
    case Layouts.LoggedInSidebar:
    default:
      return SidebarLayout;
  }
}

function LocalizedThemeProvider({ children }: { children: React.ReactNode }) {
  const locale = useLocale();
  const theme = useMemo(() => getTheme(locale), [locale]);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

/**
 * Update NextJS pathname honeycomb event attribute when the path changes.
 */
function NextJsPathnameProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { pathname } = useRouter();
  useEffect(() => {
    setNextJsPathname(pathname);
  }, [pathname]);

  return <>{children}</>;
}

function DashboardApp({ Component, pageProps }: AppPropsWithLayout) {
  const [isMounted, setIsMounted] = useState(false);
  const [createFinanceSavedViewOpen, toggleCreateFinanceSavedView] =
    useToggle(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useAutoRefresh({ onUpdateRequired });
  const { location } = useGlobalLocation();
  useEffect(() => {
    // We're always logged in here, right?
    Userpilot.reload();
  }, [location]);

  // TODO(wc): remove this once we're fully
  // on the cdn and things look ok
  const errorLogging = `
  if (window !== undefined) {
    window.addEventListener('error', function(event) {
      // POST to /api/log/error
      const xhr = new XMLHttpRequest();
      xhr.open('POST', '/api/log/error');
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      xhr.send(JSON.stringify({
        err: {
          name: event.error?.name,
          message: event.error?.message,
          stack: event.error?.stack,
        },
        targetElem: event.target ? event.target.outerHTML : '',
        targetSrc: event.target ? event.target.src : '',
        windowLocation: window.location ? window.location.href : '',
        userAgent: window.navigator ? window.navigator.userAgent : ''
      }));
    }, true /* useCapture */);
  }`;

  const head = (
    <Head>
      <title>Watershed</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <script
        id="cdn-error-logger"
        dangerouslySetInnerHTML={{ __html: errorLogging }}
      ></script>
    </Head>
  );

  if (!isMounted) {
    // Unfortunately, we have to early return here before rendering the full
    // page content until we can figure out how to server-render our styles.
    // It's currently failing on a server-client mismatch hydration error due to
    // the Emotion library.
    return head;
  }

  const Layout = getLayout(Component.layout);
  return (
    <>
      {head}
      <QueryClientProvider client={queryClient}>
        {/* having one top level jotai provider ensures that atom values won't be global across server side renders
      it's unlikely that wed use an atom value server side but ths makes sure it's safe
      see https://jotai.org/docs/guides/nextjs#provider */}
        <JotaiProvider>
          <AnalyticsTracker>
            <NextJsPathnameProvider>
              <SessionOverrideContextProvider>
                <ClientProvider>
                  <RelayEnvironment>
                    <StyledEngineProvider injectFirst>
                      <LocalizedThemeProvider>
                        <CssBaseline />
                        <Suspense fallback={<LoadingPage />}>
                          <DashboardWatershedI18nProvider>
                            <WatershedSnackbarProvider>
                              {/* We need the CurrentProductProvider to be this high up in the component tree
                      so that it does not remount when switching between pages in the finance
                      product, otherwise the sidebar will change from finance to corporate */}
                              <CurrentProductProvider>
                                <Layout
                                  createFinanceSavedViewOpen={
                                    createFinanceSavedViewOpen
                                  }
                                  toggleCreateFinanceSavedView={
                                    toggleCreateFinanceSavedView
                                  }
                                >
                                  <AppCommandPaletteProvider>
                                    <Suspense fallback={<LoadingPage />}>
                                      <Component {...pageProps} />
                                    </Suspense>
                                    <GlobalCommandPalette />
                                  </AppCommandPaletteProvider>
                                </Layout>
                              </CurrentProductProvider>
                            </WatershedSnackbarProvider>
                          </DashboardWatershedI18nProvider>
                        </Suspense>
                      </LocalizedThemeProvider>
                    </StyledEngineProvider>
                  </RelayEnvironment>
                </ClientProvider>
              </SessionOverrideContextProvider>
            </NextJsPathnameProvider>
          </AnalyticsTracker>
        </JotaiProvider>
      </QueryClientProvider>
      <Script src={LOTTIE_SCRIPT_URL} strategy="afterInteractive" />
    </>
  );
}

export default DashboardApp;
