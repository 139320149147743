import {
  Interval,
  DateTime,
  Duration,
  DurationUnit,
  DurationObject,
} from 'luxon';
import { YearMonth, YM, YMInterval } from './YearMonth';
import { DATE_FORMAT_YMD } from '../constants';
import must from './must';
import { DEFAULT_LOCALE, SupportedLocale } from '@watershed/intl/constants';

function intervalName(interval: Interval): string {
  const startMonth = interval.start.monthShort;
  const startYear = interval.start.year;
  const endMonth = interval.end.monthShort;
  const endYear = interval.end.year;

  return `${startMonth} ${startYear} – ${endMonth} ${endYear}`;
}

function intervalIsQuarter(interval: Interval): boolean {
  if (interval.start.year !== interval.end.year) {
    return false;
  }

  const startMonth = interval.start.month;
  const endMonth = interval.end.month;
  return (
    startMonth % 3 === 1 && endMonth % 3 === 0 && endMonth - startMonth === 2
  );
}

function intervalQuarter(interval: Interval): number | undefined {
  if (!intervalIsQuarter(interval)) {
    return undefined;
  }

  switch (interval.start.month) {
    case 1:
      return 1;
    case 4:
      return 2;
    case 7:
      return 3;
    case 10:
      return 4;
    default:
      throw Error('Unexpected Error: Invalid Quarter Start Month');
  }
}

function intervalYears(interval: Interval): Array<number> {
  const startYear = interval.start.year;
  const endYear = interval.end.year;

  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  return years;
}

function latestCompleteYear(interval?: Interval | null): number | undefined {
  if (!interval) {
    return undefined;
  }

  const years = intervalYears(interval).sort().reverse();

  return years.find((year) => {
    const yearInterval = intervalForYear(year);
    return interval.engulfs(yearInterval);
  });
}

function intervalLengthMillis(interval: Interval): number {
  return interval.toDuration('milliseconds').milliseconds;
}

function dateTimeDifferenceMillis(d1: DateTime, d2: DateTime): number {
  return intervalLengthMillis(Interval.fromDateTimes(d1, d2));
}

function intervalForYear(year: number): Interval {
  return Interval.after(DateTime.utc(year), Duration.fromObject({ days: 364 }));
}

/**
 * For use with our custom GraphQL DateTime scalar. By default, we
 * deserialize to a string, which can be passed to this function
 */
function dateFromIso(dateString: string): DateTime {
  return DateTime.fromISO(dateString, { zone: 'utc' });
}

/**
 * For use with our custom GraphQL DateTime scalar. By default, we
 * deserialize to a string, which can be passed to this function
 */
function dateTimeFromIso(dateTimeString: string): DateTime {
  return DateTime.fromISO(dateTimeString);
}

function toRelative(dateTime: DateTime): string {
  const result = must(dateTime.toRelative());
  if (result.includes('second') || result.startsWith('in ')) {
    return 'just now';
  }
  return result;
}

function getMonthStrFromDateTime(dateTime: DateTime): string {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return dateTime.toFormat('yyyy-MM');
}

function getDateTimeFromMonthStr(monthStrOrIsoStr: string): DateTime {
  const monthStr =
    // Allow ISO strings to also work.
    monthStrOrIsoStr.length >= 10
      ? monthStrOrIsoStr.slice(0, 7)
      : monthStrOrIsoStr;
  return DateTime.fromFormat(monthStr, 'yyyy-MM');
}

function fromJSDate(date: Date): DateTime {
  return DateTime.fromJSDate(date);
}

function fromMaybeJSDate(date: Date): DateTime;
function fromMaybeJSDate(date: Date | undefined | null): DateTime | null;
function fromMaybeJSDate(date: Date | undefined | null): DateTime | null {
  return date ? fromJSDate(date) : null;
}

function clamp({
  value,
  min,
  max,
}: {
  value: DateTime;
  min?: DateTime | null;
  max?: DateTime | null;
}): DateTime {
  const clampedMin = min ?? DateTime.min();
  const clampedMax = max ?? DateTime.max();

  if (clampedMin && clampedMax && clampedMin > clampedMax) {
    throw new Error(
      'DateTimeUtils.clamp: min must be less than or equal to max'
    );
  }

  let result = value;
  if (clampedMin) {
    result = DateTime.max(clampedMin, result);
  }
  if (clampedMax) {
    result = DateTime.min(clampedMax, result);
  }
  return result;
}

type DateTimeFormat = 'iso' | 'med' | 'full';

function formatDateTime(
  date: DateTime,
  {
    format = 'iso',
    locale,
  }: { format?: DateTimeFormat; locale?: SupportedLocale } = {}
): string {
  // TODO (LOC-409) Remove the locale override when we want locale-based number formatting.
  // This is a temporary fix until all number formatters have been updated to require a locale.
  const localeOverride = DEFAULT_LOCALE;
  if (format === 'med') {
    return date.toLocaleString({
      ...DateTime.DATETIME_MED,
      locale: localeOverride,
    });
  }
  if (format === 'full') {
    return date.toLocaleString({
      ...DateTime.DATE_FULL,
      locale: localeOverride,
    });
  }
  return date.toUTC().toFormat(DATE_FORMAT_YMD, { locale });
}

function formatDateString(
  dateString: string,
  {
    format = 'iso',
    locale,
  }: { format?: DateTimeFormat; locale?: SupportedLocale } = {}
): string {
  // TODO (LOC-409) Remove the locale override when we want locale-based number formatting.
  // This is a temporary fix until all number formatters have been updated to require a locale.
  return formatDateTime(dateTimeFromIso(dateString), {
    format,
    locale: DEFAULT_LOCALE,
  });
}

function formatTime(
  date: DateTime,
  // TODO (LOC-409) Remove the locale override when we want locale-based number formatting.
  // This is a temporary fix until all number formatters have been updated to require a locale.
  { locale }: { locale?: SupportedLocale } = {}
): string {
  return date.toFormat('t', { locale: DEFAULT_LOCALE });
}

function formatDate(
  date: DateTime,
  {
    format = 'full',
    locale,
  }: { format?: 'med' | 'full'; locale?: SupportedLocale } = {}
): string {
  // TODO (LOC-409) Remove the locale override when we want locale-based number formatting.
  // This is a temporary fix until all number formatters have been updated to require a locale.
  const localeOverride = DEFAULT_LOCALE;
  if (format === 'med') {
    return date.toLocaleString({
      ...DateTime.DATE_MED,
      locale: localeOverride,
    });
  }

  return date.toLocaleString({ ...DateTime.DATE_FULL, locale: localeOverride });
}

// Return the text unit boundary at or after date. (e.g., the next month
// boundary at or after 2020-03-29 is 2020-04-01).
function dateCeil(date: DateTime, unit: DurationUnit): DateTime {
  const start = date.startOf(unit);
  if (start.equals(date)) {
    return date;
  }
  return start.plus({ [unit]: 1 });
}

// Return a smaller interval that is a whole number of the given unit
// (2018-09-01 to 2020-03-01, 'year') -> 2019-01-01 to 2020-01-01
function intervalShrinkTo(interval: Interval, unit: DurationUnit): Interval {
  return Interval.fromDateTimes(
    dateCeil(interval.start, unit),
    interval.end.startOf(unit)
  );
}

// Return a larger interval that is a whole number of the given unit
// (2018-09-01 to 2020-03-01, 'year') -> 2018-01-01 to 2021-01-01
function intervalGrowTo(interval: Interval, unit: DurationUnit): Interval {
  return Interval.fromDateTimes(
    interval.start.startOf(unit),
    dateCeil(interval.end, unit)
  );
}

/**
 * Returns an ISO-8601 formatted string
 * without milliseconds.
 *
 * For example: 2016-05-29T09:08:34+00:00
 *
 */
function getIsoStringFromDatetimeWithoutMilliseconds(
  datetime: DateTime
): string {
  return datetime.startOf('second').toISO({ suppressMilliseconds: true });
}

function* iterInterval(
  interval: Interval,
  unit: DurationUnit
): Generator<DateTime, void, unknown> {
  for (
    let date = interval.start;
    interval.contains(date);
    date = date.plus({ [unit]: 1 })
  ) {
    yield date;
  }
}

function yearMonthToDateTime(ym: YearMonth): DateTime {
  // eslint-disable-next-line no-restricted-properties
  return DateTime.fromObject({ ...YM.toObject(ym), zone: 'utc' });
}

function intervalToYMInterval(interval: Interval): YMInterval {
  return new YMInterval(
    YM.fromObject(interval.start),
    YM.fromObject(interval.end)
  );
}

function inclusiveOfEndIntervalToYMInterval(interval: Interval): YMInterval {
  return new YMInterval(
    YM.fromObject(interval.start),
    YM.fromObject(interval.end.plus({ month: 1 }))
  );
}

function ymIntervalToLegacyInclusiveInterval(ymi: YMInterval): Interval {
  return Interval.fromDateTimes(
    yearMonthToDateTime(ymi.start),
    yearMonthToDateTime(ymi.end).minus({ month: 1 })
  );
}
function ymIntervalToLuxonInterval(ymi: YMInterval): Interval {
  return Interval.fromDateTimes(
    yearMonthToDateTime(ymi.start),
    yearMonthToDateTime(ymi.end)
  );
}

function fromYearAndMonth(year: number, month: number): DateTime {
  // eslint-disable-next-line no-restricted-properties
  return DateTime.fromObject({ year, month, zone: 'utc' });
}

/**
 * Gets the YYYY-MM-DD string from a Date object, in UTC.
 */
function getIsoDateString(date: Date): string {
  return date.toISOString().slice(0, 10);
}

/**
 * Returns a new Date with the specified number of days subtracted.
 */
function subtractDays(date: Date, days: number): Date {
  return DateTime.fromJSDate(date).minus({ days }).toJSDate();
}

/**
 * Returns a new Date with the specified number of days added.
 */
function addDays(date: Date, days: number): Date {
  return DateTime.fromJSDate(date).plus({ days }).toJSDate();
}

/**
 * Much like its peer, {@link addDays} except that it operates at
 * the {@link DateTime} granularity instead of {@link Date}
 *
 */
function addDaysToDateTime(dateTime: DateTime, days: number): DateTime {
  return dateTime.plus({ days });
}

/**
 * Returns a new Date with the specified number of months added.
 *
 */
function addMonthsToDateTime(dateTime: DateTime, months: number): DateTime {
  return dateTime.plus({ months });
}

/**
 * Much like its peer, {@link subtractDays} except that it operates at
 * the {@link DateTime} granularity instead of {@link Date}
 *
 */
function subtractDaysFromDateTime(dateTime: DateTime, days: number): DateTime {
  return dateTime.minus({ days });
}

/**
 * Subtracts a given unit of time (e.g., day, hour, minute) from a {@link DateTime}
 */
function subtractTimeUnit(dateTime: DateTime, unit: DurationObject): DateTime {
  return dateTime.minus(unit);
}

/**
 * Adds a given unit of time (e.g., day, hour, minute) to a {@link DateTime}
 */
function addTimeUnit(dateTime: DateTime, unit: DurationObject): DateTime {
  return dateTime.plus(unit);
}

/**
 * Returns a new DateTime for the current instant but at the start of the day boundary.
 */
function nowDay(): DateTime {
  return DateTime.now().startOf('day');
}

const units: ReadonlyArray<Intl.RelativeTimeFormatUnit> = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];
function timeAgo(dateTime: DateTime): string {
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
}

function getDatesFromInterval(interval: Interval): Array<DateTime> {
  return interval.splitBy({ day: 1 }).map((interval) => interval.start);
}

/**
 * Converts a YearMonth to a Luxon interval.
 */
function yearMonthToInterval(ym: YearMonth): Interval {
  return Interval.fromDateTimes(
    yearMonthToDateTime(ym),
    yearMonthToDateTime(ym).plus({ month: 1 })
  );
}

function yearMonthIntervalToInterval(ymi: YMInterval): Interval {
  return Interval.fromDateTimes(
    yearMonthToDateTime(ymi.start),
    yearMonthToDateTime(ymi.end)
  );
}

function formatDateChangelog(date: Date, showDate?: boolean): string {
  const dateTimeNow = dateFromIso(new Date().toISOString());
  const dateTimeDate = dateFromIso(date.toISOString());
  const differenceInMillis = dateTimeDifferenceMillis(
    dateTimeDate,
    dateTimeNow
  );
  const dateAtTheEnd = showDate ? ` ▪ ${formatDate(dateTimeDate)}` : '';
  if (
    differenceInMillis <
    1000 * 60 * 60 * 24 * 5 // if it is less than a week ago
  ) {
    if (Math.floor(differenceInMillis / (1000 * 60 * 60 * 24)) === 0) {
      if (Math.floor(differenceInMillis / (1000 * 60 * 60)) === 0) {
        return (
          `${Math.floor(differenceInMillis / (1000 * 60))}m ago` + dateAtTheEnd
        );
      }
      return (
        `${Math.floor(differenceInMillis / (1000 * 60 * 60))}h ago` +
        dateAtTheEnd
      );
    }
    return (
      `${Math.floor(differenceInMillis / (24 * 60 * 60 * 1000))}d ago` +
      dateAtTheEnd
    );
  }
  return formatDate(dateFromIso(date.toISOString()));
}

/**
 * Given an array of Intervals, return an array of Intervals with overlapping or
 * adjacent Intervals collapsed into a single Interval.
 * N.B. This sorts the input array but returns a new array.
 */
function collapseIntervals(intervals: Array<Interval>): Array<Interval> {
  const result: Array<Interval> = [];
  if (!intervals.length) {
    return result;
  }
  const inOrder = intervals.sort(
    (a, b) => a.start.valueOf() - b.start.valueOf()
  );
  let current = inOrder[0];
  for (let i = 1; i < inOrder.length; i++) {
    if (
      // Overlapping or adjacent
      current.end >= inOrder[i].start
    ) {
      // Merge intervals
      current = Interval.fromDateTimes(
        current.start,
        current.end > inOrder[i].end ? current.end : inOrder[i].end
      );
    } else {
      result.push(current);
      current = inOrder[i];
    }
  }
  result.push(current);
  return result;
}

const DateTimeUtils = {
  intervalQuarter,
  intervalName,
  intervalYears,
  intervalLengthMillis,
  dateTimeDifferenceMillis,
  intervalForYear,
  latestCompleteYear,
  dateFromIso,
  dateTimeFromIso,
  toRelative,
  getMonthStrFromDateTime,
  getDateTimeFromMonthStr,
  clamp,
  formatDateTime,
  formatTime,
  formatDateString,
  dateCeil,
  intervalShrinkTo,
  intervalGrowTo,
  iterInterval,
  yearMonthToDateTime,
  intervalToYMInterval,
  inclusiveOfEndIntervalToYMInterval,
  ymIntervalToLegacyInclusiveInterval,
  ymIntervalToLuxonInterval,
  fromYearAndMonth,
  fromJSDate,
  fromMaybeJSDate,
  formatDate,
  getIsoDateString,
  subtractDays,
  addDays,
  addDaysToDateTime,
  addMonthsToDateTime,
  addTimeUnit,
  subtractTimeUnit,
  subtractDaysFromDateTime,
  getIsoStringFromDatetimeWithoutMilliseconds,
  getDatesFromInterval,
  timeAgo,
  yearMonthToInterval,
  yearMonthIntervalToInterval,
  formatDateChangelog,
  now(): DateTime {
    return DateTime.now();
  },
  nowDay,
  getIntervalOrDate: (
    startDate: DateTime,
    endDate: DateTime | undefined | null
  ): Interval | DateTime => {
    return !endDate || endDate.equals(startDate)
      ? startDate
      : Interval.fromDateTimes(startDate, endDate);
  },
  collapseIntervals,
};

export default DateTimeUtils;
