import { WIN_FINANCE_PREFIX } from './dashboardRoutes';
import assertNever from './utils/assertNever';

// Articles for each dataset should be added here
export const LearningHubArticlesForDatasetsMap = {
  OperatingExpenses: 'operating-expenses',
  Waste: 'waste',
  Vehicles: 'vehicles',
  EmployeeTravelExpenses: 'travel-expenses',
  Revenue: 'revenue',
  CloudCosts: 'cloud',
  CloudUsage: 'cloud',
  CapitalExpenses: 'capital-expenses',
  Employees: 'employees',
  UpstreamLogistics: 'logistics',
  DownstreamLogistics: 'logistics',
  Flights: 'flights',
};
// All articles that we want to link to in the learning hub should be in this map
const LearningHubArticleSlugMap = {
  ...LearningHubArticlesForDatasetsMap,
  CarbonMethodologyUpdates: 'carbon-methodology-updates',
  UnderstandingEEIOModels: 'eeios-ceda-useeio',
  CustomizeEmissionsFactors: 'customize-emissions-factors',
  DataCollection: 'data-collection',
  DataCollectionFaqs: 'data-collection-faqs',
  CreateFootprint: 'how-to-create-a-draft-footprint',
  NoDataForDataset:
    'data-collection-faqs#what-if-i-dont-have-any-data-for-this-dataset-4',
  AddingAdditionalDataWithCompanyTags:
    'adding-additional-data-with-company-tags',
  ApprovingDataForFootprintCalculation:
    'how-to-approve-data-for-use-in-footprint-calculations',
  Facilities: 'how-to-upload-your-facilities-buildings-and-utilities-data',
  FacilitiesGapFilling:
    'how-to-upload-your-facilities-buildings-and-utilities-data#how-we-gap-fill-your-facilities-data-3',
  FinanceMethodologyUpdates:
    'introduction-to-watershed-finances-methodology-updates',
  FootprintReview: 'reviewing-your-footprint',
  Deduplication: 'how-to-deduplicate-your-footprint',
  Recategorization: 'how-to-change-your-data-categories-with-your-footprint',
  WatershedFootprintReview:
    'reviewing-your-footprint#requesting-a-watershed-review-1',
  ApproveFootprint: 'completing-your-watershed-footprint',
  HowToUploadData: 'how-to-upload-data',
  ValidatingAndCorrectingDataErrors: 'validating-and-correcting-data-errors',
  ScienceBasedTargets: 'science-based-targets',
  Scope1Methodology: 'how-we-measure-direct-emissions-scope-1',
  Scope2Methodology: 'how-we-measure-purchased-energy-emissions-scope-2',
  Scope31Methodology:
    'how-we-measure-emissions-from-purchased-goods-and-services-scope-31',
  Scope32Methodology: 'how-we-measure-emissions-from-capital-goods-scope-32',
  Scope33Methodology:
    'how-we-measure-emissions-from-fuel-and-energy-related-activities-scope-33',
  Scope34Methodology:
    'how-we-measure-emissions-from-upstream-transportation-and-distribution-scope-34',
  Scope35Methodology: 'how-we-measure-emissions-from-waste-scope-35',
  Scope36Methodology: 'how-we-measure-emissions-from-business-travel-scope-36',
  Scope37Methodology:
    'how-we-measure-emissions-from-employee-commuting-scope-37',
  Scope38Methodology:
    'how-we-measure-emissions-from-upstream-leased-assets-scope-38',
  Scope39Methodology:
    'how-we-measure-emissions-from-downstream-transportation-and-distribution-scope-39',
  Scope310Methodology: 'how-we-measure-emissions-from-investments-scope-310',
  Scope311Methodology:
    'how-we-measure-emissions-from-use-of-sold-products-scope-311',
  Scope312Methodology:
    'how-we-measure-emissions-for-end-of-life-treatment-of-sold-products-scope-312',
  Scope313Methodology:
    'how-we-measure-emissions-from-downstream-leased-assets-scope-313',
  Scope314Methodology: 'how-we-measure-emissions-from-franchises-scope-314',
  ScienceOfTheClimateCrisis: 'science-of-the-climate-crisis',
  SettingCompanyAmbition: 'setting-company-ambition',
  Measurements: 'carbon-accounting-and-measurement',
  SelectingFuelCombustionPurpose:
    'selecting-a-purpose-for-your-buildings-fuel-combustion',
  CleanPowerPercentGuide: 'measuring-clean-power-usage-on-building-utilities',
  GettingStartedWithMeasurement:
    'getting-started-with-measurement#setting-up-your-measurement-2',
  WaterMetricKinds: 'water-metric-kinds',
  AnomalyDetection: 'anomaly-detection',
  PreparingForSecr: 'preparing-for-secr',
  FinancialMapping: 'financial-mapping',
  FullListBeaCodes: 'full-list-of-bea-codes',
  NonEmissiveSpend: 'what-is-non-emissive-spend-',
  PCAFScoring: 'how-does-pcaf-scoring-work',
  FinancedEmissions: 'what-are-financed-emissions',
  ScopesOfEmissions: 'scopes-of-emissions',
  FinancedEmissionsFromEquityBondsLoans:
    'measuring-financed-emissions-from-equity-corporate-bonds-and-business-loans',
  ClimateTargetSetting: 'climate-target-setting',
  CompanyEstimationEngine: 'cee',
  CompanyEstimationEngineIndustryCodes: 'cee#required-inputs-3',
  FinancedEmissionsFromRealEstate:
    'measuring-financed-emissions-from-commercial-real-estate',
  ReportingAndRegulations: 'reporting-and-regulations',
  UnderstandingDataUploadFormatsAndTemplates:
    'understanding-data-upload-formats-and-templates',
  UploadingAnExistingFootprint: 'uploading-an-existing-footprint',
  CustomReports: 'build-a-report#create-custom-reports-2',
  ReducingSupplyChainEmissions:
    'reducing-supply-chain-emissions-and-how-to-engage-your-suppliers-',
  CreateCustomMaturityScoring: 'how-to-create-custom-maturity-scoring',
  CreateCustomMetrics: 'how-to-create-custom-metrics',
  CedaWatershedFinance: 'using-ceda-in-watershed-finance',
};

export type LearningHubArticle = keyof typeof LearningHubArticleSlugMap;

export const learningHubHashForArticle = (
  article: LearningHubArticle
): string => {
  return LearningHubArticleSlugMap[article];
};

// If an article changes links, add it to the redirect map here so any old links still work
const LEARNING_HUB_REDIRECTS: Record<string, string> = {
  // Consolidating articles into one central data-collections-faq page
  'what-if-i-dont-have-any-data-for-this-dataset':
    'data-collection-faqs#what-if-i-dont-have-any-data-for-this-dataset-4',
  'can-i-include-multiple-data-sources-under-each-dataset-':
    'data-collection-faqs#how-do-i-add-multiple-tasks-under-each-dataset-2',
  'how-to-add-or-remove-datasets-after-setting-up-a-measurement':
    'data-collection-faqs#how-do-i-add-or-remove-datasets-5',
};

export const getLearningHubSlugWithRedirects = (slug: string): string =>
  LEARNING_HUB_REDIRECTS[slug] || slug;

// If you add a new place in the product that links to the learning hub, add it here for analytics
const LEARNING_HUB_ENTRY_POINTS = [
  'Sidebar',
  'MethodologyExplainer',
  'SupplyChainCharts',
  'DatasourceInstructionsSidebar',
  'DatasourceSummaryHelpDialog',
  'DataImporterHelpButton',
  'DataImporterLeaveDialog',
  'EmissionsFactorsPage',
  'EmissionsFactorsImporter',
  'MethodologyConfig',
  'SupplierPortalPage',
  'HomePage',
  'FootprintQuestionMenu',
  'RequestWatershedFootprintReview',
  'DeduplicationPage',
  'Facilities',
  'FootprintOverview',
  'UploadCompleteEmail',
  'FinanceSettings',
  'FinanceAssetSummary',
  'FinanceIndustryAutocomplete',
  'FinanceSandbox',
  'FinanceFootprintAssembly',
  'MeasurementHomePage',
  'BuildingUtilitiesDialog',
  'CategoryRulesInfoTooltip',
  'RecategorizationIndexView',
  'CategoryRulesDetail',
  'SecrReportingComponent',
  'FinancialValueMappingPage',
  'FinanceProduct',
  'AnomalyDetectionCallout',
  'NewMeasurementWizard',
  'FootprintConfig',
  'DrilldownConfiguration',
  'FootprintCreation',
  'ImportExistingFootprint',
  'CustomTagsSetup',
  'DatasetsPage',
  'CustomReportWelcomeMessage',
  'SupplyChainHomePage',
  'SupplyChainMaturityScoringPage',
] as const;

export type LearningHubEntryPoint = (typeof LEARNING_HUB_ENTRY_POINTS)[number];

export type LearningHubViewMode = 'regular' | 'finance' | 'fullPage';

export function learningHubBaseUrlForViewMode(
  viewMode: LearningHubViewMode
): string {
  switch (viewMode) {
    case 'regular':
      return '/learn';
    case 'finance':
      return WIN_FINANCE_PREFIX + '/learn';
    case 'fullPage':
      return '/learn/full';
    default:
      assertNever(viewMode);
  }
}
