import { formatNumber, formatPercentageNonzero } from '../utils/helpers';
import { t } from '@lingui/core/macro';

import { isElectricityContribution } from '../utils/isElectricity';
import invariant from 'invariant';
import assertNever from '../utils/assertNever';
import IntensityDenominators, {
  IntensityDenominatorKind,
} from '../utils/intensityDenominators';
import {
  BreakdownSeries,
  displayableAttributeLabel,
  PieChartDisplayData,
} from '../utils/analytics';
import { GQAggregateKind } from '../generated/graphql';
import { condenseMonetaryValue, isCurrencyCode } from '../utils/currencyUtils';

export function formatQuantity(
  quantityTotal: number | undefined,
  quantityUnit: string | undefined
): string {
  if (quantityTotal === undefined) {
    return '';
  }
  return formatQuantityWithFormattedNumber(
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/require-locale-argument
    formatNumber(quantityTotal, { maximumFractionDigits: 0 }),
    quantityUnit
  );
}

// TODO(LOC-32) Number formatting
export function formatQuantityWithFormattedNumber(
  quantityFormatted: string | undefined,
  quantityUnit: string | undefined
): string {
  if (!quantityUnit || !quantityFormatted) {
    return '';
  }

  let unitPrefix = '';
  if (['spend', 'USD'].includes(quantityUnit)) {
    unitPrefix = '$';
  }
  const unit = formatUnit(quantityUnit);
  return `${unitPrefix}${quantityFormatted}${
    unit.length > 0 ? ' ' : ''
  }${unit}`;
}

// TODO(LOC-30) Should we internationalize units? Not all of these are SI units
export function formatUnit(unit: string): string {
  switch (unit) {
    case 'gb':
      return t({ message: 'GB', comment: 'Gigabyte unit' });
    case 'kwh':
      return t({ message: 'kWh', comment: 'Kilowatt unit' });
    case 'employees':
      return t({
        message: 'employee-months',
        comment: 'Unit representing number of employees multiplied by months',
      });
    case 'square_feet':
      return t({
        message: 'square foot-months',
        comment: 'Unit representing total square-footage multiplied by months',
      });
    case 'square_meters':
      return t({
        message: 'square meter-months',
        comment: 'Unit representing total square meter multiplied by months',
      });
    case 'mmbtu':
      return t({
        message: 'MMBtu',
        comment: 'Metric million British thermal unit',
      });
    case 'tco2e':
      return t({
        message: 'tCO₂e',
        comment: 'Tonnes of carbon dioxide equivalent unit',
      });
    case 'none':
      return '';
  }

  // We have a bug somewhere in the pipeline that lower cases some currencies
  if (isCurrencyCode(unit.toUpperCase())) {
    return unit.toUpperCase();
  }

  // Units are stored with underscores, e.g. "passenger_miles".
  // We want to display "passenger miles" in the product.
  const underscoreRegex = /_/g;
  return unit.replace(underscoreRegex, ' ');
}

export function formatConversionChainFactor(factor: number | null): string {
  if (factor === null) {
    return '';
  }
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return formatNumber(factor, { maximumFractionDigits: 10 });
}

export function getAggregateKindInfo(aggregateKindProps: {
  aggregateKind: GQAggregateKind;
  intensityKind?: IntensityDenominatorKind;
  currency?: string | null;
  isExport?: boolean;
  shouldShowDefaultIsMarketBased?: boolean;
}): { title: string; subtitle: string } {
  const title = getAggregateKindLabel(aggregateKindProps);
  const subtitle = getAggregateKindSubTitle(aggregateKindProps);
  return { title, subtitle };
}

export function getAggregateKindLabel({
  aggregateKind,
  intensityKind,
  shouldShowDefaultIsMarketBased,
}: {
  aggregateKind: GQAggregateKind;
  intensityKind?: IntensityDenominatorKind;
  shouldShowDefaultIsMarketBased?: boolean;
}): string {
  switch (aggregateKind) {
    case GQAggregateKind.OriginalTotal:
    case GQAggregateKind.Total: {
      return shouldShowDefaultIsMarketBased
        ? `Emissions: market-based`
        : `Emissions`;
    }
    case GQAggregateKind.PercentageOfTotalFootprint: {
      return 'Emissions percentage';
    }
    case GQAggregateKind.PercentageOfCurrentView: {
      return 'Emissions percentage';
    }
    case GQAggregateKind.Electricity: {
      return `Electricity`;
    }
    case GQAggregateKind.LocationBased: {
      //TODO: is it ok to just have this say emissions everywhere?
      return shouldShowDefaultIsMarketBased
        ? 'Emissions: location-based'
        : `Location-based`;
    }
    case GQAggregateKind.RevenueIntensity: {
      return IntensityDenominators.revenue.label;
    }
    case GQAggregateKind.HeadcountIntensity: {
      return IntensityDenominators.headcount.label;
    }
    case GQAggregateKind.CustomIntensity: {
      invariant(
        intensityKind,
        'getAggregateKindInfo: intensityKind must be provided for custom intensity'
      );
      return IntensityDenominators[intensityKind].label;
    }

    default:
      assertNever(aggregateKind);
  }
}

export function getAggregateKindSubTitle({
  intensityKind,
  aggregateKind,
  isExport,
  currency,
}: {
  aggregateKind: GQAggregateKind;
  intensityKind?: IntensityDenominatorKind;
  isExport?: boolean;
  currency?: string | null;
}): string {
  const subscript2 = isExport ? '2' : '₂';
  switch (aggregateKind) {
    case GQAggregateKind.OriginalTotal:
    case GQAggregateKind.Total:
      return `tCO${subscript2}e`;

    case GQAggregateKind.PercentageOfTotalFootprint:
      return '% of total footprint';

    case GQAggregateKind.PercentageOfCurrentView:
      return '% of current view';

    case GQAggregateKind.Electricity: {
      return `MWh`;
    }
    case GQAggregateKind.LocationBased: {
      return `tCO${subscript2}e`;
    }
    case GQAggregateKind.RevenueIntensity: {
      const revDenominator = getCurrencyIntensityDenominator(
        'revenue',
        currency
      );
      return `tCO${subscript2}e per ${revDenominator} of revenue`;
    }
    case GQAggregateKind.HeadcountIntensity: {
      return IntensityDenominators.headcount.intensityUnit;
    }
    case GQAggregateKind.CustomIntensity: {
      invariant(
        intensityKind,
        'getAggregateKindInfo: intensityKind must be provided for custom intensity'
      );
      return possiblyConvertCurrencyUnit(
        intensityKind,
        'intensityUnit',
        currency
      );
    }

    default:
      assertNever(aggregateKind);
  }
}

export function getAggregateKindDescription({
  aggregateKind,
  intensityKind,
}: {
  aggregateKind: GQAggregateKind;
  intensityKind?: IntensityDenominatorKind;
}): string {
  switch (aggregateKind) {
    case GQAggregateKind.OriginalTotal:
    case GQAggregateKind.Total:
    case GQAggregateKind.PercentageOfTotalFootprint:
    case GQAggregateKind.PercentageOfCurrentView:
    case GQAggregateKind.Electricity:
    case GQAggregateKind.LocationBased:
      throw new Error('Descriptions only available for intensity');
    case GQAggregateKind.RevenueIntensity: {
      return IntensityDenominators.revenue.description;
    }
    case GQAggregateKind.HeadcountIntensity: {
      return IntensityDenominators.headcount.description;
    }
    case GQAggregateKind.CustomIntensity: {
      invariant(
        intensityKind,
        'getAggregateKindInfo: intensityKind must be provided for custom intensity'
      );
      return IntensityDenominators[intensityKind].description;
    }

    default:
      assertNever(aggregateKind);
  }
}

export function getNetLabelForGQAggregateKind({
  aggregateKind,
  intensityKind,
}: {
  aggregateKind: GQAggregateKind;
  intensityKind?: IntensityDenominatorKind;
}): string {
  switch (aggregateKind) {
    case GQAggregateKind.OriginalTotal:
    case GQAggregateKind.Total:
    case GQAggregateKind.PercentageOfTotalFootprint:
    case GQAggregateKind.PercentageOfCurrentView:
    case GQAggregateKind.LocationBased:
      return 'Net emissions';
    case GQAggregateKind.Electricity:
      return 'Net MWh';
    case GQAggregateKind.RevenueIntensity:
      return IntensityDenominators.revenue.label;
    case GQAggregateKind.HeadcountIntensity:
      return IntensityDenominators.headcount.label;
    case GQAggregateKind.CustomIntensity:
      invariant(
        intensityKind,
        'getNetRowLabelForGQAggregateKind: intensityKind must be provided for custom intensity'
      );
      return IntensityDenominators[intensityKind].label;
    default:
      assertNever(aggregateKind);
  }
}

export function getUnitLabelForGQAggregateKind({
  aggregateKind,
  intensityKind,
  currency,
}: {
  aggregateKind: GQAggregateKind;
  intensityKind?: IntensityDenominatorKind;
  currency?: string | null;
}): string {
  switch (aggregateKind) {
    case GQAggregateKind.OriginalTotal:
    case GQAggregateKind.Total:
    case GQAggregateKind.PercentageOfTotalFootprint:
    case GQAggregateKind.PercentageOfCurrentView:
    case GQAggregateKind.LocationBased:
      return 'tCO₂e';
    case GQAggregateKind.Electricity:
      return 'MWh';
    case GQAggregateKind.RevenueIntensity:
      return possiblyConvertCurrencyUnit('revenue', 'tooltipUnit', currency);
    case GQAggregateKind.HeadcountIntensity:
      return IntensityDenominators.headcount.tooltipUnit;
    case GQAggregateKind.CustomIntensity:
      invariant(
        intensityKind,
        'getUnitLabel: intensityKind must be provided for custom intensity'
      );
      return possiblyConvertCurrencyUnit(
        intensityKind,
        'tooltipUnit',
        currency
      );
    default:
      assertNever(aggregateKind);
  }
}

export function getUnitTooltipForGQAggregateKind({
  aggregateKind,
  intensityKind,
  currency,
}: {
  aggregateKind: GQAggregateKind;
  intensityKind?: IntensityDenominatorKind;
  currency?: string | null;
}): string {
  switch (aggregateKind) {
    case GQAggregateKind.OriginalTotal:
    case GQAggregateKind.Total:
    case GQAggregateKind.PercentageOfTotalFootprint:
    case GQAggregateKind.PercentageOfCurrentView:
    case GQAggregateKind.LocationBased:
      return 'tCO₂e';
    case GQAggregateKind.Electricity:
      return 'MWh';
    case GQAggregateKind.RevenueIntensity:
      return possiblyConvertCurrencyUnit('revenue', 'tooltipUnit', currency);
    case GQAggregateKind.HeadcountIntensity:
      return IntensityDenominators.headcount.tooltipUnit;
    case GQAggregateKind.CustomIntensity:
      invariant(
        intensityKind,
        'getUnitTooltip: intensityKind must be provided for custom intensity'
      );
      return possiblyConvertCurrencyUnit(
        intensityKind,
        'tooltipUnit',
        currency
      );
    default:
      assertNever(aggregateKind);
  }
}

/**
 * Helper function to total pie chart values in one unit
 * This function assumes the data has been scaled down by 1000, i.e. from
 * kgco2e to tco2e or kWh to MWh.
 * As of now, the scaling for the data is done in `valueAccessorForGQAggregateKind`
 */
function sumPieChartValues(
  data: Array<{
    x: string;
    y: number;
    label: string;
  }>
): number {
  const total = data.map((d) => d.y).reduce((a, b) => a + b, 0);
  return total;
}

export function getPieChartDisplayData({
  aggregateKind,
  intensityKind,
  currency,
  data,
}: {
  aggregateKind: GQAggregateKind;
  intensityKind?: IntensityDenominatorKind;
  currency?: string | null;
  data: BreakdownSeries;
}): PieChartDisplayData {
  const totalQuantity = sumPieChartValues(data.data);
  const roundedTotalQuantity = Math.round(totalQuantity);
  const centerLabelText = getPieCenterLabelText({
    aggregateKind,
    intensityKind,
    currency,
    totalQuantity: roundedTotalQuantity,
  });

  const labeledChartData = data.data.map((item) => {
    const displayLabel = `${((item.y / totalQuantity) * 100).toFixed(2)}%`;
    return {
      fieldValue: item.x,
      quantity: item.y,
      displayLabel,
      exportLabel: displayableAttributeLabel('footprintKind', item.x),
    };
  });
  const chartDataSeries = {
    fieldName: data.attribute.field,
    fieldDisplayName: displayableAttributeLabel(
      'footprintKind',
      data.attribute.field
    ),
    displayLabel: centerLabelText,
    unit: getUnitLabelForGQAggregateKind({ aggregateKind, intensityKind }),
    totalQuantity: roundedTotalQuantity,
    items: labeledChartData,
  };
  return chartDataSeries;
}

function convertCO2Unit(
  totalQuantity: number,
  denominator: boolean = true
): string {
  const newline = denominator ? '\n' : ' ';
  if (totalQuantity >= 1e9) {
    return (
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      formatNumber(totalQuantity / 1e6, {
        maximumFractionDigits: 2,
        includeTrailingZeros: true,
      }) +
      newline +
      'MtCO₂e'
    );
  } else if (totalQuantity >= 1e6) {
    return (
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      formatNumber(totalQuantity / 1e3, {
        maximumFractionDigits: 2,
        includeTrailingZeros: true,
      }) +
      newline +
      'ktCO₂e'
    );
  } else {
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/require-locale-argument
    return formatNumber(totalQuantity) + newline + 'tCO₂e';
  }
}

export function getPieCenterLabelText({
  aggregateKind,
  totalQuantity,
  intensityKind,
  currency,
}: {
  aggregateKind: GQAggregateKind;
  totalQuantity: number;
  intensityKind?: IntensityDenominatorKind;
  currency?: string | null;
}): string {
  switch (aggregateKind) {
    case GQAggregateKind.OriginalTotal:
    case GQAggregateKind.Total:
    case GQAggregateKind.PercentageOfTotalFootprint:
    case GQAggregateKind.PercentageOfCurrentView:
    case GQAggregateKind.LocationBased:
      return convertCO2Unit(totalQuantity, false);
    case GQAggregateKind.Electricity:
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      return `${formatNumber(totalQuantity)} MWh`;
    case GQAggregateKind.RevenueIntensity:
      const revenueUnit = possiblyConvertCurrencyUnit(
        'revenue',
        'unitUserFacing',
        currency
      );
      return `${convertCO2Unit(totalQuantity)}/ ${revenueUnit}`;
    case GQAggregateKind.HeadcountIntensity:
      return `${convertCO2Unit(totalQuantity)}/ ${
        IntensityDenominators.headcount.unitUserFacing
      }`;
    case GQAggregateKind.CustomIntensity:
      invariant(
        intensityKind,
        'getPieCenterLabelText: intensityKind must be provided for custom intensity'
      );
      const customUnit = possiblyConvertCurrencyUnit(
        intensityKind,
        'unitUserFacing',
        currency
      );
      return `${convertCO2Unit(totalQuantity)}/ ${customUnit}`;
    default:
      assertNever(aggregateKind);
  }
}

/**
 * Extracts the correct scaled quantity from a FootprintRow that has not yet been scaled.
 */
export function valueAccessorForGQAggregateKind({
  aggregateKind,
  intensityDenominator,
  row,
  intensityKind,
  numeratorMode,
}: {
  aggregateKind: GQAggregateKind;
  intensityDenominator?: number | null;
  row: {
    // Either of these are "market-based emissions"
    totalEmissions?: number | null;
    value?: number | null;

    // Either of these are "location-based emissions"
    totalLocationBasedEmissions?: number | null;
    locationBasedKgco2e?: number | null;

    quantityUnit?: string | null;

    // Either of these are "electricity"
    quantityValue?: number | null;
    quantityTotal?: number | null;
  };
  intensityKind?: IntensityDenominatorKind;

  // When we are in numerator mode, we aren't calculating ratios. We just want the numerator.
  numeratorMode?: boolean;
}): number | null {
  const marketBasedEmissionsTons =
    row.totalEmissions != null || row.value != null
      ? (row.totalEmissions ?? row.value ?? 0) / 1_000
      : null;
  const locationBasedEmissionsTons =
    row.totalLocationBasedEmissions != null || row.locationBasedKgco2e != null
      ? (row.totalLocationBasedEmissions ?? row.locationBasedKgco2e ?? 0) /
        1_000
      : null;
  switch (aggregateKind) {
    case GQAggregateKind.OriginalTotal:
    case GQAggregateKind.Total:
    case GQAggregateKind.PercentageOfTotalFootprint:
      return marketBasedEmissionsTons;
    case GQAggregateKind.PercentageOfCurrentView:
      return marketBasedEmissionsTons;
    case GQAggregateKind.LocationBased:
      return locationBasedEmissionsTons;
    case GQAggregateKind.Electricity:
      const electricity = row.quantityTotal ?? row.quantityValue;
      const electricityMegawatts =
        electricity != null ? electricity / 1_000 : null;
      return isElectricityContribution(row) ? electricityMegawatts : null;

    case GQAggregateKind.RevenueIntensity:
    case GQAggregateKind.HeadcountIntensity:
    case GQAggregateKind.CustomIntensity:
      if (numeratorMode) return marketBasedEmissionsTons;
      return valueProcessorForGQAggregateKind({
        value: marketBasedEmissionsTons,
        aggregateKind,
        intensityDenominator,
        intensityKind,
      });
    default:
      assertNever(aggregateKind);
  }
}

export function valueProcessorForGQAggregateKind({
  value,
  aggregateKind,
  intensityDenominator,
  intensityKind,
}: {
  value: number | null;
  aggregateKind: GQAggregateKind;
  intensityDenominator?: number | null;
  intensityKind?: IntensityDenominatorKind;
}): number | null {
  switch (aggregateKind) {
    case GQAggregateKind.OriginalTotal:
    case GQAggregateKind.Total:
    case GQAggregateKind.PercentageOfTotalFootprint:
    case GQAggregateKind.PercentageOfCurrentView:
    case GQAggregateKind.LocationBased:
    case GQAggregateKind.Electricity:
      return value;

    case GQAggregateKind.RevenueIntensity: {
      return value != null && intensityDenominator
        ? (value / intensityDenominator) *
            IntensityDenominators.revenue.scaleFactor
        : null;
    }
    case GQAggregateKind.HeadcountIntensity: {
      return value != null && intensityDenominator
        ? (value / intensityDenominator) *
            IntensityDenominators.headcount.scaleFactor
        : null;
    }
    case GQAggregateKind.CustomIntensity: {
      invariant(
        intensityKind,
        'valueAccessorForGQAggregateKind: intensityKind must be provided for custom intensity'
      );
      return value != null && intensityDenominator
        ? (value / intensityDenominator) *
            IntensityDenominators[intensityKind].scaleFactor
        : null;
    }
    default:
      assertNever(aggregateKind);
  }
}

export function drilldownDisplayLabel({
  aggregateKind,
  intensityKind,
  currency,
}: {
  aggregateKind: GQAggregateKind;
  intensityKind?: IntensityDenominatorKind;
  currency?: string | null;
}): string {
  switch (aggregateKind) {
    case GQAggregateKind.OriginalTotal:
    case GQAggregateKind.Total:
    case GQAggregateKind.LocationBased:
      return 'tCO₂e';
    case GQAggregateKind.PercentageOfTotalFootprint:
      return '%';
    case GQAggregateKind.PercentageOfCurrentView:
      return '%';
    case GQAggregateKind.Electricity:
      return 'MWh';
    case GQAggregateKind.RevenueIntensity:
      return possiblyConvertCurrencyUnit('revenue', 'tooltipUnit', currency);
    case GQAggregateKind.HeadcountIntensity:
      return IntensityDenominators.headcount.tooltipUnit;
    case GQAggregateKind.CustomIntensity:
      invariant(
        intensityKind,
        'drilldownDisplayLabel: intensityKind must be provided for custom intensity'
      );
      return possiblyConvertCurrencyUnit(
        intensityKind,
        'tooltipUnit',
        currency
      );
    default:
      assertNever(aggregateKind);
  }
}

export function formatDrilldownValue({
  value,
  valueMetadata,
  aggregateKind,
  intensityKind,
  includeUnit = false,
  includeNumerator = false,
  forExport,
  currency,
  precision: defaultPrecision,
}: {
  value: number;
  valueMetadata?: {
    numerator: number;
    denominator: number;
  };
  aggregateKind: GQAggregateKind;
  intensityKind?: IntensityDenominatorKind;
  includeUnit?: boolean;
  includeNumerator?: boolean;
  forExport?: boolean;
  currency?: string | null;
  precision?: number;
}): string {
  const invalidResult = '—';
  const precision = forExport ? 3 : defaultPrecision;

  // Ideally, the unit of the metadata is embedded in the valueMetadata
  const suffix =
    includeNumerator && valueMetadata
      ? // TODO: i18n (please resolve or remove this TODO line if legit)
        // eslint-disable-next-line @watershed/require-locale-argument
        `${formatNumber(valueMetadata.numerator, {
          maximumFractionDigits: precision,
        })} ${aggregateKind === GQAggregateKind.Electricity ? 'MWh' : 'tCO₂e'}`
      : null;

  // TODO(marissa): Move this value formatting to ContributionViewMode
  switch (aggregateKind) {
    case GQAggregateKind.OriginalTotal:
    case GQAggregateKind.Total:
    case GQAggregateKind.LocationBased:
    case GQAggregateKind.Electricity: {
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      let result = formatNumber(value || 0, {
        maximumFractionDigits: precision,
      });
      if (includeUnit) {
        result = `${result} ${drilldownDisplayLabel({
          aggregateKind,
          intensityKind,
          currency,
        })}`;
      }

      if (suffix) result += ` (${suffix})`;
      return result;
    }
    case GQAggregateKind.RevenueIntensity:
    case GQAggregateKind.HeadcountIntensity:
    case GQAggregateKind.CustomIntensity: {
      if (valueMetadata && valueMetadata.denominator === 0) {
        return invalidResult;
      }

      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      let result = formatNumber(value || 0, {
        maximumFractionDigits: precision,
      });
      if (includeUnit) {
        result = `${result} ${drilldownDisplayLabel({
          aggregateKind,
          intensityKind,
          currency,
        })}`;
      }
      if (suffix) result += ` (${suffix})`;
      return result;
    }

    case GQAggregateKind.PercentageOfCurrentView: {
      if (valueMetadata && valueMetadata.denominator === 0) {
        return invalidResult;
      }

      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      let result = formatPercentageNonzero(value);
      if (suffix) result += ` (${suffix})`;
      return result;
    }

    case GQAggregateKind.PercentageOfTotalFootprint: {
      if (valueMetadata && valueMetadata.denominator === 0) {
        return invalidResult;
      }

      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      let result = formatPercentageNonzero(value);
      if (suffix) result += ` (${suffix})`;
      return result;
    }

    default: {
      assertNever(aggregateKind);
    }
  }
}

export function getCurrencyIntensityDenominator(
  intensityKind: IntensityDenominatorKind,
  currency?: string | null
): string {
  const revCurrency = currency ?? 'USD';
  return condenseMonetaryValue(
    IntensityDenominators[intensityKind].scaleFactor,
    revCurrency
  );
}

function possiblyConvertCurrencyUnit(
  intensityKind: IntensityDenominatorKind,
  labelType: 'intensityUnit' | 'tooltipUnit' | 'unitUserFacing',
  currency?: string | null
) {
  if (IntensityDenominators[intensityKind].isCurrency) {
    const fromCurrencyString = getCurrencyIntensityDenominator(intensityKind);
    const toCurrencyString = getCurrencyIntensityDenominator(
      intensityKind,
      currency
    );
    return IntensityDenominators[intensityKind][labelType].replace(
      fromCurrencyString,
      toCurrencyString
    );
  } else {
    return IntensityDenominators[intensityKind][labelType];
  }
}

export function parseUnitString(quantityUnit: string): string {
  const formattedString = quantityUnit.replaceAll(/[_-]/g, ' ');
  // Handle casing for special units
  return formattedString
    .replace('kwh', 'kWh')
    .replace('mwh', 'MWh')
    .replace('mmbtu', 'MMBtu');
}
