import keyBy from 'lodash/keyBy';
import { CurrencyCode } from './utils/currencies';
import { GQCountryAlpha2 } from './generated/graphql';
import {
  WS_ISO3166_COUNTRIES,
  WS_ISO3166_COUNTRY_BY_ALPHA_2,
  getCountryName,
} from './geo/countries';

export type Country = {
  name: string;
  currencyCode: CurrencyCode;
  alternativeNames?: ReadonlyArray<string>;
  'alpha-2': GQCountryAlpha2;
  'country-code': string;
  'alpha-3': string;
};

// Pulled from https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/slim-2/slim-2.json merged with https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/slim-3/slim-3.json
export const COUNTRIES_CONST = [
  {
    name: 'Afghanistan',
    currencyCode: 'AFN',
    'alpha-2': 'AF',
    'country-code': '004',
    'alpha-3': 'AFG',
  },
  {
    name: 'Åland Islands',
    currencyCode: 'EUR',
    'alpha-2': 'AX',
    'country-code': '248',
    'alpha-3': 'ALA',
  },
  {
    name: 'Albania',
    currencyCode: 'ALL',
    'alpha-2': 'AL',
    'country-code': '008',
    'alpha-3': 'ALB',
  },
  {
    name: 'Algeria',
    currencyCode: 'DZD',
    'alpha-2': 'DZ',
    'country-code': '012',
    'alpha-3': 'DZA',
  },
  {
    name: 'American Samoa',
    currencyCode: 'USD',
    'alpha-2': 'AS',
    'country-code': '016',
    'alpha-3': 'ASM',
  },
  {
    name: 'Andorra',
    currencyCode: 'EUR',
    'alpha-2': 'AD',
    'country-code': '020',
    'alpha-3': 'AND',
  },
  {
    name: 'Angola',
    currencyCode: 'AOA',
    'alpha-2': 'AO',
    'country-code': '024',
    'alpha-3': 'AGO',
  },
  {
    name: 'Anguilla',
    currencyCode: 'XCD',
    'alpha-2': 'AI',
    'country-code': '660',
    'alpha-3': 'AIA',
  },
  {
    name: 'Antarctica',
    currencyCode: 'USD',
    'alpha-2': 'AQ',
    'country-code': '010',
    'alpha-3': 'ATA',
  },
  {
    name: 'Antigua and Barbuda',
    currencyCode: 'XCD',
    'alpha-2': 'AG',
    'country-code': '028',
    'alpha-3': 'ATG',
  },
  {
    name: 'Argentina',
    currencyCode: 'ARS',
    'alpha-2': 'AR',
    'country-code': '032',
    'alpha-3': 'ARG',
  },
  {
    name: 'Armenia',
    currencyCode: 'AMD',
    'alpha-2': 'AM',
    'country-code': '051',
    'alpha-3': 'ARM',
  },
  {
    name: 'Aruba',
    currencyCode: 'AWG',
    'alpha-2': 'AW',
    'country-code': '533',
    'alpha-3': 'ABW',
  },
  {
    name: 'Australia',
    currencyCode: 'AUD',
    'alpha-2': 'AU',
    'country-code': '036',
    'alpha-3': 'AUS',
  },
  {
    name: 'Austria',
    currencyCode: 'EUR',
    'alpha-2': 'AT',
    'country-code': '040',
    'alpha-3': 'AUT',
  },
  {
    name: 'Azerbaijan',
    currencyCode: 'AZN',
    'alpha-2': 'AZ',
    'country-code': '031',
    'alpha-3': 'AZE',
  },
  {
    name: 'Bahamas',
    currencyCode: 'BSD',
    'alpha-2': 'BS',
    'country-code': '044',
    'alpha-3': 'BHS',
  },
  {
    name: 'Bahrain',
    currencyCode: 'BHD',
    'alpha-2': 'BH',
    'country-code': '048',
    'alpha-3': 'BHR',
  },
  {
    name: 'Bangladesh',
    currencyCode: 'BDT',
    'alpha-2': 'BD',
    'country-code': '050',
    'alpha-3': 'BGD',
  },
  {
    name: 'Barbados',
    currencyCode: 'BBD',
    'alpha-2': 'BB',
    'country-code': '052',
    'alpha-3': 'BRB',
  },
  {
    name: 'Belarus',
    currencyCode: 'BYN',
    'alpha-2': 'BY',
    'country-code': '112',
    'alpha-3': 'BLR',
  },
  {
    name: 'Belgium',
    currencyCode: 'EUR',
    'alpha-2': 'BE',
    'country-code': '056',
    'alpha-3': 'BEL',
  },
  {
    name: 'Belize',
    currencyCode: 'BZD',
    'alpha-2': 'BZ',
    'country-code': '084',
    'alpha-3': 'BLZ',
  },
  {
    name: 'Benin',
    currencyCode: 'XOF',
    'alpha-2': 'BJ',
    'country-code': '204',
    'alpha-3': 'BEN',
  },
  {
    name: 'Bermuda',
    currencyCode: 'BMD',
    'alpha-2': 'BM',
    'country-code': '060',
    'alpha-3': 'BMU',
  },
  {
    name: 'Bhutan',
    currencyCode: 'BTN',
    'alpha-2': 'BT',
    'country-code': '064',
    'alpha-3': 'BTN',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    alternativeNames: ['Bolivia'],
    currencyCode: 'BOB',
    'alpha-2': 'BO',
    'country-code': '068',
    'alpha-3': 'BOL',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    currencyCode: 'USD',
    'alpha-2': 'BQ',
    'country-code': '535',
    'alpha-3': 'BES',
  },
  {
    name: 'Bosnia and Herzegovina',
    alternativeNames: ['Bosnia', 'Bosnia & Herzegovina'],
    currencyCode: 'BAM',
    'alpha-2': 'BA',
    'country-code': '070',
    'alpha-3': 'BIH',
  },
  {
    name: 'Botswana',
    currencyCode: 'BWP',
    'alpha-2': 'BW',
    'country-code': '072',
    'alpha-3': 'BWA',
  },
  {
    name: 'Bouvet Island',
    currencyCode: 'NOK',
    'alpha-2': 'BV',
    'country-code': '074',
    'alpha-3': 'BVT',
  },
  {
    name: 'Brazil',
    currencyCode: 'BRL',
    'alpha-2': 'BR',
    'country-code': '076',
    'alpha-3': 'BRA',
  },
  {
    name: 'British Indian Ocean Territory',
    currencyCode: 'GBP',
    'alpha-2': 'IO',
    'country-code': '086',
    'alpha-3': 'IOT',
  },
  {
    name: 'Brunei Darussalam',
    currencyCode: 'BND',
    'alpha-2': 'BN',
    'country-code': '096',
    'alpha-3': 'BRN',
  },
  {
    name: 'Bulgaria',
    currencyCode: 'BGN',
    'alpha-2': 'BG',
    'country-code': '100',
    'alpha-3': 'BGR',
  },
  {
    name: 'Burkina Faso',
    currencyCode: 'XOF',
    'alpha-2': 'BF',
    'country-code': '854',
    'alpha-3': 'BFA',
  },
  {
    name: 'Burundi',
    currencyCode: 'BIF',
    'alpha-2': 'BI',
    'country-code': '108',
    'alpha-3': 'BDI',
  },
  {
    name: 'Cabo Verde',
    currencyCode: 'CVE',
    'alpha-2': 'CV',
    'country-code': '132',
    'alpha-3': 'CPV',
  },
  {
    name: 'Cambodia',
    currencyCode: 'KHR',
    'alpha-2': 'KH',
    'country-code': '116',
    'alpha-3': 'KHM',
  },
  {
    name: 'Cameroon',
    currencyCode: 'XAF',
    'alpha-2': 'CM',
    'country-code': '120',
    'alpha-3': 'CMR',
  },
  {
    name: 'Canada',
    currencyCode: 'CAD',
    'alpha-2': 'CA',
    'country-code': '124',
    'alpha-3': 'CAN',
  },
  {
    name: 'Cayman Islands',
    currencyCode: 'KYD',
    'alpha-2': 'KY',
    'country-code': '136',
    'alpha-3': 'CYM',
  },
  {
    name: 'Central African Republic',
    currencyCode: 'XAF',
    'alpha-2': 'CF',
    'country-code': '140',
    'alpha-3': 'CAF',
  },
  {
    name: 'Chad',
    currencyCode: 'XAF',
    'alpha-2': 'TD',
    'country-code': '148',
    'alpha-3': 'TCD',
  },
  {
    name: 'Chile',
    currencyCode: 'CLP',
    'alpha-2': 'CL',
    'country-code': '152',
    'alpha-3': 'CHL',
  },
  {
    name: 'China',
    alternativeNames: ['PRC', "People's Republic of China", 'Greater China'],
    currencyCode: 'CNY',
    'alpha-2': 'CN',
    'country-code': '156',
    'alpha-3': 'CHN',
  },
  {
    name: 'Christmas Island',
    currencyCode: 'AUD',
    'alpha-2': 'CX',
    'country-code': '162',
    'alpha-3': 'CXR',
  },
  {
    name: 'Cocos (Keeling) Islands',
    currencyCode: 'AUD',
    'alpha-2': 'CC',
    'country-code': '166',
    'alpha-3': 'CCK',
  },
  {
    name: 'Colombia',
    currencyCode: 'COP',
    'alpha-2': 'CO',
    'country-code': '170',
    'alpha-3': 'COL',
  },
  {
    name: 'Comoros',
    currencyCode: 'KMF',
    'alpha-2': 'KM',
    'country-code': '174',
    'alpha-3': 'COM',
  },
  {
    name: 'Congo',
    currencyCode: 'XAF',
    'alpha-2': 'CG',
    'country-code': '178',
    'alpha-3': 'COG',
  },
  {
    name: 'Congo, Democratic Republic of the',
    alternativeNames: [
      'Congo, DR',
      'Congo, DRC',
      'Congo, Kinshasa',
      'DRC',
      'Democratic Republic of the Congo',
    ],
    currencyCode: 'CDF',
    'alpha-2': 'CD',
    'country-code': '180',
    'alpha-3': 'COD',
  },
  {
    name: 'Cook Islands',
    currencyCode: 'NZD',
    'alpha-2': 'CK',
    'country-code': '184',
    'alpha-3': 'COK',
  },
  {
    name: 'Costa Rica',
    currencyCode: 'CRC',
    'alpha-2': 'CR',
    'country-code': '188',
    'alpha-3': 'CRI',
  },
  {
    name: "Côte d'Ivoire",
    currencyCode: 'XOF',
    'alpha-2': 'CI',
    'country-code': '384',
    'alpha-3': 'CIV',
  },
  {
    name: 'Croatia',
    currencyCode: 'HRK',
    'alpha-2': 'HR',
    'country-code': '191',
    'alpha-3': 'HRV',
  },
  {
    name: 'Cuba',
    currencyCode: 'CUP',
    'alpha-2': 'CU',
    'country-code': '192',
    'alpha-3': 'CUB',
  },
  {
    name: 'Curaçao',
    currencyCode: 'ANG',
    'alpha-2': 'CW',
    'country-code': '531',
    'alpha-3': 'CUW',
  },
  {
    name: 'Cyprus',
    currencyCode: 'EUR',
    'alpha-2': 'CY',
    'country-code': '196',
    'alpha-3': 'CYP',
  },
  {
    name: 'Czechia',
    currencyCode: 'CZK',
    'alpha-2': 'CZ',
    'country-code': '203',
    'alpha-3': 'CZE',
  },
  {
    name: 'Denmark',
    currencyCode: 'DKK',
    'alpha-2': 'DK',
    'country-code': '208',
    'alpha-3': 'DNK',
  },
  {
    name: 'Djibouti',
    currencyCode: 'DJF',
    'alpha-2': 'DJ',
    'country-code': '262',
    'alpha-3': 'DJI',
  },
  {
    name: 'Dominica',
    currencyCode: 'XCD',
    'alpha-2': 'DM',
    'country-code': '212',
    'alpha-3': 'DMA',
  },
  {
    name: 'Dominican Republic',
    currencyCode: 'DOP',
    'alpha-2': 'DO',
    'country-code': '214',
    'alpha-3': 'DOM',
  },
  {
    name: 'Ecuador',
    currencyCode: 'USD',
    'alpha-2': 'EC',
    'country-code': '218',
    'alpha-3': 'ECU',
  },
  {
    name: 'Egypt',
    currencyCode: 'EGP',
    'alpha-2': 'EG',
    'country-code': '818',
    'alpha-3': 'EGY',
  },
  {
    name: 'El Salvador',
    currencyCode: 'SVC',
    'alpha-2': 'SV',
    'country-code': '222',
    'alpha-3': 'SLV',
  },
  {
    name: 'Equatorial Guinea',
    currencyCode: 'XAF',
    'alpha-2': 'GQ',
    'country-code': '226',
    'alpha-3': 'GNQ',
  },
  {
    name: 'Eritrea',
    currencyCode: 'ERN',
    'alpha-2': 'ER',
    'country-code': '232',
    'alpha-3': 'ERI',
  },
  {
    name: 'Estonia',
    currencyCode: 'EUR',
    'alpha-2': 'EE',
    'country-code': '233',
    'alpha-3': 'EST',
  },
  {
    name: 'Eswatini',
    currencyCode: 'SZL',
    'alpha-2': 'SZ',
    'country-code': '748',
    'alpha-3': 'SWZ',
  },
  {
    name: 'Ethiopia',
    currencyCode: 'ETB',
    'alpha-2': 'ET',
    'country-code': '231',
    'alpha-3': 'ETH',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    alternativeNames: ['Falkland Islands', 'Falklands'],
    currencyCode: 'FKP',
    'alpha-2': 'FK',
    'country-code': '238',
    'alpha-3': 'FLK',
  },
  {
    name: 'Faroe Islands',
    currencyCode: 'DKK',
    'alpha-2': 'FO',
    'country-code': '234',
    'alpha-3': 'FRO',
  },
  {
    name: 'Fiji',
    currencyCode: 'FJD',
    'alpha-2': 'FJ',
    'country-code': '242',
    'alpha-3': 'FJI',
  },
  {
    name: 'Finland',
    currencyCode: 'EUR',
    'alpha-2': 'FI',
    'country-code': '246',
    'alpha-3': 'FIN',
  },
  {
    name: 'France',
    currencyCode: 'EUR',
    'alpha-2': 'FR',
    'country-code': '250',
    'alpha-3': 'FRA',
  },
  {
    name: 'French Guiana',
    currencyCode: 'EUR',
    'alpha-2': 'GF',
    'country-code': '254',
    'alpha-3': 'GUF',
  },
  {
    name: 'French Polynesia',
    currencyCode: 'XPF',
    'alpha-2': 'PF',
    'country-code': '258',
    'alpha-3': 'PYF',
  },
  {
    name: 'French Southern Territories',
    currencyCode: 'EUR',
    'alpha-2': 'TF',
    'country-code': '260',
    'alpha-3': 'ATF',
  },
  {
    name: 'Gabon',
    currencyCode: 'XAF',
    'alpha-2': 'GA',
    'country-code': '266',
    'alpha-3': 'GAB',
  },
  {
    name: 'Gambia',
    currencyCode: 'GMD',
    'alpha-2': 'GM',
    'country-code': '270',
    'alpha-3': 'GMB',
  },
  {
    name: 'Georgia',
    currencyCode: 'GEL',
    'alpha-2': 'GE',
    'country-code': '268',
    'alpha-3': 'GEO',
  },
  {
    name: 'Germany',
    currencyCode: 'EUR',
    'alpha-2': 'DE',
    'country-code': '276',
    'alpha-3': 'DEU',
  },
  {
    name: 'Ghana',
    currencyCode: 'GHS',
    'alpha-2': 'GH',
    'country-code': '288',
    'alpha-3': 'GHA',
  },
  {
    name: 'Gibraltar',
    currencyCode: 'GIP',
    'alpha-2': 'GI',
    'country-code': '292',
    'alpha-3': 'GIB',
  },
  {
    name: 'Greece',
    currencyCode: 'EUR',
    'alpha-2': 'GR',
    'country-code': '300',
    'alpha-3': 'GRC',
  },
  {
    name: 'Greenland',
    currencyCode: 'DKK',
    'alpha-2': 'GL',
    'country-code': '304',
    'alpha-3': 'GRL',
  },
  {
    name: 'Grenada',
    currencyCode: 'XCD',
    'alpha-2': 'GD',
    'country-code': '308',
    'alpha-3': 'GRD',
  },
  {
    name: 'Guadeloupe',
    currencyCode: 'EUR',
    'alpha-2': 'GP',
    'country-code': '312',
    'alpha-3': 'GLP',
  },
  {
    name: 'Guam',
    currencyCode: 'USD',
    'alpha-2': 'GU',
    'country-code': '316',
    'alpha-3': 'GUM',
  },
  {
    name: 'Guatemala',
    currencyCode: 'GTQ',
    'alpha-2': 'GT',
    'country-code': '320',
    'alpha-3': 'GTM',
  },
  {
    name: 'Guernsey',
    currencyCode: 'GBP',
    'alpha-2': 'GG',
    'country-code': '831',
    'alpha-3': 'GGY',
  },
  {
    name: 'Guinea',
    currencyCode: 'GNF',
    'alpha-2': 'GN',
    'country-code': '324',
    'alpha-3': 'GIN',
  },
  {
    name: 'Guinea-Bissau',
    currencyCode: 'XOF',
    'alpha-2': 'GW',
    'country-code': '624',
    'alpha-3': 'GNB',
  },
  {
    name: 'Guyana',
    currencyCode: 'GYD',
    'alpha-2': 'GY',
    'country-code': '328',
    'alpha-3': 'GUY',
  },
  {
    name: 'Haiti',
    currencyCode: 'HTG',
    'alpha-2': 'HT',
    'country-code': '332',
    'alpha-3': 'HTI',
  },
  {
    name: 'Heard Island and McDonald Islands',
    currencyCode: 'AUD',
    'alpha-2': 'HM',
    'country-code': '334',
    'alpha-3': 'HMD',
  },
  {
    name: 'Holy See',
    currencyCode: 'EUR',
    'alpha-2': 'VA',
    'country-code': '336',
    'alpha-3': 'VAT',
  },
  {
    name: 'Honduras',
    currencyCode: 'HNL',
    'alpha-2': 'HN',
    'country-code': '340',
    'alpha-3': 'HND',
  },
  {
    name: 'Hong Kong',
    alternativeNames: [
      'Hong Kong SAR China',
      'China, Hong Kong Special Administrative Region',
      'Hong Kong, China',
      'Hong Kong, Province of China',
    ],
    currencyCode: 'HKD',
    'alpha-2': 'HK',
    'country-code': '344',
    'alpha-3': 'HKG',
  },
  {
    name: 'Hungary',
    currencyCode: 'HUF',
    'alpha-2': 'HU',
    'country-code': '348',
    'alpha-3': 'HUN',
  },
  {
    name: 'Iceland',
    currencyCode: 'ISK',
    'alpha-2': 'IS',
    'country-code': '352',
    'alpha-3': 'ISL',
  },
  {
    name: 'India',
    currencyCode: 'INR',
    'alpha-2': 'IN',
    'country-code': '356',
    'alpha-3': 'IND',
  },
  {
    name: 'Indonesia',
    currencyCode: 'IDR',
    'alpha-2': 'ID',
    'country-code': '360',
    'alpha-3': 'IDN',
  },
  {
    name: 'Iran (Islamic Republic of)',
    alternativeNames: ['Iran'],
    currencyCode: 'IRR',
    'alpha-2': 'IR',
    'country-code': '364',
    'alpha-3': 'IRN',
  },
  {
    name: 'Iraq',
    currencyCode: 'IQD',
    'alpha-2': 'IQ',
    'country-code': '368',
    'alpha-3': 'IRQ',
  },
  {
    name: 'Ireland',
    currencyCode: 'EUR',
    'alpha-2': 'IE',
    'country-code': '372',
    'alpha-3': 'IRL',
  },
  {
    name: 'Isle of Man',
    currencyCode: 'IMP',
    'alpha-2': 'IM',
    'country-code': '833',
    'alpha-3': 'IMN',
  },
  {
    name: 'Israel',
    currencyCode: 'ILS',
    'alpha-2': 'IL',
    'country-code': '376',
    'alpha-3': 'ISR',
  },
  {
    name: 'Italy',
    currencyCode: 'EUR',
    'alpha-2': 'IT',
    'country-code': '380',
    'alpha-3': 'ITA',
  },
  {
    name: 'Jamaica',
    currencyCode: 'JMD',
    'alpha-2': 'JM',
    'country-code': '388',
    'alpha-3': 'JAM',
  },
  {
    name: 'Japan',
    currencyCode: 'JPY',
    'alpha-2': 'JP',
    'country-code': '392',
    'alpha-3': 'JPN',
  },
  {
    name: 'Jersey',
    currencyCode: 'JEP',
    'alpha-2': 'JE',
    'country-code': '832',
    'alpha-3': 'JEY',
  },
  {
    name: 'Jordan',
    currencyCode: 'JOD',
    'alpha-2': 'JO',
    'country-code': '400',
    'alpha-3': 'JOR',
  },
  {
    name: 'Kazakhstan',
    currencyCode: 'KZT',
    'alpha-2': 'KZ',
    'country-code': '398',
    'alpha-3': 'KAZ',
  },
  {
    name: 'Kenya',
    currencyCode: 'KES',
    'alpha-2': 'KE',
    'country-code': '404',
    'alpha-3': 'KEN',
  },
  {
    name: 'Kiribati',
    currencyCode: 'AUD',
    'alpha-2': 'KI',
    'country-code': '296',
    'alpha-3': 'KIR',
  },
  {
    name: "Korea (Democratic People's Republic of)",
    alternativeNames: ['North Korea', "Democratic People's Republic of Korea"],
    currencyCode: 'KPW',
    'alpha-2': 'KP',
    'country-code': '408',
    'alpha-3': 'PRK',
  },
  {
    name: 'Korea, Republic of',
    alternativeNames: ['South Korea', 'Korea', 'Republic of Korea'],
    currencyCode: 'KRW',
    'alpha-2': 'KR',
    'country-code': '410',
    'alpha-3': 'KOR',
  },
  {
    name: 'Kuwait',
    currencyCode: 'KWD',
    'alpha-2': 'KW',
    'country-code': '414',
    'alpha-3': 'KWT',
  },
  {
    name: 'Kyrgyzstan',
    currencyCode: 'KGS',
    'alpha-2': 'KG',
    'country-code': '417',
    'alpha-3': 'KGZ',
  },
  {
    name: "Lao People's Democratic Republic",
    currencyCode: 'LAK',
    'alpha-2': 'LA',
    'country-code': '418',
    'alpha-3': 'LAO',
  },
  {
    name: 'Latvia',
    currencyCode: 'EUR',
    'alpha-2': 'LV',
    'country-code': '428',
    'alpha-3': 'LVA',
  },
  {
    name: 'Lebanon',
    currencyCode: 'LBP',
    'alpha-2': 'LB',
    'country-code': '422',
    'alpha-3': 'LBN',
  },
  {
    name: 'Lesotho',
    currencyCode: 'LSL',
    'alpha-2': 'LS',
    'country-code': '426',
    'alpha-3': 'LSO',
  },
  {
    name: 'Liberia',
    currencyCode: 'LRD',
    'alpha-2': 'LR',
    'country-code': '430',
    'alpha-3': 'LBR',
  },
  {
    name: 'Libya',
    currencyCode: 'LYD',
    'alpha-2': 'LY',
    'country-code': '434',
    'alpha-3': 'LBY',
  },
  {
    name: 'Liechtenstein',
    currencyCode: 'CHF',
    'alpha-2': 'LI',
    'country-code': '438',
    'alpha-3': 'LIE',
  },
  {
    name: 'Lithuania',
    currencyCode: 'EUR',
    'alpha-2': 'LT',
    'country-code': '440',
    'alpha-3': 'LTU',
  },
  {
    name: 'Luxembourg',
    currencyCode: 'EUR',
    'alpha-2': 'LU',
    'country-code': '442',
    'alpha-3': 'LUX',
  },
  {
    name: 'Macao',
    alternativeNames: [
      'Macau',
      'China, Macao Special Administrative Region',
      'Macau, Province of China',
    ],
    currencyCode: 'MOP',
    'alpha-2': 'MO',
    'country-code': '446',
    'alpha-3': 'MAC',
  },
  {
    name: 'Madagascar',
    currencyCode: 'MGA',
    'alpha-2': 'MG',
    'country-code': '450',
    'alpha-3': 'MDG',
  },
  {
    name: 'Malawi',
    currencyCode: 'MWK',
    'alpha-2': 'MW',
    'country-code': '454',
    'alpha-3': 'MWI',
  },
  {
    name: 'Malaysia',
    currencyCode: 'MYR',
    'alpha-2': 'MY',
    'country-code': '458',
    'alpha-3': 'MYS',
  },
  {
    name: 'Maldives',
    currencyCode: 'MVR',
    'alpha-2': 'MV',
    'country-code': '462',
    'alpha-3': 'MDV',
  },
  {
    name: 'Mali',
    currencyCode: 'XOF',
    'alpha-2': 'ML',
    'country-code': '466',
    'alpha-3': 'MLI',
  },
  {
    name: 'Malta',
    currencyCode: 'EUR',
    'alpha-2': 'MT',
    'country-code': '470',
    'alpha-3': 'MLT',
  },
  {
    name: 'Marshall Islands',
    currencyCode: 'USD',
    'alpha-2': 'MH',
    'country-code': '584',
    'alpha-3': 'MHL',
  },
  {
    name: 'Martinique',
    currencyCode: 'EUR',
    'alpha-2': 'MQ',
    'country-code': '474',
    'alpha-3': 'MTQ',
  },
  {
    name: 'Mauritania',
    currencyCode: 'MRU',
    'alpha-2': 'MR',
    'country-code': '478',
    'alpha-3': 'MRT',
  },
  {
    name: 'Mauritius',
    currencyCode: 'MUR',
    'alpha-2': 'MU',
    'country-code': '480',
    'alpha-3': 'MUS',
  },
  {
    name: 'Mayotte',
    currencyCode: 'EUR',
    'alpha-2': 'YT',
    'country-code': '175',
    'alpha-3': 'MYT',
  },
  {
    name: 'Mexico',
    currencyCode: 'MXN',
    'alpha-2': 'MX',
    'country-code': '484',
    'alpha-3': 'MEX',
  },
  {
    name: 'Micronesia (Federated States of)',
    alternativeNames: ['Micronesia'],
    currencyCode: 'USD',
    'alpha-2': 'FM',
    'country-code': '583',
    'alpha-3': 'FSM',
  },
  {
    name: 'Moldova, Republic of',
    alternativeNames: ['Moldova', 'Republic of Moldova'],
    currencyCode: 'MDL',
    'alpha-2': 'MD',
    'country-code': '498',
    'alpha-3': 'MDA',
  },
  {
    name: 'Monaco',
    currencyCode: 'EUR',
    'alpha-2': 'MC',
    'country-code': '492',
    'alpha-3': 'MCO',
  },
  {
    name: 'Mongolia',
    currencyCode: 'MNT',
    'alpha-2': 'MN',
    'country-code': '496',
    'alpha-3': 'MNG',
  },
  {
    name: 'Montenegro',
    currencyCode: 'EUR',
    'alpha-2': 'ME',
    'country-code': '499',
    'alpha-3': 'MNE',
  },
  {
    name: 'Montserrat',
    currencyCode: 'XCD',
    'alpha-2': 'MS',
    'country-code': '500',
    'alpha-3': 'MSR',
  },
  {
    name: 'Morocco',
    currencyCode: 'MAD',
    'alpha-2': 'MA',
    'country-code': '504',
    'alpha-3': 'MAR',
  },
  {
    name: 'Mozambique',
    currencyCode: 'MZN',
    'alpha-2': 'MZ',
    'country-code': '508',
    'alpha-3': 'MOZ',
  },
  {
    name: 'Myanmar',
    currencyCode: 'MMK',
    'alpha-2': 'MM',
    'country-code': '104',
    'alpha-3': 'MMR',
  },
  {
    name: 'Namibia',
    currencyCode: 'NAD',
    'alpha-2': 'NA',
    'country-code': '516',
    'alpha-3': 'NAM',
  },
  {
    name: 'Nauru',
    currencyCode: 'AUD',
    'alpha-2': 'NR',
    'country-code': '520',
    'alpha-3': 'NRU',
  },
  {
    name: 'Nepal',
    currencyCode: 'NPR',
    'alpha-2': 'NP',
    'country-code': '524',
    'alpha-3': 'NPL',
  },
  {
    name: 'Netherlands',
    currencyCode: 'EUR',
    'alpha-2': 'NL',
    'country-code': '528',
    'alpha-3': 'NLD',
  },
  {
    name: 'New Caledonia',
    currencyCode: 'XPF',
    'alpha-2': 'NC',
    'country-code': '540',
    'alpha-3': 'NCL',
  },
  {
    name: 'New Zealand',
    currencyCode: 'NZD',
    'alpha-2': 'NZ',
    'country-code': '554',
    'alpha-3': 'NZL',
  },
  {
    name: 'Nicaragua',
    currencyCode: 'NIO',
    'alpha-2': 'NI',
    'country-code': '558',
    'alpha-3': 'NIC',
  },
  {
    name: 'Niger',
    currencyCode: 'XOF',
    'alpha-2': 'NE',
    'country-code': '562',
    'alpha-3': 'NER',
  },
  {
    name: 'Nigeria',
    currencyCode: 'NGN',
    'alpha-2': 'NG',
    'country-code': '566',
    'alpha-3': 'NGA',
  },
  {
    name: 'Niue',
    currencyCode: 'NZD',
    'alpha-2': 'NU',
    'country-code': '570',
    'alpha-3': 'NIU',
  },
  {
    name: 'Norfolk Island',
    currencyCode: 'AUD',
    'alpha-2': 'NF',
    'country-code': '574',
    'alpha-3': 'NFK',
  },
  {
    name: 'North Macedonia',
    currencyCode: 'MKD',
    'alpha-2': 'MK',
    'country-code': '807',
    'alpha-3': 'MKD',
  },
  {
    name: 'Northern Mariana Islands',
    currencyCode: 'USD',
    'alpha-2': 'MP',
    'country-code': '580',
    'alpha-3': 'MNP',
  },
  {
    name: 'Norway',
    currencyCode: 'NOK',
    'alpha-2': 'NO',
    'country-code': '578',
    'alpha-3': 'NOR',
  },
  {
    name: 'Oman',
    currencyCode: 'OMR',
    'alpha-2': 'OM',
    'country-code': '512',
    'alpha-3': 'OMN',
  },
  {
    name: 'Pakistan',
    currencyCode: 'PKR',
    'alpha-2': 'PK',
    'country-code': '586',
    'alpha-3': 'PAK',
  },
  {
    name: 'Palau',
    currencyCode: 'USD',
    'alpha-2': 'PW',
    'country-code': '585',
    'alpha-3': 'PLW',
  },
  {
    name: 'Palestine, State of',
    alternativeNames: ['State of Palestine'],
    currencyCode: 'ILS',
    'alpha-2': 'PS',
    'country-code': '275',
    'alpha-3': 'PSE',
  },
  {
    name: 'Panama',
    currencyCode: 'PAB',
    'alpha-2': 'PA',
    'country-code': '591',
    'alpha-3': 'PAN',
  },
  {
    name: 'Papua New Guinea',
    currencyCode: 'PGK',
    'alpha-2': 'PG',
    'country-code': '598',
    'alpha-3': 'PNG',
  },
  {
    name: 'Paraguay',
    currencyCode: 'PYG',
    'alpha-2': 'PY',
    'country-code': '600',
    'alpha-3': 'PRY',
  },
  {
    name: 'Peru',
    currencyCode: 'PEN',
    'alpha-2': 'PE',
    'country-code': '604',
    'alpha-3': 'PER',
  },
  {
    name: 'Philippines',
    currencyCode: 'PHP',
    'alpha-2': 'PH',
    'country-code': '608',
    'alpha-3': 'PHL',
  },
  {
    name: 'Pitcairn',
    currencyCode: 'NZD',
    'alpha-2': 'PN',
    'country-code': '612',
    'alpha-3': 'PCN',
  },
  {
    name: 'Poland',
    currencyCode: 'PLN',
    'alpha-2': 'PL',
    'country-code': '616',
    'alpha-3': 'POL',
  },
  {
    name: 'Portugal',
    currencyCode: 'EUR',
    'alpha-2': 'PT',
    'country-code': '620',
    'alpha-3': 'PRT',
  },
  {
    name: 'Puerto Rico',
    currencyCode: 'USD',
    'alpha-2': 'PR',
    'country-code': '630',
    'alpha-3': 'PRI',
  },
  {
    name: 'Qatar',
    currencyCode: 'QAR',
    'alpha-2': 'QA',
    'country-code': '634',
    'alpha-3': 'QAT',
  },
  {
    name: 'Réunion',
    currencyCode: 'EUR',
    'alpha-2': 'RE',
    'country-code': '638',
    'alpha-3': 'REU',
  },
  {
    name: 'Romania',
    currencyCode: 'RON',
    'alpha-2': 'RO',
    'country-code': '642',
    'alpha-3': 'ROU',
  },
  {
    name: 'Russian Federation',
    alternativeNames: ['Russia'],
    currencyCode: 'RUR',
    'alpha-2': 'RU',
    'country-code': '643',
    'alpha-3': 'RUS',
  },
  {
    name: 'Rwanda',
    currencyCode: 'RWF',
    'alpha-2': 'RW',
    'country-code': '646',
    'alpha-3': 'RWA',
  },
  {
    name: 'Saint Barthélemy',
    currencyCode: 'EUR',
    'alpha-2': 'BL',
    'country-code': '652',
    'alpha-3': 'BLM',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alternativeNames: ['Saint Helena'],
    currencyCode: 'GBP',
    'alpha-2': 'SH',
    'country-code': '654',
    'alpha-3': 'SHN',
  },
  {
    name: 'Saint Kitts and Nevis',
    currencyCode: 'XCD',
    'alpha-2': 'KN',
    'country-code': '659',
    'alpha-3': 'KNA',
  },
  {
    name: 'Saint Lucia',
    currencyCode: 'XCD',
    'alpha-2': 'LC',
    'country-code': '662',
    'alpha-3': 'LCA',
  },
  {
    name: 'Saint Martin (French part)',
    alternativeNames: ['Saint Martin', 'St. Martin'],
    currencyCode: 'EUR',
    'alpha-2': 'MF',
    'country-code': '663',
    'alpha-3': 'MAF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    currencyCode: 'EUR',
    'alpha-2': 'PM',
    'country-code': '666',
    'alpha-3': 'SPM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    alternativeNames: ['Saint Vincent'],
    currencyCode: 'XCD',
    'alpha-2': 'VC',
    'country-code': '670',
    'alpha-3': 'VCT',
  },
  {
    name: 'Samoa',
    currencyCode: 'WST',
    'alpha-2': 'WS',
    'country-code': '882',
    'alpha-3': 'WSM',
  },
  {
    name: 'San Marino',
    currencyCode: 'EUR',
    'alpha-2': 'SM',
    'country-code': '674',
    'alpha-3': 'SMR',
  },
  {
    name: 'Sao Tome and Principe',
    currencyCode: 'STD',
    'alpha-2': 'ST',
    'country-code': '678',
    'alpha-3': 'STP',
  },
  {
    name: 'Saudi Arabia',
    currencyCode: 'SAR',
    'alpha-2': 'SA',
    'country-code': '682',
    'alpha-3': 'SAU',
  },
  {
    name: 'Senegal',
    currencyCode: 'XOF',
    'alpha-2': 'SN',
    'country-code': '686',
    'alpha-3': 'SEN',
  },
  {
    name: 'Serbia',
    currencyCode: 'RSD',
    'alpha-2': 'RS',
    'country-code': '688',
    'alpha-3': 'SRB',
  },
  {
    name: 'Seychelles',
    currencyCode: 'SCR',
    'alpha-2': 'SC',
    'country-code': '690',
    'alpha-3': 'SYC',
  },
  {
    name: 'Sierra Leone',
    currencyCode: 'SLL',
    'alpha-2': 'SL',
    'country-code': '694',
    'alpha-3': 'SLE',
  },
  {
    name: 'Singapore',
    currencyCode: 'SGD',
    'alpha-2': 'SG',
    'country-code': '702',
    'alpha-3': 'SGP',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    currencyCode: 'ANG',
    'alpha-2': 'SX',
    'country-code': '534',
    'alpha-3': 'SXM',
  },
  {
    name: 'Slovakia',
    currencyCode: 'EUR',
    'alpha-2': 'SK',
    'country-code': '703',
    'alpha-3': 'SVK',
  },
  {
    name: 'Slovenia',
    currencyCode: 'EUR',
    'alpha-2': 'SI',
    'country-code': '705',
    'alpha-3': 'SVN',
  },
  {
    name: 'Solomon Islands',
    currencyCode: 'SBD',
    'alpha-2': 'SB',
    'country-code': '090',
    'alpha-3': 'SLB',
  },
  {
    name: 'Somalia',
    currencyCode: 'SOS',
    'alpha-2': 'SO',
    'country-code': '706',
    'alpha-3': 'SOM',
  },
  {
    name: 'South Africa',
    currencyCode: 'ZAR',
    'alpha-2': 'ZA',
    'country-code': '710',
    'alpha-3': 'ZAF',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    currencyCode: 'GBP',
    'alpha-2': 'GS',
    'country-code': '239',
    'alpha-3': 'SGS',
  },
  {
    name: 'South Sudan',
    currencyCode: 'SSP',
    'alpha-2': 'SS',
    'country-code': '728',
    'alpha-3': 'SSD',
  },
  {
    name: 'Spain',
    currencyCode: 'EUR',
    'alpha-2': 'ES',
    'country-code': '724',
    'alpha-3': 'ESP',
  },
  {
    name: 'Sri Lanka',
    currencyCode: 'LKR',
    'alpha-2': 'LK',
    'country-code': '144',
    'alpha-3': 'LKA',
  },
  {
    name: 'Sudan',
    currencyCode: 'SDG',
    'alpha-2': 'SD',
    'country-code': '729',
    'alpha-3': 'SDN',
  },
  {
    name: 'Suriname',
    currencyCode: 'SRD',
    'alpha-2': 'SR',
    'country-code': '740',
    'alpha-3': 'SUR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    alternativeNames: ['Svalbard and Jan Mayen Islands'],
    currencyCode: 'NOK',
    'alpha-2': 'SJ',
    'country-code': '744',
    'alpha-3': 'SJM',
  },
  {
    name: 'Sweden',
    currencyCode: 'SEK',
    'alpha-2': 'SE',
    'country-code': '752',
    'alpha-3': 'SWE',
  },
  {
    name: 'Switzerland',
    currencyCode: 'CHF',
    'alpha-2': 'CH',
    'country-code': '756',
    'alpha-3': 'CHE',
  },
  {
    name: 'Syrian Arab Republic',
    currencyCode: 'SYP',
    'alpha-2': 'SY',
    'country-code': '760',
    'alpha-3': 'SYR',
  },
  {
    name: 'Taiwan',
    alternativeNames: [
      'Taiwan',
      'Taiwan, China',
      'Taiwan, Greater China',
      'Taiwan, Province of China',
    ],
    currencyCode: 'TWD',
    'alpha-2': 'TW',
    'country-code': '158',
    'alpha-3': 'TWN',
  },
  {
    name: 'Tajikistan',
    currencyCode: 'TJS',
    'alpha-2': 'TJ',
    'country-code': '762',
    'alpha-3': 'TJK',
  },
  {
    name: 'Tanzania, United Republic of',
    alternativeNames: ['Tanzania', 'United Republic of Tanzania'],
    currencyCode: 'TZS',
    'alpha-2': 'TZ',
    'country-code': '834',
    'alpha-3': 'TZA',
  },
  {
    name: 'Thailand',
    currencyCode: 'THB',
    'alpha-2': 'TH',
    'country-code': '764',
    'alpha-3': 'THA',
  },
  {
    name: 'Timor-Leste',
    currencyCode: 'USD',
    'alpha-2': 'TL',
    'country-code': '626',
    'alpha-3': 'TLS',
  },
  {
    name: 'Togo',
    currencyCode: 'XOF',
    'alpha-2': 'TG',
    'country-code': '768',
    'alpha-3': 'TGO',
  },
  {
    name: 'Tokelau',
    currencyCode: 'NZD',
    'alpha-2': 'TK',
    'country-code': '772',
    'alpha-3': 'TKL',
  },
  {
    name: 'Tonga',
    currencyCode: 'TOP',
    'alpha-2': 'TO',
    'country-code': '776',
    'alpha-3': 'TON',
  },
  {
    name: 'Trinidad and Tobago',
    currencyCode: 'TTD',
    'alpha-2': 'TT',
    'country-code': '780',
    'alpha-3': 'TTO',
  },
  {
    name: 'Tunisia',
    currencyCode: 'TND',
    'alpha-2': 'TN',
    'country-code': '788',
    'alpha-3': 'TUN',
  },
  {
    name: 'Turkey',
    alternativeNames: ['Türkiye'],
    currencyCode: 'TRY',
    'alpha-2': 'TR',
    'country-code': '792',
    'alpha-3': 'TUR',
  },
  {
    name: 'Turkmenistan',
    currencyCode: 'TMT',
    'alpha-2': 'TM',
    'country-code': '795',
    'alpha-3': 'TKM',
  },
  {
    name: 'Turks and Caicos Islands',
    alternativeNames: ['Turks and Caicos'],
    currencyCode: 'USD',
    'alpha-2': 'TC',
    'country-code': '796',
    'alpha-3': 'TCA',
  },
  {
    name: 'Tuvalu',
    currencyCode: 'AUD',
    'alpha-2': 'TV',
    'country-code': '798',
    'alpha-3': 'TUV',
  },
  {
    name: 'Uganda',
    currencyCode: 'UGX',
    'alpha-2': 'UG',
    'country-code': '800',
    'alpha-3': 'UGA',
  },
  {
    name: 'Ukraine',
    currencyCode: 'UAH',
    'alpha-2': 'UA',
    'country-code': '804',
    'alpha-3': 'UKR',
  },
  {
    name: 'United Arab Emirates',
    currencyCode: 'AED',
    'alpha-2': 'AE',
    'country-code': '784',
    'alpha-3': 'ARE',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alternativeNames: [
      'United Kingdom',
      'UK',
      'Great Britain',
      'England',
      'Scotland',
      'Wales',
      'Northern Ireland',
    ],
    currencyCode: 'GBP',
    'alpha-2': 'GB',
    'country-code': '826',
    'alpha-3': 'GBR',
  },
  {
    name: 'United States of America',
    alternativeNames: ['United States', 'USA', 'America'],
    currencyCode: 'USD',
    'alpha-2': 'US',
    'country-code': '840',
    'alpha-3': 'USA',
  },
  {
    name: 'United States Minor Outlying Islands',
    currencyCode: 'USD',
    'alpha-2': 'UM',
    'country-code': '581',
    'alpha-3': 'UMI',
  },
  {
    name: 'Uruguay',
    currencyCode: 'UYU',
    'alpha-2': 'UY',
    'country-code': '858',
    'alpha-3': 'URY',
  },
  {
    name: 'Uzbekistan',
    currencyCode: 'UZS',
    'alpha-2': 'UZ',
    'country-code': '860',
    'alpha-3': 'UZB',
  },
  {
    name: 'Vanuatu',
    currencyCode: 'VUV',
    'alpha-2': 'VU',
    'country-code': '548',
    'alpha-3': 'VUT',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    alternativeNames: ['Venezuela'],
    currencyCode: 'VEF',
    'alpha-2': 'VE',
    'country-code': '862',
    'alpha-3': 'VEN',
  },
  {
    name: 'Viet Nam',
    alternativeNames: ['Vietnam'],
    currencyCode: 'VND',
    'alpha-2': 'VN',
    'country-code': '704',
    'alpha-3': 'VNM',
  },
  {
    name: 'Virgin Islands (British)',
    alternativeNames: ['British Virgin Islands'],
    currencyCode: 'GBP',
    'alpha-2': 'VG',
    'country-code': '092',
    'alpha-3': 'VGB',
  },
  {
    name: 'Virgin Islands (U.S.)',
    alternativeNames: ['United States Virgin Islands'],
    currencyCode: 'USD',
    'alpha-2': 'VI',
    'country-code': '850',
    'alpha-3': 'VIR',
  },
  {
    name: 'Wallis and Futuna',
    alternativeNames: ['Wallis and Futuna Islands'],
    currencyCode: 'XPF',
    'alpha-2': 'WF',
    'country-code': '876',
    'alpha-3': 'WLF',
  },
  {
    name: 'Western Sahara',
    currencyCode: 'MAD',
    'alpha-2': 'EH',
    'country-code': '732',
    'alpha-3': 'ESH',
  },
  {
    name: 'Yemen',
    currencyCode: 'YER',
    'alpha-2': 'YE',
    'country-code': '887',
    'alpha-3': 'YEM',
  },
  {
    name: 'Zambia',
    currencyCode: 'ZMW',
    'alpha-2': 'ZM',
    'country-code': '894',
    'alpha-3': 'ZMB',
  },
  {
    name: 'Zimbabwe',
    currencyCode: 'USD',
    'alpha-2': 'ZW',
    'country-code': '716',
    'alpha-3': 'ZWE',
  },
] as const;
export type CountryName = (typeof COUNTRIES_CONST)[number]['name'];
export const countries: ReadonlyArray<Country> = COUNTRIES_CONST;

export function getCurrencyCodeForCountryCode(
  countryCodeAlpha2: string
): CurrencyCode | undefined {
  return countries.find((country) => country['alpha-2'] === countryCodeAlpha2)
    ?.currencyCode;
}

export const countryByCode = keyBy(countries, (country) => country['alpha-2']);
export const countryByName = keyBy(countries, (country) => country['name']);

function countryToAltNames(c: Country) {
  return [c.name, c['alpha-2'], c['alpha-3'], ...(c.alternativeNames ?? [])];
}

const countryAltsToCanonical = Object.fromEntries(
  countries.flatMap((c) => countryToAltNames(c).map((alt) => [alt, c.name]))
);
export function cleanCountryName(country?: string | null): string {
  return countryAltsToCanonical[country ?? ''];
}

export function getIsoCountryName(countryAlpha2?: string | null): string {
  // Puerto Rico is the only country that is being overridden to be US
  if (countryAlpha2 === 'PR') {
    return getCountryName(WS_ISO3166_COUNTRY_BY_ALPHA_2['US']) ?? '';
  }
  const country = WS_ISO3166_COUNTRY_BY_ALPHA_2[countryAlpha2 ?? ''];

  return country ? getCountryName(country) : null ?? '';
}

export const validCountryAlts = COUNTRIES_CONST.flatMap(countryToAltNames);

export const wsIso3166CountryAltsToCanonical = Object.fromEntries(
  WS_ISO3166_COUNTRIES.flatMap((c) =>
    [
      c.name,
      ...(c.aliases ?? []),
      ...(c.official_name ? [c.official_name] : []),
      ...(c.common_name ? [c.common_name] : []),
      c.alpha_2,
      c.alpha_3,
    ].map((alt) => [alt, getCountryName(c)])
  )
);
export function cleanIso3166CountryName(
  country?: string | null
): string | null {
  if (!country) {
    return null;
  }
  return wsIso3166CountryAltsToCanonical[country];
}
