import isNotNullish from '../utils/isNotNullish';
import { ErrorCode } from './ErrorRegistry';

export type AnyError = any;
export type ErrorData = {
  [key: string]: unknown;
};

/**
 * WatershedErrorOptions is a little verbose but ErrorOptions is already taken
 * by native javascript types
 */
export interface WatershedErrorOptions {
  /**
   * The original exception, e.g. downstream API error
   */
  cause?: AnyError;
  /**
   * Instance-specific data for sentry, client-handling, etc.
   */
  data?: ErrorData;
  /**
   * Whether the error is a transient or intermittent failure. This property is
   * useful for the caller to make decisions about retrying a failed operation.
   */
  retryable?: boolean;
}

export interface ErrorWithData {
  data: ErrorData;
}
export function hasErrorData(error: AnyError): error is ErrorWithData {
  return isNotNullish((error as ErrorWithData)?.data);
}

interface ErrorWithMessage {
  message: string;
}
export function hasErrorMessage(error: AnyError): error is ErrorWithMessage {
  return isNotNullish((error as ErrorWithMessage)?.message);
}

interface ErrorWithCause {
  cause: AnyError;
}
export function hasErrorCause(error: AnyError): error is ErrorWithCause {
  return isNotNullish((error as ErrorWithCause)?.cause);
}

/*
  WatershedError is a general-purpose error type that includes additional
  computer-legible metadata about what sort of error it is, and who is to blame
  for it (e.g., it's a input error, and it was caused by the user). We use this
  to ensure the error is routed appropriately (e.g., to Sentry or other
  operational tooling).

  It was originally extracted from our "service-essentials" library, where it
  was intended to mirror errors raised by our GraphQL tooling. In particular,
  the computer-legible metadata was used to set error codes and HTTP response
  codes, and some aspects of that original use case have carried over to this
  "isomorphic" library (with apologies to Spike). But some of the errors that
  extend from this base class have found use more broadly in browser contexts
  and have therefore been extracted to shared-universal.

  We use a string-based code because we have had problems using `err instanceof
  ForbiddenError`. In some cases this works fine, but we have found reproducible
  error cases across module boundaries where this is always returning false, so
  the string comparison is thought to be more reliable.

  If an error is thrown from a library that doesn't match this signature,
  we will — by default — consider it a Programmer Error, which would be
  sent to Sentry for urgent resolution.

  Since `invariant()` is treated as a ProgrammerError (sent to Sentry for
  urgent resolution), we want to use these more specific errors when we
  are scanning for errors in our code.

  For more information on errors and handling them, please refer to:
  https://www.notion.so/watershedclimate/Errors-Error-Handling-Error-Debugging-bebb118d26de4f978070d7075bf22c3b
*/
export abstract class WatershedError extends Error {
  readonly name: string;
  readonly code: ErrorCode;
  readonly cause?: AnyError;
  readonly status?: number;
  readonly retryable?: boolean;

  /**
   * Instance-specific data for sentry context, client handling, etc.
   */
  readonly data: ErrorData;

  /**
   * Passed to the client by GraphQL
   * https://www.apollographql.com/docs/apollo-server/data/errors/#including-custom-error-details
   */
  extensions: {
    code: string;
    [key: string]: any;
  };

  constructor(
    code: ErrorCode,
    message?: string,
    options?: WatershedErrorOptions
  ) {
    const concatenatedMessage = [message, options?.cause?.message]
      .filter(isNotNullish)
      .join(': ');
    super(concatenatedMessage);
    this.name = this.constructor.name;
    this.cause = options?.cause;
    this.code = code;
    this.retryable = options?.retryable;
    this.data = { ...options?.data };
    // Populate `extensions` so these values are picked up by GraphQLErrors.
    this.extensions = {
      ...(!!options?.data ? { data: options?.data } : undefined),
      code,
    };
  }
}

export function makeRethrower(
  ErrorClass: new (
    message?: string,
    options?: WatershedErrorOptions
  ) => WatershedError,
  message?: string,
  options?: WatershedErrorOptions
) {
  return (cause?: AnyError): never => {
    throw new ErrorClass(message, { ...options, cause });
  };
}

export interface CustomErrorInvariant {
  (
    condition: any,
    message?: string,
    options?: WatershedErrorOptions
  ): asserts condition;
}

/**
 *  Use as a static function in a subclass to support the invariant pattern:
 *    WatershedError.invariant(foo !== bar, 'msg')
 */
export function makeCustomErrorInvariant(
  ErrorClass: new (message?: string, options?: WatershedErrorOptions) => unknown
): CustomErrorInvariant {
  return (condition, message, options) => {
    if (!condition) {
      throw new ErrorClass(message, options);
    }
  };
}
