import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import useEffectOnce from '../hooks/useEffectOnce';
import nullifyEmptyString from '@watershed/shared-universal/utils/nullifyEmptyString';
import { pushQuery, replaceQuery } from './queryParamUtils';
import assertNever from '@watershed/shared-universal/utils/assertNever';
import { mapStrToEnumOrNull } from '@watershed/shared-universal/utils/mapStrToEnum';
import filterObjectNulls from '@watershed/shared-universal/utils/filterObjectNulls';
import {
  omitQueryParams,
  parseQueryParam,
  setParam,
} from '@watershed/shared-universal/utils/queryParamUtils';
import useGlobalLocation from '@watershed/ui-core/hooks/useGlobalLocation';

interface UseQueryParamOptions {
  method?: 'replace' | 'push';
  shallow?: boolean;
}

interface UseQueryParamSetOptions {
  additionalParams?: { [key: string]: string | null };
}

/**
 * Provides the value of a query parameter, and a setter to change it.
 *
 * @param options - 'replace' (default) will replace the current history entry,
 * 'push' will add a new history entry.
 */
export function useQueryParam(
  paramName: string,
  options: UseQueryParamOptions = {}
): [
  string | null,
  (
    newValue: string | null | undefined,
    options?: UseQueryParamSetOptions
  ) => void,
] {
  const router = useRouter();
  const value = nullifyEmptyString(parseQueryParam(router.query[paramName]));

  const doUpdate = useCallback(
    (
      newValue: string | null | undefined,
      setOptions: UseQueryParamSetOptions = {}
    ) => {
      const newQuery = filterObjectNulls({
        ...(newValue == null
          ? omitQueryParams(router.query, [paramName])
          : setParam(router.query, paramName, newValue)),
        ...setOptions.additionalParams,
      });

      switch (options.method) {
        case 'replace':
        case undefined:
          replaceQuery(router, newQuery, options.shallow);
          break;
        case 'push':
          pushQuery(router, newQuery, options.shallow);
          break;
        default:
          assertNever(options.method);
      }
    },
    // `router` is not referentially stable, so this `useCallback()` is
    // basically a no-op, but we _need_ the most up-to-date router query values.
    // An alternative would be to approach would be to serialize `router.query`
    // into a string and use that as the key.
    [router, paramName, options.method, options.shallow]
  );

  return [value, doUpdate];
}

/**
 * Used to synchronize a query parameter with state that is also maintained in
 * some other form. Returns the query parameter value and a setter. When the
 * setter is called with a new value, first the input callback is called with
 * that value (to change the synchronized state), then the query parameter is
 * changed.
 *
 * When the component mounts (its first render), if the query parameter exists
 * in the URL, the input callback is called with it, to give the consumer a
 * chance to synchronize outside state with the pre-existing query parameter.
 */
export function useSyncedQueryParam(
  paramName: string,
  callback: (newValue: string | null | undefined) => unknown
): [string | null, (newValue: string | null | undefined) => unknown] {
  const router = useRouter();
  const value = nullifyEmptyString(parseQueryParam(router.query[paramName]));
  useEffectOnce(() => {
    callback(value);
  });

  const setSyncedParam = useCallback(
    (newValue: string | null | undefined) => {
      const newQuery =
        newValue == null
          ? omitQueryParams(router.query, [paramName])
          : setParam(router.query, paramName, newValue);
      replaceQuery(router, newQuery);
      callback(newValue);
    },
    [router, callback, paramName]
  );

  return [value, setSyncedParam];
}

/**
 * Returns a stateful value that is synchronized with a query parameter, and a
 * setter for updating it. The initial state value is always null, then if the
 * corresponding query parameter is in the URL the state is immediately updated
 * to match it.
 */
export function useStateWithQueryParam(
  paramName: string
): [string | null, (newValue: string | null | undefined) => unknown] {
  const [stateValue, setStateValue] = useState<string | null>(null);

  const setCleanedStateValue = useCallback(
    (newValue: string | null | undefined) => setStateValue(newValue ?? null),
    []
  );

  const [, setSyncedParam] = useSyncedQueryParam(
    paramName,
    setCleanedStateValue
  );

  return [stateValue, setSyncedParam];
}

export function useQueryParamEnum<T extends Record<string, string>>(
  paramName: string,
  enumObj: T,
  options: UseQueryParamOptions = {}
) {
  const [value, setValue] = useQueryParam(paramName, options);

  if (value === null) {
    return [null, setValue] as const;
  }

  const enumValue = mapStrToEnumOrNull(value, enumObj);
  return [enumValue, setValue] as const;
}

export function useQueryParamFromValues<T extends ReadonlyArray<string>>(
  paramName: string,
  values: T,
  options: UseQueryParamOptions = {}
): readonly [
  T[number] | null,
  (
    newValue: T[number] | null | undefined,
    options?: UseQueryParamSetOptions
  ) => void,
] {
  const [value, setValue] = useQueryParam(paramName, options);

  if (value === null || !values.includes(value)) {
    return [null, setValue] as const;
  }
  return [value, setValue] as const;
}

export function getUrlWithQueryParams(
  queryParams: Record<string, string | null>,
  urlString = window.location.href
): string {
  const url = new URL(urlString);
  const newSearchParams = new URLSearchParams(filterObjectNulls(queryParams));
  for (const [key, value] of newSearchParams.entries()) {
    url.searchParams.set(key, value);
  }
  const nullKeys = Object.entries(queryParams)
    .filter(([, v]) => v == null)
    .map(([k]) => k);
  for (const key of nullKeys) {
    url.searchParams.delete(key);
  }
  // Get just the pathname, query, hash
  return url.toString().replace(window.location.origin, '');
}
/**
 * Parses out search params from the current location.
 */

export function useSearchParams() {
  const { location } = useGlobalLocation();
  return useMemo(() => new URLSearchParams(location.search), [location.search]);
}
