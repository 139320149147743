import { Box } from '@mui/material';
import SidebarLayout from './SidebarLayout';
import { FinanceSnapshotsContextProvider } from '../utils/finance/FinanceSnapshotsContext';
import CreateFinanceSavedViewDialog from '../components/fund/dialogs/CreateFinanceSavedViewDialog';

/*
    FinanceLayout

    The FinanceLayout renders the LoggedInLayout, so it can be thought of as
    a child intepretation of that layout, in a way.

    The layout is responsible for three things:
      1. Managing global Finance data (currently only FinanceSnapshots)
      2. Rendering global Finance dialogs (CreateFinanceSavedViewDialog)
      3. Rendering the Finance product UI CSS grid

    The third piece means that all pages in the Finance product need to know 
    about the grid so they render properly. But this way, we have consistency
    without having to write a lot of code, and we don't need fixed position 
    hacks any longer.
*/
export default function FinanceLayoutWrapper({
  createFinanceSavedViewOpen,
  toggleCreateFinanceSavedView,
  children,
}: {
  createFinanceSavedViewOpen: boolean;
  toggleCreateFinanceSavedView: () => void;
  children: React.ReactNode;
}) {
  return (
    <SidebarLayout toggleCreateFinanceSavedView={toggleCreateFinanceSavedView}>
      <FinanceSnapshotsContextProvider>
        <Box
          data-test="FinanceLayout"
          sx={{
            position: 'relative',
            width: '100%',
            height: '100vh',
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
            gridTemplateColumns: '1fr 0.5fr',
            backgroundColor: 'grey10',
          }}
        >
          {children}
        </Box>
        <CreateFinanceSavedViewDialog
          open={createFinanceSavedViewOpen}
          onClose={toggleCreateFinanceSavedView}
        />
      </FinanceSnapshotsContextProvider>
    </SidebarLayout>
  );
}
