import { CompanyChangeRequestPayload } from '../companyChangeRequestSchemas';
import {
  GQSupplierType,
  GQCompanySbtCommitmentStage,
  GQCompanyClimateProgress,
  GQSupplierPriority,
  GQCompanySurveyStatus,
  GQCompanyEngagementTaskFieldsForOverviewFragment,
  GQDisclosureForSuppliersTableFragment,
  GQEngagementCohort,
  GQCompanyChangeRequestForVendorMatchingFragment,
} from '../generated/graphql';
import { isScienceBasedTargetCommitment } from '../utils/climateCommitmentUtils';
import isNotNullish from '../utils/isNotNullish';
import { PrioritizationFields } from '../utils/SuppliersUtils';
import { Dictionary } from '../utils/utilTypes';
import { YMInterval, YearMonth } from '../utils/YearMonth';

export const aggregatedSupplierRowFields = [
  'company',
  'rows',
  'historicalEmissions',
  'footprintTags',
] as const;

// Data derived from MARTA + Postgres that gets returned to the frontend.
// Without prioritization data
export type BaseSupplier = {
  id: string;
  footprintInterval: YMInterval;
  isTotalSpendPartial: boolean;
  latestDisclosureDateTime: Date | null;
  latestCdpDisclosurePublishingYear: number | null;
  supplierType: GQSupplierType;
  totalKgco2e: number;
  totalKgco2eFromFootprint: number | null;
  totalSpendUsd: number;
  emissiveSpendUsd: number;
  percentEmissions: number;
  percentSpend: number | null; // null when vendor spend artifact doesn't exist
  // TODO(Henry): Make targets and initiatives Array<string> instead of joining with a comma.
  targets: string | null; // all the DisclosureTargetDetails stringified
  initiatives: string | null;
  ghgCategoryIds: Array<string>;
  sbtiStage: GQCompanySbtCommitmentStage;
  // TODO(davidcgl): Move this into SupplierResolvers and query from SbtCommitmentChart
  sbtiCommitmentDate: Date | null;
  climateProgress: GQCompanyClimateProgress;

  // Disclosures
  climateCommitmentDisclosures: Array<GQDisclosureForSuppliersTableFragment>;
  targetDisclosures: Array<GQDisclosureForSuppliersTableFragment>;
  initiativeDisclosures: Array<GQDisclosureForSuppliersTableFragment>;
  emissionsYearDisclosures: Array<GQDisclosureForSuppliersTableFragment>;

  footprintTagKeys: Array<string>;
  footprintTagValues: Array<string | number | YearMonth | null>;
  footprintVendorNames: Array<string>; // all vendor names from footprint rows
  cleanedFootprintVendorNames: Array<string>; // all vendor names from footprint rows, cleaned

  // Fields resolved from Company
  name: string; // uses supplier vendor name if no Company
  companyId: string | null;
  isWatershedCorporateCustomer: boolean;
  // a company can have more than one bea code. eventually we should look into
  // deprecating `naicsCode` in favor of `beaCodes`
  naicsCode: string | null;
  beaCodes: Array<string> | null;
  sustainabilityWebsiteUrl: string | null; // from ClimateProgram

  engagementCohortIds: Array<string>;

  // Resolved from SupplierCustomData
  notes: string | null;
  customPriority: GQSupplierPriority | null;
  customDataV2: Record<string, any>; // includes data from supplier_custom_data as well as disclosure_company_facts

  // Climate commitment things
  netZeroCommitmentTargetYear: number | null;
  carbonNeutralCommitmentTargetYear: number | null;
  cleanEnergyCommitmentTargetPercentage: number | null;
  cleanEnergyCommitmentTargetYear: number | null;

  // for SupplierTopCategorizedEmissionCell
  topCategorizedEmissionBusinessSubcategory: string | null;
  topCategorizedEmissionBusinessCategory: string | null;
  topCategorizedEmissionKgco2e: number | null;
  topCategorizedEmissionProportion: number | null;

  // Fields needed for engagement task column
  engagementTasks: Array<
    Omit<
      GQCompanyEngagementTaskFieldsForOverviewFragment,
      'rootAssigneeDisplayName' | 'company' | 'statusChangedAt'
    >
  >;
};

// Actual object type that gets returned to the frontend
// after computed prioritization occurs
export type Supplier = BaseSupplier & PrioritizationFields;

type SupplierTableAdditionalFields = {
  // Fields needed from SupplierContact
  contactNames: Array<string | null>;
  contactEmails: Array<string>;
  contactIds: Array<string>;
  contactRoles: Array<string | null>;

  // Fields needed for survey state column
  engagementTaskNames: Array<string | null>;
  engagementTaskStatuses: Array<GQCompanySurveyStatus>;
  engagementTaskStatusesChangedAt: Array<Date | null>;
  mostActionableTaskStatus: GQCompanySurveyStatus | null;
  mostActionableTaskSubmittedAt: Date | null;
  mostActionableTaskStatusChangedAt: Date | null;

  // Fields for survey portal URL column
  surveyPortalUrl: string | null;

  // Fields for engagement cohorts column
  engagementCohortsById: Dictionary<GQEngagementCohort>;

  // Fields for pending company change request
  pendingCompanyChangeRequest: ParsedCompanyChangeRequest | null;
};

export type SupplierTableRow = Supplier & SupplierTableAdditionalFields;

export interface SuppliersWithDetailsData {
  rows: Array<Supplier>;
  __typename: 'SuppliersWithDetailsData';
}

export function isSuppliersWithDetailsData(
  data: object & { __typename: string }
): data is SuppliersWithDetailsData {
  return data.__typename === 'SuppliersWithDetailsData';
}

export function convertDisclosuresDates(
  disclosures: Array<GQDisclosureForSuppliersTableFragment>
): Array<GQDisclosureForSuppliersTableFragment> {
  return disclosures.map((disclosure) => ({
    ...disclosure,
    privateDisclosure: disclosure.privateDisclosure
      ? {
          ...disclosure.privateDisclosure,
          createdAt: new Date(disclosure.privateDisclosure.createdAt),
        }
      : null,
    targets: disclosure.targets
      ? disclosure.targets.map((target) => ({
          ...target,
          emissionsTarget: {
            ...target.emissionsTarget,
            base: new Date(target.emissionsTarget.base),
          },
        }))
      : null,
    climateCommitments: disclosure.climateCommitments
      ? disclosure.climateCommitments.map((commitment) => {
          const convertedCommitment = {
            ...commitment,
            commitmentMadeDate: commitment.commitmentMadeDate
              ? new Date(commitment.commitmentMadeDate)
              : null,
            commitmentPeriodEnd: commitment.commitmentPeriodEnd
              ? new Date(commitment.commitmentPeriodEnd)
              : null,
            commitmentPeriodStart: commitment.commitmentPeriodStart
              ? new Date(commitment.commitmentPeriodStart)
              : null,
          };
          return isScienceBasedTargetCommitment(convertedCommitment)
            ? {
                ...convertedCommitment,
                commitment: {
                  ...convertedCommitment.commitment,
                  commitmentDeadline: convertedCommitment.commitment
                    .commitmentDeadline
                    ? new Date(
                        convertedCommitment.commitment.commitmentDeadline
                      )
                    : null,
                },
              }
            : convertedCommitment;
        })
      : null,
    initiatives: disclosure.initiatives
      ? disclosure.initiatives.map((initiative) => ({
          ...initiative,
          emissionsTimeseries: initiative.emissionsTimeseries
            ? {
                ...initiative.emissionsTimeseries,
                base: new Date(initiative.emissionsTimeseries.base),
              }
            : null,
        }))
      : null,
  }));
}

// Because the data we're passing through GQL is not always typed
// ("UntypedData"), the dates are passed through as strings. This updates them
// to dates so that the code works. Ideally we would type our giant
// `get_supplier_table.sql` query, but that makes performance worse.
export function postProcessSuppliers(
  suppliers: Array<Supplier>
): Array<Supplier> {
  return suppliers.map((supplier) => ({
    ...supplier,
    climateCommitmentDisclosures: convertDisclosuresDates(
      supplier.climateCommitmentDisclosures
    ),
    initiativeDisclosures: convertDisclosuresDates(
      supplier.initiativeDisclosures
    ),
    targetDisclosures: convertDisclosuresDates(supplier.targetDisclosures),
    sbtiCommitmentDate: isNotNullish(supplier.sbtiCommitmentDate)
      ? new Date(supplier.sbtiCommitmentDate)
      : null,
    latestDisclosureDateTime: isNotNullish(supplier.latestDisclosureDateTime)
      ? new Date(supplier.latestDisclosureDateTime)
      : null,
  }));
}

export type CsvSupplier = { supplierIdentifier: string } & Record<
  string,
  string
>;

export enum FootprintType {
  Corporate = 'Corporate',
  ProductLine = 'Product line',
}

export type ParsedCompanyChangeRequest = Omit<
  GQCompanyChangeRequestForVendorMatchingFragment,
  'payload'
> & {
  payload: CompanyChangeRequestPayload;
};
