import { Card, CardProps, Stack, StackProps, Typography } from '@mui/material';
import { mixinSx } from '@watershed/style/styleUtils';
import { forwardRef, ReactNode } from 'react';

function Well({ sx, ...props }: StackProps) {
  return (
    <Stack
      component="section"
      {...props}
      className="UIWell"
      sx={mixinSx(
        {
          border: (theme) => `1px solid ${theme.palette.divider}`,
          backgroundColor: (theme) => theme.palette.grey10,
          borderRadius: '10px',
          padding: '4px',
        },
        sx
      )}
    />
  );
}

export default Well;

Well.Header = function WellHeader({
  title,
  subtitle,
  titleLevel = 2,
  titleVariant = 'h3',
  titleColor = 'secondary',
  actions,
  sx,
}: Pick<StackProps, 'sx'> & {
  title: ReactNode;
  subtitle?: ReactNode;
  titleLevel?: 2 | 3 | 4;
  titleVariant?: 'h2' | 'h3' | 'h4' | 'h5';
  titleColor?: 'primary' | 'secondary';
  actions?: ReactNode;
}) {
  const component = `h${titleLevel}` as keyof JSX.IntrinsicElements;
  return (
    <Stack
      component="header"
      direction="row"
      justifyContent="space-between"
      className="UIWellHeader"
      sx={mixinSx(
        {
          gap: 1.5,
          padding: 1.5,
          alignItems: 'center',
          posiiton: 'relative',
        },
        sx
      )}
    >
      <Stack>
        <Typography
          variant={titleVariant}
          component={component}
          color={(theme) => theme.palette.text[titleColor]}
          sx={{ textWrap: 'balance' }}
          children={title}
        />
        {subtitle && (
          <Typography
            variant="body2"
            sx={{ textWrap: 'balance' }}
            children={subtitle}
          />
        )}
      </Stack>
      {actions && (
        <Stack direction="row" gap={1} alignItems="center">
          {actions}
        </Stack>
      )}
    </Stack>
  );
};

type WellContentProps = Omit<
  CardProps,
  | 'className'
  // We're not using the `tw` experimental Tailwind prop, which is marked as
  // optional in `MuiButtonProps`. But for some reason, when we introduced
  // `next-env.d.ts` to `ui-core`, it started causing this bizarre type error,
  // so we'll just omit it here.
  // More details:
  // https://github.com/watershed-climate/ghg/pull/40050#issuecomment-1673322341
  | 'tw'
>;

Well.Content = forwardRef<HTMLDivElement, WellContentProps>(
  function WellContent({ children, sx, ...props }: WellContentProps, ref) {
    return (
      <Card
        component="article"
        {...props}
        ref={ref}
        className="UIWellContent"
        sx={mixinSx(
          {
            padding: 1.5,
            borderRadius: 1,
            position: 'relative',
          },
          sx
        )}
      >
        {children}
      </Card>
    );
  }
);
