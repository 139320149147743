import { Stack, Autocomplete, Box, Chip, TextField } from '@mui/material';
import AddIcon from '@watershed/icons/components/Add';
import React, { useContext } from 'react';
import CloseIcon from '@watershed/icons/components/Close';
import IconButton from '@watershed/ui-core/components/IconButton';
import Button from '@watershed/ui-core/components/Button';
import isNotNullish from '@watershed/shared-universal/utils/isNotNullish';
import financeFieldRegistry, {
  getFinanceFieldHeader,
} from '@watershed/shared-universal/fund/financeFieldRegistry';
import {
  GQFinanceHighchartsChartKind,
  GQFlags,
} from '@watershed/shared-universal/generated/graphql';
import { useFeatureFlag } from '../../utils/FeatureFlag';
import { supportedFinanceChartKindsForMetric } from '@watershed/shared-universal/finance/utils/chartUtils';
import mapStrToEnum from '@watershed/shared-universal/utils/mapStrToEnum';
import ChartIcon from '@watershed/icons/components/Chart';
import ChartHorizontalIcon from '@watershed/icons/components/ChartHorizontal';
import ChartPieIcon from '@watershed/icons/components/ChartPie';
import ChartTreemapIcon from '@watershed/icons/components/ChartTreemap';
import MeterIcon from '@watershed/icons/components/Meter';
import assertNever from '@watershed/shared-universal/utils/assertNever';
import { Typography } from '@mui/material';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { UserContext } from '../../utils/UserContext';
import { BadDataError } from '@watershed/shared-universal/errors/BadDataError';

// This is basically copied over from ChartKindLabel.tsx, but includes the Pareto option
export function getFinanceHighchartsChartInfo(
  chartKind: GQFinanceHighchartsChartKind
) {
  switch (chartKind) {
    case GQFinanceHighchartsChartKind.Pareto:
      return {
        Icon: ChartIcon,
        description: 'Compare dimensions',
        name: 'Pareto',
      };
    case GQFinanceHighchartsChartKind.BarVertical:
      return {
        Icon: ChartIcon,
        description: 'Compare dimensions',
        name: 'Vertical bar chart',
      };
    case GQFinanceHighchartsChartKind.BarHorizontal:
      return {
        Icon: ChartHorizontalIcon,
        description: 'Compare dimensions',
        name: 'Horizontal bar chart',
      };
    case GQFinanceHighchartsChartKind.Pie:
      return {
        Icon: ChartPieIcon,
        description: 'Percentage of whole',
        name: 'Pie chart',
      };
    case GQFinanceHighchartsChartKind.Treemap:
      return {
        Icon: ChartTreemapIcon,
        description: 'Percentage of whole',
        name: 'Treemap chart',
      };
    case GQFinanceHighchartsChartKind.Meter:
      return {
        Icon: MeterIcon,
        description: 'Percentage of whole',
        name: 'Meter chart',
      };
    default:
      assertNever(chartKind);
  }
}

export function FinanceHighchartsChartKindLabel({
  kind,
}: { kind: GQFinanceHighchartsChartKind }) {
  const { Icon, description, name } = getFinanceHighchartsChartInfo(kind);
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Icon size={16} sx={{ flexShrink: 0 }} />
      <Stack>
        <Typography>{name}</Typography>
        <Typography variant="body3">{description}</Typography>
      </Stack>
    </Stack>
  );
}

function getFinanceFieldTitleForOption(
  currency: string,
  { shouldRenameFund }: { shouldRenameFund: boolean }
) {
  return (financeFieldId: string) => {
    if (financeFieldId === '') {
      return '';
    }

    BadDataError.invariant(
      financeFieldId in financeFieldRegistry,
      'option must be in financeFieldRegistry'
    );

    const financeField =
      financeFieldRegistry[financeFieldId as keyof typeof financeFieldRegistry];
    return getFinanceFieldHeader(financeField, { shouldRenameFund })({
      currency,
    });
  };
}

export function FinanceChartSelector({
  onChange,
  value,
  options,
}: {
  value: Array<{
    primaryField: string;
    secondaryField: string;
    tertiaryField: string;
  }>;
  onChange: (
    value: Array<{
      primaryField: string;
      secondaryField: string;
      tertiaryField: string;
    }>
  ) => void;
  options: Map<string, Array<{ id: string; label: string }>>;
}) {
  const shouldRenameFund = useFeatureFlag(GQFlags.AssetManagerRenameFunds);

  const currency = useContext(UserContext).currency ?? 'USD';
  const [newFilter, setNewFilter] = React.useState<boolean>(
    value.length ? false : true
  );

  const handleDelete = async (index: number) => {
    if (index === value.length) {
      setNewFilter(false);
    } else {
      const newValue = value.filter((_, i) => i !== index);
      onChange(newValue);
    }
  };

  const handleAdd = () => {
    setNewFilter(true);
  };

  const onPrimaryFieldChange = async (index: number, newValue: string) => {
    if (index === value.length) {
      setNewFilter(false);
      onChange([
        ...value,
        {
          primaryField: newValue,
          secondaryField: '',
          tertiaryField: '',
        },
      ]);
    } else {
      value[index] = {
        primaryField: newValue,
        secondaryField: '',
        tertiaryField: '',
      };
      onChange(value);
    }
  };

  const onSecondaryFieldChange = async (
    index: number,
    newValue: { id: string; label: string }
  ) => {
    value[index] = {
      primaryField: value[index].primaryField,
      secondaryField: newValue.id,
      tertiaryField: value[index].tertiaryField,
    };
    onChange(value);
  };

  const onTertiaryFieldChange = async (
    index: number,
    newValue: { id: string; label: string }
  ) => {
    value[index] = {
      primaryField: value[index].primaryField,
      secondaryField: value[index].secondaryField,
      tertiaryField: newValue.id,
    };
    onChange(value);
  };

  const primaryFieldOptions = Array.from(options.keys());

  const values = value
    .map(({ primaryField, secondaryField, tertiaryField }) => {
      const currentSecondaryField = {
        id: secondaryField,
        label:
          options.get(primaryField)?.find((fo) => fo.id === secondaryField)
            ?.label ?? secondaryField,
      };

      const currentTertiaryField = {
        id: tertiaryField,
        label:
          tertiaryField !== ''
            ? getFinanceHighchartsChartInfo(
                mapStrToEnum(tertiaryField, GQFinanceHighchartsChartKind)
              ).name
            : '',
      };

      return {
        primaryField,
        secondaryField: currentSecondaryField,
        tertiaryField: currentTertiaryField,
      };
    })
    .filter(isNotNullish);

  if (newFilter) {
    values.push({
      primaryField: '',
      secondaryField: { id: '', label: '' },
      tertiaryField: { id: '', label: '' },
    });
  }

  return (
    <Stack width="100%">
      <Stack gap={2} paddingX={1} paddingTop={1}>
        {values.map((record, index) => (
          <ValueRow
            key={`${record.primaryField}-${record.secondaryField.id}-${index}`} // How do we key this? These will change...
            index={index}
            record={record}
            primaryFieldOptions={primaryFieldOptions}
            secondaryFieldOptions={options.get(record.primaryField) ?? []}
            disabledTertiaryFieldOptions={value
              .filter(
                (v) =>
                  v.primaryField === record.primaryField &&
                  v.secondaryField === record.secondaryField.id
              )
              .map((v) => v.tertiaryField)}
            onSecondaryFieldChange={onSecondaryFieldChange}
            onPrimaryFieldChange={onPrimaryFieldChange}
            onTertiaryFieldChange={onTertiaryFieldChange}
            onDelete={handleDelete}
            getFieldLabel={getFinanceFieldTitleForOption(currency, {
              shouldRenameFund,
            })}
          />
        ))}
      </Stack>
      <Stack direction="row" gap={1} paddingY={2}>
        <Button
          data-test={TestIds.FinanceSavedViewAddChartButton}
          disabled={newFilter}
          variant="text"
          startIcon={<AddIcon />}
          onClick={handleAdd}
        >
          {/* // TODO: i18n (please resolve or remove this TODO line if legit) //  */}
          {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready  */}
          Add chart
        </Button>
      </Stack>
    </Stack>
  );
}

function ValueRow({
  index,
  record,
  primaryFieldOptions,
  secondaryFieldOptions,
  disabledTertiaryFieldOptions,
  onPrimaryFieldChange,
  onSecondaryFieldChange,
  onTertiaryFieldChange,
  onDelete,
  getFieldLabel,
}: {
  index: number;
  record: {
    primaryField: string;
    secondaryField: { id: string; label: string };
    tertiaryField: { id: string; label: string };
  };
  primaryFieldOptions: Array<string>;
  secondaryFieldOptions: Array<{ id: string; label: string }>;
  disabledTertiaryFieldOptions: Array<string>;
  onPrimaryFieldChange: (filterIndex: number, newValue: string) => void;
  onSecondaryFieldChange: (
    index: number,
    newValue: { id: string; label: string }
  ) => void;
  onTertiaryFieldChange: (
    index: number,
    newValue: { id: string; label: string }
  ) => void;
  onDelete: (index: number) => void;
  getFieldLabel: (field: string) => string;
}) {
  return (
    <Box>
      <Box display="flex">
        <Autocomplete
          data-test={
            TestIds.FinanceSavedViewMetricSelector + `-${record.primaryField}`
          }
          value={record.primaryField}
          onChange={(evt, newValue) => {
            onPrimaryFieldChange(index, newValue);
          }}
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                component="li"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {getFieldLabel(option)}
              </Box>
            );
          }}
          options={primaryFieldOptions}
          sx={{ width: '100%' }}
          size="small"
          disableClearable
          getOptionLabel={(option) => getFieldLabel(option)}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{ padding: 0 }}
                variant="outlined"
                // TODO: i18n (please resolve or remove this TODO line if legit)
                // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
                placeholder="Select a metric"
                aria-labelledby={record.primaryField ?? undefined}
                autoComplete="off"
              />
            );
          }}
          openOnFocus={true}
        />
        {/* // TODO: i18n (please resolve or remove this TODO line if legit) //  */}
        {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready  */}
        <Box sx={{ ml: 2, mr: 2, mt: 1 }}>by</Box>
        <Autocomplete
          data-test={
            TestIds.FinanceSavedViewDimensionSelector +
            `-${record.secondaryField.id}`
          }
          // TODO: i18n (please resolve or remove this TODO line if legit)
          // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
          placeholder="Select a value"
          options={secondaryFieldOptions}
          multiple={false}
          sx={{ width: '100%' }}
          size="small"
          disableClearable
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                component="li"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {option.label}
              </Box>
            );
          }}
          renderTags={(
            value: ReadonlyArray<{ id: string; label: string }>,
            getTagProps
          ) =>
            value.map(
              (option: { id: string; label: string }, index: number) => (
                <Box key={option.id} sx={{ maxWidth: 180 }}>
                  <Chip
                    title={option.label}
                    label={option.label}
                    size="small"
                    {...getTagProps({
                      index,
                    })}
                  />
                </Box>
              )
            )
          }
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{ padding: 0 }}
                variant="outlined"
                aria-labelledby={undefined}
                autoComplete="off"
                required={record.primaryField !== ''}
                placeholder={
                  record.secondaryField.id !== '' ? undefined : 'Select a value'
                }
              />
            );
          }}
          openOnFocus={true}
          value={record.secondaryField}
          onChange={(evt, newValue: { id: string; label: string }) => {
            onSecondaryFieldChange(index, newValue);
          }}
        />
        <Autocomplete
          data-test={
            TestIds.FinanceSavedViewChartKindSelector +
            `-${record.tertiaryField.id}`
          }
          // TODO: i18n (please resolve or remove this TODO line if legit)
          // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
          placeholder="Select a chart kind"
          options={supportedFinanceChartKindsForMetric(record.primaryField).map(
            (id) => ({
              id,
              label: getFinanceHighchartsChartInfo(id).name,
            })
          )}
          multiple={false}
          sx={{ pl: 2, width: '100%' }}
          size="small"
          disableClearable
          getOptionDisabled={(option) =>
            disabledTertiaryFieldOptions.includes(option.id)
          }
          getOptionLabel={(option) =>
            option.id !== ''
              ? getFinanceHighchartsChartInfo(
                  mapStrToEnum(option.id, GQFinanceHighchartsChartKind)
                ).name
              : option.label
          }
          renderOption={(props, option) => {
            return (
              <Box
                {...props}
                component="li"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <FinanceHighchartsChartKindLabel
                  kind={mapStrToEnum(option.id, GQFinanceHighchartsChartKind)}
                />
              </Box>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                sx={{ padding: 0 }}
                variant="outlined"
                aria-labelledby={undefined}
                autoComplete="off"
                required={record.primaryField !== ''}
                placeholder={
                  record.tertiaryField.id !== ''
                    ? undefined
                    : 'Select a chart kind'
                }
              />
            );
          }}
          openOnFocus={true}
          value={record.tertiaryField}
          onChange={(evt, newValue: { id: string; label: string }) => {
            onTertiaryFieldChange(index, newValue);
          }}
        />
        <IconButton sx={{ ml: 1 }} onClick={() => onDelete(index)} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
