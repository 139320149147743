import React, { useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import { UserContext } from '../utils/UserContext';
import { useAnalyticsPath } from '@watershed/analytics/AnalyticsTracker';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import { GQPermissionType } from '@watershed/shared-universal/generated/graphql';

export default function AuthenticatedAnalyticsTracker(props: {
  children: React.ReactNode;
}) {
  const urlString = useAnalyticsPath();
  const userContext = useContext(UserContext);

  const isAdminUser = userContext.permissions.some(
    (permission) => permission.permission === GQPermissionType.Admin
  );
  const isLoginAsUser = userContext.loginAsUser !== null;
  const loginAsUserId = userContext.loginAsUser?.id;
  const loginAsUserName = userContext.loginAsUser?.name;
  const loginAsUserIsCustomer =
    userContext.loginAsUser !== null
      ? !userContext.userIsWatershedEmployee
      : undefined;

  useEffect(() => {
    // Set the UserID for our logged in user. Important things to note:
    //   1. We're using Google's Session Unification / Mixpanel's ID Merge to
    //       associate events triggered before login with this user if necessary.
    //       Unification also covers the first AnalyticsTracker event, which
    //       fires before this `set` runs.
    //   2. On logout we perform a hard refresh that will remove this association
    //       when the application is re-initialized.
    void Analytics.identify({
      userProperties: {
        userId: userContext.userId,
        createdAt: userContext.createdAt,
        userName: userContext.userName,
        userEmail: userContext.userEmail,
        orgId: userContext.orgId,
        orgName: userContext.orgName,
        isCustomer: userContext.isCustomer,
        isAdminUser,
        loginAsUserId,
        loginAsUserName,
        loginAsUserIsCustomer,
        preferredLocale: userContext.preferredLocale,
      },
      dashboardUserProperties: {
        isLoginAsUser,
        testOrg: userContext.testOrg,
        demoOrg: userContext.demoOrg,
        stagingOrg: userContext.stagingOrg,
        watershedPlan: userContext.watershedPlan,
        watershedPlanLegacy: userContext.watershedPlanLegacy,
      },
      adminUserProperties: null,
    });
  }, [
    userContext.userId,
    userContext.createdAt,
    userContext.userName,
    userContext.userEmail,
    userContext.orgId,
    userContext.orgName,
    userContext.demoOrg,
    userContext.testOrg,
    userContext.stagingOrg,
    userContext.isCustomer,
    isAdminUser,
    userContext.preferredLocale,
    isLoginAsUser,
    loginAsUserName,
    loginAsUserId,
    loginAsUserIsCustomer,
    userContext.watershedPlan,
    userContext.watershedPlanLegacy,
  ]);

  useEffect(() => {
    if (urlString === null) {
      return;
    }
    const url = new URL(urlString);

    ReactGA.set({ page: url.pathname });
    ReactGA.event({
      category: 'UserTracking',
      action: 'pageLoad',
    });

    void Analytics.updatePath(url);
  }, [urlString]);

  return <>{props.children}</>;
}
