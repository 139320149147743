import { Input, Stack } from '@mui/material';
import CommandPalettePageLayout from './CommandPalettePageLayout';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';

export default function ComandPaletteLoadingFallback() {
  return (
    <CommandPalettePageLayout
      slots={{
        header: (
          <Input
            disabled
            name="value"
            size="medium"
            // TODO: i18n (please resolve or remove this TODO line if legit)
            // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
            placeholder="Loading..."
            autoFocus
            sx={{
              pl: 0.5,
              pr: 0.5,
              gap: 1,
              fontSize: '1.25rem',
            }}
          />
        ),
        content: (
          <Stack
            height={192}
            color={(theme) => theme.palette.grey30}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress color="inherit" />
          </Stack>
        ),
      }}
    />
  );
}
