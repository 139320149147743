import { ReactNode, CSSProperties, useEffect, useRef } from 'react';
import { Box, BoxProps, Container, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useLocationHash from '../hooks/useLocationHash';
import scrollRefIntoView from '../utils/scrollRefIntoView';
import PageHeader, { PageHeaderProps } from './PageHeader';
import { SxProps, SystemStyleObject } from '@mui/system';
import MaybeTypography from '@watershed/ui-core/utils/MaybeTypography';
import { mixinSx } from '@watershed/style/styleUtils';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

const useStyles = makeStyles((theme: Theme) => ({
  pageSection: {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
  },
  pageSectionHeader: {},
}));

export function PageSection({
  id,
  title,
  titleVariant,
  subtitle,
  actions,
  anchor,
  children,
  ...props
}: BoxProps & {
  id?: string;
  title?: ReactNode;
  titleVariant?: 'h2' | 'h3';
  subtitle?: ReactNode;
  actions?: ReactNode;
  anchor?: string;
  children: ReactNode;
}) {
  const classes = useStyles();

  const ref = useRef<HTMLDivElement>(null);
  const hash = useLocationHash();

  useEffect(() => {
    if (anchor && hash === anchor) {
      scrollRefIntoView(ref);
    }
  }, [anchor, hash]);

  return (
    <Box
      component="section"
      className={classes.pageSection}
      id={anchor}
      {...props}
      ref={ref}
    >
      {title && (
        <PageSectionHeader
          title={title}
          titleVariant={titleVariant}
          subtitle={subtitle}
          actions={actions}
        />
      )}
      {children}
    </Box>
  );
}

export function PageSectionHeader({
  title,
  titleVariant = 'h2',
  subtitle,
  actions,
  ...props
}: (
  | BoxProps
  | {
      title: ReactNode; // title is already a prop on BoxProps
    }
) & {
  titleVariant?: 'h2' | 'h3';
  subtitle?: ReactNode;
  actions?: ReactNode;
}) {
  const classes = useStyles();
  return (
    <Box
      component={actions ? 'header' : undefined}
      className={classes.pageSectionHeader}
      padding={2}
      display="flex"
      alignItems="center"
      {...props}
    >
      <Box flexGrow={1}>
        <MaybeTypography variant={titleVariant}>{title}</MaybeTypography>
        {subtitle && (
          <Typography variant="body2" style={{ maxWidth: '75%' }}>
            {subtitle}
          </Typography>
        )}
      </Box>
      {actions}
    </Box>
  );
}

function FixedHeaderFullPage({
  header,
  children,
  sx,
}: {
  header: React.ReactNode;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}) {
  return (
    <Container
      disableGutters={true}
      maxWidth={false}
      sx={mixinSx(
        {
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100vh',
          height: '100%',
        },
        sx
      )}
    >
      {header}
      <Container
        disableGutters={true}
        maxWidth={false}
        sx={{ overflow: 'auto', height: '100%' }}
      >
        {children}
      </Container>
    </Container>
  );
}

export default function PageContainer({
  isFullPage = true,
  fullPageFixedHeader = false,
  maxWidth = 'md',
  component = 'article',
  headerSx,
  style,
  sx,
  bg = 'paper',
  heading,
  headingRightCol,
  headingRenderRightCol,
  isHeadingCompact,
  tabs,
  documentTitle,
  children,
  renderHeader,
  testId,
}: {
  isFullPage?: boolean;
  fullPageFixedHeader?: boolean;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
  component?: 'article' | 'div';
  style?: CSSProperties;
  sx?: SxProps<Theme>;
  bg?: 'sunken' | 'paper';
  headerSx?: SystemStyleObject<Theme>;
  heading?: PageHeaderProps['heading'];
  headingRightCol?: PageHeaderProps['rightCol'];
  headingRenderRightCol?: PageHeaderProps['renderRightCol'];
  isHeadingCompact?: PageHeaderProps['isCompact'];
  tabs?: PageHeaderProps['tabs'];
  documentTitle?: string;
  children: any;
  renderHeader?: (header: React.ReactNode) => React.ReactNode;
  testId?: TestIds;
}) {
  const baseHeader =
    heading || headingRightCol ? (
      <PageHeader
        heading={heading}
        rightCol={headingRightCol}
        renderRightCol={headingRenderRightCol}
        isCompact={isHeadingCompact}
        tabs={isFullPage ? tabs : undefined}
        documentTitle={documentTitle}
        sx={headerSx}
      />
    ) : null;
  const header = renderHeader ? renderHeader(baseHeader) : baseHeader;

  return isFullPage ? (
    fullPageFixedHeader ? (
      <FixedHeaderFullPage header={header} sx={sx}>
        {children}
      </FixedHeaderFullPage>
    ) : (
      <>
        {header}
        {children}
      </>
    )
  ) : (
    <>
      {header}
      <Box
        sx={{
          minHeight: '100%',
          backgroundColor: bg === 'sunken' ? 'grey05' : 'white',
        }}
        data-test={testId}
      >
        <Container
          maxWidth={maxWidth}
          component={component}
          style={style}
          sx={sx}
        >
          {children}
        </Container>
      </Box>
    </>
  );
}
