import {
  FilterableFootprintContributionsFields,
  SelectedBoundary,
} from '@watershed/shared-universal/forecast/utils';
import {
  GQFilterConjunction,
  GQFilterExpressionGroupWithNewFilters,
  GQFilterOperator,
} from '@watershed/shared-universal/generated/graphql';
import {
  LegacyToWatershedFilterField,
  LEGACY_REDUCTION_FILTER_FIELDS,
  REDUCTION_FILTER_FIELDS,
  WatershedToLegacyFilterField,
} from '@watershed/shared-universal/reductions/ReductionFilter';
import isIncludedIn from '@watershed/shared-universal/utils/isIncludedIn';
import invariant from 'invariant';
import {
  GhgCategoryIdAndScope3,
  SCOPE_3_CATEGORY_ID,
} from '../components/reductions/reduxEnterprise/InitiativeScopes';
import {
  GHG_CATEGORY_IDS,
  SCOPE_1_CATEGORY_ID,
  SCOPE_2_CATEGORY_ID,
} from '@watershed/shared-universal/constants';
import { FilterExpressionGroupWithNewFilters } from '@watershed/shared-universal/forecast/ForecastX';
import { YMInterval } from '@watershed/shared-universal/utils/YearMonth';

/**
 * The state of the enum migration from GQFilterFieldLegacy to GQFilterFieldWatershed is an uneven one.
 * In Redux, we always use:
 *   GQFilterFieldWatershed in SelectedBoundary and ReductionFilter
 *   GQFilterFieldLegacy in GQFilterExpressionGroup, GQFilterExpressionGroupWithNewFilters
 *      We continue to use GQFilterFieldLegacy as we have yet to do a DB data migration to the new types.
 *      This means any time we receive a filter from the network, the first we thing do is convert it to use the new types.
 *      Any time we send it back to the server, we must convert it back to the old types.
 *
 *  Filter expression groups are a shared concept across many teams, but in redux this currently means:
 *    GQFilterExpressionGroup only support GQFilterFieldLegacy filters.
 *    GQFilterExpressionGroupWithNewFilters supports arbitrary strings which means custom tags. For data compatibility, we only use
 *      the GQFilterFieldLegacy filters though.
 */

export function convertFilterWithNewFiltersToSelectedBoundary({
  filters,
}: {
  filters?: GQFilterExpressionGroupWithNewFilters;
}) {
  const selectedBoundary = new Map<
    FilterableFootprintContributionsFields,
    Array<string>
  >();

  // if we are editing, we need to populate the selected boundary with the
  // existing values instead
  for (const { field: legacyField, value, operator } of filters?.expressions ??
    []) {
    invariant(operator === 'in', 'we do not support notin right now');
    const field = isIncludedIn(legacyField, LEGACY_REDUCTION_FILTER_FIELDS)
      ? LegacyToWatershedFilterField[legacyField]
      : legacyField;
    invariant(
      !selectedBoundary.has(field),
      'we should not have seen this field yet'
    );
    // We got rid of filter checking here, we accept all allowed boundaries and ghg scopes
    selectedBoundary.set(field, value);
  }
  return selectedBoundary;
}

export function selectedBoundaryToGQFilterExpressionGroupWithNewFilters(
  selectedBoundary: SelectedBoundary
): GQFilterExpressionGroupWithNewFilters {
  const filterExpressions = Array.from(selectedBoundary.entries())
    .filter(([, value]) => value.length > 0) // Don't build filters on empty sets
    .map(([field, value]) => {
      // convert back to legacy fields to store in the data model until we do the data migration
      const legacyFieldOrTag = isIncludedIn(field, REDUCTION_FILTER_FIELDS)
        ? WatershedToLegacyFilterField[field]
        : field;
      return {
        field: legacyFieldOrTag,
        operator: GQFilterOperator.In,
        value,
      };
    });
  const filterExpressionGroup = {
    conjunction: GQFilterConjunction.AndConjunction,
    expressions: filterExpressions,
  };
  return filterExpressionGroup;
}

/**
 * Returns whether a footprint is suitable for using in the redux product. The
 * footprint must have at least a full fiscal year of data.
 */
export function hasFootprintReadyForRedux(
  orgFiscalYearStartMonth: number | null,
  footprintInterval: YMInterval | null
) {
  if (footprintInterval === null) {
    return false;
  }

  return (
    footprintInterval.containedFiscalYears(orgFiscalYearStartMonth ?? 1)
      .length > 0
  );
}

export function filterExpressionGroupToGhgCategoriesAndScope3(
  filters: FilterExpressionGroupWithNewFilters
): Array<GhgCategoryIdAndScope3> {
  return [...GHG_CATEGORY_IDS, SCOPE_3_CATEGORY_ID].filter(
    (id): id is GhgCategoryIdAndScope3 => {
      const expressions = filters.expressions;
      const categoryIds =
        expressions.find((expr) => expr.field === 'ghgCategoryId')?.value || [];
      const scopes =
        expressions
          .find((expr) => expr.field === 'ghgScope')
          ?.value.map((scope) => {
            switch (scope.toLowerCase()) {
              case 'scope 1':
                return SCOPE_1_CATEGORY_ID;
              case 'scope 2':
                return SCOPE_2_CATEGORY_ID;
              case 'scope 3':
                return SCOPE_3_CATEGORY_ID;
              default:
                return null;
            }
          }) || [];
      return [...categoryIds, ...scopes].includes(id);
    }
  );
}
