/* eslint-disable no-restricted-imports */
import {
  SnackbarMessage,
  OptionsObject,
  useSnackbar as useSnackbarNotistack,
} from 'notistack';
import type { ProviderContext as SnackbarType } from 'notistack';
import frontendHoneycomb from '../utils/frontendHoneycomb';
import { CustomSpanAttributes } from '@watershed/shared-universal/constants/otelCustomAttributes';

export type { SnackbarType };

export default function useSnackbar(): SnackbarType {
  const snackbarNotistack = useSnackbarNotistack();
  return {
    enqueueSnackbar(message: SnackbarMessage, options?: OptionsObject) {
      if (options?.variant === 'error') {
        void sendErrorEventToHoneycomb();
      }
      return snackbarNotistack.enqueueSnackbar(message, options);
    },
    closeSnackbar: snackbarNotistack.closeSnackbar,
  };
}

async function sendErrorEventToHoneycomb() {
  await frontendHoneycomb.sendEvent({
    name: CustomSpanAttributes.BROWSER_USER_VISIBLE_ERROR_SNACKBAR,
  });
}
