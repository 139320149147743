import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import {
  CommonZodFieldProps,
  getInputFieldProps,
  useFieldPropsFromTsController,
} from './fieldUtils';
import { requiredSymbol } from '@watershed/ui-core/components/Form/Field';
import InfoTooltip from '@watershed/ui-core/components/InfoTooltip';
import { mixinSx } from '@watershed/style/styleUtils';
import { AbstractSelectProps, ZodAbstractSelectField } from './ZodSelectFields';

export function ZodBooleanField(
  props: CommonZodFieldProps | AbstractSelectProps<boolean | null>
) {
  const {
    id,
    onChange,
    value,
    label,
    // TODO: do we need to reflect these for booleans? potentially for untouched checkboxes (aka the user has to touch it even if that means checking and then unchecking?)
    validationMessage: _1,
    validationState: _2,
    ...tsProps
  } = useFieldPropsFromTsController<boolean>();
  const { inline, required, hideRequired, infoTooltipProps, renderLabel } =
    props;

  if ('listOptions' in props) {
    return (
      <ZodAbstractSelectField<boolean | null>
        {...props}
        listOptions={props.listOptions}
      />
    );
  }

  const inlineLabel = (
    <Typography variant="body2">
      {props.label ?? label}
      {required && !hideRequired && requiredSymbol}
      {infoTooltipProps && (
        <InfoTooltip
          {...infoTooltipProps}
          sx={mixinSx({ verticalAlign: 'text-bottom' }, infoTooltipProps.sx)}
        />
      )}
    </Typography>
  );
  return (
    <>
      {inline ? (renderLabel ? renderLabel(inlineLabel) : inlineLabel) : null}
      <FormControlLabel
        {...tsProps}
        {...getInputFieldProps(props)}
        // if it is inline, the required asterisk will show up on the label
        required={inline ? false : props.required}
        id={id}
        control={
          <Checkbox
            checked={value ?? false}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
          />
        }
        label={inline ? null : props.label ?? label}
      />
    </>
  );
}
