import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, BoxProps } from '@mui/material';
import {
  formatCost,
  normalizeValue,
  formatNumber,
} from '@watershed/shared-universal/utils/helpers';
import clsx from 'clsx';
import { getPaletteUtils } from '@watershed/style/styleUtils';

export function computeTCO2e(kgco2e: number) {
  return Math.round(kgco2e / 1000);
}

export function formatEmission(emission: number) {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return formatNumber(emission);
}

export function percentFormatter(percentInput: string) {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return `${formatNumber(parseFloat(percentInput))}%`;
}

export function percentFormatterAllowEmpty(percentInput: string) {
  return percentInput === ''
    ? ''
    : // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      `${formatNumber(parseFloat(percentInput))}%`;
}

export function numberFormatterAllowEmpty(numberInput: string) {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return numberInput === '' ? '' : formatNumber(Number(numberInput));
}

export function percentWithPrecisionFormatter(percentInput: string) {
  const num = parseFloat(percentInput);
  const numDigits = Math.abs(num - Math.round(num)) < 0.05 ? 0 : 1;
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return `${formatNumber(num, {
    maximumFractionDigits: numDigits,
    includeTrailingZeros: true,
  })}%`;
}

export function costFormatter(costInput: string, currencyCode: string = 'USD') {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return `${formatCost(parseFloat(costInput), {
    withCents: true,
    currencyCode,
  })}`;
}

const DEFAULT_MIN_STEPPER_WIDTH_PX = 60;

export type NumberFieldProps = Omit<
  React.ComponentPropsWithoutRef<'input'>,
  'onChange'
> & {
  variant?: 'standalone' | 'inline';
  onChange: (newValue: number | null) => void;
  onBlur?: (e: SyntheticEvent) => void;
  formatter?: (unformattedStr: string) => string;
  normalizer?: (value: string) => string;
  sx?: BoxProps['sx'];
  fsUnmask?: boolean;
  suppressValidation?: boolean;
  /**
   * Show steppers for users to click up/down
   *
   * Shown by default, unless the number input is very narrow.
   * @default true
   */
  showSteppers?: boolean;
};

/*
    Context: This is used by a component we were moving to this folder,
    but the component itself doesn't necessarily make a ton of sense?

    We should look into this…
*/
export default function NumberField({
  variant = 'standalone',
  onChange: parentOnChange,
  onBlur: parentOnBlur,
  formatter,
  normalizer = normalizeValue,
  value: initialValue,
  sx: initialSx,
  fsUnmask,
  suppressValidation,
  showSteppers,
  ...props
}: NumberFieldProps) {
  // The "normalized" version of the given value.
  const normalizedInitialValue = normalizer(initialValue?.toString() ?? '');

  // 'value': displayed and changes as the user interacts
  const [value, setValue] = useState(normalizedInitialValue);
  const [focused, setFocused] = useState(false);

  const el = useRef<HTMLInputElement>(null);

  // When the given value changes and we are unfocused, we want to register this change and
  // force a rerender. The best example is the carbon cost table values changing through
  // the dropdown.
  useEffect(() => {
    if (!focused) {
      setValue(normalizedInitialValue);
    }
  }, [focused, normalizedInitialValue]);

  const shouldShowSteppers =
    showSteppers !== undefined
      ? showSteppers
      : (el.current?.clientWidth ?? 0) > DEFAULT_MIN_STEPPER_WIDTH_PX;

  const onChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setValue(evt.target.value);

      const valueAsNumber = evt.target.valueAsNumber;
      if (el.current && !suppressValidation) {
        if (isNaN(valueAsNumber)) {
          el.current.setCustomValidity('Value must be a number');
        } else if (props.min != null && valueAsNumber < Number(props.min)) {
          el.current.setCustomValidity(`Value must be ${props.min} or greater`);
        } else if (props.max != null && valueAsNumber > Number(props.max)) {
          el.current.setCustomValidity(`Value must be ${props.max} or less`);
        } else {
          el.current.setCustomValidity('');
        }
      }

      if (evt.target.value === '') {
        parentOnChange(null);
      } else if (!isNaN(valueAsNumber)) {
        parentOnChange(valueAsNumber);
      }
    },
    [parentOnChange, props.max, props.min, suppressValidation]
  );

  const isFocused = useRef(false);

  const onBlur = (evt: SyntheticEvent) => {
    isFocused.current = false;
    const normalizedValue = normalizer(value);
    const isChanged = value !== normalizedValue;

    if (isChanged) {
      setValue(normalizedValue);
      parentOnChange(parseFloat(normalizedValue));
    }

    setFocused(false);
    if (parentOnBlur) {
      parentOnBlur(evt);
    }
  };

  const onFocus = (e: React.FormEvent<HTMLInputElement>) => {
    isFocused.current = true;
    setFocused(true);

    setTimeout(() => {
      // Handle edge cases where the focus is lost before the timeout
      if (isFocused.current) {
        // Select the entire value in the input element
        (e.target as HTMLInputElement).select();
      }
    }, 0);
  };

  // Prevent scrolling from incrementing/decrementing the value
  const onWheel = (e: React.FormEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  const sx: BoxProps['sx'] = {
    appearance: 'none',
    width: '100%',
    backgroundColor: (theme) => theme.palette.background.paper,
    border: (theme) => `1px solid ${theme.palette.grey20}`,
    textAlign: 'inherit',
    font: 'inherit',
    padding: '4px 5px',
    lineHeight: '22px',
    paddingLeft: (theme) => theme.spacing(1),
    paddingRight: (theme) => theme.spacing(1),
    borderRadius: variant === 'standalone' ? 1 : 0,

    '&:disabled': {
      backgroundColor: (theme) => theme.palette.grey20,
      color: (theme) => theme.palette.text.secondary,
      boxShadow: 'none',
    },

    '&:read-only': {
      color: (theme) => theme.palette.text.secondary,
    },

    '&:focus': {
      outline: 0,
      borderColor: (theme) =>
        variant === 'standalone' ? undefined : theme.palette.primary.main,
      boxShadow:
        variant === 'standalone'
          ? (theme) => getPaletteUtils(theme.palette).boxShadowField.focus
          : undefined,
    },
    ...(initialSx ? initialSx : {}),
    ...(shouldShowSteppers
      ? {}
      : {
          // hide up/down buttons
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          MozAppearance: 'textfield',
        }),
  };

  const valueProps: BoxProps<'input'> =
    focused || formatter === undefined
      ? {
          value,
          type: 'number',
        }
      : {
          value: formatter(value),
          type: 'text',
        };

  return (
    <Box
      data-test={`input-${props.id}`}
      onWheel={onWheel}
      {...props}
      className={clsx(props.className, fsUnmask && 'fs-unmask')}
      component="input"
      ref={el}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      sx={sx}
      {...valueProps}
    />
  );
}
