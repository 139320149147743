import { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';

import Button from './Button';
import omit from 'lodash/omit';
import { mixinSx } from '@watershed/style/styleUtils';

type ButtonProps = ComponentPropsWithoutRef<typeof Button>;
export type ProgressButtonProps = Pick<
  ButtonProps,
  | 'className'
  | 'variant'
  | 'color'
  | 'type'
  | 'style'
  | 'fullWidth'
  | 'disabled'
  | 'size'
  | 'startIcon'
  | 'endIcon'
  | 'href'
  | 'tabIndex'
  | 'form'
  | 'tooltip'
  | 'autoFocus'
  | 'onClick'
  | 'sx'
> & {
  isInProgress: boolean;
  progressLabel?: LocalizedString;
};

/**
 * `<ProgressButton>` is a wrapper around `<Button>` that makes it easier to
 * handle loading states. It requires a prop `isInProgress` to be present, which
 * controls whether to render a loading state.
 */
export default forwardRef(function ProgressButton(
  {
    onClick,
    isInProgress,
    progressLabel,
    startIcon,
    endIcon,
    variant = 'contained',
    color = 'primary',
    sx,
    ...restProps
  }: ProgressButtonProps &
    (
      | { label: ButtonProps['children'] }
      | { children: ButtonProps['children'] }
    ),
  ref: Ref<HTMLButtonElement>
) {
  const realLabel = 'label' in restProps ? restProps.label : restProps.children;
  const buttonProps = omit(restProps, 'label', 'children');

  let circularProgressSize: number;
  switch (buttonProps.size) {
    case 'small':
      circularProgressSize = 12;
      break;
    default:
      circularProgressSize = 16;
  }

  return (
    <Button
      ref={ref}
      {...buttonProps}
      sx={mixinSx({ cursor: isInProgress ? 'default' : undefined }, sx)}
      variant={variant}
      color={color}
      onClick={isInProgress ? () => {} : onClick}
      startIcon={isInProgress ? null : startIcon}
      endIcon={
        isInProgress ? (
          <CircularProgress
            size={circularProgressSize}
            color="inherit"
            aria-hidden
          />
        ) : (
          endIcon
        )
      }
      aria-live={isInProgress ? 'assertive' : undefined}
      aria-busy={isInProgress}
    >
      {isInProgress ? progressLabel ?? realLabel : realLabel}
    </Button>
  );
});
