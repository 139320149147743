import throttle from 'lodash/throttle';
import { useEffect, useLayoutEffect, useRef } from 'react';

export default function useThrottledEffect(
  fn: () => void | Promise<void>,
  dependencies: Array<unknown>,
  delayMs: number
) {
  const fnRef = useRef(fn);
  // uses the latest ref pattern to obviate the need for memoizing the callback
  // https://epicreact.dev/the-latest-ref-pattern-in-react/
  useLayoutEffect(() => {
    fnRef.current = fn;
  });

  const callFn = throttle(() => fnRef.current(), delayMs);

  useEffect(
    () => {
      // TODO: URGENT Please fix this by await-ing or void-ing this line.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      callFn();
    },
    // I solemly swear I am up to no good
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dependencies, delayMs]
  );
}
