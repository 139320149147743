import isNotNullish from '@watershed/shared-universal/utils/isNotNullish';
import { safeJsonParse } from '@watershed/shared-universal/utils/jsonUtils';
import { Atom, WritableAtom, atom } from 'jotai';
import { atomEffect } from 'jotai-effect';
// eslint-disable-next-line no-restricted-imports -- we use the utility to create our preferred one below
import { atomWithHash } from 'jotai-location';
import { SetStateActionWithReset } from './types';
import {
  RESET,
  atomFamily,
  atomWithStorage,
  createJSONStorage,
} from 'jotai/utils';
import { SyncStorage, AsyncStorage } from 'jotai/vanilla/utils/atomWithStorage';
import { JotaiGetter } from './types';
import { getValueFromSetStateAction } from './utils';
import isEqual from 'lodash/isEqual';

const INIT = Symbol('init_atom');

function getIfAtomExists<Value>(get: JotaiGetter, atom: Atom<Value> | null) {
  return atom ? get(atom) : null;
}

type CommonStorageAtomArgs<Value> = {
  prefix: string;
  parseJs: (value: unknown) => Value | null;
  shouldStoreNull?: boolean;
};

type SourceAtom<Value, ExtraArg> = WritableAtom<
  Value | null,
  [SetStateActionWithReset<Value | null>, ExtraArg?],
  void
>;

type LocalStorageAtomArgs<
  Value,
  ExtraArg = unknown,
> = CommonStorageAtomArgs<Value> & {
  sourceAtom: SourceAtom<Value, ExtraArg>;
  dynamicSuffixAtom?: Atom<string | null>;
  disableRestoreAtom?: Atom<boolean>;
};
const isPromiseLike = (x: unknown): x is PromiseLike<unknown> =>
  typeof (x as any)?.then === 'function';

export function withStorageParser<Value>(parse: (v: unknown) => Value) {
  function makeStorageWithParser(
    unknownStorage: SyncStorage<unknown>
  ): SyncStorage<Value>;
  function makeStorageWithParser(
    unknownStorage: AsyncStorage<unknown>
  ): AsyncStorage<Value>;
  function makeStorageWithParser(
    unknownStorage: AsyncStorage<unknown> | SyncStorage<unknown>
  ): AsyncStorage<Value> | SyncStorage<Value> {
    const storage = {
      ...unknownStorage,
      getItem: (key: string, initialValue: Value) => {
        const value = unknownStorage.getItem(key, initialValue);
        if (isPromiseLike(value)) {
          return value.then(parse);
        }
        return parse(value);
      },
    };
    // the set and subscribe still think they are unknown
    return storage as AsyncStorage<Value> | SyncStorage<Value>;
  }
  return makeStorageWithParser;
}
/**
 * Creates a writeable atom that will store its value in the source atom and also in local storage.
 * The source atom is the source of truth for the state, but if there's no value there will pull the value from local storage and sync it to the source atom as well.
 * If neither state container has the value, it falls back to defaultValue and doesn't sync that to either.
 * We may consider making defaultValue optionally a JotaiGetter to allow it to be derived from other atoms as well.
 *
 * @param prefix the local storage key
 * @param dynamicSuffixAtom when the local storage key needs to include a piece of state such as orgId this atoms value will be appended to the key
 * @returns an atom that will store its value in the source atom and also in local storage
 */

export function makeAtomBackedUpByLocalStorage<Value, ExtraArg>({
  prefix,
  sourceAtom,
  dynamicSuffixAtom,
  parseJs,
  shouldStoreNull,
  disableRestoreAtom,
}: LocalStorageAtomArgs<Value, ExtraArg>): typeof sourceAtom {
  const dynamicSuffixLocalStorageFamily = atomFamily(
    (dynamicSuffix: string | null | undefined) => {
      const storageKey = [prefix, dynamicSuffix].filter(isNotNullish).join('-');
      return atomWithStorage<Value | null>(
        storageKey,
        null,
        withStorageParser(parseJs)(
          createJSONStorage(() => {
            return window.localStorage;
          })
        ),
        { getOnInit: true }
      );
    }
  );

  // we could export this dynamicPrefixLocalStorage atom as its own util
  function getDynamicSuffixLocalStorageAtom(get: JotaiGetter) {
    const dynamicPrefix = dynamicSuffixAtom && get(dynamicSuffixAtom);
    return dynamicSuffixLocalStorageFamily(dynamicPrefix);
  }

  const localStorageToHashEffect = atomEffect((get, set) => {
    const localStorageValue = getIfAtomExists(
      get,
      getDynamicSuffixLocalStorageAtom(get)
    );
    const hashValue = get(sourceAtom);
    const disableRestore = !!disableRestoreAtom && get(disableRestoreAtom);
    if (!disableRestore && !hashValue && localStorageValue) {
      set(sourceAtom, localStorageValue);
    }
  });

  const resultAtom: WritableAtom<
    Value | null,
    [SetStateActionWithReset<Value | null> | typeof INIT, ExtraArg?],
    void
  > = atom(
    (get) => {
      get(localStorageToHashEffect);
      return (
        get(sourceAtom) ??
        getIfAtomExists(get, getDynamicSuffixLocalStorageAtom(get)) ??
        null
      );
    },
    (get, set, ...args) => {
      const [update, ...rest] = args;
      const localStorageAtom = getDynamicSuffixLocalStorageAtom(get);

      if (update === INIT) {
        const sourceAtomValue = get(sourceAtom);
        if (
          sourceAtomValue != null &&
          !isEqual(sourceAtomValue, get(localStorageAtom))
        ) {
          set(localStorageAtom, sourceAtomValue);
        }
        return;
      }

      const value = getValueFromSetStateAction(get, update, sourceAtom);
      const possiblyResetValue =
        value === null && !shouldStoreNull ? RESET : value;
      set(sourceAtom, possiblyResetValue, ...rest);
      set(localStorageAtom, possiblyResetValue);
    }
  );
  resultAtom.onMount = (setSelf) => {
    setSelf(INIT);
  };

  return resultAtom;
}

export function makeHashAtomWithParse<Value>({
  prefix,
  parseJs,
  shouldStoreNull,
}: CommonStorageAtomArgs<Value>): ReturnType<
  typeof atomWithHash<Value | null>
> {
  const hashAtom = atomWithHash<Value | null>(prefix, null, {
    // This matches the default but since we're customizing deserialize we should be explicit and match.
    // If we decide to change it, we should also update makeBiQueryHashParams (hashUtils.ts)
    // to use the new serializer.
    serialize: JSON.stringify,
    deserialize: (str) => {
      const value = safeJsonParse(null)(str);
      return parseJs(value);
    },
    setHash: 'replaceState',
  });
  return atom(
    (get) => get(hashAtom),
    (get, set, ...args) => {
      const [value, ...rest] = args;
      const possiblyResetValue =
        value === null && !shouldStoreNull ? RESET : value;
      set(hashAtom, possiblyResetValue, ...rest);
    }
  );
}

export function makeHashAtomBackedUpByLocalStorage<Value>(
  args: Omit<LocalStorageAtomArgs<Value>, 'sourceAtom'>
): WritableAtom<Value | null, [SetStateActionWithReset<Value | null>], void> {
  return makeAtomBackedUpByLocalStorage({
    ...args,
    sourceAtom: makeHashAtomWithParse<Value | null>(args),
  });
}
