import { WIN_FINANCE_PREFIX } from '@watershed/shared-universal/dashboardRoutes';
import { createContext, useContext, useState } from 'react';

/* 
  "Product" in this case is specifically the set of options in the logo bar 
  in the navigation. You are either in one product or another and switch from
  the Nav.
*/
export enum WatershedProduct {
  Corporate = 'Corporate',
  Finance = 'Finance',
  Unknown = 'Unknown',
}

export const CurrentProductContext = createContext({
  currentProduct: WatershedProduct.Corporate,
  setCurrentProduct: (product: WatershedProduct) => {},
});

export function useCurrentProductContext() {
  return useContext(CurrentProductContext);
}

export const useIsOnFinancePage = () => {
  const { currentProduct } = useCurrentProductContext();
  return currentProduct === WatershedProduct.Finance;
};

export const useIsOnUnknownPage = () => {
  const { currentProduct } = useCurrentProductContext();
  return currentProduct === WatershedProduct.Unknown;
};

// In the future, it would be better to lean on local storage to determine
export function determineCurrentProduct(
  pathname: string,
  canAccessCorporate: boolean
) {
  if (!canAccessCorporate) {
    return WatershedProduct.Finance;
  }
  const isOnFinanceOnlyPage = pathname.startsWith(WIN_FINANCE_PREFIX);
  return isOnFinanceOnlyPage
    ? WatershedProduct.Finance
    : WatershedProduct.Corporate;
}

export function CurrentProductProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentProduct, setCurrentProduct] = useState(
    WatershedProduct.Unknown
  );

  return (
    <CurrentProductContext.Provider
      value={{ currentProduct, setCurrentProduct }}
    >
      {children}
    </CurrentProductContext.Provider>
  );
}
