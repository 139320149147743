import { Environment } from './constants';

export function environment(): Environment {
  return (process.env.NODE_ENV || 'development') as Environment;
}

/**
 * assertTesting asserts that the currently resolved config's ENVIRONMENT is 'test'
 */

export function assertTesting() {
  if (environment() !== 'test') {
    throw Error('This code should only be executed in a testing environment.');
  }
}
/**
 * assertNotTesting asserts that the currently resolved config's ENVIRONMENT is
 * not 'test' if there's code that will always fail in tests we want to highlight
 * to the user.
 */

export function assertNotTesting() {
  if (environment() === 'test') {
    throw Error(
      'This code should only be executed in a non-testing environment.'
    );
  }
}
/**
 * assertNotProduction asserts that the currently resolved config's ENVIRONMENT is 'production'
 */

export function assertNotProduction() {
  if (environment() === 'production') {
    throw Error(
      'This code should only be executed in a non production environment.'
    );
  }
}
