import must from '@watershed/shared-universal/utils/must';
import { WritableAtom, useSetAtom, atom, Atom, Getter } from 'jotai';
import { RESET, unwrap, useHydrateAtoms } from 'jotai/utils';
import { useEffect } from 'react';
import { JotaiGetter, SetStateAction } from './types';
import { SetStateActionWithReset } from './types';

/**
 * Keeps an atom in sync with a given value (usually coming from a component prop)
 * Importantly ensures that it is set on the very first render
 *
 * Make sure to memoize the value if it is an object (array, object, function etc), just like any effect hook
 * see: https://github.com/pmndrs/jotai/discussions/2337#discussioncomment-8092610
 */

export function useSyncAtom<Value, Arg, Result>(
  atom: WritableAtom<Value, [Arg], Result>,
  value: Arg
) {
  useHydrateAtoms([[atom, value]]);
  const setAtom = useSetAtom(atom);
  useEffect(() => {
    setAtom(value);
  }, [setAtom, value]);
}
/**
 * Sets the initial value of the provided atoms
 * Note: doesn't continue to sync them on changes!
 * Lifted from: https://jotai.org/docs/guides/initialize-atom-on-render#using-typescript
 */

export function AtomsHydrator({
  initialValues,
  children,
}: {
  initialValues: Iterable<
    readonly [WritableAtom<unknown, [any], unknown>, unknown]
  >;
  children: React.ReactNode;
}) {
  useHydrateAtoms(new Map(initialValues));
  return <>{children}</>;
}

export function mustAtom<Value, Result>(
  nullableAtom: WritableAtom<Value | null, Array<Value>, Result>,
  name: string
) {
  return atom(
    (get) =>
      must(get(nullableAtom), `Atom: ${name} must be set prior to using`),
    (get, set, value: Value) => {
      set(nullableAtom, value);
    }
  );
}
/**
 * Creates an atom from a nullable atom which when set to null will return the value of getDefault
 * This atom assumes the underlying atom is already resetable
 */

export function makeAtomWithDefault<Value, WriteValue, ExtraArg>(
  baseAtom: WritableAtom<
    Value | null,
    [SetStateActionWithReset<Value | null, WriteValue | null>, ExtraArg?],
    void
  >,
  getDefault: (get: JotaiGetter) => Value
): WritableAtom<
  Value,
  [SetStateActionWithReset<Value | null, WriteValue | null>, ExtraArg?],
  void
> {
  return atom(
    (get) => get(baseAtom) ?? getDefault(get),
    (
      get,
      set,
      ...args: [
        SetStateActionWithReset<Value | null, WriteValue | null>,
        ExtraArg?,
      ]
    ) => {
      set(baseAtom, ...args);
    }
  );
}

export function makeNullableAtomResetable<Value, WriteValue, ExtraArg>(
  baseAtom: WritableAtom<
    Value | null,
    [SetStateAction<WriteValue | null>],
    void
  >
) {
  return atom(
    (get) => get(baseAtom),
    (
      get,
      set,
      ...args: [
        SetStateActionWithReset<Value | null, WriteValue | null>,
        ExtraArg?,
      ]
    ) => {
      const [update] = args;
      const value = getValueFromSetStateAction(get, update, baseAtom);
      set(baseAtom, value === RESET ? null : value);
    }
  );
}

/** this overload exists to help prevent calling this function with an already resetable atom, use `makeAtomWithDefault` instead */
export function makeAtomWithResetableDefault<Value, WriteValue>(
  baseAtom: WritableAtom<
    Value | null,
    [SetStateActionWithReset<Value | null, WriteValue | null>],
    void
  >,
  getDefault: (get: JotaiGetter) => Value
): void;
/**
 * Creates an atom from a nullable atom which when set to null will return the value of getDefault
 * This atom assumes the underlying atom is not already resetable and will pass null to it instead of RESET
 */
export function makeAtomWithResetableDefault<Value, WriteValue, ExtraArg>(
  baseAtom: WritableAtom<
    Value | null,
    [SetStateAction<WriteValue | null>],
    void
  >,
  getDefault: (get: JotaiGetter) => Value
): WritableAtom<
  Value,
  [SetStateActionWithReset<WriteValue | null>, ExtraArg?],
  void
>;
export function makeAtomWithResetableDefault<Value, WriteValue, ExtraArg>(
  baseAtom: WritableAtom<
    Value | null,
    [SetStateAction<WriteValue | null>],
    void
  >,
  getDefault: (get: JotaiGetter) => Value
): WritableAtom<
  Value,
  [SetStateActionWithReset<Value | null, WriteValue | null>, ExtraArg?],
  void
> {
  const resetableAtom = makeNullableAtomResetable<Value, WriteValue, ExtraArg>(
    baseAtom
  );
  return makeAtomWithDefault<Value, WriteValue, ExtraArg>(
    resetableAtom,
    getDefault
  );
}

/**
 * This helper lets you toggle between two atoms based on a condition. (e.g.
 * we're using it to control whether atoms-with-side-effects are used, vs
 * atoms-that-are-in-memory-only, in custom reports.) So this helper lets you
 * decide whether you want that behavior to occur, without having to untangle
 * other atoms.
 */
export function makeConditionalAtom<Value, WriteValue, Result, ExtraArg>({
  predicateAtom,
  ifTrueAtom,
  ifFalseAtom,
}: {
  /** The atom that will be used to determine which atom to use */
  predicateAtom: WritableAtom<boolean, Array<boolean>, Result>;
  /** The atom that will be used if the condition is true */
  ifTrueAtom: WritableAtom<Value, [WriteValue, ExtraArg?], Result>;
  /** The atom that will be used if the condition is false  */
  ifFalseAtom: WritableAtom<Value, [WriteValue, ExtraArg?], Result>;
}) {
  return atom(
    (get) => (get(predicateAtom) ? get(ifTrueAtom) : get(ifFalseAtom)),
    (get, set, ...args: [WriteValue, ExtraArg?]) => {
      if (get(predicateAtom)) {
        set(ifTrueAtom, ...args);
      } else {
        set(ifFalseAtom, ...args);
      }
    }
  );
}

export function atomAsyncUntilResolved<T, Args extends Array<any>, Result>(
  baseAtom: WritableAtom<Promise<T>, Args, Result>
): WritableAtom<T | Promise<T>, Args, Result>;
export function atomAsyncUntilResolved<T>(
  baseAtom: Atom<Promise<T>>
): Atom<T | Promise<T>>;
export function atomAsyncUntilResolved<T>(
  baseAtom: Atom<Promise<T>> | WritableAtom<Promise<T>, Array<any>, any>
) {
  const unwrappedAtom = unwrap(baseAtom);
  const read = (get: Getter) => get(unwrappedAtom) ?? get(baseAtom);
  return 'write' in baseAtom ? atom(read, baseAtom.write) : atom(read);
}

export function getValueFromSetStateAction<Value, UpdateValue = Value>(
  get: Getter,
  value: SetStateAction<Value, UpdateValue>,
  atom: Atom<Value> | WritableAtom<Value, Array<any>, any>
): UpdateValue {
  return typeof value === 'function'
    ? (value as (prev: Value) => UpdateValue)(get(atom))
    : value;
}
