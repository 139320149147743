import {
  Box,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  alpha,
} from '@mui/material';
import Icon from '@watershed/icons/Icon';
import { mixinSx } from '@watershed/style/styleUtils';

import React from 'react';

export type SelectableTileProps = React.PropsWithChildren<
  {
    label: React.ReactNode;
    sublabel?: React.ReactNode;
    adornment?: React.ReactNode;
    selected?: boolean;
    disabled?: boolean;
    layout?: 'horizontal' | 'vertical';
    inputAffordance?: React.ReactNode;
    wrapLabel?: boolean;
    onClick?: () => void;
    tooltip?: React.ReactNode;
    tooltipProps?: Partial<TooltipProps>;
    sx?: SxProps<Theme>;
  } & (
    | { Icon?: typeof Icon; image?: never }
    | { Icon?: never; image?: React.ReactNode }
  )
>;

export default function SelectableTile({
  label,
  sublabel,
  adornment,
  selected,
  disabled,
  layout = 'horizontal',
  inputAffordance,
  wrapLabel,
  onClick,
  tooltip,
  tooltipProps,
  Icon,
  image,
  children,
  sx,
}: SelectableTileProps) {
  return (
    <Tooltip
      title={tooltip ?? ''}
      {...tooltipProps}
      placement={tooltipProps?.placement ?? 'right'} // Default placement should be to the right
    >
      <Box
        tabIndex={0}
        aria-disabled={disabled}
        aria-checked={selected}
        sx={mixinSx(
          (theme) => ({
            display: 'flex',
            flexGrow: 1,
            flexDirection: layout === 'horizontal' ? 'row' : 'column',
            gap:
              layout === 'horizontal'
                ? Icon
                  ? 1
                  : image
                    ? 1.5
                    : 0
                : Icon || image
                  ? 1
                  : 0,
            px: 2,
            py: 1.5,
            borderRadius: '6px',
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.paper,
            ':focus-visible': {
              outlineColor: theme.palette.primary.main,
              outlineOffset: '2px',
            },
            '&[aria-checked="true"]': {
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light,
            },
            '&[aria-disabled="true"]': {
              backgroundColor: alpha(theme.palette.panel, 0.5),
              '& *': {
                opacity: 0.75,
              },
            },
            '&:hover:not([aria-disabled="true"])': {
              cursor: 'pointer',
              backgroundColor: theme.palette.panel,
            },
          }),
          sx
        )}
        onClick={() => {
          if (!disabled) onClick?.();
        }}
        onKeyDown={(event) => {
          if (!disabled && event.key === ' ') {
            (event.target as HTMLDivElement | undefined)
              ?.querySelector('input')
              ?.click();
          }
        }}
      >
        <Box display="flex">
          {inputAffordance && (
            <Box sx={{ marginTop: '-1px' }}>{inputAffordance}</Box>
          )}
          {Icon ? (
            <Icon size={24} />
          ) : image ? (
            <Box flexShrink={0} display="flex" alignItems="center">
              {image}
            </Box>
          ) : null}
        </Box>
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            gap={1}
            width="100%"
          >
            {typeof label === 'string' ? (
              <Typography variant="h4" noWrap={!wrapLabel}>
                {label}
              </Typography>
            ) : (
              label
            )}
            {adornment}
          </Box>
          {sublabel && typeof sublabel === 'string' ? (
            <Typography variant="body2">{sublabel}</Typography>
          ) : (
            sublabel
          )}
          {children}
        </Box>
      </Box>
    </Tooltip>
  );
}
