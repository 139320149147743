import { GQPermissionType } from '@watershed/shared-universal/generated/graphql';
import { Supplier } from '@watershed/shared-universal/suppliers/supplierTypes';
import isNotNullish from '@watershed/shared-universal/utils/isNotNullish';
import {
  hasPermission,
  UserPermissions,
} from '@watershed/shared-universal/utils/permissionUtils';

export type SupplierPermissionObject = {
  companyId: string;
  companyName: string;
};

export function getSupplierPermissionObjects(
  suppliers: Array<Pick<Supplier, 'name' | 'companyId'>>
): Array<SupplierPermissionObject> {
  return suppliers
    .map((supplier) => {
      if (supplier.companyId) {
        return {
          companyId: supplier.companyId,
          companyName: supplier.name,
        };
      } else {
        return null;
      }
    })
    .filter(isNotNullish);
}

/**
 * Returns true if the current user has permission to manage any suppliers.
 */
export function hasPermissionToManageAnySupplier(
  userPermissions: UserPermissions
) {
  return hasPermission(userPermissions, [GQPermissionType.ManageSuppliers], {
    allowAnyObject: true,
  });
}

/**
 * Returns true if the current user has permission to manage all suppliers.
 */
export function hasPermissionToManageAllSuppliers(
  userPermissions: UserPermissions
) {
  // "Manage all suppliers" is stored as "ManageSuppliers" with no object ID.
  return hasPermission(userPermissions, [GQPermissionType.ManageSuppliers]);
}

/**
 * Returns true if the current user has permission to manage the given supplier.
 */
export function hasPermissionToManageOneSupplier(
  userPermissions: UserPermissions,
  companyId: string
) {
  return hasPermission(userPermissions, [GQPermissionType.ManageSuppliers], {
    source: { id: companyId },
  });
}

export const NO_ADMIN_PERMISSION_ERROR =
  'You do not have permissions to edit this.';

/**
 * Returns true if the current user has permission to edit custom columns.
 */
export function hasPermissionToEditCustomColumn(
  userPermissions: UserPermissions
) {
  return hasPermission(userPermissions, [GQPermissionType.Admin]);
}

export function hasPermissionToViewDrilldown(userPermissions: UserPermissions) {
  return hasPermission(userPermissions, [GQPermissionType.ViewFootprintDetail]);
}
