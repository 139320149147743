import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import PopoverButton from '@watershed/ui-core/components/PopoverButton';
import Button from '@watershed/ui-core/components/Button';
import FilterIcon from '@watershed/icons/components/Filter';
import AddIcon from '@watershed/icons/components/Add';
import { useRouter } from 'next/router';
import BrowserRefreshIcon from '@watershed/icons/components/BrowserRefresh';
import { FinanceFilterSelector } from './FinanceFilterSelector';
import {
  FinanceFiltersType,
  FullFilters,
} from '@watershed/shared-universal/apiSchemas/finance/zodFinance';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import difference from 'lodash/difference';
import { combineFilters } from '@watershed/shared-universal/finance/utils/filters';
import isNotNullish from '@watershed/shared-universal/utils/isNotNullish';
import isArray from 'lodash/isArray';

export const combineFiltersForDropdown = (
  filters: FullFilters,
  includeQueryParams: boolean
): FinanceFiltersType => {
  const filtersToCombine = [
    filters.routeFilters,
    filters.viewFilters,
    includeQueryParams ? filters.queryParamFilters : null,
  ].filter(isNotNullish);
  const combinedFilters = combineFilters(filtersToCombine);

  return omitBy(
    omit(combinedFilters, ['year', 'isTotalEmissions', 'viewId']),
    (val) => (val ? false : true)
  );
};

const FinanceFilterButton = React.memo(function FinanceFilterButton({
  filters,
  onSave,
}: {
  filters: FullFilters;
  onSave?: () => void;
}) {
  // This state is what we render, so we should make sure it starts as the combination of the
  // saved view and the URL explicit param filters
  const [pendingFilters, setPendingFilters] = React.useState<
    Array<{ field: string; value?: Array<string> | null }>
  >(
    Object.entries(combineFiltersForDropdown(filters, true)).map(
      ([field, value]) => ({
        field,
        value,
      })
    )
  );
  const router = useRouter();

  const handleReset = async () => {
    setPendingFilters(
      Object.entries(combineFiltersForDropdown(filters, false)).map(
        ([field, value]) => ({
          field,
          value,
        })
      )
    );
  };

  const applyFilters = async () => {
    const nonQueryParamFilters = combineFiltersForDropdown(filters, false);

    const newQueryFilters = pendingFilters.reduce((acc, record) => {
      const diff = difference(
        record.value,
        nonQueryParamFilters[record.field] ?? []
      );
      if (diff.length) {
        return { ...acc, [record.field]: diff };
      }
      return acc;
    }, {});

    const { query } = router;
    for (const filter of Object.keys(filters.queryParamFilters)) {
      // Clear all existing query param filters (TBD if this is dangerous)
      if (Object.keys(filters.routeFilters).includes(filter)) {
        // Skip route filters
        continue;
      }
      delete query[filter];
    }

    await router.push({
      pathname: router.pathname,
      query: {
        ...query, // Makes sure we keep any other route params
        ...newQueryFilters,
      },
    });
  };

  return (
    <>
      <PopoverButton
        startIcon={<FilterIcon />}
        buttonLabel="Filter"
        keepButtonVisible
      >
        <Stack width={550}>
          <Box
            sx={{
              padding: 2,
              paddingBottom: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Stack direction="row" gap={3} alignItems="center">
              {/* // TODO: i18n (please resolve or remove this TODO line if legit) //  */}
              {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready  */}
              <Typography variant="h5">Filters</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <Button
                startIcon={<BrowserRefreshIcon />}
                variant="text"
                onClick={handleReset}
              >
                {/* // TODO: i18n (please resolve or remove this TODO line if legit) //  */}
                {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready  */}
                Reset
              </Button>
              {onSave && (
                <Button startIcon={<AddIcon />} variant="text" onClick={onSave}>
                  {/* // TODO: i18n (please resolve or remove this TODO line if legit) //  */}
                  {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready  */}
                  Save as view...
                </Button>
              )}
              <Button variant="contained" onClick={applyFilters}>
                {/* // TODO: i18n (please resolve or remove this TODO line if legit) //  */}
                {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready  */}
                Apply
              </Button>
            </Stack>
          </Box>
          <FinanceFilterSelector
            onChange={setPendingFilters}
            value={pendingFilters}
            lockedFilters={combineFiltersForDropdown(filters, false)}
            fullyLockedFilterFields={Object.entries(filters.routeFilters)
              .map(([key, value]) => {
                if (isArray(value) ? !!value.length : !!value) return key;
                return null;
              })
              .filter(isNotNullish)}
          />
        </Stack>
      </PopoverButton>
    </>
  );
});

export default FinanceFilterButton;
