import {
  Stack,
  Typography,
  Theme,
  Box,
  SxProps,
  Divider,
  Chip,
} from '@mui/material';
import { Trans, useLingui } from '@lingui/react/macro';
import ConversationIcon from '@watershed/icons/components/Conversation';
import {
  GQCreatePermissionItemInput,
  GQDataIssueState,
  GQDataIssueWithCommentsFieldsFragment,
  GQMeasurementTaskForDataIssueKind,
} from '@watershed/shared-universal/generated/graphql';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import BlankSlate from '@watershed/ui-core/components/BlankSlate';
import { useContext, useEffect, useState } from 'react';
import { DataIssueDialog } from '@watershed/shared-frontend/components/DataIssueDialog';
import { SystemStyleObject } from '@mui/system';
import { COMPLETED_OPACITY } from '../../../../constants/measurementConstants';
import { CustomerCreateDataIssueButton } from '../../CustomerCreateDataIssueForm';
import { UserContext } from '../../../../utils/UserContext';
import { getRelativeTimeForCustomer } from '@watershed/shared-universal/utils/relativeTime';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import { DataIssueStatusIndicator } from '@watershed/shared-frontend/components/DataIssueStatusIndicator';
import partition from 'lodash/partition';
import orderBy from 'lodash/orderBy';
import {
  getLatestTimestampForDataIssue,
  getDataIssuePermissionsForDatasource,
} from '@watershed/shared-frontend/utils/dataIssueUtils';
import { mixinSx } from '@watershed/style/styleUtils';
import { useQueryParam } from '@watershed/shared-frontend/utils/queryParamHooks';
import gql from 'graphql-tag';
import { getGqlResultDataBang } from '@watershed/shared-frontend/utils/errorUtils';
import { useGetUserUploadTaskForDataIssueQuery } from '@watershed/shared-frontend/generated/urql';
import invariant from 'invariant';
import isFetchingOrStale from '@watershed/shared-frontend/utils/isFetchingOrStale';
import CenteredCircularProgress from '@watershed/ui-core/components/CenteredCircularProgress';
import SheetPanel from '@watershed/ui-core/components/Sheet/SheetPanel';
import { PAGE_HEADER_Z_INDEX } from '@watershed/shared-frontend/components/PageHeader';
import Button from '@watershed/ui-core/components/Button';
import { DATA_ISSUE_ID_QUERY_PARAM } from '@watershed/shared-universal/dashboardRoutes';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import CustomReactMarkdown from '@watershed/ui-core/components/CustomReactMarkdown';
import { CommonDiscussionFields } from '@watershed/shared-universal/utils/discussionUtils';
import { makeStyles } from '@mui/styles';

gql`
  fragment UserUploadTaskForDataIssue on UserUploadTask {
    id
    issues(isPublished: true) {
      edges {
        node {
          ...DataIssueWithCommentsFields
        }
      }
    }
    datasource {
      id
      name
    }
    measurementProject {
      id
      active
    }
  }

  fragment ValueMappingTaskForDataIssue on ValueMappingTask {
    id
    issues(isPublished: true) {
      edges {
        node {
          ...DataIssueWithCommentsFields
        }
      }
    }
    measurementProject {
      id
      active
    }
  }

  query GetUserUploadTaskForDataIssue($userUploadTaskId: ID!) {
    userUploadTask(id: $userUploadTaskId) {
      ...UserUploadTaskForDataIssue
    }
  }

  query GetValueMappingTaskForDataIssue($valueMappingTaskId: ID!) {
    valueMappingTask(id: $valueMappingTaskId) {
      ...ValueMappingTaskForDataIssue
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  issueMarkdown: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
}));

const completedSx: SystemStyleObject<Theme> = {
  opacity: COMPLETED_OPACITY,
};

export const DATA_ISSUE_SIDEBAR_WIDTH_PX = 350;

function isComplete(state: GQDataIssueState) {
  return state === GQDataIssueState.Done;
}
function needsAttention(state: GQDataIssueState) {
  return state === GQDataIssueState.NeedsUserResponse;
}

function DataIssueCard({
  issue,
  isSelected,
  onClick,
}: {
  issue: CommonDiscussionFields;
  isSelected?: boolean;
  onClick: () => void;
}) {
  const { t } = useLingui();
  const classes = useStyles();

  const { orgName, userId: activeUserId } = useContext(UserContext);

  const comments = flattenConnection(issue.comments);
  const latestComment = comments.at(-1);

  // every customer-initiated issue begins with a comment, so no comment implies
  // it was a Watershed-initiated issue
  const latestAuthor =
    !latestComment ||
    latestComment.person?.isWatershedEmployee ||
    latestComment.person?.isWatershedContractor
      ? 'Watershed'
      : latestComment?.person?.displayName
        ? `${latestComment.person?.displayName} (${orgName})`
        : t`Deleted user (${orgName})`;

  const latestMessage = latestComment?.message ?? issue.description;
  const latestTimestamp = getLatestTimestampForDataIssue(issue);
  const isActiveUserTheLatestAuthor =
    latestComment?.person?.id === activeUserId;

  return (
    <Stack
      onClick={onClick}
      sx={mixinSx(
        {
          padding: 2,
          borderRadius: 1,
          border: (theme) => `1px solid ${theme.palette.grey20}`,
          cursor: 'pointer',
          ':hover': {
            backgroundColor: (theme) => theme.palette.cobalt05,
          },
        },
        isSelected
          ? { backgroundColor: (theme) => theme.palette.cobalt05 }
          : undefined,
        isComplete(issue.state) ? completedSx : undefined
      )}
      spacing={1}
      data-test={`issue-${issue.title}`}
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <DataIssueStatusIndicator
          state={issue.state}
          isActiveUserTheLatestAuthor={isActiveUserTheLatestAuthor}
          isSmall
        />
        <Typography
          variant={needsAttention(issue.state) ? 'h3' : 'body1'}
          noWrap
        >
          {issue.title}
        </Typography>
      </Stack>

      <Stack spacing={0.5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="body3"
            sx={{ color: (theme) => theme.palette.slate }}
          >
            {latestAuthor}
          </Typography>
          <Typography
            variant="body3"
            sx={{ color: (theme) => theme.palette.slate }}
          >
            {getRelativeTimeForCustomer(latestTimestamp)}
          </Typography>
        </Stack>
        <CustomReactMarkdown
          source={latestMessage}
          variant="body3"
          className={classes.issueMarkdown}
        />
      </Stack>
    </Stack>
  );
}

export function IssuesSection({
  header,
  issues,
  selectedIssueId,
  setSelectedIssue,
}: {
  header?: string;
  issues: Array<CommonDiscussionFields>;
  selectedIssueId?: string | null;
  setSelectedIssue: (id: string) => void;
}) {
  if (!issues.length) {
    return null;
  }

  return (
    <Stack spacing={1}>
      {header && <Typography variant="body2">{header}</Typography>}
      {issues.map((issue) => (
        <DataIssueCard
          isSelected={selectedIssueId === issue.id}
          key={issue.id}
          issue={issue}
          onClick={() => {
            setSelectedIssue(issue.id);
            Analytics.action('dataIssueSidebarIssueClick', {
              issueId: issue.id,
            });
            Analytics.modal('dataIssueDialog', {
              source: 'dataIssueSidebar',
              issueId: issue.id,
            });
          }}
        />
      ))}
    </Stack>
  );
}

function DataIssuePanel({
  taskIssues,
  datasourceName,
  linkedObjectId,
  linkedObjectType,
  permissions,
  isMeasurementComplete,
  headerDirection,
  sx,
  selectedIssue,
  setSelectedIssue,
}: {
  taskIssues: Array<GQDataIssueWithCommentsFieldsFragment>;
  datasourceName: string;
  linkedObjectId: string;
  linkedObjectType: GQMeasurementTaskForDataIssueKind;
  permissions: Array<GQCreatePermissionItemInput>;
  isMeasurementComplete: boolean;
  headerDirection: 'row' | 'column';
  sx?: SxProps<Theme>;
  selectedIssue: string | null;
  setSelectedIssue: (issueId: string | null) => void;
}) {
  const { t } = useLingui();
  const { orgId } = useContext(UserContext);

  const { userId: activeUserId } = useContext(UserContext);
  const issueToDisplay = taskIssues.find((issue) => issue.id === selectedIssue);
  const incompleteIssueCount = taskIssues.filter(
    (issue) => !isComplete(issue.state)
  ).length;

  // Show issues which need attention first and completed issues
  // last. Otherwise, sort by latest timestamp.
  const issueSortKey = (issue: GQDataIssueWithCommentsFieldsFragment) => {
    if (needsAttention(issue.state)) {
      return 0;
    }
    if (isComplete(issue.state)) {
      return 2;
    }
    return 1;
  };
  const [todoIssues, otherIssues] = partition(
    orderBy(
      taskIssues,
      [issueSortKey, getLatestTimestampForDataIssue],
      ['asc', 'desc']
    ),
    (issue) => needsAttention(issue.state)
  );

  const shouldShowHeaders = todoIssues.length > 0 && otherIssues.length > 0;

  return (
    <Box
      sx={mixinSx(
        (theme) => ({
          flexBasis: DATA_ISSUE_SIDEBAR_WIDTH_PX,
          flexShrink: 0,
          maxWidth: DATA_ISSUE_SIDEBAR_WIDTH_PX,
          [theme.breakpoints.down('md')]: {
            maxWidth: '230px',
          },
        }),
        sx
      )}
    >
      <Box
        sx={
          // A standalone sidebar shows the title and button on one row.
          // The side sheet shows the button on the next row to make room for
          // the X.
          headerDirection === 'row'
            ? {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }
            : {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                gap: 2,
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }
        }
      >
        <Typography variant="h2">
          <Trans comment="Measurement sidebar subheading">
            Conversations ({incompleteIssueCount})
          </Trans>
        </Typography>
        <CustomerCreateDataIssueButton
          permissions={permissions}
          linkedObjectId={linkedObjectId}
          linkedObjectType={linkedObjectType}
          datasourceName={datasourceName}
          isMeasurementComplete={isMeasurementComplete}
        />
      </Box>
      <Stack
        direction="column"
        gap={2}
        sx={{ mt: 2 }}
        data-test={TestIds.DataIssueConversations}
      >
        {taskIssues.length > 0 ? (
          <>
            <IssuesSection
              header={shouldShowHeaders ? t`To do` : ''}
              issues={todoIssues}
              setSelectedIssue={setSelectedIssue}
            />
            <IssuesSection
              header={shouldShowHeaders ? t`Everything else` : ''}
              issues={otherIssues}
              setSelectedIssue={setSelectedIssue}
            />
          </>
        ) : (
          <BlankSlate
            icon={ConversationIcon}
            title={<Trans>No conversations yet</Trans>}
            size="small"
          />
        )}
      </Stack>
      {issueToDisplay && (
        <DataIssueDialog
          datasourceName={datasourceName}
          permissions={permissions}
          orgId={orgId}
          issue={issueToDisplay}
          onClose={() => {
            setSelectedIssue(null);
          }}
          activeUserId={activeUserId}
        />
      )}
    </Box>
  );
}

export function UserUploadTaskDataIssueSidebar({
  userUploadTaskId,
}: {
  userUploadTaskId: string;
}) {
  const [selectedIssue, setSelectedIssue] = useQueryParam(
    DATA_ISSUE_ID_QUERY_PARAM
  );
  const [result] = useGetUserUploadTaskForDataIssueQuery({
    variables: { userUploadTaskId },
  });

  if (isFetchingOrStale(result)) {
    return <CenteredCircularProgress />;
  }
  const data = getGqlResultDataBang(result);
  const userUploadTask = data.userUploadTask;
  invariant(userUploadTask !== null, `Invalid task ID ${userUploadTaskId}`);

  const taskIssues = flattenConnection(userUploadTask.issues);
  const datasource = userUploadTask.datasource;

  const dataIssuePermissions = getDataIssuePermissionsForDatasource(
    userUploadTask.datasource.id,
    userUploadTask.datasource.name
  );

  return (
    <DataIssuePanel
      taskIssues={taskIssues}
      datasourceName={datasource.name}
      linkedObjectId={userUploadTask.id}
      linkedObjectType={GQMeasurementTaskForDataIssueKind.UserUploadTask}
      permissions={dataIssuePermissions}
      isMeasurementComplete={!userUploadTask.measurementProject.active}
      headerDirection="row"
      sx={{ padding: 2 }}
      selectedIssue={selectedIssue}
      setSelectedIssue={setSelectedIssue}
    />
  );
}

/**
 * A "Support" button which pops opens a side sheet displaying data issues when clicked.
 * If the issue ID is passed in the URL, automatically opens to that issue on page load.
 */
export function DataIssueHelpButton({
  linkedObjectId,
  linkedObjectType,
  taskIssues,
  datasourceName,
  permissions,
  isMeasurementComplete,
  analyticsSource = 'DataIssueHelpButton',
  buttonText,
  endIcon,
  bottomComponent,
}: {
  linkedObjectId: string;
  linkedObjectType: GQMeasurementTaskForDataIssueKind;
  taskIssues: Array<GQDataIssueWithCommentsFieldsFragment>;
  datasourceName: string;
  permissions: Array<GQCreatePermissionItemInput>;
  isMeasurementComplete: boolean;
  analyticsSource?: string;
  buttonText: string;
  endIcon?: React.ReactNode;
  bottomComponent?: React.ReactNode;
}) {
  const [dataIssuePanelOpen, setDataIssuePanelOpen] = useState<boolean>(false);
  const [selectedIssue, setSelectedIssue] = useQueryParam(
    DATA_ISSUE_ID_QUERY_PARAM
  );

  useEffect(() => {
    if (selectedIssue) {
      setDataIssuePanelOpen(true);
    }
  }, [selectedIssue, setDataIssuePanelOpen]);

  const openIssueCount = taskIssues.filter(
    (issue) => issue.state !== GQDataIssueState.Done
  ).length;

  return (
    <>
      <Button
        startIcon={endIcon ? null : <ConversationIcon />}
        endIcon={endIcon}
        onClick={() => {
          Analytics.action(`${analyticsSource}-dataIssueSidebarOpen`);
          setDataIssuePanelOpen(true);
        }}
        data-test={TestIds.OpenDataIssuePanel}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {buttonText}
          {openIssueCount > 0 && !endIcon && (
            <Chip size="small" label={openIssueCount} />
          )}
        </Stack>
      </Button>
      <SheetPanel
        title=""
        headerSx={{
          padding: 2,
          // Hide the title text, and shift the content up to be flush with the X button
          // since this components contains its own dynamic title
          borderBottom: 'none',
          position: 'absolute',
          width: '100%',
        }}
        sx={{ zIndex: PAGE_HEADER_Z_INDEX + 1 }}
        contentSx={{ padding: 2, paddingTop: 2.5 }}
        open={dataIssuePanelOpen}
        onClose={() => {
          Analytics.action(`${analyticsSource}-dataIssueSidebarClose`);
          setSelectedIssue(null);
          setDataIssuePanelOpen(false);
        }}
        position="fixed"
      >
        <Stack gap={3}>
          <DataIssuePanel
            linkedObjectId={linkedObjectId}
            linkedObjectType={linkedObjectType}
            taskIssues={taskIssues}
            datasourceName={datasourceName}
            permissions={permissions}
            isMeasurementComplete={isMeasurementComplete}
            headerDirection="column"
            selectedIssue={selectedIssue}
            setSelectedIssue={setSelectedIssue}
            sx={
              bottomComponent
                ? { paddingBottom: 2, maxHeight: '40%', overflow: 'auto' }
                : undefined
            }
          />
          {bottomComponent ? <Divider /> : null}
          {bottomComponent}
        </Stack>
      </SheetPanel>
    </>
  );
}
