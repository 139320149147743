import { useContext, useEffect } from 'react';
import { Trans, useLingui } from '@lingui/react/macro';
import { Formik } from 'formik';

import { Analytics } from '@watershed/analytics/analyticsUtils';
import { switchPinnedOrganizationId } from '@watershed/shared-frontend/utils/pinnedOrganizationId';
import DialogForm from '@watershed/ui-core/components/DialogForm';
import { AutocompleteField } from '@watershed/ui-core/components/Form/AutocompleteField';
import { Dialog } from '@watershed/ui-core/components/Dialog';
import ErrorBox from '@watershed/ui-core/components/ErrorBox';

import { UserContext } from '../../utils/UserContext';

export default function SwitchOrganizationDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useLingui();
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (open) {
      Analytics.modal('switchOrgDialog');
    }
  }, [open]);

  const onCloseWrapped = () => {
    Analytics.action('switchOrgDialog.close');
    onClose();
  };

  const orgs = userContext.accessibleOrgs;

  if (orgs) {
    return (
      <>
        <Formik
          initialValues={{ name: null, id: null }}
          onSubmit={(value, form) => {
            if (value.id) {
              switchPinnedOrganizationId({ orgId: value.id });
              onCloseWrapped();
            } else {
              form.validateField('organization');
            }
          }}
        >
          {(form) => {
            return (
              <DialogForm
                open={open}
                onClose={() => onCloseWrapped()}
                maxWidth="sm"
                submit="Switch"
                header={{
                  title: t({
                    message: 'Switch organization',
                    context:
                      'Button copy for users with multiple Organizations in Watershed to switch from one organization to another',
                  }),
                }}
              >
                <AutocompleteField
                  options={orgs}
                  getOptionDisabled={(option) =>
                    option.id === userContext.orgId
                  }
                  sx={{
                    width: 'auto',
                  }}
                  label={t({
                    message: 'Which organization would you like to switch to?',
                    context:
                      'Form label for a list of Watershed organizations that a user can switch to',
                  })}
                  size="medium"
                  id="organization"
                  required
                  onChange={(evt, org) => {
                    form.setFieldValue('id', org?.id);
                  }}
                />
              </DialogForm>
            );
          }}
        </Formik>
      </>
    );
  } else {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        header={{
          title: t({
            message: 'Switch organization',
            context:
              'Button copy for users with multiple Organizations in Watershed to switch from one organization to another',
          }),
          subtitle: (
            <ErrorBox level="error" variant="text" sx={{ marginBottom: 2 }}>
              <Trans>
                An error occured while loading the list of your organizations
              </Trans>
            </ErrorBox>
          ),
        }}
        maxWidth="sm"
      ></Dialog>
    );
  }
}
