import { Box, MenuItem, Typography } from '@mui/material';
import { Trans, useLingui } from '@lingui/react/macro';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import QuestionMarkIcon from '@watershed/icons/components/QuestionMark';
import {
  DataIssueDialog,
  TextFieldMultilineForMentions,
} from '@watershed/shared-frontend/components/DataIssueDialog';
import {
  useCreateCustomerInitiatedDataIssueMutation,
  useGetAllAssignableUsersWithPermissionQuery,
} from '@watershed/shared-frontend/generated/urql';
import { getGqlResultDataBang } from '@watershed/shared-frontend/utils/errorUtils';
import Button from '@watershed/ui-core/components/Button';
import DialogForm from '@watershed/ui-core/components/DialogForm';
import SelectField from '@watershed/ui-core/components/Form/SelectField';
import TextField, {
  TextFieldMultiline,
} from '@watershed/ui-core/components/Form/TextField';
import IconButton from '@watershed/ui-core/components/IconButton';
import { useDialog } from '@watershed/ui-core/hooks/useDialog';
import { Formik, FormikProps } from 'formik';
import gql from 'graphql-tag';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import { useContext, useState } from 'react';
import { getMeasurementCompleteMessage } from './measureUtils';
import { UserContext } from '../../utils/UserContext';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import {
  GQCreatePermissionItemInput,
  GQMeasurementTaskForDataIssueKind,
} from '@watershed/shared-universal/generated/graphql';
import isFetchingOrStale from '@watershed/shared-frontend/utils/isFetchingOrStale';
import keyBy from 'lodash/keyBy';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { formatCommentForMentions } from '@watershed/shared-frontend/utils/dataIssueUtils';
import { CheckboxField } from '@watershed/ui-core/components/Form/CheckboxField';

gql`
  mutation CreateCustomerInitiatedDataIssue(
    $input: CreateCustomerInitiatedDataIssueInput!
  ) {
    createCustomerInitiatedDataIssue(input: $input) {
      issue {
        ...DataIssueWithCommentsFields
      }
      measurementProject {
        ...MeasurementProjectForMeasurementPage
      }
      valueMappingTask {
        ...ValueMappingPageValueMappingTask
      }
    }
  }
`;

interface FormValues {
  title: string;
  message: string;
  shouldNotifyWatershed: boolean;
}

// TODO(LOC-31) This is used in multiple places
const OTHER = 'Other';

// TODO(LOC-31) This is used in multiple places
const PROBLEM_TYPES = [
  `I don't know what data is required`,
  `I don't have the required data`,
  'The upload process is confusing',
  OTHER,
] as const;

/**
 * An interopable form for creating a customer-initiated data issue. To use in
 * Dialog form, see {@link CustomerCreateDataIssueDialog}.
 */
export default function CustomerCreateDataIssueForm({
  requiredPermissions,
  linkedObjectId,
  linkedObjectType,
  datasourceName,
  variant = 'topicQuestion',
  // TODO(LOC-31) How do we localize default prop values?
  initialTitle = variant === 'whatKindOfProblem' ? PROBLEM_TYPES[0] : '',
  initialMessage = '',
  fileMetadataIds,
  onSubmitComplete,
  children,
}: {
  /**
   * The permissions a user needs to access this data issue. Used when adding users.
   */
  requiredPermissions: Array<
    GQCreatePermissionItemInput & { objectName?: string }
  >;
  linkedObjectId: string;
  linkedObjectType: GQMeasurementTaskForDataIssueKind;
  datasourceName: string;
  initialTitle?: string;
  initialMessage?: string;
  /**
   * Associated user uploads, if any.
   */
  fileMetadataIds?: Array<string>;
  onSubmitComplete?: (values: FormValues) => void;
  variant?: 'topicQuestion' | 'whatKindOfProblem';
  children: (props: {
    children: React.ReactNode;
    disabled: boolean;
    form: FormikProps<FormValues>;
  }) => JSX.Element;
}) {
  const { t } = useLingui();
  const { userId: activeUserId, orgId } = useContext(UserContext);
  const snackbar = useSnackbar();
  const [mentionedUserIds, setMentionedUserIds] = useState<Array<string>>([]);
  const [, executeCreateCustomerInitiatedDataIssue] =
    useCreateCustomerInitiatedDataIssueMutation();
  const openDataIssueDialog = useDialog(DataIssueDialog);

  const [res] = useGetAllAssignableUsersWithPermissionQuery({
    variables: {
      permissions: requiredPermissions.map((permission) => ({
        permissionType: permission.permission,
        objectId: permission.objectId,
        objectType: permission.objectType,
      })),
      orgId,
    },
  });

  if (isFetchingOrStale(res)) {
    return <></>;
  }

  const data = getGqlResultDataBang(res);
  const userData = flattenConnection(data.organization.users);

  const userDataById = keyBy(userData, (d) => d.id);

  const onSubmit = async (values: FormValues) => {
    Analytics.action('customerCreateDataIssue', values);

    const [formattedMessageText, memberIds] = formatCommentForMentions(
      values.message,
      mentionedUserIds,
      userDataById
    );

    const result = await executeCreateCustomerInitiatedDataIssue({
      input: {
        linkedObjectId,
        linkedObjectType,
        // TODO(LOC-31) Should we persist data issue `title` in the user locale?
        title:
          values.title === OTHER
            ? linkedObjectType ===
              GQMeasurementTaskForDataIssueKind.UserUploadTask
              ? 'Needed help during file upload process'
              : 'Needed help during value mapping process'
            : values.title,
        message: formattedMessageText,
        fileMetadataIds,
        memberIds,
        shouldNotifyWatershed: values.shouldNotifyWatershed,
        // TODO, if/when we add DataIssue ↔️ UUT join table, we could add this here
        // as structured data.
        // userUploadedTableId,
      },
    });
    const issue =
      getGqlResultDataBang(result).createCustomerInitiatedDataIssue?.issue;

    if (result.error || !issue) {
      Analytics.error('customerCreateDataIssue', {
        error: result.error,
      });
      snackbar.enqueueSnackbar(t`An error occurred, please try again later`, {
        variant: 'error',
      });
    } else {
      Analytics.modal('dataIssueDialog', {
        source: 'customerCreateDataIssue',
        issueId: issue.id,
      });
      openDataIssueDialog({
        datasourceName,
        issue,
        activeUserId,
        orgId,
        permissions: requiredPermissions,
      });

      onSubmitComplete?.(values);
    }
  };

  return (
    <Formik
      initialValues={{
        title: initialTitle,
        message: initialMessage,
        shouldNotifyWatershed: true,
      }}
      onSubmit={onSubmit}
    >
      {(form) =>
        children({
          form,
          disabled:
            form.isSubmitting ||
            !form.values.title.trim() ||
            !form.values.message.trim(),
          children:
            variant === 'topicQuestion' ? (
              <>
                <TextField
                  id="title"
                  name="title"
                  label={<Trans>Topic</Trans>}
                  dataTest={TestIds.NewDataIssueTitleField}
                />
                <TextFieldMultilineForMentions
                  rows={4}
                  userDataById={userDataById}
                  setMessageText={(value) =>
                    form.setFieldValue('message', value)
                  }
                  permissions={requiredPermissions}
                  permissionCheck="hasAnyPermission" // Allow any of the permissions in requiredPermissions
                  onAddMentionedUserId={(memberId) =>
                    setMentionedUserIds([...mentionedUserIds, memberId])
                  }
                  message={form.values.message}
                  dataTest={TestIds.NewDataIssueMessageField}
                  label={<Trans>Question</Trans>}
                />

                <Typography sx={{ marginTop: '-10px' }} variant="body2">
                  <Trans>Use @ to tag teammates</Trans>
                </Typography>
              </>
            ) : (
              <>
                <SelectField
                  id="title"
                  label={<Trans>What kind of problem are you having?</Trans>}
                  labelVariant="body1"
                  placeholder={t`Choose an option`}
                  required
                >
                  {PROBLEM_TYPES.map((value) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </SelectField>
                <TextFieldMultiline
                  id="message"
                  label={<Trans>Explain your problem:</Trans>}
                  labelVariant="body1"
                  autoFocus
                />
              </>
            ),
        })
      }
    </Formik>
  );
}

function CustomerCreateDataIssueDialog({
  permissions,
  linkedObjectId,
  linkedObjectType,
  datasourceName,
  onClose,
  initialTitle,
  initialMessage,
  fileMetadataIds,
}: {
  permissions: Array<GQCreatePermissionItemInput & { objectName?: string }>;
  linkedObjectId: string;
  linkedObjectType: GQMeasurementTaskForDataIssueKind;
  datasourceName: string;
  initialTitle?: string;
  initialMessage?: string;
  /**
   * Associated user uploads, if any.
   */
  fileMetadataIds?: Array<string>;
  onClose: () => void;
}) {
  const { t } = useLingui();
  return (
    <CustomerCreateDataIssueForm
      requiredPermissions={permissions}
      linkedObjectId={linkedObjectId}
      linkedObjectType={linkedObjectType}
      datasourceName={datasourceName}
      onSubmitComplete={() => onClose()}
      initialTitle={initialTitle}
      initialMessage={initialMessage}
      fileMetadataIds={fileMetadataIds}
    >
      {({ children, disabled }) => (
        <DialogForm
          disableBackdropClick
          submit={t`Submit`}
          submitIcon={null}
          showCancelButton={false}
          header={{
            title: <Trans>Ask a question</Trans>,
            hideClose: false,
            border: false,
          }}
          onClose={onClose}
          disabled={disabled}
          componentSx={{ gap: '0px' }}
          extraButton={
            <Box
              component="label"
              sx={{ display: 'inline-flex', alignItems: 'center' }}
            >
              <CheckboxField inputId="shouldNotifyWatershed" />
              <Trans>Notify Watershed team</Trans>
            </Box>
          }
        >
          {children}
        </DialogForm>
      )}
    </CustomerCreateDataIssueForm>
  );
}

export function CustomerCreateDataIssueButton({
  permissions,
  linkedObjectId,
  linkedObjectType,
  datasourceName,
  fileMetadataIds,
  isMeasurementComplete,
  initialTitle,
  initialMessage,
  label,
  icon,
}: {
  permissions: Array<GQCreatePermissionItemInput & { objectName?: string }>;
  label?: string;
  icon?: React.ReactNode;
  linkedObjectId: string;
  linkedObjectType: GQMeasurementTaskForDataIssueKind;
  datasourceName: string;
  initialTitle?: string;
  initialMessage?: string;
  /**
   * Associated user uploads, if any.
   */
  fileMetadataIds?: Array<string>;
  isMeasurementComplete?: boolean;
}) {
  const { t } = useLingui();

  label ??= t`Ask a question`;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Button
        startIcon={icon}
        onClick={() => {
          setIsDialogOpen(true);
          Analytics.action('customerCreateDataIssueButton', {
            linkedObjectId,
            fileMetadataIds,
          });
        }}
        disabled={isMeasurementComplete}
        tooltip={
          isMeasurementComplete ? getMeasurementCompleteMessage() : undefined
        }
        data-test={TestIds.OpenNewDataIssueDialog}
      >
        {label}
      </Button>
      {isDialogOpen && (
        <CustomerCreateDataIssueDialog
          permissions={permissions}
          linkedObjectId={linkedObjectId}
          linkedObjectType={linkedObjectType}
          datasourceName={datasourceName}
          fileMetadataIds={fileMetadataIds}
          initialMessage={initialMessage}
          initialTitle={initialTitle}
          onClose={() => {
            setIsDialogOpen(false);
            Analytics.action('customerCreateDataIssueDialogClose', {
              linkedObjectId,
              fileMetadataIds,
            });
          }}
        />
      )}
    </>
  );
}

export function CustomerCreateDataIssueIconButton({
  permissions,
  linkedObjectId,
  linkedObjectType,
  datasourceName,
  initialTitle,
  initialMessage,
  fileMetadataIds,
}: {
  permissions: Array<GQCreatePermissionItemInput & { objectName?: string }>;
  linkedObjectId: string;
  linkedObjectType: GQMeasurementTaskForDataIssueKind;
  datasourceName: string;
  initialTitle?: string;
  initialMessage?: string;
  /**
   * Associated user uploads, if any.
   */
  fileMetadataIds?: Array<string>;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <IconButton
        color="secondary"
        size="large"
        onClick={() => {
          setIsDialogOpen(true);
          Analytics.action('customerCreateDataIssueButton', {
            linkedObjectId,
            fileMetadataIds,
          });
        }}
        // TODO: i18n (please resolve or remove this TODO line if legit)
        // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
        tooltip="Ask for help"
      >
        <QuestionMarkIcon />
      </IconButton>
      {isDialogOpen && (
        <CustomerCreateDataIssueDialog
          permissions={permissions}
          linkedObjectId={linkedObjectId}
          linkedObjectType={linkedObjectType}
          datasourceName={datasourceName}
          initialTitle={initialTitle}
          initialMessage={initialMessage}
          fileMetadataIds={fileMetadataIds}
          onClose={() => {
            setIsDialogOpen(false);
            Analytics.action('customerCreateDataIssueDialogClose', {
              linkedObjectId,
              fileMetadataIds,
            });
          }}
        />
      )}
    </>
  );
}
