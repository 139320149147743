import { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';
import { Box, BoxProps } from '@mui/material';
import { ButtonProps } from '@watershed/ui-core/components/Button';
import { WatershedLinkProps } from '../types/LinkProps';
import isExternalUrl, { isApiRouteUrl } from '../utils/isExternalUrl';
import { format as formatUrl } from 'url';
import { mixinSx } from '@watershed/style/styleUtils';

export type BoxLinkProps = Omit<BoxProps, 'to'> &
  Omit<WatershedLinkProps, 'onClick'> &
  Pick<ButtonProps, 'type'>;

export default forwardRef(function BoxLink(
  {
    href,
    children,
    replace,
    as,
    scroll,
    shallow,
    prefetch,
    locale,
    newTab,
    type,
    ...boxProps
  }: BoxLinkProps,
  ref
): JSX.Element {
  const hrefString = href ? formatUrl(href) : '';
  const isExternal = href !== undefined && isExternalUrl(hrefString);
  const isApiRoute = href !== undefined && isApiRouteUrl(hrefString);

  if (href && !newTab && !isExternal && !isApiRoute) {
    return (
      <NextLink
        replace={replace}
        href={href}
        passHref
        as={as}
        scroll={scroll}
        shallow={shallow}
        prefetch={prefetch}
        locale={locale}
        legacyBehavior
      >
        <Box
          {...boxProps}
          component={href ? 'a' : 'button'}
          ref={ref}
          sx={mixinSx(boxProps.sx, {
            outlineColor: (theme) => theme.palette.primary.main,
          })}
        >
          {children}
        </Box>
      </NextLink>
    );
  }

  return (
    // @ts-expect-error this case because we render as an anchor
    <Box
      {...boxProps}
      {...(newTab ? { target: '_blank' } : {})}
      {...(isExternal ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      component={href ? 'a' : 'button'}
      href={href ? hrefString : undefined}
      ref={ref}
      type={type}
      sx={mixinSx(boxProps.sx, {
        outlineColor: (theme) => theme.palette.primary.main,
      })}
    >
      {children}
    </Box>
  );
});
