import {
  TraceASTExpr,
  TraceASTReference,
} from '@watershed/shared-universal/lscript/trace';
import { yieldReferenceSubtraces } from '@watershed/shared-universal/lscript/utils/traceTraversal';
import { Immutable } from 'immer';

// Number-format options used for tiny numbers (e.g. smaller than 0.1).
const NUMBER_FORMAT_ROUNDED_SMALL: Intl.NumberFormatOptions = {
  maximumSignificantDigits: 3,
};

// Number-format options used for most numbers (e.g. above 0.1)
const NUMBER_FORMAT_ROUNDED: Intl.NumberFormatOptions = {
  maximumFractionDigits: 2,
};

const NUMBER_FORMAT_FULL: Intl.NumberFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 15,
};

/**
 * For a given number (e.g. 1234.123456789) this will return (a) the number
 * with commas, rounded to the given precision (e.g. 1,234.1 for precision of
 * 5), and (b) the full number with commas (e.g. 1,234.123456789) for display.
 */
// TODO (LOC-32) Localize numbers
export const formatNumberForDisplay = (value: number) => {
  const full = value.toLocaleString(undefined, NUMBER_FORMAT_FULL);
  const roundedOptions =
    value > 0.1 || value < -0.1
      ? NUMBER_FORMAT_ROUNDED
      : NUMBER_FORMAT_ROUNDED_SMALL;
  const rounded = value.toLocaleString(undefined, roundedOptions);
  return {
    full,
    rounded,
  };
};

// There are some older traces where the emissions factor is not
// just one reference value, but an equation including inflation
// factors as well as an emissions factor, so let's filter those
// out so we can grab just what's hopefully the emissions factor

export function getFirstReferenceWithoutInflationFactors(
  trace: Immutable<TraceASTExpr>
): TraceASTReference | null {
  for (const r of yieldReferenceSubtraces(trace)) {
    if (r.s !== 'mrio_inflation_factor') {
      return r;
    }
  }
  return null;
}
