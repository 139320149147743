import isArray from 'lodash/isArray';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';

import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { Scopes } from '../constants';
import {
  LOGIN_CUSTOMER_RELATION_OPTIONS,
  routeForCustomerHub,
  routeForFootprintSupplierDetails,
  routeForFootprintSupplierDetailsByVendorName,
  routeForFootprintSuppliersVarious,
  SUPPORT_EMAIL_ADDRESS,
  SUPPORT_EMAIL_LINK,
} from '../dashboardRoutes';
import { UnexpectedError } from '../errors/UnexpectedError';
import { WatershedErrorOptions } from '../errors/WatershedError';
import {
  GQCompanyClimateCommitmentFieldsForCcisFragment,
  GQCompanyClimateCommitmentKind,
  GQCompanyClimateProgress,
  GQCompanyEngagementTaskFieldsForOverviewFragment,
  GQCompanySbtCommitmentStage,
  GQDisclosureInitiativeType,
  GQEsgdcGhgMethodologyType,
  GQSasbIndustryType,
  GQSasbSectorType,
  GQSupplierContactFieldsFragment,
  GQSupplierDisclosureInitiativeFieldsFragment,
  GQSupplierFieldsFragment,
  GQSupplierHistoricalEmissions,
  GQSupplierPriority,
  GQSupplierTableColumnFieldsFragment,
  GQSupplierTableFieldsFragment,
  GQSupplierType,
  GQTimeseriesFrequency,
} from '../generated/graphql';
import { getMostSpecificNaicsIndustry } from '../industryCodes/industryCodeUtils';
import {
  SupplierDefaultField,
  isDefaultField,
} from '../suppliers/SupplierColumnRegistry';
import { Supplier } from '../suppliers/supplierTypes';
import DateTimeUtils from './DateTimeUtils';
import { YM, YMInterval } from './YearMonth';
import assertNever from './assertNever';
import isNotNullish from './isNotNullish';
import { formatNumber } from './helpers';
import isNullish from './isNullish';
import { getCompanyCommitmentByKind } from './companyUtils';
import { isCleanEnergyCommitment } from './climateCommitmentUtils';
import { atom } from 'jotai';
import { BiQueryFilter } from '../bi/types';
import { getValueForField } from '../suppliers/SupplierColumnGetters';
import { inferTimeseriesInfo } from './PlanTargetUtils';
import { FiscalYearlyPercentageTimeseries } from './SimpleTimeseries';
import { getFootprintScopeFromFilterGroupExpression } from '../companySurveys/utils';
import { FootprintScope } from '../forecast/types';

export const EMPTY_VALUE_DASH = '-';
export const DEFAULT_PORTAL_TITLE = "'s climate program";
export const SUPPLY_CHAIN_SANDBOX_COMPANY_ID = 'comp_27p573BfhRvJXeq721DB';

export const MISSING_CSAT_ERROR_MESSAGE = 'Missing CSAT';

// atoms we use for global state management
export const tableFiltersAtom = atom<Array<BiQueryFilter>>([]);

export type SupplierDetailsTab =
  | 'Overview'
  | 'Disclosures'
  | 'Supplier specific EFs';
/** This error should be used when we expect a supplier to be found but it is
 * not for some reason (e.g. bad vendor matching). This is better than returning
 * a "not found" error, which our code thinks is an expected error and won't
 * alert on */
export class MissingSupplierError extends UnexpectedError {
  constructor(message?: string, options?: WatershedErrorOptions) {
    super(message, options);
  }
}

export const SUPPLIER_PRIORITY_ORDERS = [
  GQSupplierPriority.Low,
  GQSupplierPriority.Medium,
  GQSupplierPriority.High,
] as const;

export const SUPPLIER_PRIORITY_COLORS = [
  'default',
  'warning',
  'error',
] as const;

export const SUPPLIERS_PIE_CHART_CLIMATE_PROGRESS_TOOLTIPS = {
  [GQCompanyClimateProgress.None]:
    'Emissions from suppliers who have not disclosed emissions or committed to reduction targets.',
  [GQCompanyClimateProgress.DisclosedEmissions]:
    'Emissions from suppliers who have disclosed emissions, but not committed to reduction targets.',
  [GQCompanyClimateProgress.DisclosedEmissionsAndSetCommitments]:
    'Emissions from suppliers who have disclosed emissions and committed to reduction targets.',
  [GQCompanyClimateProgress.DisclosedInitiatives]:
    'Emissions from suppliers who have disclosed initiatives to reduce emissions.',
  [GQCompanyClimateProgress.DisclosedTargets]:
    'Emissions from suppliers who have disclosed reduction targets, but not disclosed emissions.',
  [GQCompanyClimateProgress.DisclosedTargetsInitiatives]:
    'Emissions from suppliers who have disclosed reduction targets and initiatives to reduce emissions.',
  [GQCompanyClimateProgress.DisclosedEmissionsInitiatives]:
    'Emissions from suppliers who have disclosed emissions and initiatives to reduce emissions.',
  [GQCompanyClimateProgress.DisclosedEmissionsTargetsInitiatives]:
    'Emissions from suppliers who have disclosed emissions, reduction targets, and initiatives to reduce emissions.',
};

export const SUPPLIERS_TABLE_TOOLTIPS = {
  [GQCompanyClimateProgress.None]:
    'Watershed was not able to find disclosed emissions, reduction targets, or specific initiatives from this company.',
  [GQCompanyClimateProgress.DisclosedEmissions]:
    'This supplier has disclosed emissions, but not committed to reduction targets.',
  [GQCompanyClimateProgress.DisclosedEmissionsAndSetCommitments]:
    'This supplier has disclosed emissions and committed to reduction targets.',
  [GQCompanyClimateProgress.Unknown]:
    'Watershed has not been able to find information on this company.',
  [GQCompanyClimateProgress.DisclosedEmissionsTargetsInitiatives]:
    'This supplier has disclosed emissions, committed to reduction targets & set initiatives to reduce emissions.',
  [GQCompanyClimateProgress.DisclosedTargets]:
    'This supplier has committed to reduction targets',
  [GQCompanyClimateProgress.DisclosedTargetsInitiatives]:
    'This supplier has disclosed reduction targets & set initiatives to reduce emissions.',
  [GQCompanyClimateProgress.DisclosedInitiatives]:
    'This supplier has set initiatives to reduce emissions.',
  [GQCompanyClimateProgress.DisclosedEmissionsInitiatives]:
    'This supplier has disclosed emissions & set initiatives to reduce emissions.',
};

export function createSupplierId(
  key: { companyId: string } | { companyOrVendorName: string }
): string {
  if ('companyId' in key) {
    return `supplierRow_company_${key.companyId}`;
  }
  if ('companyOrVendorName' in key) {
    return `supplierRow_name_${key.companyOrVendorName}`;
  }
  assertNever(key);
}

export function supplierPriorityComparator(
  aPriorityOrNull: GQSupplierPriority | null,
  bPriorityOrNull: GQSupplierPriority | null
): number {
  const aPriority = aPriorityOrNull
    ? SUPPLIER_PRIORITY_ORDERS.indexOf(aPriorityOrNull)
    : -1;
  const bPriority = bPriorityOrNull
    ? SUPPLIER_PRIORITY_ORDERS.indexOf(bPriorityOrNull)
    : -1;
  return aPriority - bPriority;
}

export function getNaicsSector(supplier: PrioritizedGQSupplier): string | null {
  const naicsCode = supplier.company?.naicsCode;

  return getMostSpecificNaicsIndustry(naicsCode ?? null);
}

export const TOP_PERCENTILE_THRESHOLD = 0.1;
export const MID_PERCENTILE_THRESHOLD = 0.3;
export const BOTTOM_PERCENTILE_THRESHOLD = 0.9;
export const SCOPE_1_PLUS_2_RATIO_THRESHOLD = 0.15;
export const TARGET_HIGH_PRIORITY_COUNT = 3;
export const TARGET_MEDIUM_PRIORITY_COUNT = 10;
export const MIN_EMISSIONS_PERCENTAGE_FOR_HIGH_PRIORITY = 0.005;
export const MIN_EMISSIONS_PERCENTAGE_FOR_MEDIUM_PRIORITY = 0.0025;

export enum SupplierPriorityReason {
  TopPercentileEmitter = 'Top percentile emitter',
  MidPercentileEmitter = 'Mid percentile emitter',
  BottomPercentileEmitter = 'Bottom percentile emitter',
  TrivialEmitter = 'Trivial emitter',
  Scope1Plus2Ratio = 'Scope 1+2 ratio',
  HasCommitments = 'Has commitments',
  // Only applies to finance assets
  RealEstateAsset = 'Real estate asset',
  UnknownCompany = 'UnknownCompany',
}

export type PrioritizationFields = {
  priority: GQSupplierPriority | null;
  defaultPriority: GQSupplierPriority | null;
  defaultPriorityReasons: Array<SupplierPriorityReason>;
  emissionsRank: number | null;
};

export type PrioritizedGQSupplier = PrioritizationFields &
  GQSupplierFieldsFragment;

export function scoreToPriority(n: number): GQSupplierPriority {
  if (n <= 0) {
    return GQSupplierPriority.Low;
  } else if (n <= 1) {
    return GQSupplierPriority.Medium;
  }
  return GQSupplierPriority.High;
}

type SupplierFieldsNeededForPrioritization = {
  supplierType: GQSupplierType;
  totalKgco2e: number;
  customData?: {
    priority: null | GQSupplierPriority;
  } | null;
  customPriority?: null | GQSupplierPriority;
  historicalEmissions: null | Pick<
    GQSupplierHistoricalEmissions,
    'scope1' | 'scope2'
  >;
  climateProgress: GQCompanyClimateProgress;
  sbtiStage: GQCompanySbtCommitmentStage;
};

function prioritizeSupplier<T extends SupplierFieldsNeededForPrioritization>(
  supplier: T,
  totalOrgKgco2e: number,
  // If the suppliers are ordered by kgco2e desc, how much kgco2e is above this supplier?
  cumulativeKgco2eSoFar: number,
  // How many suppliers have already been assigned to each priority bucket?
  numSuppliersByPriority: Record<GQSupplierPriority, number>,
  emissionsRank: number | null
): T & PrioritizationFields {
  let priorityScore = 0;
  const reasons = [];

  // Don't assign a priority to "Various" since this represents multiple suppliers
  if (supplier.supplierType === GQSupplierType.SmallSuppliersAggregated) {
    return {
      ...supplier,
      priority: null,
      defaultPriority: null,
      defaultPriorityReasons: [],
      emissionsRank: null,
    };
  }

  // If the supplier has negative or no emissions, they are not a priority
  if (supplier.totalKgco2e <= 0) {
    return {
      ...supplier,
      priority: supplier.customData?.priority ?? GQSupplierPriority.Low,
      defaultPriority: GQSupplierPriority.Low,
      defaultPriorityReasons: [SupplierPriorityReason.TrivialEmitter],
      emissionsRank,
    };
  }

  // If we are unable to find information on this supplier, then mark as low priority
  if (supplier.climateProgress === GQCompanyClimateProgress.Unknown) {
    return {
      ...supplier,
      priority: supplier.customData?.priority ?? GQSupplierPriority.Low,
      defaultPriority: GQSupplierPriority.Low,
      defaultPriorityReasons: [SupplierPriorityReason.UnknownCompany],
      emissionsRank,
    };
  }

  // High or medium priority by default if either of the following are true:
  // 1. They are in the top X% of suppliers by cumulative emissions
  // 2. They are in the top Y suppliers by emissions and account for more than
  //    Z% of overall emissions
  if (
    cumulativeKgco2eSoFar <= TOP_PERCENTILE_THRESHOLD * totalOrgKgco2e ||
    (numSuppliersByPriority[GQSupplierPriority.High] <
      TARGET_HIGH_PRIORITY_COUNT &&
      supplier.totalKgco2e >=
        MIN_EMISSIONS_PERCENTAGE_FOR_HIGH_PRIORITY * totalOrgKgco2e)
  ) {
    priorityScore = 2;
    reasons.push(SupplierPriorityReason.TopPercentileEmitter);
  } else if (
    cumulativeKgco2eSoFar <= MID_PERCENTILE_THRESHOLD * totalOrgKgco2e ||
    (numSuppliersByPriority[GQSupplierPriority.Medium] <
      TARGET_MEDIUM_PRIORITY_COUNT &&
      supplier.totalKgco2e >=
        MIN_EMISSIONS_PERCENTAGE_FOR_MEDIUM_PRIORITY * totalOrgKgco2e)
  ) {
    priorityScore = 1;
    reasons.push(SupplierPriorityReason.MidPercentileEmitter);
  } else if (
    cumulativeKgco2eSoFar <
    BOTTOM_PERCENTILE_THRESHOLD * totalOrgKgco2e
  ) {
    priorityScore = 0;
    reasons.push(SupplierPriorityReason.BottomPercentileEmitter);
  } else {
    priorityScore = -1;
    reasons.push(SupplierPriorityReason.TrivialEmitter);
  }

  // If the supplier has an SBT or net zero target, decrease priority by 1.
  if (
    supplier.climateProgress ===
      GQCompanyClimateProgress.DisclosedEmissionsAndSetCommitments ||
    supplier.sbtiStage === GQCompanySbtCommitmentStage.TargetsSet ||
    supplier.sbtiStage === GQCompanySbtCommitmentStage.Committed
  ) {
    priorityScore -= 1;
    reasons.push(SupplierPriorityReason.HasCommitments);
  }

  // If no commitments and S1+S2 ≥ 15%, then increase priority by 1
  else {
    const byScope1 = supplier.historicalEmissions?.scope1;
    const byScope2 = supplier.historicalEmissions?.scope2;
    if (
      (byScope1 ?? 0) + (byScope2 ?? 0) >
      supplier.totalKgco2e * SCOPE_1_PLUS_2_RATIO_THRESHOLD
    ) {
      priorityScore += 1;
      reasons.push(SupplierPriorityReason.Scope1Plus2Ratio);
    }
  }
  const defaultPriority = scoreToPriority(priorityScore);
  return {
    priority:
      supplier.customData?.priority ??
      supplier.customPriority ??
      defaultPriority,
    defaultPriority,
    defaultPriorityReasons: reasons,
    emissionsRank,
    ...supplier,
  };
}

export function prioritizeSuppliers<
  T extends SupplierFieldsNeededForPrioritization,
>(suppliers: Array<T>): Array<T & PrioritizationFields> {
  const result: Array<T & PrioritizationFields> = [];
  const numSuppliersByPriority: Record<GQSupplierPriority, number> = {
    Low: 0,
    Medium: 0,
    High: 0,
  };
  let cumulativeKgco2eSoFar: number = 0;
  const sortedSuppliers = orderBy(suppliers, (s) => s.totalKgco2e, 'desc');

  const totalKgco2e = sum(
    sortedSuppliers.map((s) =>
      shouldIncludeInPercentileCalculations(s) ? s.totalKgco2e : 0
    )
  );

  // We keep track of when we process "Various" suppliers to assign the correct
  // emissionsRank to individual suppliers since sortedSuppliers contains "Various" suppliers
  let processedVarious = false;
  for (const [index, supplier] of sortedSuppliers.entries()) {
    const prioritizedSupplier = prioritizeSupplier(
      supplier,
      totalKgco2e,
      cumulativeKgco2eSoFar,
      numSuppliersByPriority,
      processedVarious ? index : index + 1 // "various" suppliers do not get an emissions rank
    );
    result.push(prioritizedSupplier);
    if (supplier.supplierType === GQSupplierType.SmallSuppliersAggregated) {
      processedVarious = true;
    }

    if (shouldIncludeInPercentileCalculations(supplier)) {
      cumulativeKgco2eSoFar += supplier.totalKgco2e;
      if (prioritizedSupplier.defaultPriority) {
        numSuppliersByPriority[prioritizedSupplier.defaultPriority] += 1;
      }
    }
  }
  return result;
}

// For percentile calculations, exclude Unknown and Various vendors
// (Unknown is already not included in suppliers)
function shouldIncludeInPercentileCalculations(
  supplier: SupplierFieldsNeededForPrioritization
) {
  return supplier.supplierType === GQSupplierType.KnownSupplier;
}

/** If a new default column is added, stale tabs will detect it to not be within the
 * default field list and not have a column format.
 */
function isStaleTabNewDefaultColumn(
  column: GQSupplierTableColumnFieldsFragment
): boolean {
  return !isDefaultField(column.field) && isNullish(column.columnFormat);
}

export interface SupplierTableDefaultColumn
  extends GQSupplierTableColumnFieldsFragment {
  field: SupplierDefaultField;
}
export function isDefaultColumn(
  column: GQSupplierTableColumnFieldsFragment
): column is SupplierTableDefaultColumn {
  return isDefaultField(column.field);
}

// A custom column is a column that is not any type of default column.
export function isCustomColumn(
  column: GQSupplierTableColumnFieldsFragment
): boolean {
  return !isDefaultColumn(column) && !isStaleTabNewDefaultColumn(column);
}

export function isSupplierMaturityColumn(
  column: GQSupplierTableColumnFieldsFragment
): boolean {
  return column.isSupplierScore || column.isSupplierScoreCriteria;
}

export function getCustomColumnsWithoutMaturityColumns(
  supplierTable: GQSupplierTableFieldsFragment
): Array<GQSupplierTableColumnFieldsFragment> {
  return supplierTable.columns.filter(
    (col) => isCustomColumn(col) && !isSupplierMaturityColumn(col)
  );
}

export const SCOPE_ABBREVIATIONS: Record<string, Scopes> = {
  scope1: Scopes.s1,
  scope2: Scopes.s2Market,
  scope3: Scopes.s3,
};

export const SCOPE_LABELS = {
  scope1: 'Scope 1',
  scope2: 'Scope 2',
  scope3: 'Scope 3',
} as const;

export const SUPPLIER_SCOPE_DESCRIPTIONS = {
  scope1:
    'Direct release of greenhouse gases from sources your supplier owns or controls',
  scope2:
    'Emissions from the generation of electricity, heat, or cooling your supplier purchased',
  scope3:
    'Upstream emissions of products your supplier purchased, and downstream emissions from its customers',
};

export function getContactsLinkText(
  contacts: Array<Pick<GQSupplierContactFieldsFragment, 'name' | 'email'>>
): string {
  if (contacts.length === 0) {
    return 'No contacts yet';
  } else if (contacts.length === 1) {
    return contacts[0].name || contacts[0].email;
  } else {
    return `${contacts[0].name || contacts[0].email}, +${contacts.length - 1}`;
  }
}

export function kickoffEmailTemplate(
  supplierCompanyName: string,
  supplierContactName: string,
  rootCompanyName: string,
  portalLink: string,
  dueDate?: Date
): { subject: string; generateBody: (encodeHtml?: boolean) => string } {
  const subject = `${supplierCompanyName} Supplier Survey — Carbon Footprint and Climate Commitments`;

  const link = (
    href: string,
    opts?: { encodeHtml?: boolean; displayText?: string }
  ): string => {
    return opts?.encodeHtml
      ? `<a href="${href}">${opts.displayText ?? href}</a>`
      : opts?.displayText ?? href;
  };

  const generateBody = (encodeHtml?: boolean): string => {
    const lineBreak = encodeHtml ? '<br>' : '\n';
    const strongText = (text: string): string =>
      encodeHtml ? `<strong>${text}</strong>` : text;

    const dueDateSentence = dueDate
      ? // TODO: i18n (please resolve or remove this TODO line if legit)
        // eslint-disable-next-line @watershed/require-locale-argument
        `Please submit your response by ${DateTimeUtils.formatDate(
          DateTimeUtils.fromJSDate(dueDate)
        )}.\
        ${lineBreak}${lineBreak}`
      : '';

    return `Dear ${supplierContactName.split(' ')[0]}, \
${lineBreak}${lineBreak}\
${rootCompanyName} is working to measure and reduce our supply \
chain carbon footprint. As one of our strategic vendors, we are \
asking you to participate in our sustainable supply chain program. \
${lineBreak}${lineBreak}\
We've partnered with Watershed to provide a guided portal for you \
to securely share your company's climate-related information with \
us. This portal will walk you through some questions about \
existing emissions (if you've measured them already) and any \
actions you're planning to take to reduce those emissions. The portal \
also offers a measurement tool for companies in certain industries \
looking to measure a GHG footprint for the first time. \
${lineBreak}${lineBreak}\
Click the following link to access the portal and get started:
${link(portalLink, { encodeHtml })} \
${lineBreak}${lineBreak}\
${strongText(dueDateSentence)}\
You can also forward this email to share access to the portal with \
anyone else at ${supplierCompanyName}. \
${lineBreak}${lineBreak}\
For any questions about this request, you can reply to this email or \
contact Watershed directly at ${link(SUPPORT_EMAIL_LINK, {
      encodeHtml,
      displayText: SUPPORT_EMAIL_ADDRESS,
    })}. \
${lineBreak}${lineBreak}\
Thank you!
`;
  };

  return { subject, generateBody };
}

export function getInitiativeTypeLabel(
  initiativeType: GQDisclosureInitiativeType
): string {
  switch (initiativeType) {
    case GQDisclosureInitiativeType.RenewableEnergy:
      return 'Adopt renewable energy';
    case GQDisclosureInitiativeType.EnergyEfficiency:
      return 'Energy efficiency';
    case GQDisclosureInitiativeType.Circularity:
      return 'Circularity';
    case GQDisclosureInitiativeType.EngageSuppliers:
      return 'Engage suppliers';
    case GQDisclosureInitiativeType.HeavyTransportEfficiency:
      return 'Heavy transport efficiency';
    case GQDisclosureInitiativeType.BusinessTravelEfficiency:
      return 'Business travel efficiency';
    case GQDisclosureInitiativeType.MeasureFootprint:
      return 'Measure footprint';
    case GQDisclosureInitiativeType.CarbonRemoval:
      return 'Carbon removal';
    case GQDisclosureInitiativeType.ConductInventory:
      return 'Conduct comprehensive GHG inventory';
    case GQDisclosureInitiativeType.RegenerativeAgriculture:
      return 'Regenerative agriculture';
    case GQDisclosureInitiativeType.DeforestationPrevention:
      return 'Deforestation prevention';
    case GQDisclosureInitiativeType.AlternativeFuels:
      return 'Alternative fuels/Electrification';
    case GQDisclosureInitiativeType.ManureManagement:
      return 'Manure management';
    case GQDisclosureInitiativeType.Other:
      return 'Other';
    default:
      assertNever(initiativeType);
  }
}

export function getSasbSectorTypeLabel(
  sasbSectorType: GQSasbSectorType
): string {
  switch (sasbSectorType) {
    case GQSasbSectorType.ConsumerGoods:
      return 'Consumer Goods';
    case GQSasbSectorType.ExtractivesAndMineralsProcessing:
      return 'Extractives and Minerals Processing';
    case GQSasbSectorType.Financials:
      return 'Financials';
    case GQSasbSectorType.FoodAndBeverage:
      return 'Food and Beverage';
    case GQSasbSectorType.HealthCare:
      return 'Health Care';
    case GQSasbSectorType.Infrastructure:
      return 'Infrastructure';
    case GQSasbSectorType.RenewableResourcesAndAlternativeEnergy:
      return 'Renewable Resources and Alternative Energy';
    case GQSasbSectorType.ResourceTransformation:
      return 'Resource Transformation';
    case GQSasbSectorType.Services:
      return 'Services';
    case GQSasbSectorType.TechnologyAndCommunications:
      return 'Technology and Communications';
    case GQSasbSectorType.Transportation:
      return 'Transportation';
    default:
      assertNever(sasbSectorType);
  }
}

export function getSasbIndustryTypeLabel(
  sasbIndustryType: GQSasbIndustryType
): string {
  switch (sasbIndustryType) {
    case GQSasbIndustryType.ApparelAccessoriesAndFootwear:
      return 'Apparel, Accessories and Footwear';
    case GQSasbIndustryType.ApplianceManufacturing:
      return 'Appliance Manufacturing';
    case GQSasbIndustryType.BuildingProductsAndFurnishings:
      return 'Building Products and Furnishings';
    case GQSasbIndustryType.ECommerce:
      return 'E-Commerce';
    case GQSasbIndustryType.HouseholdAndPersonalProducts:
      return 'Household and Personal Products';
    case GQSasbIndustryType.MultilineAndSpecialtyRetailersAndDistributors:
      return 'Multiline and Specialty Retailers and Distributors';
    case GQSasbIndustryType.ToysAndSportingGoods:
      return 'Toys and Sporting Goods';
    case GQSasbIndustryType.CoalOperations:
      return 'Coal Operations';
    case GQSasbIndustryType.ConstructionMaterials:
      return 'Construction Materials';
    case GQSasbIndustryType.IronAndSteelProducers:
      return 'Iron and Steel Producers';
    case GQSasbIndustryType.MetalsAndMining:
      return 'Metals and Mining';
    case GQSasbIndustryType.OilAndGasExplorationAndProduction:
      return 'Oil and Gas - Exploration and Production';
    case GQSasbIndustryType.OilAndGasMidstream:
      return 'Oil and Gas - Midstream';
    case GQSasbIndustryType.OilAndGasRefiningAndMarketing:
      return 'Oil and Gas - Refining and Marketing';
    case GQSasbIndustryType.OilAndGasServices:
      return 'Oil and Gas - Services';
    case GQSasbIndustryType.AssetManagementAndCustodyActivities:
      return 'Asset Management and Custody Activities';
    case GQSasbIndustryType.CommercialBanks:
      return 'Commercial Banks';
    case GQSasbIndustryType.ConsumerFinance:
      return 'Consumer Finance';
    case GQSasbIndustryType.Insurance:
      return 'Insurance';
    case GQSasbIndustryType.InvestmentBankingAndBrokerage:
      return 'Investment Banking and Brokerage';
    case GQSasbIndustryType.MortgageFinance:
      return 'Mortgage Finance';
    case GQSasbIndustryType.SecurityAndCommodityExchanges:
      return 'Security and Commodity Exchanges';
    case GQSasbIndustryType.AgriculturalProducts:
      return 'Agricultural Products';
    case GQSasbIndustryType.AlcoholicBeverages:
      return 'Alcoholic Beverages';
    case GQSasbIndustryType.FoodRetailersAndDistributors:
      return 'Food Retailers and Distributors';
    case GQSasbIndustryType.MeatPoultryAndDairy:
      return 'Meat, Poultry and Dairy';
    case GQSasbIndustryType.NonAlcoholicBeverages:
      return 'Non-Alcoholic Beverages';
    case GQSasbIndustryType.ProcessedFoods:
      return 'Processed Foods';
    case GQSasbIndustryType.Restaurants:
      return 'Restaurants';
    case GQSasbIndustryType.Tobacco:
      return 'Tobacco';
    case GQSasbIndustryType.BiotechnologyAndPharmaceuticals:
      return 'Biotechnology and Pharmaceuticals';
    case GQSasbIndustryType.DrugRetailers:
      return 'Drug Retailers';
    case GQSasbIndustryType.HealthCareDelivery:
      return 'Health Care Delivery';
    case GQSasbIndustryType.HealthCareDistributors:
      return 'Health Care Distributors';
    case GQSasbIndustryType.ManagedCare:
      return 'Managed Care';
    case GQSasbIndustryType.MedicalEquipmentAndSupplies:
      return 'Medical Equipment and Supplies';
    case GQSasbIndustryType.ElectricalUtilitiesAndPowerGenerators:
      return 'Electrical Utilities and Power Generators';
    case GQSasbIndustryType.EngineeringAndConstructionServices:
      return 'Engineering and Construction Services';
    case GQSasbIndustryType.GasUtilitiesAndDistributors:
      return 'Gas Utilities and Distributors';
    case GQSasbIndustryType.HomeBuilders:
      return 'Home Builders';
    case GQSasbIndustryType.RealEstate:
      return 'Real Estate';
    case GQSasbIndustryType.RealEstateServices:
      return 'Real Estate Services';
    case GQSasbIndustryType.WasteManagement:
      return 'Waste Management';
    case GQSasbIndustryType.WaterUtilitiesAndServices:
      return 'Water Utilities and Services';
    case GQSasbIndustryType.Biofuels:
      return 'Biofuels';
    case GQSasbIndustryType.ForestryManagement:
      return 'Forestry Management';
    case GQSasbIndustryType.FuelCellsAndIndustrialBatteries:
      return 'Fuel Cells and Industrial Batteries';
    case GQSasbIndustryType.PulpAndPaperProducts:
      return 'Pulp and Paper Products';
    case GQSasbIndustryType.SolarTechnologyAndProjectDevelopers:
      return 'Solar Technology and Project Developers';
    case GQSasbIndustryType.WindTechnologyAndProjectDevelopers:
      return 'Wind Technology and Project Developers';
    case GQSasbIndustryType.AerospaceAndDefense:
      return 'Aerospace and Defense';
    case GQSasbIndustryType.Chemicals:
      return 'Chemicals';
    case GQSasbIndustryType.ContainersAndPackaging:
      return 'Containers and Packaging';
    case GQSasbIndustryType.ElectricalAndElectronicEquipment:
      return 'Electrical and Electronic Equipment';
    case GQSasbIndustryType.IndustrialMachineryAndGoods:
      return 'Industrial Machinery and Goods';
    case GQSasbIndustryType.AdvertisingAndMarketing:
      return 'Advertising and Marketing';
    case GQSasbIndustryType.CasinosAndGaming:
      return 'Casinos and Gaming';
    case GQSasbIndustryType.Education:
      return 'Education';
    case GQSasbIndustryType.HotelsAndLodging:
      return 'Hotels and Lodging';
    case GQSasbIndustryType.LeisureFacilities:
      return 'Leisure Facilities';
    case GQSasbIndustryType.MediaAndEntertainment:
      return 'Media and Entertainment';
    case GQSasbIndustryType.ProfessionalAndCommercialServices:
      return 'Professional and Commercial Services';
    case GQSasbIndustryType.ElectronicManufacturingServicesAndOriginalDesignManufacturing:
      return 'Electronic Manufacturing Services and Original Design Manufacturing';
    case GQSasbIndustryType.Hardware:
      return 'Hardware';
    case GQSasbIndustryType.InternetMediaAndServices:
      return 'Internet Media and Services';
    case GQSasbIndustryType.Semiconductors:
      return 'Semiconductors';
    case GQSasbIndustryType.SoftwareAndItServices:
      return 'Software and IT Services';
    case GQSasbIndustryType.TelecommunicationServices:
      return 'Telecommunication Services';
    case GQSasbIndustryType.AirFreightAndLogistics:
      return 'Air Freight and Logistics';
    case GQSasbIndustryType.Airlines:
      return 'Airlines';
    case GQSasbIndustryType.AutoParts:
      return 'Auto Parts';
    case GQSasbIndustryType.Automobiles:
      return 'Automobiles';
    case GQSasbIndustryType.CarRentalAndLeasing:
      return 'Car Rental and Leasing';
    case GQSasbIndustryType.CruiseLines:
      return 'Cruise Lines';
    case GQSasbIndustryType.MarineTransportation:
      return 'Marine Transportation';
    case GQSasbIndustryType.RailTransportation:
      return 'Rail Transportation';
    case GQSasbIndustryType.RoadTransportation:
      return 'Road Transportation';
    default:
      assertNever(sasbIndustryType);
  }
}

export function getGhgMethodologyTypeLabel(
  ghgMethodologyType: GQEsgdcGhgMethodologyType
): string {
  switch (ghgMethodologyType) {
    case GQEsgdcGhgMethodologyType.SpendBased:
      return 'Spend-based';
    case GQEsgdcGhgMethodologyType.ActivityBased:
      return 'Activity-based';
    case GQEsgdcGhgMethodologyType.DirectEmissionsOrSupplierBased:
      return 'Direct Emissions/Supplier-based';
    case GQEsgdcGhgMethodologyType.Other:
      return 'Other';
    default:
      assertNever(ghgMethodologyType);
  }
}

// Column formatter for net zero column
export type CommitmentAndTargetYearCellValue = {
  commitmentStatus: string;
  targetYear: number | null;
} | null;

export function supplierNetZeroStatus(
  supplier: Supplier
): CommitmentAndTargetYearCellValue {
  if (isNotNullish(supplier.netZeroCommitmentTargetYear)) {
    return {
      commitmentStatus: 'Net zero',
      targetYear: supplier.netZeroCommitmentTargetYear,
    };
  }
  return null;
}

export function groupFootprintTags(
  footprintTagKeys: Array<string>,
  footprintTagValues: Array<any>
): Record<string, Array<any>> {
  const result: Record<string, Array<string>> = {};
  footprintTagKeys.forEach((key, i) => {
    if (!result[key]) {
      result[key] = [footprintTagValues[i]];
    } else {
      result[key].push(footprintTagValues[i]);
    }
  });
  return result;
}

export function getRecipientType(
  isFinance: boolean,
  lowerCase = false
): string {
  const recipient = isFinance ? 'Portfolio company' : 'Supplier';
  return lowerCase ? recipient.toLowerCase() : recipient;
}

export function trailing12MonthsInterval(interval: YMInterval): YMInterval {
  return new YMInterval(
    YM.max(interval.start, YM.minus(interval.end, 12)),
    interval.end
  );
}

export function getSurveyPortalHref(
  engagementTask: Pick<
    GQCompanyEngagementTaskFieldsForOverviewFragment,
    'companyId' | 'rootCompanyId'
  >
): string {
  const urlPathForCustomerHub = routeForCustomerHub(
    engagementTask.rootCompanyId,
    {
      previewAs: engagementTask.companyId,
      recipientCompanyId: engagementTask.companyId,
      customerId: engagementTask.rootCompanyId,
      customerRelation: LOGIN_CUSTOMER_RELATION_OPTIONS.SUPPLIER,
    }
  );
  return urlPathForCustomerHub;
}

export function recursivelyReplaceValue<T>(
  obj: T,
  oldVal: string,
  newVal: string
): T {
  if (isString(obj)) {
    if (obj === oldVal) {
      return newVal as T;
    }
  } else if (isArray(obj)) {
    return obj.map((val) => recursivelyReplaceValue(val, oldVal, newVal)) as T;
  } else if (isObject(obj)) {
    return mapValues(obj as object, (val) =>
      recursivelyReplaceValue(val, oldVal, newVal)
    ) as T;
  }
  return obj;
}

function getBucketBottom(x: number, bucketSize: number) {
  return Math.floor(x / bucketSize) * bucketSize;
}

// given a number x, put it in a bucket of size bucketSize
// for instance,
//    getBucketString(33, 50) -> "0 - 50"
//    getBucketString(100, 50) -> "100 - 150"
export function getBucketString(x: number, bucketSize: number): string {
  const bucketBottom = getBucketBottom(x, bucketSize);
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return `${formatNumber(bucketBottom)} - ${formatNumber(
    bucketBottom + bucketSize
  )}`;
}

export const safeDivide = (a: number, b: number): number => {
  const res = a / b;
  if (isNaN(res)) {
    return 0;
  }
  return res;
};

export function getFootprintSupplierDetailsRoute(
  supplier: Supplier,
  supplierViewId: string | null
): string | undefined {
  if (supplier.supplierType === GQSupplierType.UnknownSuppliersAggregated) {
    return undefined;
  }

  if (supplier.supplierType === GQSupplierType.SmallSuppliersAggregated) {
    return routeForFootprintSuppliersVarious({ supplierViewId });
  }

  if (supplier.companyId) {
    return routeForFootprintSupplierDetails(supplier.companyId, {
      supplierViewId,
    });
  }

  return routeForFootprintSupplierDetailsByVendorName(supplier.name, {
    supplierViewId,
  });
}

export function retrieveCleanEnergyInitiativeFromCommitments(
  supplierName: string,
  commitments: Array<GQCompanyClimateCommitmentFieldsForCcisFragment>
): GQSupplierDisclosureInitiativeFieldsFragment | null {
  const cleanEnergyCommitment = getCompanyCommitmentByKind(
    GQCompanyClimateCommitmentKind.CleanEnergy,
    commitments
  );

  if (
    cleanEnergyCommitment &&
    isCleanEnergyCommitment(cleanEnergyCommitment) &&
    cleanEnergyCommitment.targetPercentageCleanEnergy === 100
  ) {
    return {
      id: `renewable-energy-100-percent-${supplierName}`,
      name: 'Renewable energy',
      initiativeType: GQDisclosureInitiativeType.RenewableEnergy,
      description:
        cleanEnergyCommitment.description ??
        `${supplierName} has committed to 100% renewable energy`,
      startYearMonth: cleanEnergyCommitment.commitmentPeriodStart
        ? YM.fromJSDate(cleanEnergyCommitment.commitmentPeriodStart)
        : cleanEnergyCommitment.commitmentMadeDate
          ? YM.fromJSDate(cleanEnergyCommitment.commitmentMadeDate)
          : null,
      endYearMonth: cleanEnergyCommitment.targetYear
        ? YM.make(cleanEnergyCommitment.targetYear, 1)
        : null,
      filters: null,
      emissionsTimeseries: null,
      intensityType: null,
    };
  }

  return null;
}

export interface SupplierForInitiativesSection
  extends Pick<
    Supplier,
    'name' | 'climateCommitmentDisclosures' | 'initiativeDisclosures'
  > {}

export interface InitiativeWithDetails
  extends GQSupplierDisclosureInitiativeFieldsFragment {
  baselineYear: number | null;
  targetYear: number | null;
  reductionPercentage: number | null;
  scopes: Array<FootprintScope>;
}

export function getAllInitiativesFromSupplier(
  supplier: SupplierForInitiativesSection
): Array<GQSupplierDisclosureInitiativeFieldsFragment> {
  const initiatives = supplier.initiativeDisclosures.flatMap(
    (disclosure) => disclosure.initiatives ?? []
  );
  const commitments = supplier.climateCommitmentDisclosures.flatMap(
    (disclosure) => disclosure.climateCommitments ?? []
  );
  const cleanEnergyInitiative = retrieveCleanEnergyInitiativeFromCommitments(
    supplier.name,
    commitments
  );
  if (cleanEnergyInitiative) {
    initiatives.push(cleanEnergyInitiative);
  }

  return initiatives.map((i) => {
    const scopes = i.filters
      ? getFootprintScopeFromFilterGroupExpression(i.filters)
      : [];

    const tmInfo =
      i.emissionsTimeseries &&
      i.emissionsTimeseries.frequency === GQTimeseriesFrequency.Yearly
        ? inferTimeseriesInfo(
            FiscalYearlyPercentageTimeseries.fromGQ(i.emissionsTimeseries)
          )
        : null;

    return {
      ...i,
      baselineYear: i.startYearMonth ? YM.year(i.startYearMonth) : null,
      targetYear: i.endYearMonth ? YM.year(i.endYearMonth) : null,
      reductionPercentage: tmInfo?.percentChange
        ? tmInfo?.percentChange / 100
        : null,
      scopes,
    };
  });
}

export function getInitiativeNames(
  initiatives: Array<GQSupplierDisclosureInitiativeFieldsFragment>
): Array<string> {
  return initiatives.map(
    (initiative) =>
      initiative.name ?? getInitiativeTypeLabel(initiative.initiativeType)
  );
}

export function getEmissionsYearNames(supplier: Supplier): Array<string> {
  return getValueForField('footprints')(supplier).map(({ name }) => name);
}
