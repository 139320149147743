import {
  Box,
  Stack,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { Trans, useLingui } from '@lingui/react/macro';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import { Form, Formik } from 'formik';
import { ReactNode, useEffect, useState } from 'react';
import { gql } from 'graphql-tag';
import {
  GQCreatePermissionItemInput,
  GQDataIssueState,
  GQDataIssueWithCommentsFieldsFragment,
} from '@watershed/shared-universal/generated/graphql';
import {
  useAddPermissionMutation,
  useGetAllAssignableUsersWithPermissionQuery,
  useResolveDataIssueMutation,
  useRespondToDataIssueMutation,
} from '../generated/urql';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import { Dialog, DialogSection } from '@watershed/ui-core/components/Dialog';
import { TextFieldMultiline } from '@watershed/ui-core/components/Form/TextField';
import ProgressButton from '@watershed/ui-core/components/ProgressButton';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import CheckmarkIcon from '@watershed/icons/components/Checkmark';
import Button from '@watershed/ui-core/components/Button';
import { DataIssueChatHeader } from './DataIssueChatHeader';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import {
  DiscussionCommentProps,
  DiscussionThread,
} from '@watershed/ui-core/components/DiscussionThread';
import isFetchingOrStale from '../utils/isFetchingOrStale';
import { getGqlResultDataBang } from '../utils/errorUtils';
import keyBy from 'lodash/keyBy';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import ConfirmPermissionDialog from './ConfirmPermissionDialog';
import invariant from 'invariant';
import enqueueMutationSnackbar from '../utils/enqueueMutationSnackbar';
import { formatCommentForMentions } from '../utils/dataIssueUtils';
import isAdminApp from '@watershed/ui-core/utils/isAdminApp';
import { CheckboxField } from '@watershed/ui-core/components/Form/CheckboxField';
import { formatObjectName } from '@watershed/shared-universal/utils/AddUserPermissionsUtils';
import { VALUE_MAPPING_OBJECT_NAME } from '@watershed/shared-universal/utils/permissionUtils';

import useLocale from '@watershed/intl/frontend/useLocale';
import { UnexpectedError } from '@watershed/shared-universal/errors/UnexpectedError';

gql`
  mutation RespondToDataIssue($input: RespondToDataIssueInput!) {
    respondToDataIssue(input: $input) {
      measurementTask {
        ...MeasurementParentTaskForMeasurementPage
      }
      userUploadTask {
        id
        state
      }
      issue {
        ...DataIssueWithCommentsFields
      }
    }
  }

  mutation ResolveDataIssue($input: ResolveDataIssueInput!) {
    resolveDataIssue(input: $input) {
      issue {
        ...DataIssueWithCommentsFields
      }
    }
  }
`;

function ResolveDataIssueButton({
  dataIssueId,
  setIssue,
  latestProjectIsComplete,
  sendMessage,
  label,
}: {
  dataIssueId: string;
  setIssue?: (issue: GQDataIssueWithCommentsFieldsFragment) => void;
  latestProjectIsComplete?: boolean;
  sendMessage: () => void;
  label: string;
}): JSX.Element {
  const { t } = useLingui();
  const snackbar = useSnackbar();
  const [, executeResolveDataIssue] = useResolveDataIssueMutation();

  const onClick = async (dataIssueId: string) => {
    Analytics.action('resolveDataIssue', {
      issueId: dataIssueId,
    });
    const res = await executeResolveDataIssue({
      input: {
        id: dataIssueId,
      },
    });

    const newIssue = res.data?.resolveDataIssue?.issue;

    if (res.error || !newIssue) {
      Analytics.error('resolveDataIssue', {
        issueId: dataIssueId,
        error: res.error,
      });
      snackbar.enqueueSnackbar(t`An error occurred, please try again later`, {
        variant: 'error',
      });
    } else {
      setIssue?.(newIssue);
    }
  };

  return (
    <Button
      startIcon={<CheckmarkIcon />}
      onClick={async () => {
        sendMessage();
        await onClick(dataIssueId);
      }}
      disabled={latestProjectIsComplete}
      data-test={TestIds.ResolveDataIssueButton}
    >
      {label}
    </Button>
  );
}

export function MentionsPopover({
  users,
  anchorEl,
  onClose,
  onClick,
  setAnchorEl,
  handleKeyDown,
}: {
  users: Array<{ id: string; name: string }>;
  anchorEl?: HTMLTextAreaElement | null;
  onClose: () => void;
  onClick: (user: { id: string; name: string }) => void;
  setAnchorEl: (anchorEl: HTMLTextAreaElement | null) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLLIElement>) => void;
}) {
  if (users.length === 0) {
    onClose();
  }

  return (
    <Menu
      open={anchorEl !== null}
      anchorEl={anchorEl}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        style: {
          transform: `translateY(-9px)`, // Move the menu up
          minWidth: '500px',
          maxHeight: '400px',
        },
      }}
      onClose={onClose}
    >
      {users.map((user) => (
        <MenuItem
          key={user.id}
          onClick={(e) => {
            onClick(user);
            setAnchorEl(null);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          onKeyUp={(e) => {
            if (e.key === ' ') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <ListItemText primary={user.name} />
        </MenuItem>
      ))}
    </Menu>
  );
}

interface WithMentionsRenderProps {
  value: string;
  setValue: (value: string) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyUp: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

interface WithMentionsProps {
  userDataById: Record<
    string,
    {
      id: string;
      name: string;
      hasPermissions: boolean;
      hasAnyPermissions: boolean;
      orgAccessId: string;
    }
  >;
  setMessageText: (value: string) => void;
  permissions?: Array<GQCreatePermissionItemInput & { objectName?: string }>;
  onAddMentionedUserId: (memberId: string) => void;
  message: string;
  children(props: WithMentionsRenderProps): ReactNode;
  permissionCheck: 'hasAnyPermission' | 'hasEveryPermission';
}

export function WithMentions({
  userDataById,
  setMessageText,
  permissions,
  onAddMentionedUserId,
  message,
  children,
  permissionCheck = 'hasEveryPermission',
}: WithMentionsProps) {
  const locale = useLocale();
  const { t } = useLingui();
  const snackbar = useSnackbar();
  const [mentionText, setMentionText] = useState('');
  const [mentionMode, setMentionMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLTextAreaElement | null>(null);
  const [userIdToConfirmPermissionsFor, setUserIdToConfirmPermissionsFor] =
    useState<string | null>(null);
  const [, executeAddPermission] = useAddPermissionMutation();
  const [usersGrantedPermissions, setUsersGrantedPermissions] = useState<
    Array<string>
  >([]);
  const [textBeforeMention, setTextBeforeMention] = useState('');
  const [textAfterMention, setTextAfterMention] = useState('');

  const permissionsOptions = permissions ? [permissions] : [[]];

  const handleConfirmPermissions = async (
    permissions: Array<GQCreatePermissionItemInput & { objectName?: string }>
  ) => {
    invariant(
      userIdToConfirmPermissionsFor,
      'must have one user with insufficient permissions'
    );
    // TODO: Potentially allow people to assign ApproveDatasource permissions here
    const permissionToAssign =
      permissions[0] ??
      permissions.find((p) => p.permission === 'ManageDatasource');

    invariant(permissionToAssign, 'must have one permission to assign');

    const result = await executeAddPermission({
      input: {
        orgAccessId: userDataById[userIdToConfirmPermissionsFor].orgAccessId,
        permission: permissionToAssign.permission,
        objectId: permissionToAssign.objectId,
        objectType: permissionToAssign.objectType,
      },
    });

    void enqueueMutationSnackbar(snackbar, result, {
      onSuccess: () => {
        setUsersGrantedPermissions([
          ...usersGrantedPermissions,
          userIdToConfirmPermissionsFor,
        ]);
        setUserIdToConfirmPermissionsFor(null);
      },
    });
  };

  const handleSelectUserMention = async (
    user: {
      id: string;
      name: string;
    },
    setMessageText: (text: string) => void
  ) => {
    setMentionMode(false);
    const newText =
      textBeforeMention + '@' + user.name + ' ' + textAfterMention;
    const cursorIndex = textBeforeMention.length + user.name.length + 1;
    setMentionText('');
    setMessageText(newText);
    onAddMentionedUserId(user.id);
    setTimeout(() => {
      anchorEl?.setSelectionRange(cursorIndex, cursorIndex);
    }, 0);
  };

  const handleCloseMentionsPopover = async (
    resetText: (value: string) => void,
    value: string
  ) => {
    setMentionMode(false);
    setMentionText('');
    setAnchorEl(null);
    resetText(value);
  };

  const userNameToConfirmPermissionsFor = userIdToConfirmPermissionsFor
    ? userDataById[userIdToConfirmPermissionsFor].name
    : null;

  const objectNames = new Set(permissions?.map((p) => p.objectName));
  UnexpectedError.invariant(
    !permissions || objectNames.size === 1,
    'Expected only one object name for DataIssueDialog permissions'
  );
  const objectName = objectNames.values().next().value;
  const formattedObjectName = formatObjectName(locale, objectName);
  const permissionsText = userNameToConfirmPermissionsFor
    ? objectName === VALUE_MAPPING_OBJECT_NAME
      ? t`${userNameToConfirmPermissionsFor} doesn't have access to this discussion. To add them, you'll need to grant them higher level permissions that enable them to view financial mapping data. Would you like to grant permission to manage measurements?`
      : t`${userNameToConfirmPermissionsFor} doesn't have access to this discussion. To add them, you'll need to grant access to upload and view summaries for the ${formattedObjectName} data source only.`
    : null;

  return (
    <>
      {children({
        value: message,
        setValue: setMessageText,
        onKeyDown: (e) => {
          const target = e.target;
          if (target instanceof HTMLTextAreaElement) {
            if (e.key === '@') {
              const mentionStartIndex = target.selectionStart;
              setMentionMode(true);
              setTextBeforeMention(message.substring(0, mentionStartIndex));
              setTextAfterMention(message.substring(mentionStartIndex));
            }
          }
        },
        onKeyUp: (e) => {
          if (mentionMode) {
            setAnchorEl(e.target as HTMLTextAreaElement);
          }
        },
      })}
      {userIdToConfirmPermissionsFor && (
        <ConfirmPermissionDialog
          onConfirm={async (permissions) => {
            await handleConfirmPermissions(permissions);
            await handleSelectUserMention(
              userDataById[userIdToConfirmPermissionsFor],
              setMessageText
            );
          }}
          onCancel={() => {
            setUserIdToConfirmPermissionsFor(null);
          }}
          permissionsOptions={permissionsOptions}
          shouldShowPermissions={false}
        >
          {permissionsText}
        </ConfirmPermissionDialog>
      )}
      {mentionMode && (
        <MentionsPopover
          // TODO(LOC-31) Should `toLocaleLowerCase` be used?
          users={Object.values(userDataById).filter((user) => {
            return user.name.toLowerCase().includes(mentionText.toLowerCase());
          })}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          onClose={async () => {
            await handleCloseMentionsPopover(
              setMessageText,
              textBeforeMention + '@' + mentionText + textAfterMention
            );
          }}
          onClick={async (user) => {
            if (
              !usersGrantedPermissions.includes(user.id) &&
              !(permissionCheck === 'hasAnyPermission'
                ? userDataById[user.id].hasAnyPermissions
                : userDataById[user.id].hasPermissions)
            ) {
              setUserIdToConfirmPermissionsFor(user.id);
            } else {
              await handleSelectUserMention(user, async (value) =>
                setMessageText(value)
              );
            }
          }}
          handleKeyDown={async (event) => {
            const key = event.key;
            if (key === 'Backspace' && mentionText === '') {
              await handleCloseMentionsPopover(
                (value: string) => setMessageText(value),
                textBeforeMention + textAfterMention
              );
            } else if (
              !event.ctrlKey &&
              !event.altKey &&
              !event.metaKey &&
              key.length === 1
            ) {
              setMessageText(
                textBeforeMention + '@' + mentionText + key + textAfterMention
              );
              setMentionText(mentionText + key);
            } else if (key === 'Backspace') {
              setMessageText(
                textBeforeMention +
                  '@' +
                  mentionText.slice(0, -1) +
                  textAfterMention
              );
              setMentionText(mentionText.slice(0, -1));
            }
          }}
        />
      )}
    </>
  );
}

type TextFieldMultilineForMentionsProps = Omit<
  WithMentionsProps,
  'children'
> & {
  rows: number;
  placeholder?: string;
  dataTest: string;
  label?: ReactNode;
};
export function TextFieldMultilineForMentions(
  props: TextFieldMultilineForMentionsProps
) {
  return (
    <WithMentions {...props}>
      {({ onKeyDown, onKeyUp }) => (
        <TextFieldMultiline
          id="message"
          rows={props.rows}
          placeholder={props.placeholder}
          label={props.label}
          dataTest={props.dataTest}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      )}
    </WithMentions>
  );
}

export function DataIssueForm({
  issue,
  permissions,
  orgId,
  setIssue,
  commentToPrepend, // a hack - in a few scenarios we have a comment from outside the data model to prepend to the discussion thread
  activeUserId,
}: {
  issue: GQDataIssueWithCommentsFieldsFragment;
  permissions?: Array<GQCreatePermissionItemInput & { objectName?: string }>;
  orgId?: string;
  setIssue?: (issue: GQDataIssueWithCommentsFieldsFragment) => void;
  commentToPrepend?: DiscussionCommentProps;
  activeUserId?: string;
}) {
  const { t } = useLingui();
  const snackbar = useSnackbar();
  const [, executeRespondToDataIssueMutation] = useRespondToDataIssueMutation();
  const [mentionedUserIds, setMentionedUserIds] = useState<Array<string>>([]);
  const isMeasurementComplete = !!issue.measurementProject.completedAt;
  const comments: Array<DiscussionCommentProps> = flattenConnection(
    issue.comments
  );

  if (!issue.isCustomerInitiated && issue.description) {
    comments.unshift({
      id: issue.id,
      message: issue.description,
      createdAt: issue.createdAt,
      person: { name: 'Watershed' },
    });
  }
  if (commentToPrepend) {
    comments.unshift(commentToPrepend);
  }

  const [res] = useGetAllAssignableUsersWithPermissionQuery({
    variables: {
      permissions: permissions
        ? permissions.map((permission) => ({
            permissionType: permission.permission,
            objectId: permission.objectId,
            objectType: permission.objectType,
          }))
        : [],
      orgId: orgId ?? 'unused-since-paused',
    },
    pause: isAdminApp() || !orgId,
  });

  if (isFetchingOrStale(res)) {
    return <CircularProgress />;
  }

  // mentions are not supported in admin app
  const userDataById = (() => {
    if (!isAdminApp() && orgId) {
      const data = getGqlResultDataBang(res);
      const userData = flattenConnection(data.organization.users);
      const userDataById = keyBy(userData, (d) => d.id);
      return userDataById;
    }
    return {} as Record<
      string,
      {
        id: string;
        name: string;
        orgAccessId: string;
        hasPermissions: boolean;
        hasAnyPermissions: boolean;
      }
    >;
  })();

  return (
    <>
      <Box marginX={-2}>
        <DiscussionThread
          comments={comments}
          activeUserId={activeUserId}
          sx={{ maxHeight: 480 }}
        />
      </Box>
      <Divider sx={{ marginX: -2, marginBottom: 2 }} />
      <Formik
        initialValues={{
          message: '',
          shouldNotifyWatershed: true,
        }}
        onSubmit={async ({ message, shouldNotifyWatershed }, actions) => {
          Analytics.action('respondToIssue', {
            issueId: issue.id,
            message,
          });
          actions.setSubmitting(true);

          const [formattedCommentText, memberIds] = formatCommentForMentions(
            message,
            mentionedUserIds,
            userDataById
          );

          const result = await executeRespondToDataIssueMutation({
            input: {
              id: issue.id,
              message: formattedCommentText,
              shouldNotifyWatershed,
              memberIds,
            },
          });
          actions.setSubmitting(false);

          const newIssue = result.data?.respondToDataIssue?.issue;

          if (result.error || !newIssue) {
            Analytics.error('respondToIssue', { issueId: issue.id });
            snackbar.enqueueSnackbar(
              t`An error occurred, please try again later`,
              { variant: 'error' }
            );
          } else {
            actions.resetForm({
              values: { message: '', shouldNotifyWatershed: true },
            });
            setMentionedUserIds([]);
            setIssue?.(newIssue);
          }
        }}
      >
        {(formik) => (
          <Form>
            <TextFieldMultilineForMentions
              rows={1}
              placeholder={t`Type your message`}
              userDataById={userDataById}
              setMessageText={(value) => formik.setFieldValue('message', value)}
              permissions={permissions}
              permissionCheck="hasAnyPermission" // Allow any of the permissions in requiredPermissions
              onAddMentionedUserId={(memberId) =>
                setMentionedUserIds([...mentionedUserIds, memberId])
              }
              message={formik.values.message}
              dataTest={TestIds.DataIssueResponseField}
            />
            <Stack
              spacing={1}
              direction="row"
              sx={{
                paddingTop: 2,
                justifyContent: 'space-between',
              }}
            >
              <Box
                component="label"
                sx={{ display: 'inline-flex', alignItems: 'center' }}
              >
                <CheckboxField inputId="shouldNotifyWatershed" />
                <Trans comment="Checkbox label">Notify Watershed team</Trans>
              </Box>
              <Stack spacing={1} direction="row">
                {GQDataIssueState.Done !== issue.state && (
                  <ResolveDataIssueButton
                    dataIssueId={issue.id}
                    setIssue={setIssue}
                    label={
                      formik.values.message ? t`Send & resolve` : t`Resolve`
                    }
                    latestProjectIsComplete={isMeasurementComplete}
                    sendMessage={async () => {
                      if (formik.values.message === '') {
                        return;
                      }

                      const [formattedCommentText, memberIds] =
                        formatCommentForMentions(
                          formik.values.message,
                          mentionedUserIds,
                          userDataById
                        );

                      const result = await executeRespondToDataIssueMutation({
                        input: {
                          id: issue.id,
                          message: formattedCommentText,
                          shouldNotifyWatershed:
                            formik.values.shouldNotifyWatershed,
                          memberIds,
                        },
                      });
                      if (result.error) {
                        Analytics.error('respondToIssue', {
                          issueId: issue.id,
                        });
                        snackbar.enqueueSnackbar(
                          t`An error occurred, please try again later`,
                          { variant: 'error' }
                        );
                      } else {
                        formik.setFieldValue('message', '');
                        formik.setFieldValue('shouldNotifyWatershed', true);
                      }
                    }}
                  />
                )}
                <ProgressButton
                  isInProgress={formik.isSubmitting}
                  label={
                    issue.state === GQDataIssueState.Done ? (
                      <Trans>Reopen & send</Trans>
                    ) : (
                      <Trans>Send</Trans>
                    )
                  }
                  progressLabel={t`Submitting…`}
                  type="submit"
                  disabled={
                    isMeasurementComplete ||
                    formik.isSubmitting ||
                    formik.values.message === ''
                  }
                  tooltip={
                    isMeasurementComplete
                      ? t`The measurement is complete, so you can't respond to this conversation.`
                      : undefined
                  }
                />
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
}

export function DataIssueDialog({
  datasourceName,
  permissions,
  orgId,
  issue: initialIssue,
  onClose,
  activeUserId,
}: {
  datasourceName: string;
  permissions: Array<GQCreatePermissionItemInput & { objectName?: string }>;
  orgId: string;
  issue: GQDataIssueWithCommentsFieldsFragment;
  onClose: () => void;
  activeUserId: string;
}) {
  // The input issue to this component is not always obtained from a GraphQL
  // query, meaning that it won't always update when mutations are executed. To
  // alleviate this, we imperatively store the issue in state and update it
  // manually when the two mutations in this component are executed.
  //
  // Ideally we'd use a query to fetch the issue in all cases, but it was more
  // trouble than it was worth to do that at the time of writing.
  // See this for context: https://watershedclimate.slack.com/archives/C022LBWT9GA/p1670911382733319?thread_ts=1670908868.178369&cid=C022LBWT9GA
  const [issue, setIssue] = useState(initialIssue);

  useEffect(() => {
    Analytics.modal('viewIssue', {
      issueId: initialIssue.id,
    });
  }, [initialIssue.id]);

  return (
    <Dialog
      onClose={() => {
        onClose();
        Analytics.action('dataIssueDialogClose', { issueId: issue.id });
      }}
      maxWidth="sm"
      header={{
        title: (
          <DataIssueChatHeader
            issue={issue}
            activeUserId={activeUserId}
            datasourceName={datasourceName}
          />
        ),
      }}
    >
      <DialogSection paddingRight={2} paddingLeft={2} paddingTop={0}>
        <DataIssueForm
          issue={issue}
          permissions={permissions}
          orgId={orgId}
          setIssue={setIssue}
          activeUserId={activeUserId}
        />
      </DialogSection>
    </Dialog>
  );
}
