import { UseQueryState } from 'urql';
import { getGqlResultDataBang } from './errorUtils';

export default function isFetchingOrStale<T extends object>(
  result: UseQueryState<T>
) {
  return result.fetching || result.stale;
}

// This is Adam's quixotic attempt to refine this
// and be a bit more careful to avoid unecessary loading states
export function isFetching<T extends object>(result: UseQueryState<T>) {
  return !result.data && result.fetching;
}

export function isFetchingOrDataBang<T extends object>(
  result: UseQueryState<T>
):
  | ({ fetching: true } & Omit<UseQueryState<T>, 'fetching'>)
  | ({ fetching: false; data: T } & Omit<
      UseQueryState<T>,
      'fetching' | 'data'
    >) {
  return result.fetching
    ? { ...result, fetching: true }
    : { ...result, fetching: false, data: getGqlResultDataBang(result) };
}
