import {
  filterExpressionGroupToReductionFilter,
  reductionFilterToReadableString,
} from '../reductions/ReductionFilter';
import invariant from 'invariant';
import {
  GQCommonPlanTargetFieldsFragment,
  GQFilterExpressionGroup,
  GQPlanTargetIntensityType,
  GQPlanTargetTargetComparisonType,
  GQSimpleTimeseriesFieldsFragment,
  GQTargetReductionRate,
  GQTimeseriesFrequency,
} from '../generated/graphql';
import assertNever from './assertNever';
import { formatPercentageNonzero, smartSentenceCase } from './helpers';
import {
  annualYoyForStartEndAndNumYears,
  FiscalYearlyPercentageTimeseries,
  FiscalYearlyTimeseries,
  getCompoundedValues,
  getStraightlineValues,
  inferStraightlineEnd,
  yearOverYearByAnnualRateOfReduction,
} from './SimpleTimeseries';
import { FiscalYear, FiscalYearSequence, YearMonth, YM } from './YearMonth';
import { condenseMoneyPerMillion } from './currencyUtils';
import { PlanTargetForForecasting } from '../forecast/ReductionsForecastX';
import sortBy from 'lodash/sortBy';
import { FootprintScope } from '../forecast/types';
import {
  SCOPE_1_CATEGORY_ID,
  SCOPE_2_CATEGORY_ID,
  SCOPE_3_GHG_CATEGORY_IDS,
} from '../constants';
import isIncludedIn from './isIncludedIn';
import { SBT_RENEWABLE_ENERGY_INTERIM_TARGET } from '../reductions/constants';
import { BadInputError } from '../errors/BadInputError';
import { GrowthForecastConfig } from '../forecast/GrowthForecastConfig';
import { TargetIdentifier } from '../forecast/TargetIdentifier';

export interface CommonPlanTargetFields
  extends Pick<
    GQCommonPlanTargetFieldsFragment,
    | 'filters'
    | 'intensityType'
    | 'customIntensityConfigId'
    | 'comparisonType'
    | 'emissionsTargetCustom'
    | 'baseYear'
  > {}

/**
 *
 * @param planTarget
 * @returns a number between 0 and 1 representing how much this
 * target reduces from the reference point
 */
export function getPlanTargetReductionPct(
  reductionsTimeseries: GQSimpleTimeseriesFieldsFragment
): number {
  const { percentChange } = inferTimeseriesInfo(
    FiscalYearlyPercentageTimeseries.fromGQ(reductionsTimeseries)
  );
  return percentChange / 100;
}

// TODO: can remove this when GQL properly return Date instead of any
export function getPlanTargetForForecastingReductionPct(
  planTarget: Pick<PlanTargetForForecasting, 'emissionsTargetCustom'>
): number {
  const usedTs = planTarget.emissionsTargetCustom;
  const { percentChange } = inferTimeseriesInfo(
    FiscalYearlyPercentageTimeseries.fromSimpleTimeseriesForForecasting(usedTs)
  );
  return percentChange / 100;
}

export function getPlanTargetReductionPctString(
  planTarget: Pick<GQCommonPlanTargetFieldsFragment, 'emissionsTargetCustom'>
): string {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return formatPercentageNonzero(
    getPlanTargetReductionPct(planTarget.emissionsTargetCustom),
    { maximumFractionDigits: 1, includeTrailingZeros: true }
  );
}

export function getTargetInterimReductionPctString(
  planTarget: Partial<
    Pick<GQCommonPlanTargetFieldsFragment, 'interimTargetValue'>
  >
): string {
  const interimTargetValue = planTarget.interimTargetValue;
  if (!interimTargetValue) return 'N/A';
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return formatPercentageNonzero(1 - interimTargetValue / 100, {
    maximumFractionDigits: 1,
    includeTrailingZeros: true,
  });
}

export const IntensityTypeToLabelStr: Record<
  Exclude<GQPlanTargetIntensityType, 'Custom'>,
  string
> = {
  [GQPlanTargetIntensityType.Absolute]: 'Absolute reduction',
  [GQPlanTargetIntensityType.Revenue]: 'Revenue intensity reduction',
  [GQPlanTargetIntensityType.Headcount]: 'Headcount intensity reduction',
  [GQPlanTargetIntensityType.GrossProfit]: 'Gross profit intensity reduction',
  [GQPlanTargetIntensityType.NightsStayed]: 'Nights stayed intensity reduction',
  [GQPlanTargetIntensityType.SupplierEngagement]:
    'Suppliers by emissions commit to SBT',
  [GQPlanTargetIntensityType.SupplierEngagementBySpend]:
    'Suppliers by spend commit to SBT',
  [GQPlanTargetIntensityType.RenewableElectricity]:
    'Renewable electricity procurement',
  [GQPlanTargetIntensityType.Orders]: 'Orders intensity reduction',
  [GQPlanTargetIntensityType.Patients]: 'Patients intensity reduction',
};

export function getTargetLabel(
  { intensityType, customIntensityConfigId }: TargetIdentifier,
  config: GrowthForecastConfig | null
): string {
  // TODO(EUR-2103): If we move all labels to the config, we won't need this conditional passthrough.
  if (intensityType !== GQPlanTargetIntensityType.Custom) {
    return IntensityTypeToLabelStr[intensityType];
  }

  BadInputError.invariant(
    config,
    `Growth forecast config not found for custom intensity config with ID ${customIntensityConfigId}`
  );
  return config.displayName + ' intensity reduction';
}

const IntensityTypeToReadableStr: Record<GQPlanTargetIntensityType, string> = {
  [GQPlanTargetIntensityType.Absolute]: 'absolute emissions',
  [GQPlanTargetIntensityType.Revenue]: 'revenue intensity',
  [GQPlanTargetIntensityType.Headcount]: 'headcount intensity',
  [GQPlanTargetIntensityType.GrossProfit]: 'gross profit intensity',
  [GQPlanTargetIntensityType.NightsStayed]: 'nights stayed intensity',
  [GQPlanTargetIntensityType.SupplierEngagement]: 'suppliers by emissions',
  [GQPlanTargetIntensityType.SupplierEngagementBySpend]: 'suppliers by spend',
  [GQPlanTargetIntensityType.RenewableElectricity]: 'renewable electricity',
  [GQPlanTargetIntensityType.Orders]: 'orders intensity',
  [GQPlanTargetIntensityType.Patients]: 'patients intensity',
  // TODO(EUR-2043): Look up custom intensity config.
  [GQPlanTargetIntensityType.Custom]: 'Unimplemented',
};

export function targetToReadableStr(
  { intensityType, customIntensityConfigId }: TargetIdentifier,
  config: GrowthForecastConfig | null
): string {
  if (intensityType === GQPlanTargetIntensityType.Custom) {
    BadInputError.invariant(
      config,
      `Growth forecast config not found for custom intensity config with ID ${customIntensityConfigId}`
    );
    return config.displayName.toLowerCase() + ' intensity';
  }

  return IntensityTypeToReadableStr[intensityType];
}

/** Creates the title label for a target. */
export function targetHeaderLabel(
  target: Pick<
    GQCommonPlanTargetFieldsFragment,
    'intensityType' | 'customIntensityConfigId' | 'filters'
  >,
  config: GrowthForecastConfig | null
): string {
  const boundaryString = reductionFilterToReadableString(
    filterExpressionGroupToReductionFilter(target.filters),
    {
      includeFieldNames: false,
      hideGhgCategoryNames: true,
    }
  );

  let targetLabel = '';
  if (target.intensityType === GQPlanTargetIntensityType.SupplierEngagement) {
    targetLabel = 'supplier engagement';
  } else {
    targetLabel = targetToReadableStr(target, config);
  }

  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return smartSentenceCase(
    `${
      // The boundary string is only relevant for non-renewable electricity targets because
      // renewable electricity targets don't have an editable scope boundary.
      target.intensityType !== GQPlanTargetIntensityType.RenewableElectricity
        ? `${boundaryString} `
        : ''
    }${targetLabel} target`
  );
}

function generateIntensityStringForIntensityType(
  intensityType: GQPlanTargetIntensityType,
  config: GrowthForecastConfig | null,
  currency: string | null
): string {
  switch (intensityType) {
    case GQPlanTargetIntensityType.Absolute:
      return '';
    case GQPlanTargetIntensityType.Revenue:
      return `per ${condenseMoneyPerMillion(currency)} of revenue`;
    case GQPlanTargetIntensityType.Headcount:
      return 'per employee';
    case GQPlanTargetIntensityType.GrossProfit:
      return `per ${condenseMoneyPerMillion(currency)} of gross profit`;
    case GQPlanTargetIntensityType.NightsStayed:
      return 'per night stayed';
    case GQPlanTargetIntensityType.SupplierEngagement:
      return 'of suppliers by emissions';
    case GQPlanTargetIntensityType.SupplierEngagementBySpend:
      return 'of suppliers by spend';
    case GQPlanTargetIntensityType.RenewableElectricity:
      return 'of all electricity used as renewable electricity';
    case GQPlanTargetIntensityType.Orders:
      return 'per thousand orders';
    case GQPlanTargetIntensityType.Patients:
      return 'per thousand patients';
    case GQPlanTargetIntensityType.Custom:
      return config !== null
        ? `per ${config.quantityUnit(currency)}`
        : `per ${currency}`;
    default:
      assertNever(intensityType);
  }
}

export function isIntensityTypeSupplierEngagement(
  intensityType: GQPlanTargetIntensityType
): boolean {
  return (
    intensityType === GQPlanTargetIntensityType.SupplierEngagement ||
    intensityType === GQPlanTargetIntensityType.SupplierEngagementBySpend
  );
}

/**
 * Deprecated - only used in the legacy tool.
 *
 * Generates a description for a given target.
 */
export function generateDescriptionForParentTargetLegacy(
  planTarget: CommonPlanTargetFields & {
    interimTargetValue?: number | null;
  },
  currency: string | null,
  orgName?: string
): string {
  return generateDescriptionForParentTarget(
    planTarget,
    // The legacy tool does not support custom intensity configs.
    null,
    currency,
    orgName
  );
}

/**
 * Generates a description for a given target.
 */
export function generateDescriptionForParentTarget(
  planTarget: CommonPlanTargetFields & {
    interimTargetValue?: number | null;
    interimTargetDate?: YearMonth | null;
  },
  config: GrowthForecastConfig | null,
  currency: string | null,
  orgName?: string
): string {
  const {
    prefix,
    reductionPctString,
    reductionPctPerString,
    interimReductionPctString,
    suffix,
    targetYearString,
    interimTargetYearString,
  } = generateDescriptionComponentsForParentTarget(
    planTarget,
    config,
    currency,
    {
      orgName,
    }
  );

  if (planTarget.interimTargetValue) {
    return `${prefix} ${interimReductionPctString} ${reductionPctPerString} ${suffix} ${interimTargetYearString} and ${reductionPctString} ${suffix} ${targetYearString}`;
  }

  return `${prefix} ${reductionPctString}${
    reductionPctPerString ? ` ${reductionPctPerString} ` : ' '
  }${suffix} ${targetYearString}`;
}

/**
 * Some callers may need to identify the significant components of the description, e.g. reduction
 * percent and target year. To support this, the description is broken down into its components:
 * prefix, reduction pct, suffix, and target year.
 * Join the components together (with no delimiter) to compose the full description.
 */
export function generateDescriptionComponentsForParentTarget(
  planTarget: CommonPlanTargetFields & {
    interimTargetValue?: number | null;
    interimTargetDate?: YearMonth | null;
  },
  config: GrowthForecastConfig | null,
  currency: string | null,
  options: {
    orgName?: string;
    baseYearIsAdjustable?: boolean;
  }
): {
  prefix: string;
  reductionPctString: string;
  reductionPctPerString: string;
  suffix: string;
  targetYearString: string;
  interimReductionPctString: string;
  interimTargetYearString: string;
} {
  const { baseYearIsAdjustable, orgName } = options;
  const reductionFilter = filterExpressionGroupToReductionFilter(
    planTarget.filters
  );

  const tmInfo = inferTimeseriesInfo(
    FiscalYearlyPercentageTimeseries.fromGQ(planTarget.emissionsTargetCustom)
  );
  const boundaryString = reductionFilterToReadableString(reductionFilter, {
    includeFieldNames: false,
  });
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  const targetYearString = FiscalYear.displayLabelForYearStartingAtYearMonth(
    tmInfo.targetEndYear,
    { short: true }
  );
  const intensityType = planTarget.intensityType;
  const perString = generateIntensityStringForIntensityType(
    intensityType,
    config,
    currency
  );
  if (isIntensityTypeSupplierEngagement(intensityType)) {
    return {
      prefix: `${orgName ? `${orgName} commits to engage` : 'Engage'}`,
      reductionPctString: getPlanTargetReductionPctString(planTarget),
      reductionPctPerString: '',
      suffix: `of suppliers by ${
        planTarget.intensityType ===
        GQPlanTargetIntensityType.SupplierEngagementBySpend
          ? 'spend'
          : 'emissions'
      } to commit to SBTs by`,
      targetYearString,
      interimReductionPctString: '',
      interimTargetYearString: '',
    };
  }

  if (
    planTarget.intensityType === GQPlanTargetIntensityType.RenewableElectricity
  ) {
    const interimTargetYearString = planTarget.interimTargetDate
      ? // TODO: i18n (please resolve or remove this TODO line if legit)
        // eslint-disable-next-line @watershed/require-locale-argument
        FiscalYear.displayLabelForYearStartingAtYearMonth(
          planTarget.interimTargetDate,
          { short: true }
        )
      : '';
    return {
      prefix: `${orgName ? `${orgName} will procure` : 'Procure'}`,
      reductionPctString: getPlanTargetReductionPctString(planTarget),
      reductionPctPerString: 'renewable electricity',
      suffix: `by`,
      targetYearString,
      interimReductionPctString: getTargetInterimReductionPctString(planTarget),
      interimTargetYearString,
    };
  }

  // The suffix of the description depends on the comparison type. All other fields are
  // constant across the comparison types
  const baseStringComponents = {
    prefix: `${
      orgName ? `${orgName} commits to reduce` : 'Reduce'
    } ${boundaryString} emissions by`,
    reductionPctString: getPlanTargetReductionPctString(planTarget),
    reductionPctPerString: perString,
    interimReductionPctString: '',
    interimTargetYearString: '',
  };

  if (planTarget.comparisonType === GQPlanTargetTargetComparisonType.BaseYear) {
    return {
      ...baseStringComponents,
      suffix: baseYearIsAdjustable
        ? 'by'
        : // TODO: i18n (please resolve or remove this TODO line if legit)
          // eslint-disable-next-line @watershed/require-locale-argument
          `from a base year of ${FiscalYear.displayLabelForYearStartingAtYearMonth(
            planTarget.baseYear,
            { short: true }
          )} by`,
      targetYearString,
    };
  }

  return {
    ...baseStringComponents,
    suffix: `by ${targetYearString}`,
    targetYearString: '',
  };
}

export enum NewFilterRejectionReasons {
  IntersectsWithOtherTargets,
  IsNotSubsetOfParent,
  NoMatchingRows,
  UnsupportedFilter,
}

export function getPlanTargetRejectionMessageFromReason(
  reason: NewFilterRejectionReasons
): string {
  switch (reason) {
    case NewFilterRejectionReasons.IntersectsWithOtherTargets:
      return 'The target intersects with other targets';
    case NewFilterRejectionReasons.IsNotSubsetOfParent:
      return 'The target is not a subset of parent target';
    case NewFilterRejectionReasons.NoMatchingRows:
      return 'The target does not affect any part of footprint';
    case NewFilterRejectionReasons.UnsupportedFilter:
      return 'The target contains an unsupported filter';
    default:
      assertNever(reason);
  }
}

export const IntensityTypeToReadableStrWithUnit: Record<
  GQPlanTargetIntensityType,
  (currency: string | null) => string
> = {
  [GQPlanTargetIntensityType.Absolute]: () => 'Absolute (total tCO₂e)',
  [GQPlanTargetIntensityType.Revenue]: (currency: string | null) =>
    `Revenue Intensity (tCO₂e / ${condenseMoneyPerMillion(currency)} revenue)`,
  [GQPlanTargetIntensityType.Headcount]: () =>
    'Headcount Intensity (tCO₂e / employee)',
  [GQPlanTargetIntensityType.GrossProfit]: () =>
    'Gross Profit Intensity (tCO₂e / $1M revenue)',
  [GQPlanTargetIntensityType.NightsStayed]: () =>
    'Nights Stayed Intensity (tCO₂e / XXX night stayed)',
  [GQPlanTargetIntensityType.SupplierEngagement]: () => 'Suppliers Engaged',
  [GQPlanTargetIntensityType.SupplierEngagementBySpend]: () =>
    'Suppliers Engaged',
  [GQPlanTargetIntensityType.RenewableElectricity]: () =>
    'Renewable electricity procured (%)',
  [GQPlanTargetIntensityType.Orders]: () =>
    'Orders Intensity (tCO₂e / Xk orders)',
  [GQPlanTargetIntensityType.Patients]: () =>
    'Orders Intensity (tCO₂e / Xk patients)',
  // TODO(EUR-2043): Look up custom intensity config.
  [GQPlanTargetIntensityType.Custom]: () => 'Unimplemented',
} as const;

export const GQTimeseriesFrequencyToYearlyDivider = {
  [GQTimeseriesFrequency.Monthly]: 12,
  [GQTimeseriesFrequency.Yearly]: 1,
} as const;

/**
 * TARGET TIMESERIES UTILITY FUNCTIONS
 */

/**
 * @returns the inclusive target end year, that is, the start of the
 * final year covered in the timeseries and the delta between 100% and
 * the final timeseries value
 */
export function inferTimeseriesInfo(
  emissionsTargetTimeseries: FiscalYearlyPercentageTimeseries
): { targetEndYear: YearMonth; percentChange: number } {
  const targetEndYear = inferStraightlineEnd(emissionsTargetTimeseries);
  const percentChange =
    100 - emissionsTargetTimeseries.percentageAt(targetEndYear);

  return { targetEndYear, percentChange };
}

// Allows you to submit a series of target years and reductions, and it will
// return a combination of straightline series that will hit those targets.
// This is the canonical way to create a series for targets, whether or not
// they have interim targets.
// Example:
// fiscal sequence starts at 201801
// start = 100
// targetYearsAndReductions = [
//   { reductionTarget: 50, targetYear: 202001 },
//   { reductionTarget: 25, targetYear: 202501 },
// ]
// reductionRate = LinearAnnualReduction
//
// returns a fiscal yearly timeseries with values:
// [100, 75, 50, 45, 40, 35, 30, 25]
export function calculateTargetSequence(
  fiscalYearSequence: FiscalYearSequence,
  targetYearsAndReductions: Array<{
    reductionTarget: number;
    targetYearExclusive: YearMonth;
  }>,
  reductionRate: GQTargetReductionRate,
  startingValue: number = 100
): FiscalYearlyTimeseries<number> {
  const startReductionPct = startingValue;
  const sortedTargetYearsAndReductions = sortBy(
    targetYearsAndReductions,
    (t) => t.targetYearExclusive
  );

  // validate that there are no duplicate target years and that the targets are decreasing.
  const allYms = new Set<YearMonth>();
  for (const { targetYearExclusive } of sortedTargetYearsAndReductions) {
    invariant(
      !allYms.has(targetYearExclusive),
      'there can only be one target per ym'
    );
    allYms.add(targetYearExclusive);
  }
  invariant(
    sortedTargetYearsAndReductions.length >= 1,
    'must have at least one target year'
  );

  let values = [startReductionPct];
  let currentStart = startReductionPct; // what is the reduction pct for the current year in the target interval?
  let currentPeriodStart = fiscalYearSequence.startYearMonth(); // what is the start of the current year in the target interval?
  // what is the current idx of target section we are dealing with in the
  // targetYearsAndReductions array?
  let idx = 0;
  while (idx < sortedTargetYearsAndReductions.length) {
    // example: this will return 100, 90, 80 the first time,
    // and 80, 50, 20 the second time
    const fnToUse = (() => {
      invariant(
        reductionRate !== 'Custom',
        'connot use custom reduction rate here'
      );
      switch (reductionRate) {
        case GQTargetReductionRate.LinearAnnualReduction:
          return getStraightlineValues;
        case GQTargetReductionRate.YearOverYear:
          return getCompoundedValues;
        default:
          assertNever(reductionRate);
      }
    })();

    const numValuesOverInterval = YM.diff(
      sortedTargetYearsAndReductions[idx].targetYearExclusive,
      currentPeriodStart,
      'year'
    );
    const valuesForInterimTarget = fnToUse(
      currentStart,
      sortedTargetYearsAndReductions[idx].reductionTarget,
      numValuesOverInterval
    );
    values = [
      ...values,
      // We do this slicing because (w/ our example) the first iteration of values has
      // 100 90 80, and then the next iteration has 80 50 20, but the
      // first sequence's last value is referring to the same year as the next sequence's
      // first value, so we need to remove the first value of the next sequence
      // when concatenating.
      ...valuesForInterimTarget.map((v) => Math.max(v, 0)).slice(1),
    ];
    currentStart = sortedTargetYearsAndReductions[idx].reductionTarget;
    currentPeriodStart = YM.minus(
      sortedTargetYearsAndReductions[idx].targetYearExclusive,
      1,
      'year'
    );
    idx += 1;
  }
  invariant(
    values.length === fiscalYearSequence.intervalStarts.length,
    'the number of values should be the same as the number of years'
  );

  return new FiscalYearlyTimeseries(
    fiscalYearSequence.entireInterval().start,
    values
  );
}

export function getDefaultPlanSubmissionYm(
  planCreatedAt: YearMonth
): YearMonth {
  // It usually takes CAs about a quarter to go from 0 to approved plan.
  return YM.plus(planCreatedAt, 4, 'month');
}

/**
 * This function returns whether or not a target is any combination of scope 1/2/3 target.
 */
export function hasScopeFilter({
  filters,
  scopes,
  opts,
}: {
  filters: GQFilterExpressionGroup;
  scopes: ReadonlyArray<FootprintScope>;
  opts?: {
    includesAtLeastOne?: boolean; // default is all must be present
    includeGhgCategories?: boolean;
  };
}): boolean {
  const reductionFilter = filterExpressionGroupToReductionFilter(filters);
  if (reductionFilter.filter.length === 0) {
    return false;
  }

  const ghgScopeFilter = reductionFilter.filter.find(
    ({ field }) => field === 'ghgScope'
  );
  const ghgCategoryFilter = reductionFilter.filter.find(
    ({ field }) => field === 'ghgCategoryId'
  );

  const belongsToScope = (scope: FootprintScope) => {
    if (ghgScopeFilter?.value.map((v) => v.toLowerCase()).includes(scope)) {
      return true;
    }

    // There are GHG categories that represent entire scopes, e.g. 1 Scope 1
    // and 2 Scope 2.
    const scopeToCategory = {
      'scope 1': SCOPE_1_CATEGORY_ID,
      'scope 2': SCOPE_2_CATEGORY_ID,
      'scope 3': null,
    };
    const ghgCategoryForScope = scopeToCategory[scope];
    if (
      ghgCategoryForScope !== null &&
      ghgCategoryFilter?.value.includes(ghgCategoryForScope)
    ) {
      return true;
    }

    // For scope 3, we may want to check if there is a GHG category filter
    // that belongs to scope 3
    return (
      scope === 'scope 3' &&
      opts?.includeGhgCategories &&
      ghgCategoryFilter?.value.some((v) =>
        isIncludedIn(v, SCOPE_3_GHG_CATEGORY_IDS)
      )
    );
  };

  if (opts?.includesAtLeastOne) {
    for (const scope of scopes) {
      if (belongsToScope(scope)) {
        return true;
      }
    }
  }

  for (const scope of scopes) {
    if (!belongsToScope(scope)) {
      return false;
    }
  }
  return true;
}

export type InterimReductionInput = {
  ymInclusive: YearMonth;
  reduction: number;
};

type ConstructFiscalYearTimeseriesInput = {
  fiscalYearSequence: FiscalYearSequence;
  reduction: number;
  startValue: number;
  interimReduction?: InterimReductionInput;
};

// Note that the total reduction is a reduction from 100, not from the start value.
// We might want to change this in the future.
export function straightlineByTotalReduction({
  fiscalYearSequence,
  reduction,
  startValue,
  interimReduction,
}: ConstructFiscalYearTimeseriesInput): FiscalYearlyTimeseries<number> {
  invariant(reduction <= 100, 'if this is a reduction, it has to be <= 100');
  if (interimReduction) {
    invariant(
      YM.month(interimReduction.ymInclusive) ===
        YM.month(fiscalYearSequence.startYearMonth()),
      'interim target must be FY aligned'
    );
    const yearsInInterimTarget = YM.diff(
      interimReduction.ymInclusive,
      fiscalYearSequence.startYearMonth(),
      'year'
    );
    const firstLeg = getStraightlineValues(
      startValue,
      100 - interimReduction.reduction,
      yearsInInterimTarget + 1
    );
    const secondLeg = getStraightlineValues(
      100 - interimReduction.reduction,
      100 - reduction,
      fiscalYearSequence.length - yearsInInterimTarget
    );
    return new FiscalYearlyTimeseries(fiscalYearSequence.startYearMonth(), [
      ...firstLeg,
      ...secondLeg.slice(1),
    ]);
  }
  const newTimeseriesValues = getStraightlineValues(
    startValue,
    100 - reduction,
    fiscalYearSequence.length
  );
  return new FiscalYearlyTimeseries(
    fiscalYearSequence.startYearMonth(),
    newTimeseriesValues
  );
}

export function yearOverYearByTotalReduction({
  fiscalYearSequence,
  // This is the final % reduction. In other words, a reduction of 80% here would
  // be passed in as 80.
  reduction,
  startValue,
  interimReduction,
}: ConstructFiscalYearTimeseriesInput): FiscalYearlyTimeseries<number> {
  invariant(
    interimReduction === undefined,
    'we are not ready to support interim targets in YoY yet'
  );
  const numYears = Array.from(
    fiscalYearSequence.entireInterval().iter('year')
  ).length;
  return yearOverYearByAnnualRateOfReduction(
    fiscalYearSequence,
    startValue,
    annualYoyForStartEndAndNumYears(startValue, 100 - reduction, numYears)
  );
}

// This function takes a base year and a final target end year exclusive, and finds the
// best possible year to create an interim target on (most in-the-future year before 2025).
export function findInterimTargetYearExclusiveForRenewableEnergyTarget(input: {
  baseYear: YearMonth;
  targetYearExclusive: YearMonth;
}): YearMonth | null {
  const fiscalMonth = YM.month(input.baseYear);

  let interimTargetYearExclusive = YM.make(
    // We subtract a year because we want this to be the first month of FY25, which will be
    // in 2024.
    SBT_RENEWABLE_ENERGY_INTERIM_TARGET.year + 1 - (fiscalMonth !== 1 ? 1 : 0),
    fiscalMonth
  );
  while (interimTargetYearExclusive >= input.targetYearExclusive) {
    interimTargetYearExclusive = YM.minus(
      interimTargetYearExclusive,
      1,
      'year'
    );
  }
  // We don't want to add an interim target on the base year
  if (interimTargetYearExclusive > YM.plus(input.baseYear, 1, 'year')) {
    return interimTargetYearExclusive;
  }
  return null;
}
