import { GQCurrencyCode } from '../generated/graphql';
import { getObjectKeys } from '../getObjectKeys';
import assertKeyOf from './assertKeyOf';

// Pulled from https://www.xe.com/symbols/
export const CURRENCY_MAP: {
  [key in GQCurrencyCode]: {
    name: string;
    code: GQCurrencyCode;
    symbol: string;
  };
} = {
  ALL: {
    name: 'Albania Lek',
    code: 'ALL',
    symbol: 'Lek',
  },
  AFN: {
    name: 'Afghanistan Afghani',
    code: 'AFN',
    symbol: '؋',
  },
  ARS: {
    name: 'Argentina Peso',
    code: 'ARS',
    symbol: '$',
  },
  AOA: {
    name: 'Angolan Kwanza',
    code: 'AOA',
    symbol: 'Kz',
  },
  AMD: {
    name: 'Armenian Dram',
    code: 'AMD',
    symbol: 'դր',
  },
  AWG: {
    name: 'Aruba Guilder',
    code: 'AWG',
    symbol: 'ƒ',
  },
  AUD: {
    name: 'Australia Dollar',
    code: 'AUD',
    symbol: '$',
  },
  AZN: {
    name: 'Azerbaijan Manat',
    code: 'AZN',
    symbol: '₼',
  },
  BSD: {
    name: 'Bahamas Dollar',
    code: 'BSD',
    symbol: '$',
  },
  BBD: {
    name: 'Barbados Dollar',
    code: 'BBD',
    symbol: '$',
  },
  BYN: {
    name: 'Belarus Ruble',
    code: 'BYN',
    symbol: 'Br',
  },
  BZD: {
    name: 'Belize Dollar',
    code: 'BZD',
    symbol: 'BZ$',
  },
  BHD: {
    name: 'Bahraini Dinar',
    code: 'BHD',
    symbol: '.د.ب',
  },
  BDT: {
    name: 'Bangladeshi Taka',
    code: 'BDT',
    symbol: '৳',
  },
  BMD: {
    name: 'Bermuda Dollar',
    code: 'BMD',
    symbol: '$',
  },
  BTN: {
    name: 'Bhutanese Ngultrum',
    code: 'BTN',
    symbol: 'Nu.',
  },
  BOB: {
    name: 'Bolivia Bolíviano',
    code: 'BOB',
    symbol: '$b',
  },
  BAM: {
    name: 'Bosnia and Herzegovina Convertible Mark',
    code: 'BAM',
    symbol: 'KM',
  },
  BWP: {
    name: 'Botswana Pula',
    code: 'BWP',
    symbol: 'P',
  },
  BGN: {
    name: 'Bulgaria Lev',
    code: 'BGN',
    symbol: 'лв',
  },
  BRL: {
    name: 'Brazil Real',
    code: 'BRL',
    symbol: 'R$',
  },
  BND: {
    name: 'Brunei Darussalam Dollar',
    code: 'BND',
    symbol: '$',
  },
  BIF: {
    name: 'Burundian Franc',
    code: 'BIF',
    symbol: 'FBu',
  },
  DZD: {
    name: 'Algerian Dinar',
    code: 'DZD',
    symbol: 'دج',
  },
  KMF: {
    name: 'Comorian Franc',
    code: 'KMF',
    symbol: 'CF',
  },
  CVE: {
    name: 'Cape Verde Escudo',
    code: 'CVE',
    symbol: '$',
  },
  XAF: {
    name: 'Central African CFA',
    code: 'XAF',
    symbol: 'FCFA',
  },
  KHR: {
    name: 'Cambodia Riel',
    code: 'KHR',
    symbol: '៛',
  },
  CAD: {
    name: 'Canada Dollar',
    code: 'CAD',
    symbol: '$',
  },
  KYD: {
    name: 'Cayman Islands Dollar',
    code: 'KYD',
    symbol: '$',
  },
  JOD: {
    name: 'Jordanian Dinar',
    code: 'JOD',
    symbol: 'ينار',
  },
  KES: {
    name: 'Kenyan Shilling',
    code: 'KES',
    symbol: 'KSh',
  },
  KWD: {
    name: 'Kuwaiti Dinar',
    code: 'KWD',
    symbol: 'ك',
  },
  LSL: {
    name: 'Lesotho Loti',
    code: 'LSL',
    symbol: 'L',
  },
  LYD: {
    name: 'Libyan Dinar',
    code: 'LYD',
    symbol: 'LD',
  },
  MOP: {
    name: 'Macanese Pataca',
    code: 'MOP',
    symbol: 'MOP$',
  },
  MGA: {
    name: 'Malagasy Ariary',
    code: 'MGA',
    symbol: 'Ar',
  },
  MWK: {
    name: 'Malawian Kwacha',
    code: 'MWK',
    symbol: 'K',
  },
  MVR: {
    name: 'Maldivian Rufiyaa',
    code: 'MVR',
    symbol: 'MRf',
  },
  MRU: {
    name: 'Mauritanian Ouguiya',
    code: 'MRU',
    symbol: 'UM',
  },
  MDL: {
    name: 'Moldovan Leu',
    code: 'MDL',
    symbol: 'L',
  },
  MAD: {
    name: 'Moroccan Dirham',
    code: 'MAD',
    symbol: 'DH',
  },
  MMK: {
    name: 'Kyat',
    code: 'MMK',
    symbol: 'K',
  },
  PGK: {
    name: 'Papua New Guinean Kina',
    code: 'PGK',
    symbol: 'K',
  },
  RUR: {
    name: 'Russian Ruble',
    code: 'RUR',
    symbol: '₽',
  },
  RWF: {
    name: 'Rwandan Franc',
    code: 'RWF',
    symbol: 'R₣',
  },
  WST: {
    name: 'Samoan Tala',
    code: 'WST',
    symbol: 'SAT',
  },
  STD: {
    name: 'Sao Tome and Principe Dobra',
    code: 'STD',
    symbol: 'Db',
  },
  SLL: {
    name: 'Sierra Leonean Leone',
    code: 'SLL',
    symbol: 'Le',
  },
  SSP: {
    name: 'South Sudanese Pound',
    code: 'SSP',
    symbol: 'SS£',
  },
  SDG: {
    name: 'Sudanese Pound',
    code: 'SDG',
    symbol: 'SDG',
  },
  TJS: {
    name: 'Somoni',
    code: 'TJS',
    symbol: 'TJS',
  },
  TZS: {
    name: 'Tanzanian Shilling',
    code: 'TZS',
    symbol: 'TSh',
  },
  TND: {
    name: 'Tunisian Dinar',
    code: 'TND',
    symbol: 'د.ت',
  },
  TOP: {
    name: "Tongan Pa'anga",
    code: 'TOP',
    symbol: 'T$',
  },
  TMT: {
    name: 'Turkmenistani Manat',
    code: 'TMT',
    symbol: 'T',
  },
  UGX: {
    name: 'Ugandan Shilling',
    code: 'UGX',
    symbol: 'Ush',
  },
  AED: {
    name: 'Emirati Dirham',
    code: 'AED',
    symbol: 'AED',
  },
  VUV: {
    name: 'Vanuatu Vatu',
    code: 'VUV',
    symbol: 'VT',
  },
  HTG: {
    name: 'Haitian Gourde',
    code: 'HTG',
    symbol: 'G',
  },
  CLP: {
    name: 'Chile Peso',
    code: 'CLP',
    symbol: '$',
  },
  CNY: {
    name: 'China Yuan Renminbi',
    code: 'CNY',
    symbol: '¥',
  },
  CDF: {
    name: 'Congolese Franc',
    code: 'CDF',
    symbol: 'FC',
  },
  COP: {
    name: 'Colombia Peso',
    code: 'COP',
    symbol: '$',
  },
  CRC: {
    name: 'Costa Rica Colon',
    code: 'CRC',
    symbol: '₡',
  },
  HRK: {
    name: 'Croatia Kuna',
    code: 'HRK',
    symbol: 'kn',
  },
  CUP: {
    name: 'Cuba Peso',
    code: 'CUP',
    symbol: '₱',
  },
  CZK: {
    name: 'Czech Republic Koruna',
    code: 'CZK',
    symbol: 'Kč',
  },
  DJF: {
    name: 'Djibouti Franc',
    code: 'DJF',
    symbol: 'Fdj',
  },
  DKK: {
    name: 'Denmark Krone',
    code: 'DKK',
    symbol: 'kr',
  },
  DOP: {
    name: 'Dominican Republic Peso',
    code: 'DOP',
    symbol: 'RD$',
  },
  SZL: {
    name: 'Swazi Lilangeni',
    code: 'SZL',
    symbol: 'E',
  },
  ETB: {
    name: 'Ethiopian Birr',
    code: 'ETB',
    symbol: 'ብር',
  },
  ERN: {
    name: 'Eritrean Nakfa',
    code: 'ERN',
    symbol: 'ናቕፋ',
  },
  XCD: {
    name: 'East Caribbean Dollar',
    code: 'XCD',
    symbol: '$',
  },
  EGP: {
    name: 'Egypt Pound',
    code: 'EGP',
    symbol: '£',
  },
  SVC: {
    name: 'El Salvador Colon',
    code: 'SVC',
    symbol: '$',
  },
  EUR: {
    name: 'Euro',
    code: 'EUR',
    symbol: '€',
  },
  FKP: {
    name: 'Falkland Islands (Malvinas) Pound',
    code: 'FKP',
    symbol: '£',
  },
  FJD: {
    name: 'Fiji Dollar',
    code: 'FJD',
    symbol: '$',
  },
  GHS: {
    name: 'Ghana Cedi',
    code: 'GHS',
    symbol: '¢',
  },
  GNF: {
    name: 'Guinean Franc',
    code: 'GNF',
    symbol: 'FG',
  },
  GIP: {
    name: 'Gibraltar Pound',
    code: 'GIP',
    symbol: '£',
  },
  GTQ: {
    name: 'Guatemala Quetzal',
    code: 'GTQ',
    symbol: 'Q',
  },
  GGP: {
    name: 'Guernsey Pound',
    code: 'GGP',
    symbol: '£',
  },
  GYD: {
    name: 'Guyana Dollar',
    code: 'GYD',
    symbol: '$',
  },
  HNL: {
    name: 'Honduras Lempira',
    code: 'HNL',
    symbol: 'L',
  },
  HKD: {
    name: 'Hong Kong Dollar',
    code: 'HKD',
    symbol: '$',
  },
  HUF: {
    name: 'Hungary Forint',
    code: 'HUF',
    symbol: 'Ft',
  },
  ISK: {
    name: 'Iceland Krona',
    code: 'ISK',
    symbol: 'kr',
  },
  INR: {
    name: 'India Rupee',
    code: 'INR',
    symbol: '₹',
  },
  IDR: {
    name: 'Indonesia Rupiah',
    code: 'IDR',
    symbol: 'Rp',
  },
  IRR: {
    name: 'Iran Rial',
    code: 'IRR',
    symbol: '﷼',
  },
  IMP: {
    name: 'Isle of Man Pound',
    code: 'IMP',
    symbol: '£',
  },
  ILS: {
    name: 'Israel Shekel',
    code: 'ILS',
    symbol: '₪',
  },
  JMD: {
    name: 'Jamaica Dollar',
    code: 'JMD',
    symbol: 'J$',
  },
  JPY: {
    name: 'Japan Yen',
    code: 'JPY',
    symbol: '¥',
  },
  JEP: {
    name: 'Jersey Pound',
    code: 'JEP',
    symbol: '£',
  },
  KZT: {
    name: 'Kazakhstan Tenge',
    code: 'KZT',
    symbol: 'лв',
  },
  KPW: {
    name: 'Korea (North) Won',
    code: 'KPW',
    symbol: '₩',
  },
  KRW: {
    name: 'Korea (South) Won',
    code: 'KRW',
    symbol: '₩',
  },
  KGS: {
    name: 'Kyrgyzstan Som',
    code: 'KGS',
    symbol: 'лв',
  },
  LAK: {
    name: 'Laos Kip',
    code: 'LAK',
    symbol: '₭',
  },
  LBP: {
    name: 'Lebanon Pound',
    code: 'LBP',
    symbol: '£',
  },
  LRD: {
    name: 'Liberia Dollar',
    code: 'LRD',
    symbol: '$',
  },
  MKD: {
    name: 'Macedonia Denar',
    code: 'MKD',
    symbol: 'ден',
  },
  MYR: {
    name: 'Malaysia Ringgit',
    code: 'MYR',
    symbol: 'RM',
  },
  MUR: {
    name: 'Mauritius Rupee',
    code: 'MUR',
    symbol: '₨',
  },
  MXN: {
    name: 'Mexico Peso',
    code: 'MXN',
    symbol: '$',
  },
  MNT: {
    name: 'Mongolia Tughrik',
    code: 'MNT',
    symbol: '₮',
  },
  MZN: {
    name: 'Mozambique Metical',
    code: 'MZN',
    symbol: 'MT',
  },
  NAD: {
    name: 'Namibia Dollar',
    code: 'NAD',
    symbol: '$',
  },
  NPR: {
    name: 'Nepal Rupee',
    code: 'NPR',
    symbol: '₨',
  },
  ANG: {
    name: 'Netherlands Antilles Guilder',
    code: 'ANG',
    symbol: 'ƒ',
  },
  NZD: {
    name: 'New Zealand Dollar',
    code: 'NZD',
    symbol: '$',
  },
  NIO: {
    name: 'Nicaragua Cordoba',
    code: 'NIO',
    symbol: 'C$',
  },
  NGN: {
    name: 'Nigeria Naira',
    code: 'NGN',
    symbol: '₦',
  },
  NOK: {
    name: 'Norway Krone',
    code: 'NOK',
    symbol: 'kr',
  },
  OMR: {
    name: 'Oman Rial',
    code: 'OMR',
    symbol: '﷼',
  },
  PKR: {
    name: 'Pakistan Rupee',
    code: 'PKR',
    symbol: '₨',
  },
  PAB: {
    name: 'Panama Balboa',
    code: 'PAB',
    symbol: 'B/.',
  },
  PYG: {
    name: 'Paraguay Guarani',
    code: 'PYG',
    symbol: 'Gs',
  },
  PEN: {
    name: 'Peru Sol',
    code: 'PEN',
    symbol: 'S/.',
  },
  PHP: {
    name: 'Philippines Peso',
    code: 'PHP',
    symbol: '₱',
  },
  PLN: {
    name: 'Poland Zloty',
    code: 'PLN',
    symbol: 'zł',
  },
  QAR: {
    name: 'Qatar Riyal',
    code: 'QAR',
    symbol: '﷼',
  },
  RON: {
    name: 'Romania Leu',
    code: 'RON',
    symbol: 'lei',
  },
  RUB: {
    name: 'Russia Ruble',
    code: 'RUB',
    symbol: '₽',
  },
  SHP: {
    name: 'Saint Helena Pound',
    code: 'SHP',
    symbol: '£',
  },
  SAR: {
    name: 'Saudi Arabia Riyal',
    code: 'SAR',
    symbol: '﷼',
  },
  RSD: {
    name: 'Serbia Dinar',
    code: 'RSD',
    symbol: 'Дин.',
  },
  SCR: {
    name: 'Seychelles Rupee',
    code: 'SCR',
    symbol: '₨',
  },
  SGD: {
    name: 'Singapore Dollar',
    code: 'SGD',
    symbol: '$',
  },
  SBD: {
    name: 'Solomon Islands Dollar',
    code: 'SBD',
    symbol: '$',
  },
  SOS: {
    name: 'Somalia Shilling',
    code: 'SOS',
    symbol: 'S',
  },
  ZAR: {
    name: 'South Africa Rand',
    code: 'ZAR',
    symbol: 'R',
  },
  LKR: {
    name: 'Sri Lanka Rupee',
    code: 'LKR',
    symbol: '₨',
  },
  SEK: {
    name: 'Sweden Krona',
    code: 'SEK',
    symbol: 'kr',
  },
  CHF: {
    name: 'Switzerland Franc',
    code: 'CHF',
    symbol: 'CHF',
  },
  SRD: {
    name: 'Suriname Dollar',
    code: 'SRD',
    symbol: '$',
  },
  SYP: {
    name: 'Syria Pound',
    code: 'SYP',
    symbol: '£',
  },
  TWD: {
    name: 'Taiwan New Dollar',
    code: 'TWD',
    symbol: 'NT$',
  },
  THB: {
    name: 'Thailand Baht',
    code: 'THB',
    symbol: '฿',
  },
  TTD: {
    name: 'Trinidad and Tobago Dollar',
    code: 'TTD',
    symbol: 'TT$',
  },
  TRY: {
    name: 'Turkey Lira',
    code: 'TRY',
    symbol: '₺',
  },
  TVD: {
    name: 'Tuvalu Dollar',
    code: 'TVD',
    symbol: '$',
  },
  UAH: {
    name: 'Ukraine Hryvnia',
    code: 'UAH',
    symbol: '₴',
  },
  IQD: {
    name: 'Iraqi Dinar',
    code: 'IQD',
    symbol: 'د.ع',
  },
  GBP: {
    name: 'United Kingdom Pound',
    code: 'GBP',
    symbol: '£',
  },
  USD: {
    name: 'United States Dollar',
    code: 'USD',
    symbol: '$',
  },
  UYU: {
    name: 'Uruguay Peso',
    code: 'UYU',
    symbol: '$U',
  },
  UZS: {
    name: 'Uzbekistan Som',
    code: 'UZS',
    symbol: 'лв',
  },
  VEF: {
    name: 'Venezuela Bolívar',
    code: 'VEF',
    symbol: 'Bs',
  },
  VND: {
    name: 'Viet Nam Dong',
    code: 'VND',
    symbol: '₫',
  },
  YER: {
    name: 'Yemen Rial',
    code: 'YER',
    symbol: '﷼',
  },
  XOF: {
    name: 'West African CFA',
    code: 'XOF',
    symbol: 'CFA',
  },
  XPF: {
    name: 'CFP Franc',
    code: 'XPF',
    symbol: 'F',
  },
  GMD: {
    name: 'Gambian Dalasi',
    code: 'GMD',
    symbol: 'D',
  },
  GEL: {
    name: 'Georgian Lari',
    code: 'GEL',
    symbol: '₾',
  },
  ZMW: {
    name: 'Zambian Kwacha',
    code: 'ZMW',
    symbol: 'ZK',
  },
} as const;

export type CurrencyCode = keyof typeof CURRENCY_MAP;
export type CurrencyOption = (typeof CURRENCY_MAP)[CurrencyCode];
export type CurrencySymbol = (typeof CURRENCY_MAP)[CurrencyCode]['symbol'];

export const currencies = Object.values(CURRENCY_MAP).map(({ code, name }) => ({
  id: code,
  name: `${name} (${code})`,
}));

export function currencyFromCode(
  currency?: CurrencyCode | string | null
): CurrencyOption {
  if (!currency) {
    return CURRENCY_MAP.USD;
  }
  assertIsValidCurrencyCode(currency);
  return CURRENCY_MAP[currency];
}

// This function takes in a currency code or string ('USD' or 'GBP'),
// and returns the symbol ('$' for example).
export function currencySymbolFromCode(
  currency?: CurrencyCode | string | null
): CurrencySymbol {
  if (!currency) {
    return CURRENCY_MAP.USD.symbol;
  }
  assertIsValidCurrencyCode(currency);
  return CURRENCY_MAP[currency].symbol;
}

export function optionalCurrencySymbolFromCode(
  currency?: CurrencyCode | null
): CurrencySymbol {
  if (!currency) {
    return '';
  }
  assertIsValidCurrencyCode(currency);
  return CURRENCY_MAP[currency].symbol;
}

export function isValidCurrencyCode(
  currency?: CurrencyCode | string | null
): currency is CurrencyCode {
  return !!currency && CURRENCY_MAP.hasOwnProperty(currency);
}

export function convertToCurrencyCode(
  str: string | undefined
): CurrencyCode | null {
  if (isValidCurrencyCode(str)) return str;
  return null;
}

export function assertIsValidCurrencyCode(
  currency: CurrencyCode | string
): asserts currency is keyof typeof CURRENCY_MAP {
  assertKeyOf(currency, CURRENCY_MAP);
}

export function getCurrencyForOrg({
  currency,
}: {
  currency: string | null;
}): CurrencyCode {
  const code = currency ?? 'USD';
  assertIsValidCurrencyCode(code);
  return code;
}

export function allCurrencyCodes(): Array<CurrencyCode> {
  return getObjectKeys(CURRENCY_MAP);
}
