import {
  GQSimpleTimeseriesFieldsFragment,
  GQTimeseriesFrequency,
} from '../generated/graphql';
import invariant from 'invariant';
import isEqual from 'lodash/isEqual';
import {
  FiscalYear,
  FiscalYearSequence,
  YearMonth,
  YearMonthUnit,
  YM,
  YMInterval,
} from './YearMonth';
import assertNever from './assertNever';
import { EqualityFunction, GasRef, samesies } from './samesies';
import { SimpleTimeseriesForForecasting } from '../forecast/ForecastX';
import { BadInputError } from '../errors/BadInputError';
import { AggregateMethod } from '../bi/types';
import numberIsCloseTo from './numberIsCloseTo';

export type Frequency = 'monthly' | 'yearly';

function frequencyToUnit(f: Frequency): YearMonthUnit {
  switch (f) {
    case 'monthly':
      return 'month';
    case 'yearly':
      return 'year';
    // istanbul ignore next
    default:
      assertNever(f);
  }
}

export abstract class SimpleTimeseries<F extends Frequency, T> {
  constructor(
    public base: YearMonth,
    public frequency: F,
    public values: Array<T>
  ) {}

  interval(): YMInterval {
    return new YMInterval(
      this.base,
      YM.plus(this.base, this.values.length, frequencyToUnit(this.frequency))
    );
  }

  /**
   * Returns the value at provided date if it exists. Assumes that the timeseries
   * extends infinitely in either direction, by returning whatever is at the bound if
   * the provided date is out of bounds.
   */
  valueAt(date: YearMonth): T {
    const periods = YM.diff(date, this.base, frequencyToUnit(this.frequency));
    if (date < this.base) {
      return this.values[0];
    }
    if (periods >= this.values.length) {
      return this.values[this.values.length - 1];
    }
    return this.values[periods];
  }

  /**
   * Get the YearMonth corresponding to the value at the specified index
   */
  yearMonthAt(i: number): YearMonth {
    const length = this.length;
    if (i > length) {
      throw new Error(`Invalid index ${i} for timeseries of length ${length}`);
    }
    return YM.plus(this.base, i, frequencyToUnit(this.frequency));
  }

  /**
   * Similar to valueAt, returns the value at provided date if it exists. What's different is that,
   * if the specified date falls out of bounds of the timeseries, it will return the default value.
   */
  valueOrDefaultAt<D>(date: YearMonth, def: D): T | D {
    const periods = YM.diff(date, this.base, frequencyToUnit(this.frequency));
    if (date < this.base || periods >= this.values.length) {
      return def;
    }
    return this.values[periods];
  }

  equals(other: SimpleTimeseries<F, T>): boolean {
    return (
      this.base === other.base &&
      this.frequency === other.frequency &&
      isEqual(this.values, other.values)
    );
  }

  [EqualityFunction](other: SimpleTimeseries<F, T>, gas: GasRef): boolean {
    return (
      this.base === other.base &&
      this.frequency === other.frequency &&
      samesies(this.values, other.values, gas)
    );
  }

  mapValues<V>(fn: (ym: YearMonth, t: T) => V): Array<V> {
    return this.values.map((t, i) => {
      const ym = YM.plus(this.base, i, frequencyToUnit(this.frequency));
      return fn(ym, t);
    });
  }

  *[Symbol.iterator](): Generator<[YearMonth, T], void, unknown> {
    const interval = this.interval();
    for (
      let date = this.base, i = 0;
      interval.contains(date);
      date = YM.next(date, frequencyToUnit(this.frequency)), i++
    ) {
      yield [date, this.values[i]] as [YearMonth, T];
    }
  }

  get length(): number {
    return this.values.length;
  }
}

const methods = {
  map<F extends Frequency, T, V, OutTimeseries extends SimpleTimeseries<F, V>>(
    ts: SimpleTimeseries<F, T>,
    fn: (ym: YearMonth, t: T) => V
  ): OutTimeseries {
    return new (
      ts.constructor as new (
        base: YearMonth,
        values: Array<V>
      ) => OutTimeseries
    )(
      ts.base,
      ts.values.map((t, i) => {
        const ym = YM.plus(ts.base, i, frequencyToUnit(ts.frequency));
        return fn(ym, t);
      })
    );
  },

  async mapAsync<
    F extends Frequency,
    T,
    V,
    OutTimeseries extends SimpleTimeseries<F, V>,
  >(
    ts: SimpleTimeseries<F, T>,
    fn: (ym: YearMonth, t: T) => PromiseLike<V>
  ): Promise<OutTimeseries> {
    return new (
      ts.constructor as new (
        base: YearMonth,
        values: Array<V>
      ) => OutTimeseries
    )(
      ts.base,
      await Promise.all(
        ts.values.map((t, i) => {
          const ym = YM.plus(ts.base, i, frequencyToUnit(ts.frequency));
          return fn(ym, t);
        })
      )
    );
  },

  clamp<F extends Frequency, T, TS extends SimpleTimeseries<F, T>>(
    ts: TS,
    interval: YMInterval
  ): TS {
    const clampedInterval = interval.intersect(ts.interval());
    if (clampedInterval === null) {
      return new (ts.constructor as any)(ts.base, []);
    }

    const start = YM.diff(
      clampedInterval.start,
      ts.base,
      frequencyToUnit(ts.frequency)
    );
    const end = YM.diff(
      clampedInterval.end,
      ts.base,
      frequencyToUnit(ts.frequency)
    );
    const values = ts.values.slice(start, end);
    return new (ts.constructor as any)(clampedInterval.start, values);
  },

  concat<F extends Frequency, T, TS extends SimpleTimeseries<F, T>>(
    ts: TS,
    other: TS
  ): TS {
    const end = ts.interval().end;
    const periods = YM.diff(end, other.base, frequencyToUnit(other.frequency));
    if (periods >= other.values.length) {
      return new (ts.constructor as any)(ts.base, ts.values.slice());
    } else if (periods < 0) {
      throw new BadInputError(`timeseries don't abut each other`, {
        data: { ts, other },
      });
    }
    const values = ts.values.concat(other.values.slice(periods));
    return new (ts.constructor as any)(ts.base, values);
  },

  reduce<
    F extends Frequency,
    T,
    TS extends SimpleTimeseries<F, T>,
    Aggregate = T,
  >(
    ts: TS,
    fn: (
      aggregate: Aggregate,
      currentValue: T,
      yearMonth: YearMonth
    ) => Aggregate,
    initialValue: Aggregate
  ): Aggregate {
    let aggregate = initialValue;
    for (const [ym, t] of ts) {
      aggregate = fn(aggregate, t, ym);
    }
    return aggregate;
  },

  truncate<F extends Frequency, T, TS extends SimpleTimeseries<F, T>>(
    ts: TS,
    num: number
  ): TS {
    if (num < 0) {
      throw new Error(`Number of elements to truncate must be positive ${num}`);
    }
    let newValues = ts.values;
    if (num > 0) newValues = ts.values.slice(0, -1 * num);
    return new (ts.constructor as any)(ts.base, newValues);
  },
};

export class FiscalYearlyTimeseries<T> extends SimpleTimeseries<'yearly', T> {
  constructor(base: YearMonth, values: Array<T>) {
    super(base, 'yearly', values);
  }

  static fromSimpleTimeseriesForForecasting(
    ts: SimpleTimeseriesForForecasting
  ): FiscalYearlyTimeseries<number> {
    invariant(ts.frequency === 'Yearly', 'not yearly');
    return new FiscalYearlyTimeseries(YM.fromJSDate(ts.base), ts.values);
  }

  static fromGQ(
    ts: GQSimpleTimeseriesFieldsFragment
  ): FiscalYearlyTimeseries<number> {
    invariant(ts.frequency === GQTimeseriesFrequency.Yearly, 'not yearly');
    return new FiscalYearlyTimeseries(YM.fromJSDate(ts.base), ts.values);
  }

  static fromInterval<T>(
    interval: YMInterval,
    fn: (ym: YearMonth) => T
  ): FiscalYearlyTimeseries<T> {
    const values = interval.map('year', fn);
    return new FiscalYearlyTimeseries(interval.start, values);
  }

  fiscalMonthOffset(): number {
    return YM.month(this.base);
  }

  map<V>(fn: (ym: YearMonth, t: T) => V): FiscalYearlyTimeseries<V> {
    return methods.map(this, fn);
  }

  mapAsync<V>(
    fn: (ym: YearMonth, t: T) => Promise<V>
  ): Promise<FiscalYearlyTimeseries<V>> {
    return methods.mapAsync(this, fn);
  }

  reduce<Aggregate>(
    fn: (
      aggregate: Aggregate,
      currentValue: T,
      yearMonth: YearMonth
    ) => Aggregate,
    initialValue: Aggregate
  ): Aggregate {
    return methods.reduce(this, fn, initialValue);
  }

  clamp(interval: YMInterval): this {
    return methods.clamp(this, interval);
  }

  concat(other: FiscalYearlyTimeseries<T>): FiscalYearlyTimeseries<T> {
    return methods.concat(this, other);
  }

  truncate(num: number): FiscalYearlyTimeseries<T> {
    return methods.truncate(this, num);
  }

  get finalYearStartYm(): YearMonth {
    return YM.plus(this.base, this.values.length - 1, 'year');
  }

  /**
   * Shortens or extends the timeseries
   * When shortening the timeseries, this function truncates values outside of the new interval
   * When extending the timeseries, this function fills new values with a default value
   * @newInterval a FiscalYearSequence that represents the updated interval. This type is used to
   *              enforce the yearly intervals are aligned by month.
   * @returns The timeseries with the updated interval
   */
  updateInterval(
    newInterval: FiscalYearSequence,
    defaultPrependValue: T,
    defaultAppendValue: T
  ): FiscalYearlyTimeseries<T> {
    invariant(
      YM.month(this.base) === newInterval.offsetMonth,
      `New interval month must align with the base year month: expected ${YM.month(
        this.base
      )}, found ${newInterval.offsetMonth}`
    );

    const interval = newInterval.entireIntervalInclusive();
    const numValuesToPrepend = YM.diff(this.base, interval.start, 'year');
    const numValuesToAppend = YM.diff(
      interval.end,
      this.yearMonthAt(this.length - 1),
      'year'
    );

    let newTsValues: Array<T> = this.values;
    if (numValuesToAppend < 0) {
      const numValuesToTrunc = numValuesToAppend * -1;
      newTsValues = newTsValues.slice(0, this.length - numValuesToTrunc);
    }
    if (numValuesToPrepend < 0) {
      const startIndex = numValuesToPrepend * -1;
      newTsValues = newTsValues.slice(startIndex);
    }

    if (numValuesToAppend > 0) {
      newTsValues = [
        ...newTsValues,
        ...new Array(numValuesToAppend).fill(defaultAppendValue),
      ];
    }
    if (numValuesToPrepend > 0) {
      newTsValues = [
        ...new Array(numValuesToPrepend).fill(defaultPrependValue),
        ...newTsValues,
      ];
    }

    return new FiscalYearlyTimeseries<T>(interval.start, newTsValues);
  }
}

// NOT INTUITIVE
// This extends FiscalYearlyTimeseries because YearlyTimeseries requires the
// year start in January, vs. FiscalYearlyTimeseries is general.
export class YearlyTimeseries<T> extends FiscalYearlyTimeseries<T> {
  constructor(base: YearMonth, values: Array<T>) {
    invariant(YM.month(base) === 1, 'not year aligned');
    super(base, values);
  }

  static fromFiscalYearlyTimeseries<T>(
    fiscalYearlyTimeseries: FiscalYearlyTimeseries<T>
  ): YearlyTimeseries<T> {
    invariant(YM.month(fiscalYearlyTimeseries.base) === 1, 'not year aligned');
    return new YearlyTimeseries(
      fiscalYearlyTimeseries.base,
      fiscalYearlyTimeseries.values
    );
  }

  static fromGQ(
    ts: GQSimpleTimeseriesFieldsFragment
  ): YearlyTimeseries<number> {
    invariant(YM.month(YM.fromJSDate(ts.base)) === 1, 'not year aligned');
    const fiscalYearlyTimeseries = FiscalYearlyTimeseries.fromGQ(ts);
    return YearlyTimeseries.fromFiscalYearlyTimeseries(fiscalYearlyTimeseries);
  }

  static fromInterval<T>(
    interval: YMInterval,
    fn: (ym: YearMonth) => T
  ): YearlyTimeseries<T> {
    invariant(
      interval.start === YM.yearFloor(interval.start),
      'interval not year aligned'
    );
    return YearlyTimeseries.fromFiscalYearlyTimeseries(
      FiscalYearlyTimeseries.fromInterval(interval, fn)
    );
  }

  // is this even used?
  concat(other: YearlyTimeseries<T>): YearlyTimeseries<T> {
    return methods.concat(this, other);
  }
}

export class MonthlyTimeseries<T> extends SimpleTimeseries<'monthly', T> {
  static fromInterval<T>(
    interval: YMInterval,
    fn: (ym: YearMonth) => T
  ): MonthlyTimeseries<T> {
    const values = interval.map('month', fn);
    return new MonthlyTimeseries(interval.start, values);
  }

  constructor(base: YearMonth, values: Array<T>) {
    super(base, 'monthly', values);
  }

  map<V>(fn: (ym: YearMonth, t: T) => V): MonthlyTimeseries<V> {
    return methods.map(this, fn);
  }

  mapAsync<V>(
    fn: (ym: YearMonth, t: T) => Promise<V>
  ): Promise<MonthlyTimeseries<V>> {
    return methods.mapAsync(this, fn);
  }

  reduce<Aggregate>(
    fn: (
      aggregate: Aggregate,
      currentValue: T,
      yearMonth: YearMonth
    ) => Aggregate,
    initialValue: Aggregate
  ): Aggregate {
    return methods.reduce(this, fn, initialValue);
  }

  clamp(interval: YMInterval): this {
    return methods.clamp(this, interval);
  }

  concat(other: MonthlyTimeseries<T>): MonthlyTimeseries<T> {
    return methods.concat(this, other);
  }

  truncate(num: number): MonthlyTimeseries<T> {
    return methods.truncate(this, num);
  }
}

export class FiscalYearlyPercentageTimeseries extends FiscalYearlyTimeseries<number> {
  static fromGQ(
    ts: GQSimpleTimeseriesFieldsFragment
  ): FiscalYearlyPercentageTimeseries {
    invariant(ts.frequency === GQTimeseriesFrequency.Yearly, 'not yearly');
    return new FiscalYearlyPercentageTimeseries(
      YM.fromJSDate(ts.base),
      ts.values
    );
  }

  static fromNonUTCTimeZone(
    ts: GQSimpleTimeseriesFieldsFragment
  ): FiscalYearlyPercentageTimeseries {
    invariant(ts.frequency === GQTimeseriesFrequency.Yearly, 'not yearly');
    return new FiscalYearlyPercentageTimeseries(
      YM.fromISO(ts.base.toISOString()),
      ts.values
    );
  }

  static fromSimpleTimeseriesForForecasting(
    ts: SimpleTimeseriesForForecasting
  ): FiscalYearlyPercentageTimeseries {
    invariant(ts.frequency === 'Yearly', 'not yearly');
    return new FiscalYearlyPercentageTimeseries(
      YM.fromJSDate(ts.base),
      ts.values
    );
  }

  percentageAt(date: YearMonth): number {
    return this.valueAt(date);
  }

  multiplierAt(date: YearMonth): number {
    return 1 + this.valueAt(date) / 100;
  }
}

export class YearlyPercentageTimeseries extends YearlyTimeseries<number> {
  static fromGQ(
    ts: GQSimpleTimeseriesFieldsFragment
  ): YearlyPercentageTimeseries {
    invariant(ts.frequency === GQTimeseriesFrequency.Yearly, 'not yearly');
    return new YearlyPercentageTimeseries(YM.fromJSDate(ts.base), ts.values);
  }

  percentageAt(date: YearMonth): number {
    return this.valueAt(date);
  }

  multiplierAt(date: YearMonth): number {
    return 1 + this.valueAt(date) / 100;
  }
}

/**
 * Used when you have a fiscal year sequence, and you want to
 * get from 5% to 58% in X years (for example).
 */
export function straightlineByStartAndEnd(
  fiscalYearSequence: FiscalYearSequence,
  start: number,
  end: number
): FiscalYearlyTimeseries<number> {
  const periods = fiscalYearSequence.length;
  invariant(periods >= 2, 'not enough periods to straightline');
  const values = getStraightlineValues(start, end, periods);
  return new FiscalYearlyTimeseries(
    fiscalYearSequence.entireInterval().start,
    values
  );
}

/**
 *
 * The interval that should be straightlined across. note that
 * if you pass in a non-year aligned interval (202003 - 202405), it will be shrunk
 * to the closest year interval (202101 - 202401)
 */
export function straightlineYearly(
  interval: YMInterval,
  start: number,
  end: number
): FiscalYearlyTimeseries<number> {
  // Exclusive end interval.
  const effectiveInterval = interval.shrinkToYear();
  return straightlineByStartAndEnd(
    new FiscalYearSequence(effectiveInterval.start, effectiveInterval.end),
    start,
    end
  );
}

export function yearOverYearByAnnualRateOfReduction(
  fiscalYearSequence: FiscalYearSequence,
  start: number,
  annualPercentReduction: number // would be 7 if YoY reduction is 7%
): FiscalYearlyTimeseries<number> {
  invariant(
    fiscalYearSequence.length >= 2,
    'not enough periods to do a year over year sequence'
  );
  const targetEndValue = Math.pow(
    (100 - annualPercentReduction) / 100,
    fiscalYearSequence.length - 1
  );
  return new FiscalYearlyTimeseries(
    fiscalYearSequence.entireInterval().start,
    getCompoundedValues(
      start,
      start * targetEndValue,
      fiscalYearSequence.length
    )
  );
}

/**
 * Similar to straightlineYearly, but for compounding/year-over-year reductions.
 * The interval that should be reduced across. note that
 * if you pass in a non-year aligned interval (202003 - 202405), it will be shrunk
 * to the closest year interval (202101 - 202401)
 */
export function yearOverYear(
  interval: YMInterval,
  start: number,
  annualRateOfReduction: number
): YearlyTimeseries<number> {
  // Exclusive end interval.
  const effectiveInterval = interval.shrinkToYear();
  const fiscalYearlyTimeseries = yearOverYearByAnnualRateOfReduction(
    new FiscalYearSequence(effectiveInterval.start, effectiveInterval.end),
    start,
    annualRateOfReduction
  );
  return YearlyTimeseries.fromFiscalYearlyTimeseries(fiscalYearlyTimeseries);
}

export function getFinalValueOfTimeseries(
  ts: YearlyTimeseries<number>
): number {
  return ts.valueAt(inferStraightlineEnd(ts));
}

export function truncateTimeseriesToStraightlineEnd(
  ts: YearlyTimeseries<number>
): YearlyTimeseries<number> {
  const straightlineEnd = inferStraightlineEnd(ts);
  return YearlyTimeseries.fromSimpleTimeseriesForForecasting({
    frequency: 'Yearly',
    base: YM.toJSDate(ts.base),
    values: ts.values.slice(0, YM.diff(straightlineEnd, ts.base, 'year') + 1),
  });
}

/**
 * Sometimes we pad the end our timeseries values with non-trivial values
 *
 * E.g. let's say we have a target timeseries of base 01-2020 and
 * non-trivial values [100, 70, 40]. In the db, these timeseries values
 * may be extended to reach a fixed length, e.g. [100, 70, 40, 40, 40]
 *
 * In this case, calculating the end from the values length isn't reliable.
 * Instead, we know the timeseries values are either constant or monotonous;
 * we can infer the timeseries end by finding the YM corresponding to the
 * first occurrence of the last value in the timeseries.
 *
 * NOTE: This returns an inclusive end
 */
export function inferStraightlineEnd(ts: YearlyTimeseries<number>): YearMonth {
  const lastValue = ts.values[ts.values.length - 1];
  for (const [ym, val] of ts) {
    // Use numberIsCloseTo to handle floating point comparisons
    if (numberIsCloseTo(val, lastValue)) {
      return ym;
    }
  }
  // Can't get here, since the last value of the timeseries must be equal to
  // itself
  throw new Error('no end?');
}

/**
 * The same as inferStraightlineEnd, but returns the default value if the
 * gradient of the straight line is zero.
 */
export function inferStraightlineEndOrDefault(
  ts: YearlyTimeseries<number>,
  defaultValue: YearMonth
): YearMonth {
  // Use numberIsCloseTo to handle floating point comparisons
  if (numberIsCloseTo(ts.values[0], ts.values[ts.values.length - 1])) {
    return defaultValue;
  }
  return inferStraightlineEnd(ts);
}

/**
 *
 * @param timeseries
 * a monthly timeseries that will be converted to a yearly timeseries that starts w
 * January of the first year (even if the monthly ts starts in another month)
 * @param opts.alignToFiscalMonth
 * let's say 201901-202105 is passed in with a fiscal month of 3. the returned yearly timeseries would
 * have a base of 201803, and each entry would represent (end inclusive)
 * 201803-201902, 201903-202002, 202003-202102, 202103-202202
 */
export function monthlyToYearly(
  timeseries: MonthlyTimeseries<number>,
  opts?: {
    alignToFiscalMonth?: number;
    aggregateType?: AggregateMethod;
  }
): FiscalYearlyTimeseries<number> {
  let base;
  // this is when we have a timeseries that is offset from january, but we want it to be
  // in reference to the end of the timeseries
  if (opts?.alignToFiscalMonth) {
    const desiredMonth = opts?.alignToFiscalMonth;
    base = FiscalYear.findStartingFiscalYearMonthForYearMonth(
      timeseries.base,
      desiredMonth
    );
  } else {
    base = YM.yearFloor(timeseries.base);
  }
  const values = new Array<number>();

  let currentYearStart = base;
  let currentYearEnd = YM.plus(base, 12, 'month');
  let currentValue = 0;
  let currentMonthCount = 0;

  for (const [ym, value] of timeseries) {
    if (ym === currentYearEnd) {
      currentYearStart = ym;
      currentYearEnd = YM.plus(currentYearStart, 12, 'month');
      switch (opts?.aggregateType ?? 'sum') {
        case 'avg':
          values.push(currentValue / currentMonthCount);
          break;
        default:
          values.push(currentValue);
          break;
      }
      currentValue = 0;
      currentMonthCount = 0;
    }
    currentValue += value;
    currentMonthCount += 1;
  }
  switch (opts?.aggregateType ?? 'sum') {
    case 'avg':
      values.push(currentValue / currentMonthCount);
      break;
    default:
      values.push(currentValue);
      break;
  }

  return new FiscalYearlyTimeseries(base, values);
}

export function sumTimeseries<F extends Frequency>(
  ts: SimpleTimeseries<F, number>
): number {
  let sum = 0;
  for (const [, value] of ts) {
    sum += value;
  }
  return sum;
}

export function sumMonthlyMulti(
  timeseries: Array<MonthlyTimeseries<number>>,
  interval: YMInterval
): Array<number> {
  const values = interval.map('month', (ym) => {
    let sum = 0;
    timeseries.forEach((ts) => {
      sum += ts.valueOrDefaultAt(ym, 0);
    });
    return sum;
  });
  return values;
}

export function sumYearlyMulti(
  timeseries: Array<YearlyTimeseries<number>>,
  interval: YMInterval
): Array<number> {
  const values = interval.map('year', (ym) => {
    let sum = 0;
    timeseries.forEach((ts) => {
      sum += ts.valueOrDefaultAt(ym, 0);
    });
    return sum;
  });
  return values;
}

// Like sumYearlyMulti, but persists the last value of each timeseries
export function sumYearlyMultiPersistent(
  timeseries: Array<YearlyTimeseries<number>>,
  interval: YMInterval
): Array<number> {
  const values = interval.map('year', (ym) => {
    let sum = 0;
    timeseries.forEach((ts) => {
      // Do nothing if ym is before TS starts
      if (ym >= ts.base) {
        sum += ts.valueAt(ym);
      }
    });
    return sum;
  });
  return values;
}

export function multiplyYearlyMulti(
  timeseries: Array<YearlyTimeseries<number>>,
  interval: YMInterval
): Array<number> {
  const values = interval.map('year', (ym) => {
    let product = 1;
    timeseries.forEach((ts) => {
      product *= ts.valueOrDefaultAt(ym, 0);
    });
    return product;
  });
  return values;
}

// TODO(ishaan): The below two functions are REDUX specific and should
// most likely be moved over to the PlanTargetUtils
// This assumes that the start number is 100
export function annualToTotalYoY(
  annualReduction: number,
  numYears: number
): number {
  return 100 - Math.pow(1 - annualReduction / 100, numYears - 1) * 100;
}

// Solve for x in the equation:
// start * (1 - x/100) ^ (numYears - 1) = end
// The above applies the reduction rate x (number between 0 and 100) numYears - 1 times
// to start to get to end.
export function annualYoyForStartEndAndNumYears(
  start: number,
  end: number,
  numYears: number
): number {
  invariant(start >= end, 'start must be greater than or equal to end');
  return 100 - Math.pow(end / start, 1 / (numYears - 1)) * 100;
}

export function totalToAnnualYoY(
  totalReduction: number,
  numYears: number // inclusive of start and end
): number {
  return annualYoyForStartEndAndNumYears(100, 100 - totalReduction, numYears);
}

/**
 * Given a monthly timeseries, return a yearly timeseries from startYearMonth to
 * endYearMonth where each year is the average of the values from the monthly
 * timeseries. If the start or end year are outside of the monthly timeseries,
 * the first or last values are used.
 */
export function annualAverage(
  monthly: MonthlyTimeseries<number>,
  startYearMonth: YearMonth,
  endYearMonth: YearMonth
): FiscalYearlyTimeseries<number> {
  const values = new Array<number>();
  let currentYearStart = startYearMonth;
  let currentYearEnd = YM.plus(startYearMonth, 12, 'month');
  let currentSum = 0;
  for (
    let ym = startYearMonth;
    ym <= endYearMonth;
    ym = YM.plus(ym, 1, 'month')
  ) {
    if (ym === currentYearEnd || ym === endYearMonth) {
      values.push(currentSum / YM.diff(ym, currentYearStart, 'month'));
      currentYearStart = ym;
      currentYearEnd = YM.plus(currentYearStart, 12, 'month');
      currentSum = 0;
    }
    currentSum += monthly.valueAt(ym);
  }
  return new FiscalYearlyTimeseries(startYearMonth, values);
}

/**
 * Base helpers to grab an array of numbers based on start and end.
 */

// Given a start, end, and length, returns an array of values that will
// linearly decrease from start to end over the length of the array.
export function getStraightlineValues(
  start: number,
  end: number,
  length: number
): Array<number> {
  invariant(length >= 2, 'not enough periods to straightline');
  return Array.from({ length }).map((_, i) => {
    return start + ((end - start) * i) / (length - 1);
  });
}

// Given a start, end, and length, returns an array of values that will
// decrease by a constant percentage from start to end over the length of the
// array.
export function getCompoundedValues(
  start: number,
  end: number,
  length: number
): Array<number> {
  invariant(length >= 2, 'not enough periods to year over year');
  // If you are looking to get to the end value of 0, that's technically impossible with
  // a yoy target because you can't divide by 0. So we'll just set the end to 1.
  const trueEnd = end === 0 ? 1 : end;
  const annualYoy = Math.pow(trueEnd / start, 1 / (length - 1));
  return Array.from({ length }).map((_, i) => start * Math.pow(annualYoy, i));
}
