import { Stack, TextField, TextFieldProps } from '@mui/material';
import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';

// eslint-disable-next-line no-restricted-imports
import {
  DateRangePicker as MuiDateRangePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import CalendarIcon from '@watershed/icons/components/Calendar';
import ChevronDownIcon from '@watershed/icons/components/ChevronDown';
import ChevronLeftIcon from '@watershed/icons/components/ChevronLeft';
import ChevronRightIcon from '@watershed/icons/components/ChevronRight';
import isNullish from '@watershed/shared-universal/utils/isNullish';
import { DateTime } from 'luxon';
import { DATE_FORMAT_MDY_TEXT } from '@watershed/shared-universal/constants';

import useLocale from '@watershed/intl/frontend/useLocale';

const getPropsForInputPair = (props: TextFieldProps): TextFieldProps => ({
  ...props,
  label: null,
  variant: 'outlined',
  // a bunch of styling, since the defaults don't match our norms
  sx: {
    '& .MuiOutlinedInput-root': {
      cursor: 'pointer',
      boxShadow: (theme) => `inset 0 0 0 1px ${theme.palette.grey20}`,
      paddingInline: 1,
      height: '32px',
      minWidth: '160px',
    },
  },
  InputProps: {
    ...props.InputProps,
    startAdornment: <CalendarIcon marginRight={1} />,
    endAdornment: <ChevronDownIcon />,
  },
  inputProps: {
    ...props.inputProps,
    placeholder: t`Choose date`,
  },
});

const getPropsForSingleInput = (
  startProps: TextFieldProps,
  endProps: TextFieldProps
): TextFieldProps => {
  let inputValue = null;
  if (
    !isNullish(startProps.inputProps?.value) &&
    startProps.inputProps?.value.length > 0 &&
    !isNullish(endProps.inputProps?.value) &&
    endProps.inputProps?.value.length > 0
  ) {
    const startDateRaw = startProps.inputProps?.value.split('/');
    const startDateString = [startDateRaw[0], startDateRaw[2]].join('/');
    const endDateRaw = endProps.inputProps?.value.split('/');
    const endDateString = [endDateRaw[0], endDateRaw[2]].join('/');
    inputValue = t`${startDateString} through ${endDateString}`;
  }

  const props = getPropsForInputPair(startProps);
  props.inputProps = {
    ...props.inputProps,
    value: inputValue,
  };
  return props;
};

export default function DateRangePicker({
  startDate,
  endDate,
  onChange,
  singleInput = false,
  disabled,
  format = DATE_FORMAT_MDY_TEXT,
}: {
  startDate: DateTime | null;
  endDate: DateTime | null;
  onChange: (startDate: DateTime | null, endDate: DateTime | null) => void;
  singleInput?: boolean;
  disabled?: boolean;
  format?: string;
}) {
  const locale = useLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
      <MuiDateRangePicker<DateTime>
        value={[startDate, endDate]}
        onChange={([start, end]) => onChange(start, end)}
        disableMaskedInput={true}
        disabled={disabled}
        inputFormat={format}
        renderInput={(startProps, endProps) => {
          if (singleInput) {
            return (
              <Stack direction="row" alignItems="center" gap={1}>
                <TextField {...getPropsForSingleInput(startProps, endProps)} />
              </Stack>
            );
          }

          const startDateInput = (
            <TextField {...getPropsForInputPair(startProps)} />
          );
          const endDateInput = (
            <TextField {...getPropsForInputPair(endProps)} />
          );

          return (
            <Stack direction="row" alignItems="center" gap={1}>
              <Trans>
                {startDateInput} through {endDateInput}
              </Trans>
            </Stack>
          );
        }}
        components={{
          LeftArrowIcon: ChevronLeftIcon,
          RightArrowIcon: ChevronRightIcon,
          SwitchViewIcon: ChevronDownIcon,
        }}
      />
    </LocalizationProvider>
  );
}
