import {
  ColumnValueFormatter,
  getFormatterForField,
} from './SupplierColumnFormatters';
import { Supplier, SupplierTableRow } from './supplierTypes';
import { parseSupplierFilterDimension } from './SupplierFilterUtils';

export const SUPPLIER_DEFAULT_FIELD_ORDER = [
  'beaCodes',
  'supplierName',
  'priority',
  'emissionsRank',
  'targetsAndCommitments',
  'initiatives',
  'footprints',
  'totalEmissions',
  'percentEmissions',
  'emissiveSpend',
  'spend',
  'percentSpend',
  'surveyState',
  'engagementTasks',
  'topCategorizedEmission',
  'disclosures',
  'netZero',
  'carbonNeutral',
  'cleanEnergy',
  'sbtCommitmentStage',
  'engagementCohorts',
  'notes',
  'contacts',
  'latestDisclosureDate',
  'latestCdpDisclosurePublishingYear',
  'surveyPortalUrl',
  'ghgCategoryIds',
  'emissionsFactors',
  'targets',
  // Always keeps supplierDetailsUrl last.
  'supplierDetailsUrl',
] as const;

export type SupplierColumnName = (typeof SUPPLIER_DEFAULT_FIELD_ORDER)[number];

export type SupplierDefaultField =
  (typeof SUPPLIER_DEFAULT_FIELD_ORDER)[number];

type SupplierDefaultColumnSchema = {
  field: SupplierDefaultField;
  headerName: string;
  defaultVisible: boolean;
  defaultWidth: number;
  getFormattedValue: ColumnValueFormatter<Supplier | SupplierTableRow>;
};

export const SUPPLIER_DEFAULT_COLUMN_SCHEMAS: {
  [key in SupplierDefaultField]: SupplierDefaultColumnSchema;
} = {
  beaCodes: {
    field: 'beaCodes',
    headerName: 'Industries',
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('beaCodes'),
  },
  supplierName: {
    field: 'supplierName',
    headerName: 'Supplier',
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('supplierName'),
  },
  priority: {
    field: 'priority',
    headerName: 'Priority',
    defaultVisible: true,
    defaultWidth: 120,
    getFormattedValue: getFormatterForField('priority'),
  },
  emissionsRank: {
    field: 'emissionsRank',
    headerName: 'Rank by emissions',
    defaultVisible: true,
    defaultWidth: 90,
    getFormattedValue: getFormatterForField('emissionsRank'),
  },
  totalEmissions: {
    field: 'totalEmissions',
    headerName: 'Emissions',
    defaultVisible: true,
    defaultWidth: 100,
    getFormattedValue: getFormatterForField('totalEmissions'),
  },
  spend: {
    field: 'spend',
    headerName: 'Spend',
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('spend'),
  },
  percentSpend: {
    field: 'percentSpend',
    headerName: '% of your spend',
    defaultVisible: false,
    defaultWidth: 100,
    getFormattedValue: getFormatterForField('percentSpend'),
  },
  percentEmissions: {
    field: 'percentEmissions',
    headerName: '% of your emissions',
    defaultVisible: true,
    defaultWidth: 100,
    getFormattedValue: getFormatterForField('percentEmissions'),
  },
  topCategorizedEmission: {
    field: 'topCategorizedEmission',
    headerName: 'Top emissions category',
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('topCategorizedEmission'),
  },
  disclosures: {
    field: 'disclosures',
    headerName: 'Disclosures',
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('disclosures'),
  },
  netZero: {
    field: 'netZero',
    headerName: 'Net zero',
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('netZero'),
  },
  carbonNeutral: {
    field: 'carbonNeutral',
    headerName: 'Carbon neutral',
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('carbonNeutral'),
  },
  cleanEnergy: {
    field: 'cleanEnergy',
    headerName: 'Clean energy',
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('cleanEnergy'),
  },
  sbtCommitmentStage: {
    field: 'sbtCommitmentStage',
    headerName: 'SBTi status',
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('sbtCommitmentStage'),
  },
  notes: {
    field: 'notes',
    headerName: 'Notes',
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('notes'),
  },
  engagementCohorts: {
    field: 'engagementCohorts',
    headerName: 'Cohorts',
    defaultVisible: true,
    defaultWidth: 130,
    getFormattedValue: getFormatterForField('engagementCohorts'),
  },
  contacts: {
    field: 'contacts',
    headerName: 'Contacts',
    defaultVisible: false,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('contacts'),
  },
  latestDisclosureDate: {
    field: 'latestDisclosureDate',
    headerName: 'Latest disclosure date',
    defaultVisible: true,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField('latestDisclosureDate'),
  },
  latestCdpDisclosurePublishingYear: {
    field: 'latestCdpDisclosurePublishingYear',
    headerName: 'Latest CDP publishing year',
    defaultVisible: false,
    defaultWidth: 150,
    getFormattedValue: getFormatterForField(
      'latestCdpDisclosurePublishingYear'
    ),
  },
  surveyState: {
    field: 'surveyState',
    headerName: 'Status',
    defaultVisible: false,
    defaultWidth: 175,
    getFormattedValue: getFormatterForField('surveyState'),
  },
  engagementTasks: {
    field: 'engagementTasks',
    headerName: 'Tasks',
    defaultVisible: false,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('engagementTasks'),
  },
  surveyPortalUrl: {
    field: 'surveyPortalUrl',
    headerName: 'Survey portal',
    defaultVisible: false,
    defaultWidth: 130,
    getFormattedValue: getFormatterForField('surveyPortalUrl'),
  },
  ghgCategoryIds: {
    field: 'ghgCategoryIds',
    headerName: 'GHG categories',
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('ghgCategoryIds'),
  },
  targets: {
    field: 'targets',
    headerName: 'Targets',
    defaultVisible: true,
    defaultWidth: 250,
    getFormattedValue: getFormatterForField('targets'),
  },
  targetsAndCommitments: {
    field: 'targetsAndCommitments',
    headerName: 'Targets',
    defaultVisible: true,
    defaultWidth: 250,
    getFormattedValue: getFormatterForField('targetsAndCommitments'),
  },
  supplierDetailsUrl: {
    field: 'supplierDetailsUrl',
    headerName: 'View details',
    defaultVisible: true,
    defaultWidth: 130,
    getFormattedValue: getFormatterForField('supplierDetailsUrl'),
  },
  emissionsFactors: {
    field: 'emissionsFactors',
    headerName: 'Emissions factors',
    defaultVisible: true,
    defaultWidth: 130,
    getFormattedValue: getFormatterForField('emissionsFactors'),
  },
  emissiveSpend: {
    field: 'emissiveSpend',
    headerName: 'Emissive spend',
    defaultVisible: true,
    defaultWidth: 200,
    getFormattedValue: getFormatterForField('emissiveSpend'),
  },
  initiatives: {
    field: 'initiatives',
    headerName: 'Initiatives',
    defaultVisible: true,
    defaultWidth: 250,
    getFormattedValue: getFormatterForField('initiatives'),
  },
  footprints: {
    field: 'footprints',
    headerName: 'Reported footprints',
    defaultVisible: true,
    defaultWidth: 250,
    getFormattedValue: getFormatterForField('footprints'),
  },
};

export function isDefaultField(field: string): field is SupplierDefaultField {
  const { dimension } = parseSupplierFilterDimension(field);
  return dimension in SUPPLIER_DEFAULT_COLUMN_SCHEMAS;
}
