import { Box, Chip, Checkbox } from '@mui/material';
import { Trans } from '@lingui/react/macro';
import { GQFlags } from '@watershed/shared-universal/generated/graphql';
import { useFeatureFlagContext } from '../utils/FeatureFlag';
import { Dialog } from '@watershed/ui-core/components/Dialog';
import ErrorBox from '@watershed/ui-core/components/ErrorBox';

export default function FeatureFlagOverrideDialog({
  onClose,
}: {
  onClose: () => void;
}) {
  const { flags, flagOverrides, setOverride, originalFlags } =
    useFeatureFlagContext();
  return (
    <Dialog
      onClose={onClose}
      header={{
        title: 'Feature flag overrides',
        subtitle: (
          <Box>
            <Trans>
              Override a feature flag for to preview how a new feature would
              appear to this org. Overrides expire at the end of your browser
              session.
            </Trans>
            <ErrorBox level="warning" variant="text" sx={{ marginTop: 2 }}>
              <Trans context="feature flags means we can turn a feature on or off">
                This only affects client-side flag checks!
              </Trans>
            </ErrorBox>
          </Box>
        ),
      }}
    >
      <div>
        {Object.values(GQFlags).map((flag) => {
          // Overrides get cleared out from flagOverrides once they're returned
          // to the real flag state. But it's still possible you'd end up with
          // an "override" that is the same as the actual flag value, if the real
          // flag value changes after an override is applied, and the page reloads.
          // In that case the override has no effect.
          const usingRealFlagValue =
            !flagOverrides.has(flag) ||
            !!originalFlags.get(flag) === flagOverrides.get(flag);
          return (
            <Box key={flag}>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={!!flags.get(flag)}
                  onChange={(event, checked) => {
                    setOverride(flag, checked);
                  }}
                />
                {flag}
                {!usingRealFlagValue && (
                  // TODO: i18n (please resolve or remove this TODO line if legit)
                  // eslint-disable-next-line @watershed/literals-must-be-i18n-ready
                  <Chip sx={{ marginLeft: 1 }} label="Override" color="error" />
                )}
              </label>
            </Box>
          );
        })}
      </div>
    </Dialog>
  );
}
