import invariant from 'invariant';
import {
  GQPlanTargetIntensityType,
  GQPlanTargetTargetComparisonType,
  GQSbtScope1And2Target,
  GQSbtScope3Target,
  GQSbtTermLength,
} from '../generated/graphql';
import {
  getSBTCategoryForTarget,
  PlanTargetSBTCategory,
  SBTAchievement,
} from '../reductions/ReductionsUtils';
import {
  inferTimeseriesInfo,
  getPlanTargetForForecastingReductionPct,
  isIntensityTypeSupplierEngagement,
} from '../utils/PlanTargetUtils';
import {
  FiscalYearlyTimeseries,
  FiscalYearlyPercentageTimeseries,
  inferStraightlineEnd,
  truncateTimeseriesToStraightlineEnd,
} from '../utils/SimpleTimeseries';
import { FiscalYear, YM, YMInterval, YearMonth } from '../utils/YearMonth';
import assertNever from '../utils/assertNever';
import { PlanTargetForForecasting } from './ReductionsForecastX';
import { EqualityFunction } from '../utils/samesies';
import {
  ActualAndNecessaryReduction,
  ReduxTargetChartSeriesDatum,
  SbtTargetValidity,
  TargetValidity,
} from './types';
import must from '../utils/must';
import {
  LONG_TERM_ABSOLUTE_REQUIRED_REDUCTION_PCT,
  LONG_TERM_INTENSITY_REQUIRED_REDUCTION_PCT,
  NEAR_TERM_1_POINT_5_SCOPE_3_LINEAR_ANNUAL_REDUCTION,
  NEAR_TERM_SCOPE_1_2_LINEAR_ANNUAL_REDUCTION,
  NEAR_TERM_SCOPE_3_REQUIRED_COVERAGE_FRACTION,
  NEAR_TERM_WELL_BELOW_2_SCOPE_3_LINEAR_ANNUAL_REDUCTION,
  NEAR_TERM_YOY_REDUCTION_PCT,
  PRECISION_THESHOLD,
  REQUIRED_SCOPE_3_EMISSIONS_PCT_FOR_SBT_TARGETS,
  SBTI_FORWARD_LOOKING_AMBITION_START_YEAR,
  SBTI_FORWARD_LOOKING_AMBITION_START_YEAR_MONTH,
  SBT_RENEWABLE_ENERGY_INTERIM_TARGET,
  SBT_RENEWABLE_ENERGY_TARGET,
} from '../reductions/constants';
import { formatPercentageNonzero } from '../utils/helpers';
import sumBy from 'lodash/sumBy';
import {
  reductionFilterMatches,
  filterExpressionGroupToReductionFilter,
} from '../reductions/ReductionFilter';
import { SummarizedRow } from './SummarizedFootprint';
import { PlanVariables } from '../utils/PlanUtils';
import isNotNullish from '../utils/isNotNullish';
import { GFI, GrowthFactorIdentifier } from './GrowthFactorIdentifier';
import { GrowthForecastConfig } from './GrowthForecastConfig';
import { BadInputError } from '../errors/BadInputError';

export type Sbt3AchievementIntermediateValues =
  | {
      supplierSum: number;
      nonSupplierTargetSum: number;
      totalScope3Emissions: number;
      isApplicable: true;
      supplierTargetToEmissionsDetails: {
        [targetId: string]: {
          baselineYearEmissions: number;
          targetYearEmissions: number;
        };
      };
      hasSupplierEngagementBySpendTarget: boolean;
    }
  | {
      isApplicable: false;
    };
export default class SBTCalculator {
  public readonly termLength: GQSbtTermLength;
  public readonly submissionYm?: YearMonth;
  public readonly scope12Target?: GQSbtScope1And2Target;
  public readonly scope3Target?: GQSbtScope3Target;
  public readonly fiscalMonth?: number;

  constructor({
    termLength,
    submissionYm,
    scope12Target,
    scope3Target,
    fiscalMonth,
  }: {
    termLength: GQSbtTermLength;
    submissionYm?: YearMonth;
    scope12Target?: GQSbtScope1And2Target;
    scope3Target?: GQSbtScope3Target;
    fiscalMonth?: number;
  }) {
    this.termLength = termLength;
    this.submissionYm = submissionYm;
    this.scope12Target = scope12Target;
    this.scope3Target = scope3Target;
    this.fiscalMonth = fiscalMonth;
  }

  [EqualityFunction](other: SBTCalculator): boolean {
    return (
      this.termLength === other.termLength &&
      this.submissionYm === other.submissionYm &&
      this.scope12Target === other.scope12Target &&
      this.scope3Target === other.scope3Target &&
      this.fiscalMonth === other.fiscalMonth
    );
  }

  public static generateInvalidResponse<
    T extends Pick<
      PlanTargetForForecasting,
      'baseYear' | 'emissionsTargetCustom' | 'intensityType' | 'comparisonType'
    >,
  >({
    target,
    reason,
    reduction,
  }: Omit<SbtTargetValidity<T>, 'validity' | 'type'>): SbtTargetValidity<T> {
    const sbtValidityType = ((): SbtTargetValidity<T>['type'] => {
      switch (target.intensityType) {
        case 'Absolute':
        case 'GrossProfit':
        case 'Headcount':
        case 'Revenue':
        case 'NightsStayed':
        case 'Orders':
        case 'Patients':
        case 'Custom':
          return 'emissions';
        case 'RenewableElectricity':
          return 'renewableElectricity';
        case 'SupplierEngagement':
        case 'SupplierEngagementBySpend':
          return 'supplierEngagement';
        default:
          assertNever(target.intensityType);
      }
    })();
    return {
      target,
      validity: TargetValidity.Invalid,
      reason,
      reduction,
      type: sbtValidityType,
    };
  }

  /**
   * This getter and the one below are quick sanity checks to make sure that the term length and
   * ambition are compatible with each other.
   */
  public get sbtScope12TermLengthAndAmbitionCompatibility(): null | SBTAchievement<GQSbtScope1And2Target> {
    invariant(this.scope12Target, 'scope 1 and 2 target must be defined');
    const target = this.scope12Target;
    if (target === GQSbtScope1And2Target.WellBelowTwoC) {
      return {
        type: 'fail',
        target,
        reason: 'Well below 2°C ambition is not supported by scope 1 + 2 SBTs.',
      };
    }
    return null; // null means there is no compatibility issue
  }

  /**
   * This getter and the one above are quick sanity checks to make sure that the term length and
   * ambition are compatible with each other.
   */
  public get sbtScope3TermLengthAndAmbitionCompatibility(): null | SBTAchievement<GQSbtScope3Target> {
    invariant(this.scope3Target, 'scope 3 target must be defined');
    const termLength = this.termLength;
    const target = this.scope3Target;

    invariant(
      target === GQSbtScope3Target.WellBelowTwoC ||
        target === GQSbtScope3Target.OnePointFiveC,
      'watershed only supports these two targets for scope 3'
    );

    switch (termLength) {
      case GQSbtTermLength.NearTerm:
        switch (target) {
          // SBT does not ~really~ accept scope 3 near term 1.5 C targets
          // They only allow you to reduce by 2 degrees C for near term
          case GQSbtScope3Target.WellBelowTwoC:
            break;
          case GQSbtScope3Target.OnePointFiveC:
            break;
          default:
            assertNever(target);
        }
        break;
      case GQSbtTermLength.LongTerm:
        switch (target) {
          // SBT does not allow you to reduce by well below 2 for long term plans
          case GQSbtScope3Target.WellBelowTwoC:
            return {
              type: 'fail',
              target,
              reason:
                'Well below 2°C ambition is not supported for long-term scope 3 SBTs.',
            };
          case GQSbtScope3Target.OnePointFiveC:
            break;
          default:
            assertNever(target);
        }
        break;
    }
    return null; // null means there is no compatibility issue
  }

  // TODO(ishaan): Let's verify how this works with fiscal years. We are giving people until FY26
  // to achieve the 2025 target, and we should make sure that's okay.
  public doesRenewableElectricityTargetMeetSbtCriteria<
    T extends Pick<PlanTargetForForecasting, 'emissionsTargetCustom'>,
  >(target: T): SbtTargetValidity<T> {
    const fyTs = FiscalYearlyTimeseries.fromSimpleTimeseriesForForecasting(
      target.emissionsTargetCustom
    );
    const fiscalMonth = YM.month(fyTs.base);

    const interimDate = YM.make(
      fiscalMonth === 1
        ? SBT_RENEWABLE_ENERGY_INTERIM_TARGET.year
        : SBT_RENEWABLE_ENERGY_INTERIM_TARGET.year - 1,
      fiscalMonth
    );
    const valueAtInterm = fyTs.valueOrDefaultAt(interimDate, null);
    const validInterim =
      valueAtInterm !== null
        ? 100 - valueAtInterm >= SBT_RENEWABLE_ENERGY_INTERIM_TARGET.pct
        : false;
    // We use valueAt here because if the target ends in 2029, we still want to check
    // that value.
    const endDate = YM.make(
      fiscalMonth === 1
        ? SBT_RENEWABLE_ENERGY_TARGET.year
        : SBT_RENEWABLE_ENERGY_TARGET.year - 1,
      fiscalMonth
    );
    const valueAtEnd = fyTs.valueAt(endDate);
    const validEnd =
      valueAtEnd !== null
        ? 100 - valueAtEnd >= SBT_RENEWABLE_ENERGY_TARGET.pct
        : false;

    const reason = `Renewable electricity targets must procure ${SBT_RENEWABLE_ENERGY_INTERIM_TARGET.pct}% renewable energy by ${SBT_RENEWABLE_ENERGY_INTERIM_TARGET.year} and ${SBT_RENEWABLE_ENERGY_TARGET.pct}% renewable energy by ${SBT_RENEWABLE_ENERGY_TARGET.year}.`;
    return {
      target,
      type: 'renewableElectricity',
      validity:
        validInterim && validEnd
          ? TargetValidity.Valid
          : TargetValidity.Invalid,
      reason,
      interimReduction:
        valueAtInterm !== null
          ? {
              actualReduction: 100 - valueAtInterm,
              necessaryReduction: SBT_RENEWABLE_ENERGY_INTERIM_TARGET.pct,
            }
          : undefined,
      interimTargetYearInclusive: SBT_RENEWABLE_ENERGY_INTERIM_TARGET.year,
      reduction:
        valueAtEnd !== null
          ? {
              actualReduction: 100 - valueAtEnd,
              necessaryReduction: SBT_RENEWABLE_ENERGY_TARGET.pct,
            }
          : undefined,
      targetYearInclusive: SBT_RENEWABLE_ENERGY_TARGET.year,
    };
  }

  /**
   * Given a target and a scope, let's check its validity.
   */
  public targetToSbtValidityForScope<
    T extends Pick<
      PlanTargetForForecasting,
      'baseYear' | 'emissionsTargetCustom' | 'intensityType' | 'comparisonType'
    >,
  >(
    target: T,
    config: GrowthForecastConfig | null,
    scope: 'scope12' | 'scope3'
  ): SbtTargetValidity<T> {
    const maybeSbt12TermLengthAndAmbitionCompatibilityFailure =
      this.sbtScope12TermLengthAndAmbitionCompatibility;
    const maybeSbt3TermLengthAndAmbitionCompatibilityFailure =
      this.sbtScope3TermLengthAndAmbitionCompatibility;

    if (scope === 'scope12') {
      // If our SBT is not compatible with the term length and ambition, we fail.
      if (
        maybeSbt12TermLengthAndAmbitionCompatibilityFailure?.type === 'fail'
      ) {
        return SBTCalculator.generateInvalidResponse({
          target,
          reason: maybeSbt12TermLengthAndAmbitionCompatibilityFailure.reason,
        });
      }

      // Scope 12 targets must be absolute reductions.
      if (
        target.intensityType !== GQPlanTargetIntensityType.Absolute &&
        target.intensityType !== GQPlanTargetIntensityType.RenewableElectricity
      ) {
        return SBTCalculator.generateInvalidResponse({
          target,
          reason: 'Scope 1 and 2 targets must be absolute reductions.',
        });
      }

      if (
        target.intensityType === GQPlanTargetIntensityType.RenewableElectricity
      ) {
        return this.doesRenewableElectricityTargetMeetSbtCriteria(target);
      }

      // Verify the target is valid for the SBT term length. For example, near term supplier engagement
      // targets must have a target year that is within 5 years of the plan submission date.
      const { isValid, reason: targetLengthReason } =
        this.verifyTargetLengthForSbt(target);
      if (!isValid) {
        return SBTCalculator.generateInvalidResponse({
          target,
          reason: must(
            targetLengthReason,
            'reason must exist if target is invalid'
          ),
        });
      }

      // If the reduction amount is invalid, we fail.
      const {
        isValid: isValidReductionAmount,
        reason,
        reduction,
      } = this.doesNonSupplierTargetMeetYearlySbtReductionCriteria(
        { ...target, intensityType: target.intensityType },
        'scope12'
      );
      if (!isValidReductionAmount) {
        return SBTCalculator.generateInvalidResponse({
          target,
          reason,
          reduction,
        });
      } else {
        return {
          type: 'emissions',
          target,
          validity: TargetValidity.Valid,
          reason,
          reduction,
        };
      }
    } else {
      invariant(scope === 'scope3', 'unexpected scope');

      // If our SBT is not compatible with the term length and ambition, we fail.
      if (maybeSbt3TermLengthAndAmbitionCompatibilityFailure?.type === 'fail') {
        return SBTCalculator.generateInvalidResponse({
          target,
          reason: maybeSbt3TermLengthAndAmbitionCompatibilityFailure.reason,
        });
      }

      // Verify the target is valid for the SBT term length. For example, near term supplier engagement
      // targets must have a target year that is within 5 years of the plan submission date.
      const { isValid, reason: targetLengthReason } =
        this.verifyTargetLengthForSbt(target);
      if (!isValid) {
        return SBTCalculator.generateInvalidResponse({
          target,
          reason: must(
            targetLengthReason,
            'reason must exist if target is invalid'
          ),
        });
      }

      // Must be a target against a base year (not BAU)
      if (target.comparisonType !== GQPlanTargetTargetComparisonType.BaseYear) {
        return SBTCalculator.generateInvalidResponse({
          target,
          reason: 'Targets must be compared against a base year.',
        });
      }

      // No revenue targets
      if (target.intensityType === GQPlanTargetIntensityType.Revenue) {
        return SBTCalculator.generateInvalidResponse({
          target,
          reason: 'Revenue intensity targets are not allowed.',
        });
      }

      BadInputError.invariant(
        !(target.intensityType === 'Custom' && config === null),
        'A growth forecast config must be provided when the target intensity type is Custom'
      );
      // This narrows the type of config to CustomIntensityGrowthForecastConfig.
      if (config?.intensityType === 'Custom' && !config.isSbtValidated) {
        return SBTCalculator.generateInvalidResponse({
          target,
          reason: `${config.displayName} is not SBTi compatible.`,
        });
      }

      // Supplier engagement targets are only allowed for near-term plans
      const termLength = this.termLength;
      if (isIntensityTypeSupplierEngagement(target.intensityType)) {
        if (termLength !== GQSbtTermLength.NearTerm) {
          return SBTCalculator.generateInvalidResponse({
            target,
            reason:
              'Supplier engagement targets are only allowed for near-term plans.',
          });
        } else {
          return {
            type: 'supplierEngagement',
            validity: TargetValidity.Valid,
            reason:
              'Near-term SBTi plans are permitted to use supplier engagement targets.',
            target,
          };
        }
      }

      // This is the allowlist of target intensity types.
      if (
        !(
          target.intensityType === GQPlanTargetIntensityType.Absolute ||
          target.intensityType === GQPlanTargetIntensityType.GrossProfit ||
          target.intensityType === GQPlanTargetIntensityType.Headcount ||
          target.intensityType === GQPlanTargetIntensityType.Orders ||
          target.intensityType === GQPlanTargetIntensityType.Patients ||
          target.intensityType === GQPlanTargetIntensityType.Custom
        )
      ) {
        return SBTCalculator.generateInvalidResponse({
          target,
          reason: 'Invalid target type',
        });
      }

      // If the reduction amount is invalid, we fail.
      const {
        isValid: isValidReductionAmount,
        reason,
        reduction,
      } = this.doesNonSupplierTargetMeetYearlySbtReductionCriteria(
        { ...target, intensityType: target.intensityType },
        'scope3'
      );
      if (!isValidReductionAmount) {
        return SBTCalculator.generateInvalidResponse({
          target,
          reason,
          reduction,
        });
      } else {
        return {
          validity: TargetValidity.Valid,
          type: 'emissions',
          target,
          reason,
          reduction,
        };
      }
    }
  }

  /**
   * For Renewable Electricity, we have an interim point and a final point several years apart
   * which does not lend itself well to being modeled as a timeseries so we use chart datum instead
   */
  public expectedSbtChartDataForRenewableElectricityTarget({
    targetTimeseries,
  }: {
    targetTimeseries: FiscalYearlyTimeseries<number>;
  }): Array<ReduxTargetChartSeriesDatum & { kind: 'sbtTarget' }> {
    // We want the target interim year to be the beginning of FY25, which will be 2024 for
    // non-calendar fiscal years. The same logic holds for the final target year.
    const fiscalMonth = this.fiscalMonth ?? 1;
    const targetEndDate = inferStraightlineEnd(targetTimeseries);

    if (this.termLength === 'NearTerm') {
      const yearSubtractor = fiscalMonth === 1 ? 0 : 1;
      const adjustedSbtTargetYear =
        SBT_RENEWABLE_ENERGY_TARGET.year - yearSubtractor;
      const adjustedIntermSbtTargetYear =
        SBT_RENEWABLE_ENERGY_INTERIM_TARGET.year - yearSubtractor;

      // SBT target is a constant
      // If the target ends before the interim year, exclude the interim target
      // Inclusive
      const showInterimTarget =
        YM.year(targetEndDate) >= adjustedIntermSbtTargetYear;

      // If the target ends before the SBT target year, display the SBT target in the last year of the target
      const targetYear = Math.min(
        adjustedSbtTargetYear,
        YM.year(targetEndDate)
      );

      return [
        showInterimTarget
          ? {
              x: adjustedIntermSbtTargetYear,
              y: SBT_RENEWABLE_ENERGY_INTERIM_TARGET.pct,
              kind: 'sbtTarget' as const,
              actualXRange: new FiscalYear(
                YM.make(adjustedIntermSbtTargetYear, fiscalMonth),
                YM.make(adjustedIntermSbtTargetYear + 1, fiscalMonth)
              ),
            }
          : null,
        {
          x: targetYear,
          y: SBT_RENEWABLE_ENERGY_TARGET.pct,
          kind: 'sbtTarget' as const,
          actualXRange: new FiscalYear(
            YM.make(targetYear, fiscalMonth),
            YM.make(targetYear + 1, fiscalMonth)
          ),
        },
      ].filter(isNotNullish);
    } else {
      // LongTerm case
      return [
        {
          x: YM.year(targetEndDate),
          y: SBT_RENEWABLE_ENERGY_TARGET.pct,
          kind: 'sbtTarget' as const,
          actualXRange: new FiscalYear(
            targetEndDate,
            YM.plus(targetEndDate, 1, 'year')
          ),
        },
      ];
    }
  }

  /**
   * Returns the final percentage of emissions relative to the baseline needed
   * for SBT alignment.
   */
  public expectedSbtFinalPercentageForTarget({
    baseYear,
    intensityType,
    config,
    targetEndDate,
    scope,
  }: {
    baseYear: YearMonth;
    intensityType: GQPlanTargetIntensityType;
    config: GrowthForecastConfig | null;
    targetEndDate: YearMonth;
    scope: 'scope12' | 'scope3';
  }): number | null {
    // These target types are not SBT eligible.
    if (
      intensityType === 'SupplierEngagement' ||
      intensityType === 'SupplierEngagementBySpend' ||
      intensityType === 'Revenue'
    ) {
      return null;
    }

    // The logic for renewable electricity is inverted to other types i.e. more
    // renewable electricity is better.
    if (intensityType === 'RenewableElectricity') {
      return 100;
    }

    BadInputError.invariant(
      !(intensityType === 'Custom' && config === null),
      'A growth forecast config must be provided when the target intensity type is Custom'
    );
    // This narrows the type of config to CustomIntensityGrowthForecastConfig.
    if (config?.intensityType === 'Custom' && !config.isSbtValidated) {
      return null;
    }

    const targetCategory = getSBTCategoryForTarget({ intensityType });
    switch (targetCategory) {
      case PlanTargetSBTCategory.RenewableElectricity:
        return 100;
      case PlanTargetSBTCategory.Supplier:
        return (1 - NEAR_TERM_SCOPE_3_REQUIRED_COVERAGE_FRACTION) * 100;
      case PlanTargetSBTCategory.Absolute:
        switch (this.termLength) {
          case 'NearTerm':
            const { linearAnnualReduction } =
              this.getSbtLinearAnnualReductionRateForAbsoluteTarget({
                baseYearStartYm: baseYear,
                targetYearInclusiveStartYm: YM.minus(targetEndDate, 1, 'year'),
                scope,
              });
            const numStepsToNearTermTarget =
              YM.diff(targetEndDate, baseYear, 'year') - 1;
            return linearAnnualReduction * numStepsToNearTermTarget;
          case 'LongTerm':
            return (
              100 *
              SBTCalculator.getLongTermTargetRequiredReductionPercentage({
                sbtTargetCategory: PlanTargetSBTCategory.Absolute,
              })
            );
          default:
            assertNever(this.termLength);
        }
      case PlanTargetSBTCategory.PhysicalIntensity:
      case PlanTargetSBTCategory.EconomicIntensity:
        switch (this.termLength) {
          case 'NearTerm':
            const numStepsToNearTermTarget =
              YM.diff(targetEndDate, baseYear, 'year') - 1;
            const { yoyReduction } =
              SBTCalculator.getSbtCompoundingReductionPctForNearTermIntensityTarget(
                {
                  baseYear,
                  numYears: numStepsToNearTermTarget,
                  isCalendarYear: YM.month(targetEndDate) === 1,
                }
              );
            return 100 - Math.pow(yoyReduction, numStepsToNearTermTarget) * 100;
          case 'LongTerm':
            return (
              100 *
              SBTCalculator.getLongTermTargetRequiredReductionPercentage({
                sbtTargetCategory: targetCategory,
              })
            );
          default:
            assertNever(this.termLength);
        }

      default:
        assertNever(targetCategory);
    }
  }

  /**
   * Given a target and some scope information, output what the expected target timeseries should be.
   * In the new redux tool, this is the green line.
   */
  public expectedSbtTimeseriesForTarget({
    target,
    scope,
    baselineEmissions,
    growthFactorForIntervalFn,
  }: {
    target: Pick<
      PlanTargetForForecasting,
      | 'emissionsTargetCustom'
      | 'intensityType'
      | 'customIntensityConfigId'
      | 'baseYear'
    >;
    scope: 'scope12' | 'scope3';
    baselineEmissions: number;
    growthFactorForIntervalFn: (
      growthFactor: GrowthFactorIdentifier,
      interval: YMInterval
    ) => number;
  }): FiscalYearlyTimeseries<number> | null {
    const { isValid } = this.verifyTargetLengthForSbt(target);
    if (!isValid) {
      // if we don't have a valid target, we can't create an expected timeseries
      // beacuse the bounds need to be changed
      return null;
    }
    const tsInfo = inferTimeseriesInfo(
      FiscalYearlyPercentageTimeseries.fromSimpleTimeseriesForForecasting(
        target.emissionsTargetCustom
      )
    );
    const intensityType = target.intensityType;
    // We do not have an expected timeseries for these kinds of targets at this point
    if (
      intensityType === 'SupplierEngagement' ||
      intensityType === 'SupplierEngagementBySpend' ||
      intensityType === 'Revenue' ||
      // For Renewable Electricity, we have an interim point and a final point which does not
      // lend itself well to being modeled as a timeseries
      intensityType === 'RenewableElectricity'
    )
      return null;
    const targetCategory = getSBTCategoryForTarget(target);
    const truncatedTimeseries = truncateTimeseriesToStraightlineEnd(
      FiscalYearlyTimeseries.fromSimpleTimeseriesForForecasting(
        target.emissionsTargetCustom
      )
    );
    invariant(
      targetCategory !== 'Supplier',
      'filtered out these target on previous line'
    );
    invariant(
      targetCategory !== 'RenewableElectricity',
      "we don't support renewable electricity expected timeseries"
    );
    switch (targetCategory) {
      case PlanTargetSBTCategory.Absolute:
        switch (this.termLength) {
          case 'NearTerm':
            const { linearAnnualReduction } =
              this.getSbtLinearAnnualReductionRateForAbsoluteTarget({
                baseYearStartYm: target.baseYear,
                targetYearInclusiveStartYm: tsInfo.targetEndYear,
                scope,
              });
            return new FiscalYearlyTimeseries(
              target.baseYear,
              truncatedTimeseries.values.map(
                (_, idx) =>
                  ((100 - linearAnnualReduction * idx) / 100) *
                  baselineEmissions
              )
            );
          case 'LongTerm':
            // Returns a singleton FiscalYearlyTimeSeries with the target
            // reduction percentage at the target end year.
            const targetReductionPercentage =
              SBTCalculator.getLongTermTargetRequiredReductionPercentage({
                sbtTargetCategory: targetCategory,
              });
            return new FiscalYearlyTimeseries(tsInfo.targetEndYear, [
              baselineEmissions * (1 - targetReductionPercentage),
            ]);
          default:
            assertNever(this.termLength);
        }
      case PlanTargetSBTCategory.PhysicalIntensity:
      case PlanTargetSBTCategory.EconomicIntensity:
        invariant(
          intensityType !== 'Absolute',
          'we already should have processed absolute targets'
        );
        switch (this.termLength) {
          case 'NearTerm':
            const historicalGrowthFactor = growthFactorForIntervalFn(
              GFI.make(intensityType, target.customIntensityConfigId),
              FiscalYear.fromStartYearMonth(target.baseYear)
            );
            const baseYearIntensity =
              baselineEmissions / historicalGrowthFactor;
            const numYears = YM.diff(
              tsInfo.targetEndYear,
              target.baseYear,
              'year'
            );
            const { yoyReduction } =
              SBTCalculator.getSbtCompoundingReductionPctForNearTermIntensityTarget(
                {
                  numYears,
                  baseYear: target.baseYear,
                  isCalendarYear: YM.month(target.baseYear) === 1,
                }
              );
            return new FiscalYearlyTimeseries(
              target.baseYear,
              truncatedTimeseries.values.map(
                (_, idx) =>
                  Math.pow(yoyReduction, idx) *
                  baseYearIntensity *
                  growthFactorForIntervalFn(
                    GFI.make(intensityType, target.customIntensityConfigId),
                    FiscalYear.fromStartYearMonth(
                      YM.plus(target.baseYear, idx, 'year')
                    )
                  )
              )
            );
          case 'LongTerm':
            // Returns a singleton FiscalYearlyTimeSeries with the target
            // reduction percentage at the target end year.
            const targetReductionPercentage =
              SBTCalculator.getLongTermTargetRequiredReductionPercentage({
                sbtTargetCategory: targetCategory,
              });
            return new FiscalYearlyTimeseries(tsInfo.targetEndYear, [
              baselineEmissions * (1 - targetReductionPercentage),
            ]);
          default:
            assertNever(this.termLength);
        }
      default:
        assertNever(targetCategory);
    }
  }

  /**
   * SBT targets are supposed to reduce by a certain amount to be valid. This function takes
   * a target + the term length to check if that's valid.
   */
  public doesNonSupplierTargetMeetYearlySbtReductionCriteria(
    // TODO(ishaan): Split this and all callers out into `emissionsTimeseries` and `intensityType
    planTarget: Pick<
      PlanTargetForForecasting,
      'intensityType' | 'emissionsTargetCustom'
    > & {
      intensityType: Exclude<GQPlanTargetIntensityType, 'Revenue'>;
    },
    scope: 'scope12' | 'scope3'
  ): {
    isValid: boolean;
    reason: string;
    reduction: ActualAndNecessaryReduction;
  } {
    const targetCategory = getSBTCategoryForTarget(planTarget);
    const baseYear = YM.fromJSDate(planTarget.emissionsTargetCustom.base);
    invariant(
      targetCategory !== PlanTargetSBTCategory.Supplier,
      'should have already been filtered out'
    );
    invariant(
      targetCategory !== 'RenewableElectricity',
      "we don't support renewable electricity expected timeseries"
    );
    switch (this.termLength) {
      case GQSbtTermLength.NearTerm:
        // ts that are stored in the db are rounded to X decimal places,
        // and we calculate numbers like Math.pow(0.93, Y) in this logic. Even
        // if someone inputs a 7% year-over-year reduction, the ts values will
        // not exactly equal Math.pow(0.93, Y) because of rounding. We can probably
        // come up with a smarter solution here, but we're going to say that if they are
        // within 0.00000001 of each other, a is not actually greater than b.
        const closeToGreaterThan = (
          tsValue: number,
          calculatedNumber: number
        ) => {
          if (tsValue > calculatedNumber) {
            return !(Math.abs(tsValue - calculatedNumber) < 0.00000001);
          }
          return false;
        };

        const tsValues = planTarget.emissionsTargetCustom.values;
        const tsInfo = inferTimeseriesInfo(
          FiscalYearlyPercentageTimeseries.fromSimpleTimeseriesForForecasting(
            planTarget.emissionsTargetCustom
          )
        );

        const numYears = YM.diff(tsInfo.targetEndYear, baseYear, 'year');

        // Targets can fall into three different categories:
        // 1. Absolute reductions - these require that you are reducing emissions
        //    by the amount specified by the scope 3 target (1.5 degress or well below 2)
        //    each year.
        // 2. Economic intensity targets - these MUST be gross profit targets. SBT will not
        //    accept revenue intensity targets. Must reduce by 7% compounded each year.
        // 3. Physical intensity targets - Must reduce by 7% compounded each year.
        let finalNecessaryReductionAmount;
        let reason;
        switch (targetCategory) {
          case PlanTargetSBTCategory.Absolute:
            const {
              linearAnnualReduction,
              affectedByForwardLookingAmbition,
              nonForwardLookingAmbitionLinearAnnualReduction,
            } = this.getSbtLinearAnnualReductionRateForAbsoluteTarget({
              baseYearStartYm: baseYear,
              targetYearInclusiveStartYm: tsInfo.targetEndYear,
              scope,
            });
            finalNecessaryReductionAmount = numYears * linearAnnualReduction;
            reason = `SBTi ${
              affectedByForwardLookingAmbition ? 'usually ' : ''
            }requires a minimum of ${
              nonForwardLookingAmbitionLinearAnnualReduction ?? // if it doesn't exist, that means we're not affected by forward-looking ambition
              linearAnnualReduction
            }% linear absolute reduction each year. ${
              affectedByForwardLookingAmbition
                ? `However, because your baseline year starts after ${SBTI_FORWARD_LOOKING_AMBITION_START_YEAR}, your targets are affected by SBTi's minimum ambition rules. Based on ${
                    YM.month(baseYear) !== 1
                      ? "the calendar year your target's fiscal year majority falls in"
                      : 'your target year'
                    // TODO: i18n (please resolve or remove this TODO line if legit)
                    // eslint-disable-next-line @watershed/require-locale-argument
                  }, your minimum linear annual reduction is ${formatPercentageNonzero(
                    linearAnnualReduction / 100,
                    { maximumFractionDigits: 1, includeTrailingZeros: true }
                  )}. `
                : ''
              // TODO: i18n (please resolve or remove this TODO line if legit)
              // eslint-disable-next-line @watershed/require-locale-argument
            }As a result, your minimum aggregate absolute reduction is ${formatPercentageNonzero(
              finalNecessaryReductionAmount / 100,
              { maximumFractionDigits: 1, includeTrailingZeros: true }
            )} over ${numYears} years.`;

            // tsInfo.targetEndYear is inclusive of the end year
            for (let i = 0; i <= numYears; i += 1) {
              const requiredPercentForAbsoluteTarget =
                100 - linearAnnualReduction * i;
              if (
                closeToGreaterThan(
                  tsValues[i],
                  requiredPercentForAbsoluteTarget
                )
              ) {
                return {
                  isValid: false,
                  reason,
                  reduction: {
                    actualReduction: tsInfo.percentChange,
                    necessaryReduction: finalNecessaryReductionAmount,
                  },
                };
              }
            }
            finalNecessaryReductionAmount = linearAnnualReduction * numYears;
            break;
          case PlanTargetSBTCategory.EconomicIntensity:
          case PlanTargetSBTCategory.PhysicalIntensity:
            // This would be .93 if our target reduces 7%.

            const {
              yoyReduction,
              affectedByForwardLookingAmbition: affectedByForwardLookingAmbitionIntensity,
            } =
              SBTCalculator.getSbtCompoundingReductionPctForNearTermIntensityTarget(
                {
                  baseYear,
                  numYears,
                  isCalendarYear: YM.month(baseYear) === 1,
                }
              );

            finalNecessaryReductionAmount =
              100 * (1 - Math.pow(yoyReduction, numYears));

            // TODO: i18n (please resolve or remove this TODO line if legit)
            // eslint-disable-next-line @watershed/require-locale-argument
            reason = `SBTi requires a minimum of ${formatPercentageNonzero(
              1 - NEAR_TERM_YOY_REDUCTION_PCT
            )} compounded reduction each year.`;
            if (affectedByForwardLookingAmbitionIntensity) {
              reason = `${reason} However, because your baseline year starts after ${SBTI_FORWARD_LOOKING_AMBITION_START_YEAR}, your targets are affected by SBTi's minimum ambition rules. Based on ${
                YM.month(baseYear) !== 1
                  ? "the calendar year your target's fiscal year majority falls in"
                  : 'your target year'
                // TODO: i18n (please resolve or remove this TODO line if legit)
                // eslint-disable-next-line @watershed/require-locale-argument
              }, your minimum year-over-year compounded reduction is ${formatPercentageNonzero(
                1 - yoyReduction,
                { maximumFractionDigits: 1, includeTrailingZeros: true }
              )}.`;
            }
            // TODO: i18n (please resolve or remove this TODO line if legit)
            // eslint-disable-next-line @watershed/require-locale-argument
            reason = `${reason} As a result, your minimum aggregate reduction is ${formatPercentageNonzero(
              finalNecessaryReductionAmount / 100,
              { maximumFractionDigits: 1, includeTrailingZeros: true }
            )} over ${numYears} years.`;
            for (let i = 0; i <= numYears; i += 1) {
              const requiredPercentForIntensity =
                100 * Math.pow(yoyReduction, i);
              if (
                closeToGreaterThan(tsValues[i], requiredPercentForIntensity)
              ) {
                return {
                  isValid: false,
                  reason,
                  reduction: {
                    actualReduction: tsInfo.percentChange,
                    necessaryReduction: finalNecessaryReductionAmount,
                  },
                };
              }
            }
            break;

          default:
            assertNever(targetCategory);
        }
        return {
          isValid: true,
          reason,
          reduction: {
            actualReduction: tsInfo.percentChange,
            necessaryReduction: finalNecessaryReductionAmount,
          },
        };
      case GQSbtTermLength.LongTerm:
        const reductionPct =
          getPlanTargetForForecastingReductionPct(planTarget);
        const targetReductionPercentage =
          SBTCalculator.getLongTermTargetRequiredReductionPercentage({
            sbtTargetCategory: targetCategory,
          });
        switch (targetCategory) {
          case PlanTargetSBTCategory.Absolute:
            const doesPassAbsolute = reductionPct >= targetReductionPercentage;
            return {
              isValid: doesPassAbsolute,
              reason:
                'Long-term absolute targets must reduce emissions by ' +
                // TODO: i18n (please resolve or remove this TODO line if legit)
                // eslint-disable-next-line @watershed/require-locale-argument
                formatPercentageNonzero(targetReductionPercentage),
              reduction: {
                actualReduction: reductionPct * 100,
                necessaryReduction: targetReductionPercentage * 100,
              },
            };
          case PlanTargetSBTCategory.EconomicIntensity:
          case PlanTargetSBTCategory.PhysicalIntensity:
            const doesPassIntensity = reductionPct >= targetReductionPercentage;
            return {
              isValid: doesPassIntensity,
              reason:
                'Long-term intensity targets must reduce emissions by ' +
                // TODO: i18n (please resolve or remove this TODO line if legit)
                // eslint-disable-next-line @watershed/require-locale-argument
                formatPercentageNonzero(targetReductionPercentage),
              reduction: {
                actualReduction: reductionPct * 100,
                necessaryReduction: targetReductionPercentage * 100,
              },
            };
          default:
            assertNever(targetCategory);
        }
      default:
        assertNever(this.termLength);
    }
  }

  /**
   * This takes in the number of years we have to reduce (so 1 year if our timeseries array
   * length is 2) and outputs the compounding reduction percentage we need to hit in those
   * number of years, properly accounting for FLA. A yearly reduction of 5% would be returned
   * as .95.
   */
  public static getSbtCompoundingReductionPctForNearTermIntensityTarget({
    numYears,
    baseYear,
    isCalendarYear,
  }: {
    numYears: number;
    baseYear: YearMonth;
    isCalendarYear: boolean;
  }): {
    affectedByForwardLookingAmbition: boolean;
    yoyReduction: number;
  } {
    // This would be .93 if our target reduces 7%.
    const nonFlaTargetReduction = Math.pow(
      NEAR_TERM_YOY_REDUCTION_PCT,
      numYears
    );

    // If our plan is fiscal year aligned, then we should be conservative and make sure that
    // we add an extra year since our start year is in the previous year.
    // https://www.notion.so/watershedclimate/January-2024-changes-to-the-reduction-tool-36d74cf6af984e9bb5ad392bd9b7a4a9
    const fiscalYearAdjustment = // Per-SBTI, we choose the calendar year that your year is majority contained in. Therefore if you start in Jan - Jun, we
      // can use the target year as is. If you start in Jul - Dec, we need to add a year to the end year.
      YM.month(baseYear) <= 6 ? 0 : 1;
    const flaTargetReduction =
      baseYear > SBTI_FORWARD_LOOKING_AMBITION_START_YEAR_MONTH
        ? // If our base year is after the specified year (2020 as of April 2023),
          // our total reduction must take into account FLA rules.
          Math.pow(
            NEAR_TERM_YOY_REDUCTION_PCT,
            numYears +
              YM.year(baseYear) -
              SBTI_FORWARD_LOOKING_AMBITION_START_YEAR +
              fiscalYearAdjustment
          )
        : nonFlaTargetReduction;

    const affectedByFla = flaTargetReduction !== nonFlaTargetReduction;
    const actualYoyReduction = affectedByFla
      ? Math.pow(flaTargetReduction, 1 / numYears)
      : NEAR_TERM_YOY_REDUCTION_PCT;
    return {
      yoyReduction: actualYoyReduction,
      affectedByForwardLookingAmbition: affectedByFla,
    };
  }

  /**
   * An emissions timeseries is only valid if it falls in certain date ranges. This function
   * takes into account the intensity type + term length to determine if the timeseries is
   * valid.
   */
  public verifyTargetLengthForSbt(
    // TODO(ishaan): Break this out into an `emissionsTimeseries` and `intensityType`
    planTarget: Pick<
      PlanTargetForForecasting,
      'emissionsTargetCustom' | 'intensityType'
    >
  ): { isValid: boolean; reason?: string } {
    invariant(
      this.submissionYm && this.fiscalMonth,
      'we must have submission YM to verify target length'
    );
    const targetYearMonth = inferStraightlineEnd(
      // inclusive of the last year
      FiscalYearlyPercentageTimeseries.fromSimpleTimeseriesForForecasting(
        planTarget.emissionsTargetCustom
      )
    );
    invariant(
      YM.month(targetYearMonth) === this.fiscalMonth,
      'target must end in the same month as the fiscal month'
    );
    switch (this.termLength) {
      case GQSbtTermLength.NearTerm:
        const validEndInterval = this.getDateBoundsForNearTermPlanTarget({
          intensityType: planTarget.intensityType,
        });
        // handle supplier engagement targets slightly differently
        if (isIntensityTypeSupplierEngagement(planTarget.intensityType)) {
          if (!validEndInterval.contains(targetYearMonth)) {
            return {
              isValid: false,
              // TODO: i18n (please resolve or remove this TODO line if legit)
              // eslint-disable-next-line @watershed/require-locale-argument
              reason: `This supplier engagement target must end on or before ${FiscalYear.displayLabelForYearStartingAtYearMonth(
                YM.minus(validEndInterval.end, 1, 'year'),
                { short: true }
              )} to be compliant with SBT.`,
            };
          }
          return { isValid: true };
        }
        if (!validEndInterval.contains(targetYearMonth)) {
          return {
            isValid: false,
            // TODO: i18n (please resolve or remove this TODO line if legit)
            // eslint-disable-next-line @watershed/require-locale-argument
            reason: `Target end year must be between ${FiscalYear.displayLabelForYearStartingAtYearMonth(
              validEndInterval.start,
              { short: true }
              // TODO: i18n (please resolve or remove this TODO line if legit)
              // eslint-disable-next-line @watershed/require-locale-argument
            )} and ${FiscalYear.displayLabelForYearStartingAtYearMonth(
              YM.minus(validEndInterval.end, 1, 'year'),
              { short: true }
            )} to be compliant with SBT.`,
          };
        }
        return { isValid: true };
      case GQSbtTermLength.LongTerm:
        if (!(YM.year(targetYearMonth) <= 2050)) {
          return {
            isValid: false,
            reason: 'Target end year must be before 2050.',
          };
        }
        return { isValid: true };
    }
  }

  public sbtLongTermCarbonRemoval({
    baselineEmissions,
    finalEmissions,
    finalRemoval,
  }: {
    baselineEmissions: number;
    finalEmissions: number;
    // Provide a negative number please
    finalRemoval: number;
  }): SbtTargetValidity<'netZero'> {
    // 1st we check to make sure that the reductions are aligned to remove 90% of emissions.
    if (
      finalEmissions / baselineEmissions >
      1 - LONG_TERM_ABSOLUTE_REQUIRED_REDUCTION_PCT + PRECISION_THESHOLD
    ) {
      return {
        target: 'netZero',
        validity: TargetValidity.Invalid,
        reason:
          'In order to reach SBTi net zero, you must first reduce emissions by 90% from your baseline year before applying any carbon removal',
        type: 'longTermNetZero',
      };
      // 2nd we check to see that you've reached net zero
    } else if (finalRemoval < 100) {
      return {
        target: 'netZero',
        validity: TargetValidity.Invalid,
        reason: `In order to reach SBTi net zero, you must have net zero emissions but you only set removal for ${finalRemoval}% of your remaining emissions`,
        type: 'longTermNetZero',
      };
    }
    return {
      target: 'netZero',
      validity: TargetValidity.Valid,
      reason:
        'You must reduce absolute emissions by 90% and removed the remaining emissions to achieve SBTi Net Zero',
      type: 'longTermNetZero',
    };
  }

  /**
   * The reason we have intermediate values here is because the validity of a scope 3
   * supplier engagement target depends on the validity of the other targets. So, we need
   * to calculate the intermediate values first.
   */
  public sbt3AchievementIntermediateValues({
    scope3BaseYearRows,
    baselineYearEmissionsSum,
    scope3Targets,
  }: {
    scope3BaseYearRows: Array<SummarizedRow>;
    baselineYearEmissionsSum: number;
    scope3Targets: Array<PlanTargetForForecasting>;
  }): Sbt3AchievementIntermediateValues {
    /**
     * For near term, we have two goals
     * 1. verify that all scope 3 non-supplier engagement targets have a linear annual
     *    reduction of 2.5% or greater
     * 2. verify that (a + b) / c >= 2/3 where
     *    a = sum(supplier engagement target reduction pct * addressable base year emissions)
     *        ^ for each supplier engagement target
     *    b = sum(addressable base year emissions)
     *        ^ for each non-supplier engagement target scope 3 base year emission
     *    c = total scope 3 base year emissions
     */

    // a
    let supplierSum = 0;
    // b
    let nonSupplierTargetSum = 0;
    // c
    const totalScope3Emissions = sumBy(scope3BaseYearRows, (row) => row.value);

    // You do not need to set a scope 3 near term target if your scope 3 is
    // not more than 40% of your emission
    const termLength = this.termLength;
    if (
      termLength === GQSbtTermLength.NearTerm &&
      totalScope3Emissions / baselineYearEmissionsSum <
        REQUIRED_SCOPE_3_EMISSIONS_PCT_FOR_SBT_TARGETS
    ) {
      return {
        isApplicable: false,
      };
    }

    const filteredTargets = scope3Targets;

    const nonSupplierEngagementTargets = new Set<PlanTargetForForecasting>();

    const targetToAmountReduced: { [targetId: string]: number } = {};
    const supplierTargetToEmissionsDetails: {
      [targetId: string]: {
        baselineYearEmissions: number;
        targetYearEmissions: number;
      };
    } = Object.fromEntries(
      filteredTargets
        .filter((t) => isIntensityTypeSupplierEngagement(t.intensityType))
        .map((t) => [
          t.id,
          {
            baselineYearEmissions: 0,
            targetYearEmissions: 0,
          },
        ])
    );

    let hasSupplierEngagementBySpendTarget = false;
    for (const row of scope3BaseYearRows) {
      const { supplierTarget, nonSupplierTarget } =
        this.getMatchingTargetsOutOfSetForRow(filteredTargets, row);
      if (nonSupplierTarget) {
        // Prioritize non-supplier target match because it counts for more towards SBTi
        // we only want to count base year targets because that's what SBT allows
        const targetId = nonSupplierTarget.id;
        targetToAmountReduced[targetId] =
          (targetToAmountReduced[targetId] ?? 0) + row.value;
        // We want to save the total portion of the base year emissions that this target
        // is responsible for emitting (this is b from the above equation)
        nonSupplierEngagementTargets.add(nonSupplierTarget);
      } else if (supplierTarget) {
        // We want to get the total emissions for all of the emissions that will be converted
        // to SBTs.

        const reduxPct =
          getPlanTargetForForecastingReductionPct(supplierTarget);
        supplierTargetToEmissionsDetails[
          supplierTarget.id
        ].baselineYearEmissions += row.value;
        // Count SupplierEngagementBySpend as having zero emissions impact because we can't map spend to emissions
        if (
          supplierTarget.intensityType ===
          GQPlanTargetIntensityType.SupplierEngagementBySpend
        ) {
          hasSupplierEngagementBySpendTarget = true;
        } else {
          supplierTargetToEmissionsDetails[
            supplierTarget.id
          ].targetYearEmissions += row.value * reduxPct;
          supplierSum += row.value * reduxPct;
        }
      }
    }

    for (const planTarget of nonSupplierEngagementTargets) {
      invariant(
        planTarget.intensityType !== GQPlanTargetIntensityType.Revenue,
        'should not be revenue'
      );
      const targetMeetsCriteria =
        this.doesNonSupplierTargetMeetYearlySbtReductionCriteria(
          { ...planTarget, intensityType: planTarget.intensityType },
          'scope3'
        );
      invariant(targetMeetsCriteria.isValid, 'target should be valid');
      nonSupplierTargetSum += targetToAmountReduced[planTarget.id] ?? 0;
    }

    return {
      supplierSum,
      nonSupplierTargetSum,
      totalScope3Emissions,
      supplierTargetToEmissionsDetails,
      isApplicable: true,
      hasSupplierEngagementBySpendTarget,
    };
  }

  /**
   * This is a helper that helps us match a set of targets to a row, and easily split
   * them into supplier and non-supplier targets. We need to do it this way because you could
   * have supplier engagement targets intersect with non-supplier engagement targets.
   */
  private getMatchingTargetsOutOfSetForRow(
    planTargets: Array<PlanTargetForForecasting>,
    row: SummarizedRow
  ): {
    supplierTarget: PlanTargetForForecasting | null;
    nonSupplierTarget: PlanTargetForForecasting | null;
  } {
    const matchingPlanTargets = planTargets.filter(
      (planTarget) =>
        reductionFilterMatches(
          filterExpressionGroupToReductionFilter(planTarget.filters),
          row
        ) &&
        // We don't ever care about rows that match RE, so let's filter this out.
        planTarget.intensityType !== 'RenewableElectricity'
    );
    const matchingSupplierTargets = matchingPlanTargets.filter((t) =>
      isIntensityTypeSupplierEngagement(t.intensityType)
    );
    const matchingNonSupplierTargets = matchingPlanTargets.filter(
      (t) => !isIntensityTypeSupplierEngagement(t.intensityType)
    );
    invariant(
      matchingSupplierTargets.length <= 1 &&
        matchingNonSupplierTargets.length <= 1,
      'row should only match at most one non-supplier engagement target and/or one supplier engagement target'
    );
    return {
      supplierTarget: matchingSupplierTargets[0] ?? null,
      nonSupplierTarget: matchingNonSupplierTargets[0] ?? null,
    };
  }

  /**
   * Tell us whether or not our targets for scope 1 and 2 are compatible with our base year rows.
   */
  public sbt12Achievement({
    scope12BaseYearRows,
    scope12Targets,
  }: {
    scope12BaseYearRows: Array<SummarizedRow>;
    scope12Targets: Array<PlanTargetForForecasting>;
  }): SBTAchievement<GQSbtScope1And2Target> {
    invariant(
      this.scope12Target,
      'should have scope 1 and 2 target to calculate achievement'
    );
    const maybeTermAndTargetFailure =
      this.sbtScope12TermLengthAndAmbitionCompatibility;
    if (maybeTermAndTargetFailure) {
      return maybeTermAndTargetFailure;
    }
    const target = this.scope12Target;

    const targetToAmountReduced: { [targetId: string]: number } = {};
    let coveredEmissions = 0;
    for (const row of scope12BaseYearRows) {
      const { nonSupplierTarget } = this.getMatchingTargetsOutOfSetForRow(
        scope12Targets,
        row
      );
      if (nonSupplierTarget) {
        // Prioritize non-supplier target match because it counts for more towards SBTi
        // we only want to count base year targets because that's what SBT allows
        const targetId = nonSupplierTarget.id;
        targetToAmountReduced[targetId] =
          (targetToAmountReduced[targetId] ?? 0) + row.value;
      }
    }

    for (const planTargetId of scope12Targets.map((t) => t.id)) {
      coveredEmissions += targetToAmountReduced[planTargetId] ?? 0;
    }

    const totalScope12Emissions = sumBy(
      scope12BaseYearRows,
      (row) => row.value
    );

    // our covered emissions must be 95% for both near and long term
    if (coveredEmissions / totalScope12Emissions < 0.95) {
      return {
        type: 'fail',
        target,
        reason:
          'Valid targets must reduce 95% of your scope 1 and 2 emissions.',
      };
    }

    return {
      type: 'on-track',
      target,
    };
  }

  /**
   * Check out https://www.notion.so/watershedclimate/SBT-Scope-3-Checks-Slack-Thread-63335224de8642b091c9e9026949dc84
   * for some details on how this was derived.
   */
  public sbt3Achievement({
    intermediateSbt3AchievementValues,
  }: {
    intermediateSbt3AchievementValues: Sbt3AchievementIntermediateValues;
  }): SBTAchievement<GQSbtScope3Target> {
    invariant(
      this.scope3Target,
      'should have scope 3 target to calculate achievement'
    );
    const termLength = this.termLength;
    const maybeTermAndTargetFailure =
      this.sbtScope3TermLengthAndAmbitionCompatibility;
    if (maybeTermAndTargetFailure) {
      return maybeTermAndTargetFailure;
    }

    if (!intermediateSbt3AchievementValues.isApplicable) {
      return { type: 'not-applicable' };
    }

    const {
      supplierSum,
      nonSupplierTargetSum,
      totalScope3Emissions,
      hasSupplierEngagementBySpendTarget,
      supplierTargetToEmissionsDetails,
    } = intermediateSbt3AchievementValues;

    const target = this.scope3Target;
    switch (termLength) {
      case GQSbtTermLength.NearTerm:
        if (
          (supplierSum + nonSupplierTargetSum) / totalScope3Emissions <
          NEAR_TERM_SCOPE_3_REQUIRED_COVERAGE_FRACTION
        ) {
          // Could supplier engagement targets possibly bring us to the required coverage at 100% implementation?
          const maxPossibleEmissionsCoverageWithSupplierEngagement =
            nonSupplierTargetSum +
            sumBy(
              Object.values(supplierTargetToEmissionsDetails),
              (details) => details.baselineYearEmissions
            );
          const couldSupplierEngagementPossiblyCoverScope3 =
            maxPossibleEmissionsCoverageWithSupplierEngagement /
              totalScope3Emissions >=
            NEAR_TERM_SCOPE_3_REQUIRED_COVERAGE_FRACTION;
          if (
            hasSupplierEngagementBySpendTarget &&
            couldSupplierEngagementPossiblyCoverScope3
          ) {
            // The SupplierEngagementBySpendTarget *might* make this plan valid,
            // but we really don't know! #ClimateIntelligence
            return {
              type: 'undetermined',
              reason:
                'Unable to validate if Scope 3 is aligned to SBTi due to the inclusion of a spend-based supplier engagement target.',
            };
          }

          // TODO: i18n (please resolve or remove this TODO line if legit)
          // eslint-disable-next-line @watershed/require-locale-argument
          const supplierPct = formatPercentageNonzero(
            supplierSum / totalScope3Emissions,
            { maximumFractionDigits: 1, includeTrailingZeros: true }
          );
          // TODO: i18n (please resolve or remove this TODO line if legit)
          // eslint-disable-next-line @watershed/require-locale-argument
          const nonSupplierPct = formatPercentageNonzero(
            nonSupplierTargetSum / totalScope3Emissions,
            { maximumFractionDigits: 1, includeTrailingZeros: true }
          );
          // TODO: i18n (please resolve or remove this TODO line if legit)
          // eslint-disable-next-line @watershed/require-locale-argument
          const totalPct = formatPercentageNonzero(
            (supplierSum + nonSupplierTargetSum) / totalScope3Emissions,
            { maximumFractionDigits: 1, includeTrailingZeros: true }
          );

          let detailStr = '';
          if (supplierSum > 0) {
            detailStr = `Supplier engagement targets account for ${supplierPct} of your scope 3 emissions. `;
          }
          if (nonSupplierTargetSum > 0) {
            detailStr = `${detailStr}Non-supplier engagement targets account for ${nonSupplierPct} of your scope 3 emissions. `;
          }
          if (supplierSum > 0 && nonSupplierTargetSum > 0) {
            detailStr = `${detailStr}Together, these targets account for ${totalPct} of your scope 3 emissions.`;
          }

          return {
            type: 'fail',
            target,
            // TODO: i18n (please resolve or remove this TODO line if legit)
            // eslint-disable-next-line @watershed/require-locale-argument
            reason: `For near-term plans, you must cover at least ${formatPercentageNonzero(
              NEAR_TERM_SCOPE_3_REQUIRED_COVERAGE_FRACTION,
              { maximumFractionDigits: 1, includeTrailingZeros: true }
            )} of your scope 3 emissions with valid targets. ${detailStr.trimEnd()}`,
          };
        }
        break;
      case GQSbtTermLength.LongTerm:
        if (nonSupplierTargetSum / totalScope3Emissions < 0.9) {
          return {
            type: 'fail',
            target,
            reason:
              'Long-term plans must have at least 90% of scope 3 emissions covered by valid targets.',
          };
        }
    }
    return {
      type: 'on-track',
      target,
    };
  }

  /**
   * An SBT target's LAR is dependent on the emissions timeseries bound, term length, intensity,
   * and scope.
   */
  public getSbtLinearAnnualReductionRateForAbsoluteTarget({
    baseYearStartYm,
    targetYearInclusiveStartYm,
    scope,
  }: {
    baseYearStartYm: YearMonth;
    targetYearInclusiveStartYm: YearMonth;
    scope: 'scope3' | 'scope12';
  }):
    | {
        linearAnnualReduction: number;
        affectedByForwardLookingAmbition: true;
        nonForwardLookingAmbitionLinearAnnualReduction: number;
      }
    | {
        linearAnnualReduction: number;
        affectedByForwardLookingAmbition: false;
        nonForwardLookingAmbitionLinearAnnualReduction?: undefined;
      } {
    const termLength = this.termLength;
    invariant(
      termLength === GQSbtTermLength.NearTerm,
      'long term plans do not look at linear annual reductions'
    );
    const linearAnnualReduction = (() => {
      switch (scope) {
        case 'scope12':
          invariant(
            this.scope12Target === GQSbtScope1And2Target.OnePointFiveC,
            'only option for scope 12 linear annual reduction targets'
          );
          return NEAR_TERM_SCOPE_1_2_LINEAR_ANNUAL_REDUCTION;
        case 'scope3':
          invariant(
            this.scope3Target === GQSbtScope3Target.WellBelowTwoC ||
              this.scope3Target === GQSbtScope3Target.OnePointFiveC,
            'only option for scope 3 linear annual reduction targets'
          );
          switch (this.scope3Target) {
            case 'OnePointFiveC':
              return NEAR_TERM_1_POINT_5_SCOPE_3_LINEAR_ANNUAL_REDUCTION;
            case 'WellBelowTwoC':
              return NEAR_TERM_WELL_BELOW_2_SCOPE_3_LINEAR_ANNUAL_REDUCTION;
            default:
              assertNever(this.scope3Target);
          }
        default:
          assertNever(scope);
      }
    })();
    // If the base year is before June 2020, then that's majority still in 2020
    if (baseYearStartYm > SBTI_FORWARD_LOOKING_AMBITION_START_YEAR_MONTH) {
      // If our plan is fiscal year aligned, then we should be conservative and make sure that
      // we add an extra year since our start year is in the previous year.
      // https://www.notion.so/watershedclimate/January-2024-changes-to-the-reduction-tool-36d74cf6af984e9bb5ad392bd9b7a4a9
      const fiscalYearOffset =
        // Per-SBTI, we choose the calendar year that your year is majority contained in. Therefore if you start in Jan - Jun, we
        // can use the target year as is. If you start in Jul - Dec, we need to add a year to the end year.
        YM.month(targetYearInclusiveStartYm) <= 6 ? 0 : 1;
      const endYear = YM.year(targetYearInclusiveStartYm) + fiscalYearOffset;
      return {
        linearAnnualReduction:
          ((endYear - SBTI_FORWARD_LOOKING_AMBITION_START_YEAR) *
            linearAnnualReduction) /
          YM.diff(targetYearInclusiveStartYm, baseYearStartYm, 'year'),
        affectedByForwardLookingAmbition: true,
        nonForwardLookingAmbitionLinearAnnualReduction: linearAnnualReduction,
      };
    }
    return {
      linearAnnualReduction,
      affectedByForwardLookingAmbition: false,
    };
  }

  public getDateBoundsForSbtTarget({
    intensityType,
  }: {
    intensityType: GQPlanTargetIntensityType;
  }): YMInterval {
    return this.termLength === 'NearTerm'
      ? this.getDateBoundsForNearTermPlanTarget({ intensityType })
      : this.getDateBoundsForLongTermPlanTarget({ intensityType });
  }

  /**
   * Returns the exclusive-end valid interval for the long-term target.
   *
   * There are no SBTi guidelines on the minimum target year, so we just return the submission year.
   */
  private getDateBoundsForLongTermPlanTarget({
    intensityType,
  }: {
    intensityType: GQPlanTargetIntensityType;
  }): YMInterval {
    invariant(
      this.fiscalMonth && this.submissionYm,
      'fiscal month and submission date must have been passed in to construct long term target bounds'
    );

    // Renewable electricity has a 100% target by 2030 for near-term SBT and a valid long-term plan
    // is only possible if you have a valid near-term plan. Thus we import the same year bounds here.
    // For fiscal years, we require you hit it by FY30 otherwise that could be less ambitious than SBT guidance.
    if (intensityType === 'RenewableElectricity') {
      return new YMInterval(
        YM.make(SBT_RENEWABLE_ENERGY_INTERIM_TARGET.year, this.fiscalMonth),
        this.fiscalMonth === 1
          ? YM.make(SBT_RENEWABLE_ENERGY_TARGET.year + 1)
          : YM.make(SBT_RENEWABLE_ENERGY_TARGET.year, this.fiscalMonth)
      );
    }
    const fyFloorOfPlanSubmissionDate =
      FiscalYear.findStartingFiscalYearMonthForYearMonth(
        this.submissionYm,
        this.fiscalMonth
      );
    return new YMInterval(
      fyFloorOfPlanSubmissionDate,
      YM.make(2051, this.fiscalMonth)
    );
  }

  /**
   * Returns the exclusive-end valid interval for the near term target end year
   */
  private getDateBoundsForNearTermPlanTarget({
    intensityType,
  }: {
    intensityType: GQPlanTargetIntensityType;
  }): YMInterval {
    invariant(
      this.fiscalMonth && this.submissionYm,
      'fiscal month and submission date must have been passed in to construct near term target bounds'
    );
    const sbtTermLength = this.termLength;
    const sbtSubmissionDate = this.submissionYm;
    const fiscalMonth = this.fiscalMonth;
    invariant(sbtTermLength === GQSbtTermLength.NearTerm, 'only near term');

    // For fiscal years, we require you hit it by FY30 otherwise that could be less ambitious than SBT guidance.
    if (intensityType === 'RenewableElectricity') {
      return new YMInterval(
        YM.make(SBT_RENEWABLE_ENERGY_INTERIM_TARGET.year, this.fiscalMonth),
        this.fiscalMonth === 1
          ? YM.make(SBT_RENEWABLE_ENERGY_TARGET.year + 1)
          : YM.make(SBT_RENEWABLE_ENERGY_TARGET.year, this.fiscalMonth)
      );
    }
    const fyFloorOfPlanSubmissionDate =
      FiscalYear.findStartingFiscalYearMonthForYearMonth(
        sbtSubmissionDate,
        fiscalMonth
      );
    const monthsBetweenFiscalStartAndSubmission = YM.diff(
      sbtSubmissionDate,
      fyFloorOfPlanSubmissionDate,
      'month'
    );
    const isSubmissionInH1OfFiscalYear =
      monthsBetweenFiscalStartAndSubmission < 6;
    invariant(
      monthsBetweenFiscalStartAndSubmission >= 0 &&
        monthsBetweenFiscalStartAndSubmission < 12,
      'submission should always come after the year floor of the fiscal year that submissions happen'
    );

    if (isIntensityTypeSupplierEngagement(intensityType)) {
      // SBT targets must end <= 4 or 5 years after the plan submission date (4 years if the submission date is
      // in the first half of the fiscal year, 5 years if the submission date is in the second half of the fiscal year)
      return new YMInterval(
        sbtSubmissionDate,
        YM.plus(
          fyFloorOfPlanSubmissionDate,
          isSubmissionInH1OfFiscalYear ? 5 : 6,
          'year'
        )
      );
    }

    // The rule for near-term targets are:
    // if your plan was submitted in H1 of FY X, your target year must be between FY X + 4 and FY X + 9
    // if your plan was submitted in H2 of FY X, your target year must be between FY X + 5 and FY X + 10
    const minTargetYmInclusive = YM.plus(
      fyFloorOfPlanSubmissionDate,
      isSubmissionInH1OfFiscalYear ? 4 : 5,
      'year'
    );
    const maxTargetYmExclusive = YM.plus(
      fyFloorOfPlanSubmissionDate,
      isSubmissionInH1OfFiscalYear ? 10 : 11,
      'year'
    );
    return new YMInterval(minTargetYmInclusive, maxTargetYmExclusive);
  }

  // Constructor wrapper for ease
  public static fromPlanVariables<T extends PlanVariables>({
    variables,
    fiscalMonth,
  }: {
    variables: T;
    fiscalMonth?: number;
  }): SBTCalculator {
    return new SBTCalculator({
      termLength: variables.commitmentsSBTTermLength,
      submissionYm: variables.commitmentsSBTSubmissionDate,
      scope12Target: variables.commitmentsSBTScope12,
      scope3Target: variables.commitmentsSBTScope3,
      fiscalMonth,
    });
  }

  public static getLongTermTargetRequiredReductionPercentage({
    sbtTargetCategory,
  }: {
    sbtTargetCategory: PlanTargetSBTCategory;
  }): number {
    invariant(
      sbtTargetCategory !== PlanTargetSBTCategory.Supplier &&
        sbtTargetCategory !== PlanTargetSBTCategory.RenewableElectricity,
      'supplier targets are not based on reduction percentage, and renewable electricity targets are not supported yet'
    );
    switch (sbtTargetCategory) {
      case PlanTargetSBTCategory.Absolute:
        return LONG_TERM_ABSOLUTE_REQUIRED_REDUCTION_PCT;
      case PlanTargetSBTCategory.EconomicIntensity:
      case PlanTargetSBTCategory.PhysicalIntensity:
        return LONG_TERM_INTENSITY_REQUIRED_REDUCTION_PCT;
      default:
        assertNever(sbtTargetCategory);
    }
  }
}
