import compact from 'lodash/compact';
import upperFirst from 'lodash/upperFirst';

/**
 * Adds a period to the end of a string, if the string doesn't already have
 * terminal punctuation.
 */
export function addStop(v: string): string {
  const trimmed = v.trim();
  return /[.!?]['"]?$/.test(trimmed) ? trimmed : `${trimmed}.`;
}

/**
 * Takes a list of stringish values, removes the falsy ones, and joins them all
 * with spaces.
 */
export function spaceJoin(
  ...args: Array<string | undefined | null | Array<string | undefined | null>>
): string {
  return compact(args.flat()).join(' ');
}

/**
 * Trims and lowercases a string.
 */
export function trimLower(x: string): string {
  return x.trim().toLowerCase();
}

/**
 * Lowercases the first letter of a string.
 */
export function lowerFirst(x: string): string {
  return x.charAt(0).toLowerCase() + x.slice(1);
}

export interface StringSortSpec {
  top?: Array<string>;
  bottom?: Array<string>;
}

/**
 * Sorts a list of strings according to a StringSortSpec. Any strings in `top`
 * will be at the top (in order); any in `bottom` wil be at the bottom (in
 * order); and any unspecified will be in between, in alphabetical order.
 */
export function sortStringsBySpec(
  values: Array<string>,
  sortSpec?: StringSortSpec
): Array<string> {
  const alphaSortedEntries = [...values].sort();
  if (!sortSpec?.top && !sortSpec?.bottom) {
    return alphaSortedEntries;
  }

  const top = sortSpec?.top ?? [];
  const bottom = sortSpec?.bottom ?? [];
  const topSet = new Set(top);
  const bottomSet = new Set(bottom);

  const result: Array<string> = [];

  for (const value of top) {
    if (values.includes(value)) {
      result.push(value);
    }
  }

  for (const value of alphaSortedEntries) {
    if (topSet.has(value) || bottomSet.has(value)) {
      continue;
    }
    result.push(value);
  }

  for (const value of bottom) {
    if (values.includes(value)) {
      result.push(value);
    }
  }

  return result;
}

/**
 * Sorts a string array in which there may be numbers in each string.
 *
 * For example, C2.3a_C9 will be sorted before C2.3a_C10.
 */
export function sortStringsWithNumbers(strings: Array<string>): Array<string> {
  const ret = new Array(...strings);
  ret.sort(sortStringsWithNumbersComparator);
  return ret;
}

/**
 * Comparator for sorting two strings in which there may be numbers in the
 * string.
 *
 * For example, C2.3a_C9 will be sorted before C2.3a_C10.
 */
export function sortStringsWithNumbersComparator(a: string, b: string): number {
  const splitString = (input: string) =>
    input
      .split(/(\d+)/)
      .filter(Boolean)
      .map((segment) => {
        return isNaN(Number(segment)) ? segment : Number(segment);
      });

  const aSegments = splitString(a);
  const bSegments = splitString(b);

  for (let i = 0; i < Math.min(aSegments.length, bSegments.length); i++) {
    if (typeof aSegments[i] === 'number' && typeof bSegments[i] === 'number') {
      if (aSegments[i] !== bSegments[i]) {
        return aSegments[i] < bSegments[i] ? -1 : 1;
      }
    } else {
      if (aSegments[i] !== bSegments[i]) {
        return aSegments[i] < bSegments[i] ? -1 : 1;
      }
    }
  }

  if (aSegments.length !== bSegments.length) {
    return aSegments.length < bSegments.length ? -1 : 1;
  }

  return 0;
}

export function toSentenceCase(s: string): string {
  const [firstWord, ...rest] = s.split(/(?=[A-Z])/);
  return [
    upperFirst(firstWord.trim()),
    ...rest.map((word) => word.trim().toLowerCase()),
  ].join(' ');
}

export function toSentenceCaseOrNull(s: string | null): string | null {
  return s === null ? s : toSentenceCase(s);
}

/**
 * Transforms a string to snake case. The main difference from _.snakeCase is
 * that it allows for multiple underscores in a row and doesn't trim
 * underscores at the start and end. Those differences are because it's meant
 * to be used as an input mask.
 */
export function snakeCaseInputMask(input: string): string {
  return input.toLowerCase().replaceAll(/[^a-z0-9_]/g, '_');
}

/**
 * Collapse all whitespace. Mostly useful for tests
 */
export function collapseAllWhitespace(
  input: string,
  replaceWith: string = '\n'
): string {
  return input.replace(/\s+/g, replaceWith);
}

export function removeAllWhitespace(input: string): string {
  return input.replace(/\s/g, '');
}

export function toBase64(str: string): string {
  return Buffer.from(str).toString('base64').replace('=', '');
}

export function fromBase64(str: string): string {
  return Buffer.from(str, 'base64').toString('utf-8');
}

export const KGCO2E = 'kgCO₂e';

export function firstNameLastInitial(name: string): string {
  if (!name.includes(' ')) {
    return name;
  }
  const [firstName, lastName] = name.split(' ');
  return `${firstName} ${lastName.charAt(0)}`;
}

// Also known as lcs() for people grepping for that.
// if two subsequences are equal length, function returns the first one
export function longestConsecutiveSubsequence(
  string1: string,
  string2: string
): string {
  const string1Length = string1.length;
  const string2Length = string2.length;
  const dp = new Array(string1Length + 1)
    .fill(0)
    .map(() => new Array(string2Length + 1).fill(0));
  let longestSubsequenceLength = 0;
  let longestSubsequenceEndIndex = 0;
  for (let i = 1; i <= string1Length; i++) {
    for (let j = 1; j <= string2Length; j++) {
      if (string1[i - 1] === string2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
        if (dp[i][j] > longestSubsequenceLength) {
          longestSubsequenceLength = dp[i][j];
          longestSubsequenceEndIndex = i;
        }
      }
    }
  }
  return string1.slice(
    longestSubsequenceEndIndex - longestSubsequenceLength,
    longestSubsequenceEndIndex
  );
}

// To be used like localeCompare: returns 1 if a is greater than b, 0 if they're equal, and -1 otherwise.
// This function considers nulls to be the smallest string by order. e.g. null < 'any non null string' is always true.
export const nullableStringCompare = (
  a: string | null,
  b: string | null
): number => {
  if (a === b) {
    return 0;
  }
  if (a === null) {
    return -1;
  }
  if (b === null) {
    return 1;
  }
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return a.localeCompare(b);
};

export function formatCurrencyUSD(amount: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}

/**
 * Extract the json-related substring from a string.
 * We assume there is only one json object in the input string and it is
 * surrounded by curly braces.
 */
export function extractJsonString(input: string): string | null {
  const jsonStart = input.indexOf('{');
  const jsonEnd = input.lastIndexOf('}');
  if (jsonStart === -1 || jsonEnd === -1 || jsonEnd < jsonStart) {
    return null;
  }
  return input.slice(jsonStart, jsonEnd + 1);
}
