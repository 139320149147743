import { useState } from 'react';
import useThrottledEffect from './useThrottledEffect';

const STANDARD_THROTTLE_THRESHOLD_MS = 200;

export default function useThrottledState<T>(
  value: T,
  delay: number = STANDARD_THROTTLE_THRESHOLD_MS
): T {
  const [throttledValue, setThrottledValue] = useState<T>(value);
  useThrottledEffect(
    () => {
      setThrottledValue(value);
    },
    [value],
    delay
  );
  return throttledValue;
}
