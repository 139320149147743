// TODO: i18n (please resolve or remove this TODO line if legit)
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { humanize } from '@watershed/shared-universal/utils/helpers';
import { ListOption, ListOptionValueTypes, ZodFormSchemaType } from './types';
import { z } from 'zod';
import {
  UseFormReturn,
  useWatch,
  WatchObserver,
  FieldValues,
} from 'react-hook-form';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { useForm, UseFormProps } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMaybeFieldName, RTFSupportedZodTypes } from 'zod-form';
import isNotNullish from '@watershed/shared-universal/utils/isNotNullish';
import { ValueOf } from '@watershed/shared-universal/utils/utilTypes';

type ZNullish<T extends z.ZodTypeAny> =
  | z.ZodOptional<z.ZodNullable<T>>
  | z.ZodNullable<z.ZodOptional<T>>;

//TODO: add a version of this that infers values of a record and uses Object.values on it (aka an enum version)
export function getListOptionsFromValues<T extends ListOptionValueTypes>(
  values: ReadonlyArray<T>,
  displayFn = (v: T) => humanize(v?.toString() ?? 'None')
): Array<ListOption<T>> {
  return values.map((value) => ({ value, display: displayFn(value) }));
}

export function makeListOptionDisabledIfUsed<T extends ListOptionValueTypes>(
  { value, display, disabled }: ListOption<T>,
  used: boolean
): ListOption<T> {
  return {
    value,
    display: `${display}${used ? ' (in use)' : ''}`,
    disabled: disabled || used,
  };
}

export function getZodEnumValuesFromEnum<T extends Record<string, string>>(
  enumType: T
): [ValueOf<T>, ...Array<ValueOf<T>>] {
  const kindValues = Object.values(enumType) as Array<ValueOf<T>>;
  const categoryEnum: [ValueOf<T>, ...Array<ValueOf<T>>] = [
    kindValues[0],
    ...kindValues.slice(1),
  ];
  return categoryEnum;
}

/** This should only be used for things that are either nullish or not! This
    will not work as writen for things that are just .optional() or just
    .nullable(). ZodForm requires this anyway so it's okay here.*/
export function isZodTypeNullish<T extends z.ZodTypeAny>(
  zodObject: ZNullish<T> | T
): zodObject is ZNullish<T> {
  return zodObject.isNullable() && zodObject.isOptional();
}

export function isZodTypeNullable<T extends z.ZodTypeAny>(
  zodObject: z.ZodNullable<T> | T
): zodObject is z.ZodNullable<T> {
  return zodObject.isNullable();
}
export function isZodTypeOptional<T extends z.ZodTypeAny>(
  zodObject: z.ZodOptional<T> | T
): zodObject is z.ZodOptional<T> {
  return zodObject.isOptional();
}

export function isZodTypeArray(
  zodType: RTFSupportedZodTypes
): zodType is z.ZodArray<RTFSupportedZodTypes> {
  return (
    'element' in zodType &&
    // distinguishes the type as an array and not record
    'length' in zodType
  );
}

/** Avoids double wrapping if something is already nullish */
export function ensureZodFormTypeNullish<T extends z.ZodTypeAny>(
  zodObject: T | ZNullish<T>
): ZNullish<T> {
  return isZodTypeNullish(zodObject) ? zodObject : zodObject.nullish();
}

export function ensureZodTypeRequired<T extends z.ZodTypeAny>(
  zodObject:
    | T
    | z.ZodNullable<z.ZodOptional<T>>
    | z.ZodOptional<T>
    | z.ZodNullable<T>
): T {
  return isZodTypeNullable(zodObject) || isZodTypeOptional(zodObject)
    ? ensureZodTypeRequired(zodObject.unwrap())
    : zodObject;
}

export function unwrapNullishZodType(zodType: RTFSupportedZodTypes) {
  const isNullish = isZodTypeNullish(zodType);
  const unwrapped = isNullish ? zodType.unwrap().unwrap() : zodType;
  return unwrapped;
}

/** Lets us operate on a schema that may or may not be nullable */
export function maybeUnwrapAndTransform<
  Input extends z.ZodTypeAny,
  Output extends z.ZodTypeAny,
>(
  zodObject: Input | ZNullish<Input>,
  transform: (a: Input) => Output,
  transformIfNullush: true
): Output | ZNullish<Output>;
export function maybeUnwrapAndTransform<
  Input extends z.ZodTypeAny,
  Output extends z.ZodTypeAny,
>(
  zodObject: Input | ZNullish<Input>,
  transform: (a: Input) => Output,
  transformIfNullush: false
): Input | ZNullish<Input>;
export function maybeUnwrapAndTransform<
  Input extends z.ZodTypeAny,
  Output extends z.ZodTypeAny,
>(
  zodObject: Input | ZNullish<Input>,
  transform: (a: Input) => Output,
  transformIfNullish: boolean = false
): Output | ZNullish<Output> | Input | ZNullish<Input> {
  const isNullish = isZodTypeNullish(zodObject);
  const unwrapped: Input = isNullish ? zodObject.unwrap().unwrap() : zodObject;
  const shouldTransform = !isNullish || transformIfNullish;
  const transformed = shouldTransform ? transform(unwrapped) : unwrapped;
  return isNullish ? transformed.nullish() : transformed;
}

/** Get the form values either from the watch or from the form directly:
 See https://react-hook-form.com/docs/usewatch for why this exists.
*/
export function useFormValues<T extends {}>(
  form: UseFormReturn<T, any>
): Partial<T> {
  return {
    ...useWatch({
      control: form.control,
    }),
    ...form.getValues(),
  };
}

/**
 * Zod types are nested, but their type system is a bit confusing.
 * All I want is a function that will look for a description and check
 * inner types if they exist. I'd love to clean this up!
 *
 * I tried:
 *    export function getDescription<
 *      T extends { description?: string; _def: { innerType?: T } }
 *    >(t: T) {
 */
export function getDescription(t: any): string | undefined {
  if (t.description) return t.description;
  if (t._def.innerType) return getDescription(t._def.innerType);
  return undefined;
}

type WatchCallback<T extends FieldValues> = (
  values: Parameters<WatchObserver<T>>[0]
) => void;

export function useZodFormWatchCallback<T extends FieldValues>(
  cb: WatchCallback<T>,
  form: UseFormReturn<T, any>
) {
  const { watch } = form;
  const cbRef = useRef(cb);
  // uses the latest ref pattern to obviate the need for memoizing the callback
  // https://epicreact.dev/the-latest-ref-pattern-in-react/
  useLayoutEffect(() => {
    cbRef.current = cb;
  });
  useEffect(() => {
    const { unsubscribe } = watch((values) => {
      cbRef.current(values);
    });
    return () => unsubscribe();
  }, [watch]);
}

export function useZodForm<SchemaType extends ZodFormSchemaType>(
  options: { schema: SchemaType } & Omit<
    UseFormProps<z.infer<SchemaType>, any>,
    'resolver'
  >
) {
  return useZodFormWithDynamicSchema(options).form;
}

export function useZodFormWithDynamicSchema<
  SchemaType extends ZodFormSchemaType,
>({
  schema,
  ...formOptions
}: { schema: SchemaType } & Omit<
  UseFormProps<z.infer<SchemaType>, any>,
  'resolver'
>) {
  const schemaRef = useRef(schema);
  // hopefully the only usage of useForm in our app is here
  const form = useForm<z.infer<SchemaType>>({
    ...formOptions,
    // wrapping this in a function lets us use the "latest ref" for schema
    resolver: (...args) => zodResolver(schemaRef.current)(...args),
  });
  return {
    form,
    useSchema: (schema: SchemaType) => {
      useLayoutEffect(() => {
        schemaRef.current = schema;
      });
    },
  };
}

/**
 * Returns the path to a field within a form fragment.
 * @propertyName key within the current schema for the field
 * @schemaKey optional specifier for where the current fragment lives within a containing field
 */
export function useFullFieldName(
  propertyName: string,
  schemaKey?: string | number
): string {
  // Get the current path for this component within its containing schema (if any).
  const fieldName = useMaybeFieldName();
  return [
    fieldName,
    typeof schemaKey === 'number' ? `[${schemaKey}]` : schemaKey,
    propertyName,
  ]
    .filter(isNotNullish)
    .join('.');
}
