import invariant from 'invariant';
import {
  getTargetScopeFromFilterGroupExpression,
  getTargetName,
  getSurveyStatusText,
} from '../companySurveys/utils';
import DateTimeUtils from '../utils/DateTimeUtils';
import {
  EMPTY_VALUE_DASH,
  getEmissionsYearNames,
  getInitiativeNames,
  getInitiativeTypeLabel,
} from '../utils/SuppliersUtils';
import { displayableAttributeValue } from '../utils/analytics';
import assertNever from '../utils/assertNever';
import { SBT_COMMITMENT_STAGE_LABELS } from '../utils/companyUtils';
import { formatNumber, formatPercentageNonzero } from '../utils/helpers';
import isNotNullish from '../utils/isNotNullish';
import isNullish from '../utils/isNullish';
import { SupplierColumnName } from './SupplierColumnRegistry';
import { Supplier, SupplierTableRow } from './supplierTypes';
import { kgToTonnes } from '../fund/math';
import { getIndustryLabelFromIndustryCode } from '@watershed/shared-universal/industryCodes/industryCodeUtils';
import Company from '@watershed/shared-universal/companyData/Company';
import { toSentenceCase } from '../utils/stringUtils';
import capitalize from 'lodash/capitalize';
import { getValueForField } from './SupplierColumnGetters';
import { getDimension } from './SupplierFilterUtils';

export type ColumnValueFormatter<T extends Supplier> = (supplier: T) => string;
export type ColumnIndividualValueFormatter<T extends Supplier> = (
  supplier: T,
  nestedDimensions?: Array<string>
) => Array<{ rawValue: any; formattedValue: string }>;

export const formatSupplierName: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  return getValueForField('supplierName')(supplier);
};

export const formatPriority: ColumnValueFormatter<Supplier> = (supplier) => {
  return getValueForField('priority')(supplier) ?? 'None';
};

export const formatEmissionsRank: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  const rank = getValueForField('emissionsRank')(supplier);
  return isNotNullish(rank)
    ? // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      formatNumber(rank, { maximumFractionDigits: 0 })
    : EMPTY_VALUE_DASH;
};

export const formatTotalEmissions: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  const totalKgco2e = getValueForField('totalEmissions')(supplier);
  return isNotNullish(totalKgco2e)
    ? // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      formatNumber(kgToTonnes(totalKgco2e), { maximumFractionDigits: 2 })
    : EMPTY_VALUE_DASH;
};

function formatMonetaryValue(value: number | null | undefined): string {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return isNotNullish(value) ? formatNumber(value) : EMPTY_VALUE_DASH;
}

export const formatSpend: ColumnValueFormatter<Supplier> = (supplier) => {
  return formatMonetaryValue(getValueForField('spend')(supplier));
};

export const formatEmissiveSpend: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  return formatMonetaryValue(getValueForField('emissiveSpend')(supplier));
};

export const formatPercentEmissions: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return formatPercentageNonzero(
    getValueForField('percentEmissions')(supplier),
    {
      maximumFractionDigits: 2,
      includeTrailingZeros: true,
    }
  );
};

export const formatTopCategorizedEmission: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  if (
    isNullish(supplier.topCategorizedEmissionBusinessCategory) ||
    isNullish(supplier.topCategorizedEmissionKgco2e) ||
    isNullish(supplier.topCategorizedEmissionProportion)
  ) {
    return EMPTY_VALUE_DASH;
  }
  return displayableAttributeValue(
    supplier.topCategorizedEmissionBusinessCategory ??
      supplier.topCategorizedEmissionBusinessSubcategory
  );
};

export const formatDisclosures: ColumnValueFormatter<Supplier> = (supplier) => {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/no-join-commas
  return toSentenceCase(getValueForField('disclosures')(supplier).join(', '));
};

export const formatNetZero: ColumnValueFormatter<Supplier> = (supplier) => {
  const netZeroCommitmentTargetYear = getValueForField('netZero')(supplier);
  return isNotNullish(netZeroCommitmentTargetYear)
    ? `Net zero by ${netZeroCommitmentTargetYear.targetYear}`
    : 'None';
};

export const formatCarbonNeutral: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  const carbonNeutral = getValueForField('carbonNeutral')(supplier);
  return carbonNeutral
    ? `${carbonNeutral.commitmentStatus} by ${carbonNeutral.targetYear}`
    : 'None';
};

export const formatCleanEnergy: ColumnValueFormatter<Supplier> = (supplier) => {
  const { year, percentage, companyId } =
    getValueForField('cleanEnergy')(supplier);

  if (year && percentage) {
    return `${percentage}% by ${year}`;
  } else if (percentage) {
    return `Committed to ${percentage}%`;
  } else if (year) {
    return `Committed by ${year}`;
  }

  if (companyId) {
    return 'None';
  } else {
    return 'Unknown';
  }
};

export const formatSbtCommitmentStage: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  return SBT_COMMITMENT_STAGE_LABELS[
    getValueForField('sbtCommitmentStage')(supplier)
  ];
};

export const formatNotes: ColumnValueFormatter<Supplier> = (supplier) => {
  return getValueForField('notes')(supplier) ?? EMPTY_VALUE_DASH;
};

export const formatEngagementCohorts: ColumnValueFormatter<
  Supplier | SupplierTableRow
> = (supplier) => {
  // hack to check if object is a SupplierTableRow
  // if it is not, then we're not in the suppliers table and this formatter should not be used
  if ('engagementCohortsById' in supplier) {
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/no-join-commas
    return getValueForField('engagementCohorts')(supplier)
      .map((id) => supplier.engagementCohortsById[id]?.name ?? '')
      .join(', ');
  }

  // Choose to return our "empty" string value instead of error, since this is just a formatting thing.
  return EMPTY_VALUE_DASH;
};

export const formatContacts: ColumnValueFormatter<Supplier | SupplierTableRow> =
  (supplier) => {
    // hack to check if object is a SupplierTableRow
    // if it is not, then we're not in the suppliers table and this formatter should not be used
    const contacts = getValueForField('contacts')(supplier);
    if ('contactIds' in supplier) {
      // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/no-join-commas
      return contacts
        .map((_, idx) => {
          const email = supplier.contactEmails[idx];
          return `${email}`;
        })
        .join(', ');
    }
    // Choose to return our "empty" string value instead of error, since this is just a formatting thing.
    return EMPTY_VALUE_DASH;
  };

export const formatLatestDisclosureDate: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  const latestDisclosureDate = getValueForField('latestDisclosureDate')(
    supplier
  );
  return isNotNullish(latestDisclosureDate)
    ? // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      DateTimeUtils.formatDate(DateTimeUtils.fromJSDate(latestDisclosureDate))
    : EMPTY_VALUE_DASH;
};

export const formatLatestCdpDisclosurePublishingYear: ColumnValueFormatter<
  Supplier
> = (supplier) => {
  const latestCdpDisclosurePublishingYear = getValueForField(
    'latestCdpDisclosurePublishingYear'
  )(supplier);
  return isNotNullish(latestCdpDisclosurePublishingYear)
    ? latestCdpDisclosurePublishingYear.toString()
    : 'N/A';
};

export const formatSurveyState: ColumnValueFormatter<
  Supplier | SupplierTableRow
> = (supplier) => {
  return getValueForField('surveyState')(supplier);
};

export const formatEngagementTasks: ColumnValueFormatter<
  Supplier | SupplierTableRow
> = (supplier) => {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/no-join-commas
  return getValueForField('engagementTasks')(supplier)
    .map((task) => task.engagementTaskConfig.name)
    .join(', ');
};

export const formatSurveyPortalUrl: ColumnValueFormatter<
  Supplier | SupplierTableRow
> = (supplier) => {
  invariant(
    // eslint-disable-next-line no-restricted-globals
    typeof window !== 'undefined',
    'window must be defined to format survey portal urls'
  );
  const portalUrl = getValueForField('surveyPortalUrl')(supplier);
  // Choose to return our "nullish" empty string instead of error, since this is just a formatting thing.
  return portalUrl ?? EMPTY_VALUE_DASH;
};

export const formatPercentSpend: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  const percentSpend = getValueForField('percentSpend')(supplier);
  return isNotNullish(percentSpend)
    ? // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/require-locale-argument
      formatPercentageNonzero(percentSpend, {
        maximumFractionDigits: 2,
        includeTrailingZeros: true,
      })
    : EMPTY_VALUE_DASH;
};

export const formatGhgCategoryIds: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  // TODO: Figure out how ghg categories should be formatted
  // const ghgCategoryIdsNumbers = new Set(
  //   getValueForField('ghgCategoryIds')(supplier)
  //     .map((category) => category.split(' ').shift())
  //     .filter(isNotNullish)
  // );
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/no-join-commas
  return Array.from(getValueForField('ghgCategoryIds')(supplier))
    .sort()
    .join(', ');
};

export const formatTargets: ColumnValueFormatter<Supplier> = (supplier) => {
  return getValueForField('targets')(supplier) ?? EMPTY_VALUE_DASH;
};

export const formatEmissionsFactors: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  return getValueForField('emissionsFactors')(supplier) ? 'True' : 'False';
};

export const formatBeaCodes: ColumnValueFormatter<Supplier> = (supplier) => {
  // TODO: call formatIndividualBeaCodes instead?
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/no-join-commas
  return getValueForField('beaCodes')(supplier)
    .map((beaCode) => getIndustryLabelFromIndustryCode(beaCode))
    .join(', ');
};

export const formatTargetsAndCommitments: ColumnValueFormatter<Supplier> = (
  supplier
) => {
  const climateCommitmentNames = supplier.climateCommitmentDisclosures
    .flatMap((disclosure) => {
      return disclosure.climateCommitments?.map((commitment) =>
        Company.getCommitmentTitle(commitment)
      );
    })
    .filter(isNotNullish);
  const targetNames = supplier.targetDisclosures
    .flatMap((disclosure) =>
      disclosure.targets?.map((target) => {
        const scopeType = getTargetScopeFromFilterGroupExpression(
          target.filters
        );
        return getTargetName(target.name, target.baseYear, scopeType);
      })
    )
    .filter(isNotNullish);

  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/no-join-commas
  return targetNames.concat(climateCommitmentNames).join(', ');
};

export const formatInitiatives: ColumnValueFormatter<Supplier> = (supplier) => {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/no-join-commas
  return getInitiativeNames(getValueForField('initiatives')(supplier)).join(
    ', '
  );
};

export const formatFootprints: ColumnValueFormatter<Supplier> = (supplier) => {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/no-join-commas
  return getEmissionsYearNames(supplier).join(', ');
};

export const formatIndividualBeaCodes: ColumnIndividualValueFormatter<
  Supplier
> = (supplier) => {
  return getValueForField('beaCodes')(supplier).map((beaCode) => ({
    rawValue: beaCode,
    formattedValue: getIndustryLabelFromIndustryCode(beaCode),
  }));
};

export const formatIndividualEngagementCohorts: ColumnIndividualValueFormatter<
  Supplier | SupplierTableRow
> = (supplier) => {
  return getValueForField('engagementCohorts')(supplier).map((id) => {
    if ('engagementCohortsById' in supplier) {
      return {
        rawValue: id,
        formattedValue: supplier.engagementCohortsById[id]?.name ?? '',
      };
    } else {
      return { rawValue: id, formattedValue: EMPTY_VALUE_DASH };
    }
  });
};

export const formatIndividualDisclosures: ColumnIndividualValueFormatter<
  Supplier
> = (supplier) => {
  return getValueForField('disclosures')(supplier).map((climateProgress) => ({
    rawValue: climateProgress,
    formattedValue: capitalize(climateProgress),
  }));
};

export const formatIndividualTopCategorizedEmission: ColumnIndividualValueFormatter<
  Supplier
> = (supplier) => {
  if (
    isNullish(supplier.topCategorizedEmissionBusinessCategory) ||
    isNullish(supplier.topCategorizedEmissionKgco2e) ||
    isNullish(supplier.topCategorizedEmissionProportion)
  ) {
    return [];
  } else {
    return [
      {
        rawValue:
          supplier.topCategorizedEmissionBusinessCategory ??
          supplier.topCategorizedEmissionBusinessSubcategory,
        formattedValue: formatTopCategorizedEmission(supplier),
      },
    ];
  }
};

export const formatIndividualFootprints: ColumnIndividualValueFormatter<
  Supplier
> = (supplier, nestedDimensions = []) => {
  return getValueForField('footprints')(supplier).map((footprint) => ({
    rawValue: getDimension(footprint, nestedDimensions),
    formattedValue: getDimension(
      {
        ...footprint,
        expenseCategory: footprint.expenseCategory
          ? getIndustryLabelFromIndustryCode(footprint.expenseCategory)
          : 'None',
      },
      nestedDimensions
    ),
  }));
};

export const formatIndividualInitiatives: ColumnIndividualValueFormatter<
  Supplier
> = (supplier, nestedDimensions = []) => {
  return getValueForField('initiatives')(supplier).map((initiative) => ({
    rawValue: getDimension(initiative, nestedDimensions),
    formattedValue: getDimension(
      {
        ...initiative,
        initiativeType: getInitiativeTypeLabel(initiative.initiativeType),
        scopes: initiative.scopes.map((s) => capitalize(s)),
      },
      nestedDimensions
    ),
  }));
};

export const formatIndividualEngagementTasks: ColumnIndividualValueFormatter<
  Supplier
> = (supplier, nestedDimensions = []) => {
  return getValueForField('engagementTasks')(supplier).map((task) => ({
    rawValue: getDimension(task, nestedDimensions),
    formattedValue: getDimension(
      {
        ...task,
        status: getSurveyStatusText(task, 'rootCustomerReviewSubmission'),
      },
      nestedDimensions
    ),
  }));
};

export const formatIndividualTargetsAndCommitments: ColumnIndividualValueFormatter<
  Supplier
> = (supplier, nestedDimensions = []) => {
  return getValueForField('targetsAndCommitments')(supplier).map(
    (targetOrCommitment) => {
      return {
        rawValue: getDimension(targetOrCommitment, nestedDimensions),
        formattedValue: getDimension(
          {
            ...targetOrCommitment,
            intensityType: targetOrCommitment.intensityType ?? 'None',
            scopes: targetOrCommitment.scopes.map((s) => capitalize(s)),
          },
          nestedDimensions
        ),
      };
    }
  );
};

export function getFormatterForField(field: SupplierColumnName) {
  return (supplier: Supplier | SupplierTableRow): string => {
    switch (field) {
      case 'supplierName':
        return formatSupplierName(supplier);
      case 'priority':
        return formatPriority(supplier);
      case 'emissionsRank':
        return formatEmissionsRank(supplier);
      case 'totalEmissions':
        return formatTotalEmissions(supplier);
      case 'spend':
        return formatSpend(supplier);
      case 'percentEmissions':
        return formatPercentEmissions(supplier);
      case 'topCategorizedEmission':
        return formatTopCategorizedEmission(supplier);
      case 'disclosures':
        return formatDisclosures(supplier);
      case 'netZero':
        return formatNetZero(supplier);
      case 'carbonNeutral':
        return formatCarbonNeutral(supplier);
      case 'cleanEnergy':
        return formatCleanEnergy(supplier);
      case 'sbtCommitmentStage':
        return formatSbtCommitmentStage(supplier);
      case 'notes':
        return formatNotes(supplier);
      case 'engagementCohorts':
        return formatEngagementCohorts(supplier);
      case 'contacts':
        return formatContacts(supplier);
      case 'latestDisclosureDate':
        return formatLatestDisclosureDate(supplier);
      case 'latestCdpDisclosurePublishingYear':
        return formatLatestCdpDisclosurePublishingYear(supplier);
      case 'surveyState':
        return formatSurveyState(supplier);
      case 'engagementTasks':
        return formatEngagementTasks(supplier);
      case 'surveyPortalUrl':
        return formatSurveyPortalUrl(supplier);
      case 'ghgCategoryIds':
        return formatGhgCategoryIds(supplier);
      case 'targets':
        return formatTargets(supplier);
      case 'emissionsFactors':
        return formatEmissionsFactors(supplier);
      case 'percentSpend':
        return formatPercentSpend(supplier);
      case 'supplierDetailsUrl':
        return '';
      case 'beaCodes':
        return formatBeaCodes(supplier);
      case 'emissiveSpend':
        return formatEmissiveSpend(supplier);
      case 'targetsAndCommitments':
        return formatTargetsAndCommitments(supplier);
      case 'initiatives':
        return formatInitiatives(supplier);
      case 'footprints':
        return formatFootprints(supplier);
      default:
        assertNever(field);
    }
  };
}

export interface SupplierFilterOption {
  rawValue: any;
  formattedValue: string;
}

// Note - this is currently just used to map from the raw value to formatted
// value for our new filters and our old "array contains" or
// "commitmentStatusAndTargetYear" filters. Therefore, we are only implementing
// this for columns that are used in those filters.
export function getFilterOptionsGetterForDefaultFields(
  field: SupplierColumnName,
  nestedDimensions?: Array<string>
) {
  return (
    supplier: Supplier | SupplierTableRow
  ): Array<SupplierFilterOption> => {
    switch (field) {
      case 'priority':
        return [
          {
            rawValue: getValueForField('priority')(supplier),
            formattedValue: formatPriority(supplier),
          },
        ];
      case 'topCategorizedEmission':
        return formatIndividualTopCategorizedEmission(supplier);
      case 'sbtCommitmentStage':
        return [
          {
            rawValue: supplier.sbtiStage,
            formattedValue: formatSbtCommitmentStage(supplier),
          },
        ];
      case 'latestCdpDisclosurePublishingYear':
        return [
          {
            rawValue: getValueForField('latestCdpDisclosurePublishingYear')(
              supplier
            ),
            formattedValue: formatLatestCdpDisclosurePublishingYear(supplier),
          },
        ];
      case 'emissionsFactors':
        return [
          {
            rawValue: getValueForField('emissionsFactors')(supplier),
            formattedValue: formatEmissionsFactors(supplier),
          },
        ];
      case 'beaCodes':
        return formatIndividualBeaCodes(supplier);
      case 'engagementCohorts':
        return formatIndividualEngagementCohorts(supplier);
      case 'disclosures':
        return formatIndividualDisclosures(supplier);
      case 'footprints':
        return formatIndividualFootprints(supplier, nestedDimensions);
      case 'initiatives':
        return formatIndividualInitiatives(supplier, nestedDimensions);
      case 'engagementTasks':
        return formatIndividualEngagementTasks(supplier, nestedDimensions);
      case 'targetsAndCommitments':
        return formatIndividualTargetsAndCommitments(
          supplier,
          nestedDimensions
        );
      case 'supplierName':
      case 'emissionsRank':
      case 'totalEmissions':
      case 'spend':
      case 'percentEmissions':
      case 'netZero':
      case 'carbonNeutral':
      case 'cleanEnergy':
      case 'notes':
      case 'contacts':
      case 'latestDisclosureDate':
      case 'surveyState':
      case 'surveyPortalUrl':
      case 'ghgCategoryIds':
      case 'targets':
      case 'percentSpend':
      case 'supplierDetailsUrl':
      case 'emissiveSpend':
        return [];
      default:
        assertNever(field);
    }
  };
}
