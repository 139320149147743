import { ParsedUrlQueryInput } from 'querystring';
import React from 'react';

export const DEFAULT_DURATION_MS = 200;

export const transition = (...properties: Array<string | [string, number]>) =>
  // eslint-disable-next-line @watershed/no-join-commas
  properties
    .map((property) => {
      let duration = DEFAULT_DURATION_MS;
      if (typeof property !== 'string') {
        duration = property[1];
        property = property[0];
      }
      return `${property} cubic-bezier(0.33, 1, 0.68, 1) ${duration}ms`;
    })
    .join(', ');

export interface NavSection {
  name?: string;
  disabled?: boolean;
  dataTest?: string;
  subentries: Array<NavSectionItem>;
}

export interface NavSectionData extends NavSection {
  // Finance
  // Used for the "Add" button in the top level section
  onAdd?: () => void;
  // Data-test id for "Add" button
  onAddDataTest?: string;
}

interface Location {
  pathname: string;
  query?: ParsedUrlQueryInput;
  search?: string;
  hash?: string;
}

export interface NavSectionItem {
  icon?: React.ElementType;
  name: string;
  tooltip?: string;
  objectId?: string;
  location: Location;
  chip?: string | null;
  chipTooltip?: string;
  disabled?: boolean;
  hasPermission: boolean;
  openInNewTab?: boolean;
  dataTest?: string;
  menu?: {
    name: string;
    items: Array<{
      id: string;
      name: string;
      location: Location;
      disabled?: boolean;
    }>;
  };
  // Finance: Nested groups of subentries
  subentries?: Array<NavSectionItem>;
}

export interface SideBarVariant {
  navEntries: Array<NavSectionData>;
  syntheticNavEntries?: Array<NavSectionData>;
  contentAfterNavEntries?: (props: {
    appearsCollapsed: boolean;
    activeEntry: NavSectionItem | null;
  }) => JSX.Element;
}
