import { Chip, ChipProps, chipClasses, Tooltip } from '@mui/material';
import { mixinSx } from '@watershed/style/styleUtils';
import { forwardRef } from 'react';

export type PillProps = Omit<
  ChipProps,
  // We're not using the `tw` experimental Tailwind prop, which is marked as
  // optional in `MuiButtonProps`. But for some reason, when we introduced
  // `next-env.d.ts` to `ui-core`, it started causing this bizarre type error,
  // so we'll just omit it here.
  // More details:
  // https://github.com/watershed-climate/ghg/pull/40050#issuecomment-1673322341
  'tw'
> & {
  tooltip?: React.ComponentProps<typeof Tooltip>['title'];
  tooltipProps?: Omit<
    React.ComponentProps<typeof Tooltip>,
    'title' | 'children'
  >;
};

export const Pill = forwardRef<HTMLDivElement, PillProps>(function Pill(
  { tooltip, tooltipProps, sx, ...props }: PillProps,
  ref
) {
  const chip = (
    <Chip
      ref={ref}
      aria-label={typeof tooltip === 'string' ? tooltip : undefined}
      {...props}
      tabIndex={tooltip ? 0 : undefined}
      sx={mixinSx(
        {
          borderRadius: 2,
          height: 'auto',
          minWidth: 28,
          cursor: 'default',
          [`& .${chipClasses.label}`]: {
            padding: '0 8px',
          },
          [`& .${chipClasses.icon}`]: {
            width: 14,
            height: 14,
            marginLeft: '8px',
            marginRight: '-4px',
          },
          [`&.${chipClasses.sizeMedium}`]: {
            fontSize: '14px',
            lineHeight: '24px',
          },
          [`&.${chipClasses.sizeSmall}`]: {
            fontSize: '13px',
            lineHeight: '20px',
            [`& .${chipClasses.label}`]: {
              padding: '0 6px',
            },
            [`& .${chipClasses.icon}`]: {
              width: 12,
              height: 12,
              marginLeft: '6px',
              marginRight: '-2px',
            },
          },
        },
        sx
      )}
    />
  );
  return tooltip ? (
    <Tooltip {...tooltipProps} title={tooltip}>
      {chip}
    </Tooltip>
  ) : (
    chip
  );
});
