import { BadInputError } from '../errors/BadInputError';
import { GQGrowthFactorType } from '../generated/graphql';
import isIncludedIn from '../utils/isIncludedIn';
import { getBusinessMetricsKey } from './BusinessMetrics';

export interface GrowthFactorIdentifier {
  growthFactorType: GQGrowthFactorType;
  customIntensityConfigId: string | null;
}

export const GFI = Object.freeze({
  make(
    growthFactorType: GQGrowthFactorType,
    customIntensityConfigId?: string | null
  ): GrowthFactorIdentifier {
    return {
      growthFactorType,
      customIntensityConfigId: customIntensityConfigId ?? null,
    };
  },
  equals(a: GrowthFactorIdentifier, b: GrowthFactorIdentifier): boolean {
    return (
      a.growthFactorType === b.growthFactorType &&
      a.customIntensityConfigId === b.customIntensityConfigId
    );
  },
  businessMetricsKey(gfi: GrowthFactorIdentifier): string {
    return getBusinessMetricsKey(
      gfi.growthFactorType,
      gfi.customIntensityConfigId ?? undefined
    );
  },
  fromBusinessMetricsKey(key: string): GrowthFactorIdentifier {
    const [growthFactorType, customIntensityConfigId] = key.split('__');

    BadInputError.invariant(
      isIncludedIn(growthFactorType, Object.values(GQGrowthFactorType))
    );

    return {
      growthFactorType,
      customIntensityConfigId: customIntensityConfigId ?? null,
    };
  },
});
