import {
  GQBiFilterOperator,
  GQBiQueryFilter,
} from '@watershed/shared-universal/generated/graphql';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export type SupplierFilterMetric = {
  dimension: string;
  label: string;
  familyLabel?: string;
  biFilterOperators: Array<GQBiFilterOperator>;
  valueInputProps?: {
    useNumberInput?: boolean;
    useSingleSelectInput?: boolean;
    unit?: string;
  };
};

export type ParsedSupplierFilterDimension = {
  dimension: string;
  nestedDimensions: Array<string>;
};

export function parseSupplierFilterDimension(
  dimension: string
): ParsedSupplierFilterDimension {
  const [parentDimension, ...childrenDimensions] = dimension.split('.');
  return {
    dimension: parentDimension,
    nestedDimensions: childrenDimensions,
  };
}

export type ParsedSupplierFilterMetric = GQBiQueryFilter &
  ParsedSupplierFilterDimension;

export type SupplierFilterOption = string | number | boolean | null;

export function getDimension<I, R>(
  value: I,
  nestedDimensions: Array<string>
): R {
  return isEmpty(nestedDimensions) ? value : get(value, nestedDimensions);
}
