import Icon, { IconProps } from '../Icon';
const CommitmentIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M12 2a1 1 0 0 1 1 1v1.37C14.9 5.238 17.388 6 19 6h2a1 1 0 1 1 0 2h-.932l2.868 7.649A1 1 0 0 1 22.6 16.8 6.008 6.008 0 0 1 19 18a6.008 6.008 0 0 1-3.598-1.199 1 1 0 0 1-.338-1.152l2.893-7.716c-1.57-.188-3.4-.747-4.957-1.385V20h4a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2h4V6.548c-1.556.638-3.386 1.197-4.957 1.385l2.893 7.716A1 1 0 0 1 8.6 16.8 6.008 6.008 0 0 1 5 18a6.008 6.008 0 0 1-3.599-1.199 1 1 0 0 1-.337-1.152L3.932 8H3a1 1 0 0 1 0-2h2c1.612 0 4.1-.762 6-1.63V3a1 1 0 0 1 1-1ZM3.224 15.584a3.996 3.996 0 0 0 3.552 0L5 10.848l-1.776 4.736Zm14 0a3.996 3.996 0 0 0 3.552 0L19 10.848l-1.776 4.736Z"
      clipRule="evenodd"
    />
  </Icon>
);
export default CommitmentIcon;
