import { formatNumber } from './helpers';
import { DateTime } from 'luxon';
import DateTimeUtils from './DateTimeUtils';

/**
 * Very simple function to display values consistently with the same number of
 * sig digs and units.
 */
export function displayNumericValue(
  value: number | null | undefined
): string | null {
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return value ? formatNumber(value, { maximumFractionDigits: 4 }) : null;
}

export function parseDate(
  value: number | string | null | undefined
): Date | null {
  // NOTE: This function used to work entirely with Luxon objects until a MUI
  // table upgrade forced us to use native Date types. It's unclear to me if the
  // Luxon stuff is load bearing at all; it's possible it can be removed
  if (typeof value === 'string') {
    return DateTime.fromISO(value).toJSDate();
  } else {
    return value ? DateTime.fromMillis(value).setZone('utc').toJSDate() : null;
  }
}

/**
 * Displays the given Date in UTC (without regard for user's local timezone).
 * @param value The date (or null/undefined)
 * @returns A formatted string
 */
export function displayUTCDate(value: Date | null | undefined): string | null {
  // See above on Luxon vs native Dates and the MUI upgrade
  if (!value) {
    return null;
  }

  // NOTE (bryan, CALC-3936): Our dates in the backend are stored in UTC. That's
  // the date we want to display to customers - not their local time.
  // (otherwise, they upload a CSV file with 2020-01-01 and in Seattle it's
  // shown as 2019-12-31). Hence the use of `{ zone: 'utc' }` here.
  const dateTimeUtc = DateTime.fromJSDate(value, { zone: 'utc' });
  // TODO: i18n (please resolve or remove this TODO line if legit)
  // eslint-disable-next-line @watershed/require-locale-argument
  return DateTimeUtils.formatDate(dateTimeUtc, { format: 'med' });
}
