import {
  Box,
  Checkbox,
  Radio,
  RadioGroup as MUIRadioGroup,
  SxProps,
  Theme,
} from '@mui/material';
import SelectableTile, { type SelectableTileProps } from '../SelectableTile';
import { mixinSx } from '@watershed/style/styleUtils';

export type SelectableTileGroupOption<ValueType> = SelectableTileProps & {
  value: ValueType;
};

type CommonPropTypes<ValueType> = {
  options: Array<SelectableTileGroupOption<ValueType>>;
  row?: boolean;
  hideSelectAffordance?: boolean;
  numColumns?: number;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

type SingleSelectPropType<ValueType extends string | number | boolean | null> =
  {
    value: ValueType | null;
    onChange: (value: ValueType) => void;
    multiple?: false;
  } & CommonPropTypes<ValueType>;

type MultiSelectPropType<ValueType extends string | number | boolean | null> = {
  value: Array<ValueType>;
  onChange: (value: Array<ValueType>) => void;
  multiple: true;
} & CommonPropTypes<ValueType>;

const labelSx: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  // So when numColumns > 1, each tile is same height
  height: '100%',
};

export type SelectableTileGroupProps<
  ValueType extends string | number | boolean | null,
> = SingleSelectPropType<ValueType> | MultiSelectPropType<ValueType>;

export default function SelectableTileGroup<
  ValueType extends string | number | boolean | null,
>({
  options,
  value,
  onChange,
  row,
  hideSelectAffordance = false,
  multiple,
  numColumns = 1,
  disabled,
  sx,
}: SelectableTileGroupProps<ValueType>) {
  return multiple ? (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${numColumns}, minmax(0, 1fr))`}
      sx={mixinSx({ width: '100%', gap: 2 }, sx)}
      role="checkboxgroup"
    >
      {options.map((option) => (
        <Box component="label" key={String(option.value)} sx={labelSx}>
          <SelectableTile
            {...option}
            selected={value?.includes(option.value)}
            disabled={disabled}
            inputAffordance={
              <Checkbox
                sx={hideSelectAffordance ? { display: 'none' } : undefined}
                value={option.value}
                checked={value?.includes(option.value)}
                disabled={option.disabled || disabled}
                onChange={(_, checked) => {
                  checked
                    ? onChange([...(value ?? []), option.value])
                    : onChange((value ?? []).filter((v) => v !== option.value));
                }}
                data-test={`radioGroupOption-${option.value}`}
              />
            }
          />
        </Box>
      ))}
    </Box>
  ) : (
    <MUIRadioGroup
      onChange={(_, value) => onChange(value as ValueType)}
      value={value}
      row={row}
      sx={mixinSx(
        {
          flexWrap: 'nowrap',
          alignItems: 'flex-start',
          width: '100%',
          gap: 2,
          gridTemplateColumns: `repeat(${numColumns}, minmax(0, 1fr))`,
          display: 'grid',
        },
        sx
      )}
    >
      {options.map((option) => (
        <Box
          component="label"
          key={String(option.value)}
          sx={labelSx}
          data-test={`radioGroupOption-${option.value}`}
        >
          <SelectableTile
            {...option}
            disabled={option.disabled || disabled}
            selected={value === option.value}
            inputAffordance={
              <Radio
                sx={hideSelectAffordance ? { display: 'none' } : undefined}
                value={option.value}
                disabled={option.disabled || disabled}
              />
            }
          />
        </Box>
      ))}
    </MUIRadioGroup>
  );
}
