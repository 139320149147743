import {
  GQPermissionObjectType,
  GQPermissionType,
  GQUserUploadForDatasourceFragment,
} from '../generated/graphql';

import { z } from 'zod';
import { UserUploadForDatasource } from '../measurement/userUploadUtils';

// This is a combination of the ApprovalState enum and the user who's viewing the UI
export const DataGovApprovalViewState = {
  // 1a. Earliest state ex. no files have been uploaded or they're processing/errored
  CANNOT_SUBMIT: 'CANNOT_SUBMIT',
  // 1b. Cannot submit because you don't have permission
  CANNOT_SUBMIT_NO_PERMISSION: 'CANNOT_SUBMIT_NO_PERMISSION',
  // 2a. No files exist. User can submit empty data as "none to upload"
  CAN_SUBMIT_EMPTY: 'CAN_SUBMIT_EMPTY',
  // 2b. 1+ file exists, none are processing or errored, not yet approved or marked ready to approve
  // TODO: do we ever want to limit who can submit for approval? ie. approver can't or uploader must?
  CAN_SUBMIT: 'CAN_SUBMIT',
  // 3a. Marked ready to approve, but is not the approver
  SUBMITTED_BUT_NO_PERMISSION: 'SUBMITTED_BUT_NO_PERMISSION',
  // 3b. Marked ready to approve, is the approver
  SUBMITTED_HAS_PERMISSION: 'SUBMITTED_HAS_PERMISSION',
  // 3c. Is the approver, awaiting other approvers
  APPROVED_AWAITING_OTHERS: 'APPROVED_AWAITING_OTHERS',
  // TODO: 3??. Marked ready to approve and you were the submitter (just shows you're done, button disabled?)
  // TODO: 3??. If you're price gated out and don't have approvals, tooltip/dialog smth like "If you submit, it will auto-approve"
  // 4. Approver marked this as approved.
  APPROVED: 'APPROVED',
  // TODO: do we want to show anything different for data that's been "unapproved"
  // by a step earlier in the chain? leaning no, activity log should handle this
  // TODO: how should data lock affect this?
  // TODO: relatedly, measurement is marked as complete? is it the same state as data locked?
};
// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type to mirror enum behavior
export type DataGovApprovalViewState =
  (typeof DataGovApprovalViewState)[keyof typeof DataGovApprovalViewState];

export const DataGovApprovalsButtonType = {
  APPROVAL_DROPDOWN: 'APPROVAL_DROPDOWN',
  APPROVED: 'APPROVED',
  SUBMIT_FOR_APPROVAL: 'SUBMIT_FOR_APPROVAL',
};

export type DataGovDatasourceUploadsApprovalSettings = {
  approvalViewState: string; // TODO: make type of values of obj
  buttonColor?: 'primary' | undefined;
  buttonType: string; // TODO: make type of values of obj
  isDisabled: boolean;
  tooltip: string;
  variant?: 'text' | undefined;
};

export type DataGovUserPermissions = Array<DataGovUserPermission>;

type DataGovUserPermission = {
  permission: GQPermissionType;
  objectId?: string | null | undefined;
  objectType?: GQPermissionObjectType | null | undefined;
};

export type DataApprovalsFiles = Array<DataApprovalsFile>;

export type DataApprovalsFile = UserUploadForDatasource &
  Pick<GQUserUploadForDatasourceFragment, 'status' | 'processingMode'>;

////////////////////////////////////////////////////////////////////
// Types originating in the service layer
////////////////////////////////////////////////////////////////////

// Schema for the dataSnapshot field we store in the measurement approval event db
export const USER_UPLOAD_TASK_SNAPSHOT_SCHEMA = z
  .object({
    userUploadIds: z.array(z.string()),
  })
  .or(
    z.object({
      buildings: z.array(
        z.object({
          id: z.string(),
          revision: z.number().int().nonnegative(),
        })
      ),
    })
  );
export type UserUploadTaskDataSnapshot = z.infer<
  typeof USER_UPLOAD_TASK_SNAPSHOT_SCHEMA
>;

export const BUILDING_SNAPSHOT_SCHEMA = z.object({
  revision: z.number().int().nonnegative(),
});

// Utility type to extract the type from the union based on the presence of a property
type ExtractTypeWithProperty<T, K extends string> = T extends Record<K, any>
  ? T
  : never;

export function isUserUploadSnapshot(
  snapshot: UserUploadTaskDataSnapshot
): snapshot is ExtractTypeWithProperty<
  UserUploadTaskDataSnapshot,
  'userUploadIds'
> {
  return 'userUploadIds' in snapshot;
}

export function isBuildingSnapshot(
  snapshot: UserUploadTaskDataSnapshot
): snapshot is ExtractTypeWithProperty<
  UserUploadTaskDataSnapshot,
  'buildings'
> {
  return 'buildings' in snapshot;
}
