import { Select, SelectProps, Theme } from '@mui/material';
import { createStyles, makeStyles, ClassNameMap } from '@mui/styles';
import { useField } from 'formik';
import clsx from 'clsx';
import Field, { filterFieldInputProps } from './Field';
import type { FieldProps } from './Field';
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '../DataGrid/DataGrid';
import { ReactNode } from 'react';
import { getPaletteUtils } from '@watershed/style/styleUtils';

const FONT_SIZE_SMALL = 13;
const SVG_SIZE_SMALL = 12;
const SVG_RIGHT_PADDING_SMALL = 5;

const useStyles = makeStyles((theme: Theme) => {
  const paletteUtils = getPaletteUtils(theme.palette);
  return createStyles({
    field: {
      margin: 0,
      width: '100%',
    },
    inputSmall: {
      // Duplicate class selector for specificity.
      '& .MuiSelect-select.MuiSelect-select': {
        fontSize: FONT_SIZE_SMALL,
        padding: theme.spacing(0, '20px', 0, 1) + '!important',
      },
      '& .MuiSelect-icon': {
        width: SVG_SIZE_SMALL,
        height: SVG_SIZE_SMALL,
        right: SVG_RIGHT_PADDING_SMALL,
      },
    },
    inputWarning: {
      '& .MuiSelect-select': {
        boxShadow: paletteUtils.boxShadowField.warning,
      },
    },
    inputError: {
      '& .MuiSelect-select': {
        boxShadow: paletteUtils.boxShadowField.error,
      },
    },
  });
});

export interface SelectFieldProps<T = unknown>
  extends Omit<FieldProps, 'inputId'>,
    Omit<SelectProps<T>, 'label' | 'ref'> {
  id: string;
  size?: 'small' | 'medium';
  fieldChildren?: ReactNode;
}

function getInputProps<T = unknown>(
  props: SelectFieldProps<T>,
  classes: ClassNameMap
) {
  const {
    id,
    validationState,
    validationMessage,
    sublabel,
    required,
    SelectDisplayProps,
  } = props;
  return {
    // Using className on parent here is a sad hack to work around a bug in MUI
    // where classNames inside `SelectDisplayProps` aren't merged correctly,
    // which was never fixed on v4, only v5:
    // https://github.com/mui-org/material-ui/pull/23211
    className: clsx(
      classes.field,
      props.className,
      props.size === 'small' && classes.inputSmall,
      validationState === 'error' && classes.inputError,
      validationState === 'warning' && classes.inputWarning
    ),
    SelectDisplayProps: {
      id,
      'aria-invalid': validationState === 'error',
      'aria-required': required,
      'aria-describedby': clsx(
        sublabel && `${id}-sublabel`,
        validationMessage && `${id}-validationMessage`
      ),
      ...SelectDisplayProps,
    },
    MenuProps: {
      ...(props.size === 'small'
        ? {
            sx: {
              '& .MuiMenu-list': {
                paddingX: 0,
                paddingY: 0.5,
                '& .MuiMenuItem-root': {
                  fontSize: FONT_SIZE_SMALL,
                  paddingX: 1,
                  paddingY: 0.25,
                },
              },
            },
          }
        : {}),
      ...(props.MenuProps ?? {}),
    },
  };
}

export default function SelectField({
  size = 'medium',
  className,
  children,
  ...rawProps
}: SelectFieldProps) {
  const classes = useStyles();
  const [formikProps, meta] = useField({
    name: rawProps.name ?? rawProps.id,
    value: rawProps.value,
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore Formik is expecting input events
    onChange: rawProps.onChange,
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore Formik is expecting input events
    onBlur: rawProps.onBlur,
  });

  const props: SelectFieldProps = {
    validationState: meta.touched && meta.error ? 'error' : 'default',
    validationMessage: meta.touched && meta.error ? meta.error : undefined,
    ...rawProps,
  };

  const finalProps = {
    ...formikProps,
    ...filterFieldInputProps(rawProps),
    ...getInputProps({ size, ...props }, classes),
  };

  return (
    <Field {...props} inputId={rawProps.id} className={className}>
      <Select
        data-test={`select-${rawProps.id}`}
        {...finalProps}
        children={children}
        value={finalProps.multiple ? finalProps.value ?? [] : finalProps.value}
      />
    </Field>
  );
}

export function SelectFieldNonFormik<T>({
  size = 'medium',
  className,
  children,
  fieldChildren,
  ...props
}: SelectFieldProps<T>) {
  const classes = useStyles();

  return (
    <Field {...props} inputId={props.id} className={className}>
      {fieldChildren ?? (
        <Select
          data-test={`select-${props.id}`}
          {...filterFieldInputProps(props)}
          {...getInputProps({ size, ...props }, classes)}
          children={children}
        />
      )}
    </Field>
  );
}

export function DataGridSelectCell({
  id,
  value,
  field,
  size = 'medium',
  children,
  ...props
}: GridRenderEditCellParams & SelectFieldProps) {
  const classes = useStyles();
  const apiRef = useGridApiContext();

  return (
    <Select
      value={props.multiple ? value ?? [] : value}
      onChange={(event) => {
        // TODO: URGENT Please fix this by await-ing or void-ing this line.
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        apiRef.current.setEditCellValue({
          id,
          field,
          value: event.target.value,
        });
      }}
      {...getInputProps({ id, size, ...props }, classes)}
      multiple={props.multiple}
      children={children}
    />
  );
}
