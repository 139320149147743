import isNotNullish from '../utils/isNotNullish';

export function nullishAdd(
  a: number | null | undefined,
  b: number | null | undefined
): number | null {
  if (isNotNullish(a) && isNotNullish(b)) {
    return a + b;
  }
  return null;
}

export function nullishMinus(
  a: number | null | undefined,
  b: number | null | undefined
): number | null {
  if (isNotNullish(a) && isNotNullish(b)) {
    return a - b;
  }
  return null;
}

// Return null if all args are null or undefined, otherwise return the sum of the non-nullish args.
export function nullishSum(
  values: Array<number | null | undefined>
): number | null {
  const nonNullishArgs = values.filter(isNotNullish);
  if (nonNullishArgs.length === 0) {
    return null;
  }
  return nonNullishArgs.reduce((acc, val) => acc + val, 0);
}

export function nullishMultiply(
  a: number | null | undefined,
  b: number | null | undefined
): number | null {
  if (isNotNullish(a) && isNotNullish(b)) {
    return a * b;
  }
  return null;
}

export function nullishDivide(
  a: number | null | undefined,
  b: number | null | undefined
): number | null {
  if (b === 0) {
    return null;
  }
  if (isNotNullish(a) && isNotNullish(b)) {
    return a / b;
  }
  return null;
}

export function nullishRound(a: number | null | undefined): number | null {
  if (isNotNullish(a)) {
    return Math.round(a);
  }
  return null;
}

export function kgToTonnes(a: number): number {
  return a / 1000;
}

export function nullishKgToTonnes(a: number | null | undefined): number | null {
  if (isNotNullish(a)) {
    return a / 1000;
  }
  return null;
}

export function tonnesToKg(a: number): number {
  return a * 1000;
}

export function nullishTonnesToKg(a: number | null | undefined): number | null {
  if (isNotNullish(a)) {
    return tonnesToKg(a);
  }
  return null;
}
